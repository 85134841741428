<template>
	<div
		:class="['quick-search', {'dark-search': isDarkmode}]"
		tabindex="0"
		@keydown.enter="fullSearch()"
		@keydown.down="selectedResult++"
		@keydown.up="selectedResult--"
		@keydown.escape="hide">
		<dropdown class="static" v-model="isShowing">
			<div slot="show" class="input-group">
				<input
					v-model="searchString"
					class="form-control"
					:autofocus="! staticData.isTouchDevice && this.$route.name !== 'search'"
					ref="input"
					type="text"
					:placeholder="tq('PLACEHOLDER')"
					autocomplete="off"
					autocorrect="off"
					autocapitalize="off"
					spellcheck="false"
					@focus="onFocus()"
				>
				<quick-search-selector />
				<span class="input-group-btn">
					<button type="button" class="search-button" @click="fullSearch()" aria-label="search-button">
						<img :src="convertToCdnUrl('/api/dynimg/icon/search/FFFFFF')" width="16" height="16" alt="Search" />
					</button>
				</span>
			</div>
			<div :class="quickSearchResultsPanel" @touchstart="unfocusInput">
				<div :class="quickSearchResultsProducts">
					<div
						v-for="(event, index) in events"
						class="quick-search-event-item"
						:key="'event-item-' + index"
					>
						<router-link class="page-link" :to="event.link">
							<img class="page-icon" :src="priceIconCampaign" alt="campaign icon" width="16" height="16">
							{{ event.linkText }}
						</router-link>
					</div>

					<div
						v-for="(page, index) in pages"
						class="quick-search-page-item"
						:key="'page-item-' + index"
					>
						<router-link class="page-link" :to="page.link">
							<img v-if="page.icon" class="page-icon" :src="convertToCdnUrl(page.icon)" :alt="page.iconAlt" width="16" height="16">
							{{ page.linkText }}
						</router-link>
					</div>

					<div v-for="(phrase, index) in phrases" class="col-xs-12 quick-search-list-item phrase-item" :key="'phrase-item-' + index">
						<router-link class="panel panel-body secondary-link" :to="phrasePath(phrase)" @click.native="searchString = phrase.suggestion" v-html="phrase.raw">
						</router-link>
					</div>
					<quick-search-list-item
						v-for="(product, index) in products"
						:key="'search-list-item-' + index"
						:product="product"
						@click="isShowing = false; trackProductClick(product, index)"
					/>
					<div class="clearfix" />
					<div class="text-center">
						<router-link :to="searchPath">Visa fler resultat</router-link>
					</div>
				</div>
				<!-- Temporary set to false until we wanna use categories again -->
				<div v-if="false" v-show="categories" class="quick-search-results-categories py-3">
					<span class="px-4 semibold d-block">Kategorier</span>
					<div @click="isShowing = false;" v-for="(category, index) in categories" :key="index">
						<div class="px-4" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size:0.9em"><!-- @TODO: Egen komponent -->
							<hr style="border-top: 1px solid #d5e2ec">
							<!--<router-link :to="$router.route({name: 'section', params: {id: category.section.id, name: category.section.name}})">{{category.section.name}}</router-link>-->
							<template v-for="(category2, index2) in category.path">
								<span v-if="index2 > 0" :key="'category_path_span_'+index2" style="color:#97c1d9"> &#187;</span>
								<router-link
									:key="'category-path-link-' + index2"
									:to="$router.route({ name: 'category', params: {id: category2.id, name: category2.name} })"
								>
									{{category2.name}}
								</router-link>
							</template>
						</div>
					</div>
				</div>
			</div>
		</dropdown>
	</div>
</template>

<script>
import { debounce } from 'lodash';
import waja from 'waja';
import router from 'router';
import store from 'datastore';
import { mapGetters } from 'vuex';
import { t } from 'datastore/i18n';
import * as staticData from 'staticData';
import bus from 'eventbus';
import { convertToCdnUrl, getExtraProductData } from 'utils';
import businessVariables from 'businessVariables';
import { trackImpression } from 'gtm';
import { isDesktop } from 'mediaQuery';

export default {
	data () {
		return {
			products: [],
			categories: [],
			events: [],
			pages: [],
			phrases: [],
			searchString: '',
			showResults: true,
			hideTimeout: null,
			selectedResult: null,
			staticData,
			lastSuccessfulRequestTime: 0,
			convertToCdnUrl,
			getExtraProductData,
		};
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		setSearchMode (mode) {
			this.searchMode = mode;
		},
		unfocusInput () {
			if (document.activeElement === this.$refs.input) {
				document.activeElement.blur();
			}
		},
		tq: t.prefix('QUICKSEARCH'),
		doSearch: debounce(function () {
			const trimmedSearchString = this.searchString.trim();
			if (trimmedSearchString.length < 2) {
				this.products = [];
				this.categories = [];
				return;
			}
			// This might be the place to store the query for retrieval in the product carousel.

			const apiPath = 'productdiscovery/autocomplete/' + this.searchString;
			const requestTime = Date.now();
			const data = {
				touchpoint: isDesktop() ? 'DESKTOP' : 'MOBILE'
			};

			waja.get(apiPath)
				.data(data)
				.on('success', res => {
					// Om vi redan fått svar från ett tidigare utfört anrop så skippar vi detta
					if (this.lastSuccessfulRequestTime > requestTime) {
						return;
					}
					this.lastSuccessfulRequestTime = requestTime;
					this.products = res.data.products;
					this.categories = res.data.categories;
					this.phrases = res.data.phrases;
					this.phrases.forEach(phrase => {
						phrase.raw = phrase.raw.replaceAll('} {', ' ').replaceAll('{', '<span>').replaceAll('}', '</span>').replaceAll(' ', '&nbsp;')
						phrase.link = 'search?searchString=' + phrase.suggestion;
					});
				})
				.go();
		}, 200),
		fullSearch () {
			if (! this.searchString.length) {
				return;
			}
			/* Redirect till produktsida om artikelnr skrivs in. @TODO Gör till inställning.
			if (! isNaN(this.searchString)) {
				router.push('/product/' + this.searchString);
				this.showResults = false;
			} else {*/
			bus.$emit('quick-search.search', this.searchString);
			// }
		},
		standardSearch () {
			this.$store.commit('productFilters/setOrigin', 'ORGANIC');
			router.push(this.searchPath);
			//this.searchString = '';
		},
		hide () {
			this.hideTimeout = setTimeout(() => { // @TODO crazy fulhack...
				this.isShowing = false;
			}, 10);
		},
		onFocus () {
			this.show();
			this.$refs.input.select();
		},
		show () {
			setTimeout(() => { // @TODO crazy fulhack...
				if (this.hideTimeout) {
					clearTimeout(this.hideTimeout);
					this.hideTimeout = null;
				}
				this.isShowing = true;
			}, 1);
		},
		trackProductClick (product, index) {
			trackImpression([product], 'Live Search', { rating: true, stock: true, event: 'eec_productclick', position: index});
			if (product?.ticket) {
				const data = {
					touchpoint: isDesktop() ? 'DESKTOP' : 'MOBILE'
				};
				waja.get('productdiscovery/click/' + product.ticket).data(data).go();
			}
		},
		phrasePath (phrase) {
			return this.$router.route({
				name: 'search',
				params: {
					name: 'search',
					title: 'Sökresultat för ' + phrase.suggestion,
				},
				query: {
					searchString: phrase.suggestion,
				},
			});
		},
		sendTrackingImpression: debounce(products => {
			const extraData = {
				stock: true,
			};
			trackImpression(products, 'Live Search', extraData);
		}, 1000),
		doEventSearch: debounce(function () {
			const trimmedSearchString = this.searchString.trim();
			if (trimmedSearchString.length < 4) {
				this.events = [];
				return;
			}

			/* Adds searchstring to RegEx with all spaces removed */
			const regex = new RegExp('^(' + trimmedSearchString.replace(/\s/g,'') + ')', 'gi');

			const matchingEvents = [];
			for(let i = 0; i < this.currentEvents.length; i++) {
				if (this.currentEvents[i].name.replace(/\s/g,'').match(regex)) {
					matchingEvents.push(this.currentEvents[i]);
				}
			}
			this.events = matchingEvents;
		}, 200),
		doPageSearch: debounce(function () {
			const trimmedSearchString = this.searchString.trim();
			if (trimmedSearchString.length < 4) {
				this.pages = [];
				return;
			}

			/* Adds searchstring to RegEx with all spaces removed */
			const regex = new RegExp('(' + trimmedSearchString.replace(/\s/g,'') + ')', 'gi');

			const matchingPages = [];
			for(let i = 0; i < this.searchPages.length; i++) {
				if (this.searchPages[i].keyWords.replace(/\s/g,'').match(regex)) {
					matchingPages.push(this.searchPages[i]);
				}
			}
			this.pages = matchingPages;
		}, 200),
	},
	computed: {
		quickSearchResultsPanel () {
			let classes = 'panel quick-search-results-panel';
			if(! this.categories || ! this.categories.length) {
				classes += '-slim';
			}
			return classes;
		},
		quickSearchResultsProducts () {
			let classes = 'py-3 quick-search-results-products';
			if(! this.categories || ! this.categories.length) {
				classes += '-slim';
			}
			return classes;
		},
		searchPath () {
			return this.$router.route({
				name: 'search',
				params: {
					name: 'search',
					title: 'Sökresultat för ' + this.searchString,
				},
				query: {
					searchString: this.searchString,
				},
			});
		},
		isDarkmode () {
			// @DARKMODE TODO
			// return this.$store.getters['user/isDark'];
			return false;
		},
		/* Use this computed to set all current or upcoming events to set redirects in search results */
		currentEvents () {
			const now = new Date();
			const currentEvents = [];
			const events = [
				{
					name: 'Black Week',
					linkText: 'Se våra erbjudanden från Black Week',
					link: '/campaign/7866',
					start: new Date('2024/11/01 00:00:01'),
					end: new Date('2024/12/02 23:59:59'),
				},
			];

			for (let i = 0; i < events.length; i++) {
				if (now >= events[i].start && now <= events[i].end) {
					currentEvents.push(events[i]);
				}
			}

			return currentEvents.length > 0 ? currentEvents : false;
		},
		/* Use this computed to set page search results */
		searchPages () {
			// Seperate keyWords with | since spaces will be trimmed
			const pages = [
				{
					name: 'Revive',
					linkText: 'Webhallen Revive: Byt in din mobil eller iPad!',
					keyWords: 'Revive|Inbyte|byta|byta in|byt in',
					link: '/campaign/8984',
					icon: '/images/619419-revive-presentkort-logo',
					iconAlt: 'Webhallen revive logo',
				},
			];

			return pages.length > 0 ? pages : false;
		},
		priceIconCampaign () {
			return convertToCdnUrl('/img/icons/price/campaign.svg');
		},
		isShowing: {
			get () {
				return !! (this.products.length || this.events.length || this.pages.length) && this.showResults;
			},
			set (value) {
				this.showResults = value;
			},
		},
		...mapGetters({
			adminPanel: 'user/isAdminPanelShowing',
			isLoggedIn: 'user/isLoggedIn',
		}),
	},
	watch: {
		searchString () {
			this.doSearch();
			if (this.currentEvents) {
				this.doEventSearch();
			}
			if (this.searchPages) {
				this.doPageSearch();
			}
			this.showResults = true;
			this.selectedResult = null;
		},
		isShowing (to) {
			this.$emit('input', to);
			this.$emit('change', to);
		},
		value (to) {
			if (to) this.show();
			else this.hide();
		},
		products (to) {
			if (to.length < 1) {
				return;
			}
			getExtraProductData(this.products, this);
			this.sendTrackingImpression(this.products);
		},
		adminPanel (to) {
			if (to) {
				getExtraProductData(this.products, this);
			}
		},
		$route () {
			this.showResults = false;
		},
	},
	mounted () {
		bus.$on('quick-search.run-standard-search', this.standardSearch);
	},
	beforeDestroy () {
		bus.$off('quick-search.run-standard-search', this.standardSearch);
	},
};
</script>
