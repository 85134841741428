import { giftCardProductId } from 'staticData';

const redirects = [
	{ // Datorer och tillbehör > stationära > Webhallen config
		from: {
			name: 'category',
			params: {
				id: 13852,
			},
		},
		to: {
			name: 'campaign',
			params: {
				id: 4619,
				name: 'Webhallen-Config-2018',
			},
		},
	},
	{ // Datorkomponenter > config
		from: {
			name: 'category',
			params: {
				id: 13853,
			},
		},
		to: {
			name: 'campaign',
			params: {
				id: 4619,
				name: 'Webhallen-Config-2018',
			},
		},
	},
	{ // Lego-kategorin redirectar till legokampanjen
		from: {
			name: 'category',
			params: {
				id: 3144,
			},
		},
		to: {
			name: 'campaign',
			params: {
				id: 6813,
				name: 'LEGO',
			},
		},
	},
	{
		from: {
			name: 'product',
			params: {
				id: giftCardProductId,
			},
		},
		to: {
			name: 'info',
			params: {
				id: 28, // Köp presentkort
			},
		},
	},
	{ // 'Byt in din gamla mobil'-category redirect to Webhallen Revive campaign
		from: {
			name: 'category',
			params: {
				id: 16630,
			},
		},
		to: {
			name: 'campaign',
			params: {
				id: 8984,
				name: 'Webhallen-Revive',
			},
		},
	},
	{ // 'Webhallen Revive'-category redirect to Webhallen Revive campaign
		from: {
			name: 'category',
			params: {
				id: 16631,
			},
		},
		to: {
			name: 'campaign',
			params: {
				id: 8984,
				name: 'Webhallen-Revive',
			},
		},
	},
	{
		from: {
			name: 'campaign',
			params: {
				id: 6911, // BF 2017
			},
		},
		to: {
			name: 'campaign',
			params: {
				id: 7005, // BF 2018
			},
		},
	},
	{
		from: {
			name: 'campaign',
			params: {
				id: 6280, // BF 2016
			},
		},
		to: {
			name: 'campaign',
			params: {
				id: 7005, // BF 2018
			},
		},
	},
	{
		from: {
			name: 'campaign',
			params: {
				id: 6933, // Mellandagsrea
			},
		},
		to: {
			name: 'campaign',
			params: {
				id: 7894, // Mellandagsrea
			},
		},
	},
	{
		from: {
			name: 'campaign',
			params: {
				id: 7869, // Cyber Monday
			},
		},
		to: {
			name: 'campaign',
			params: {
				id: 7866, // Black Friday (blir Cyber Monday kampanjer)
			},
		},
	},
	{
		from: {
			name: 'info',
			params: {
				id: 14,  // B2B och finansiering
			},
		},
		to: {
			name: 'campaign',
			params: {
				id: 8827, // Webhallen B2B
			}
		}
	},
	// Redirect Gaming Suite - Steam Hotel category to campaign page
	{
		from: {
			name: 'category',
			params: {
				id: 16859,
			},
		},
		to: {
			name: 'campaign',
			params: {
				id: 9952,
				name: 'STEAM-Hotel',
			}
		}

	},
	// {
	// 	from: {
	// 		name: 'category',
	// 		params: {
	// 			id: 16065,
	// 		},
	// 	},
	// 	to: {
	// 		name: 'apple-cto',
	// 		params: {
	// 			id: 16065
	// 		},
	// 	},
	// },
	// {
	// 	from: {
	// 		name: 'category',
	// 		params: {
	// 			id: 16350,
	// 		},
	// 	},
	// 	to: {
	// 		name: 'apple-cto',
	// 		params: {
	// 			id: 16350
	// 		},
	// 	},
	// }

];

const now = new Date();
const blackFridayStart = new Date('2020/11/26 16:00'); // Torsdag
const blackFridayEnd = new Date('2020/11/29 23:59:59'); // Söndag
if (now >= blackFridayStart && now <= blackFridayEnd) {
	redirects.push({
		from: {
			name: 'index',
		},
		to: {
			name: 'campaign',
			params: {
				id: 7866,
				name: 'Black-Friday',
			},
		},
	});
}

// const webhallenFestivalenStart = new Date('2021/02/28 16:00'); //Söndag
// if (now >= webhallenFestivalenStart) {
// 	redirects.push({
// 		from: {
// 			name: 'campaign',
// 			params: {
// 				id: 8335,
// 			},
// 		},
// 		to: {
// 			name: 'campaign',
// 			params: {
// 				id: 8317,
// 			},
// 		},
// 	});
// }

export default redirects;
