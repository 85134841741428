<template>
	<div class="panel panel-product" :title="highlight.description">
		<router-link :to="path" class="text-center" :style="{padding: cover ? '0' : '10%'}" style="display: block; line-height: 0;">
			<ximg
				:class="[ cover ? 'cover' : 'smaller-width' ]"
				:src="imageUrl"
				:ariaLabel="highlight.category.name + ' category highlight'"
				role="img"
				:viewportMargin="1"
				@inViewport="$emit('inViewport')"
			/>
		</router-link>
		<router-link :to="path" class="p-3 highlight-info relative">
			<div class="highlight-text mr-3">
				<span v-if="highlight.category.internalName" class="fake-link">{{ highlight.category.internalName }}</span>
				<span v-else class="fake-link">{{ highlight.category.name }}</span>
				<br/>
				<span class="highlight-subtitle">{{ highlight.description || highlight.category.info || '&nbsp;' }}</span>
			</div>
		</router-link>
	</div>
</template>

<script>
import { convertToCdnUrl, urlFormat } from 'utils';
import router from 'router';

export default {
	name: 'categoryHighlight',
	props: {
		highlight: {
			type: Object,
			required: true,
		},
		cover: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		path () {
			const c = this.highlight.category;
			return router.route({
				name: 'category',
				params: {
					id: c.id,
					name: urlFormat(c.name),
				},
			});
		},
		imageUrl () {
			if (this.highlight.imageUrl) {
				return this.highlight.imageUrl + '?w=500';
			}
			return convertToCdnUrl('/api/dynimg/category/' + this.highlight.category.icon + '/1e222d');
		},
	},
};
</script>
