<template>
	<router-link
		:to="computedTo"
		:class="buttonStyleClasses"
		:replace="replace"
		:append="append"
		:tag="tag"
		:active-class="activeClass"
		:exact="exact"
		:event="event"
		:exact-active-class="exactActiveClass"
	>
		<slot />
	</router-link>
</template>

<script>
export default {
	props: {
		to: {
			type: [String, Object],
			required: true,
		},
		replace: {
			type: Boolean,
			default: false,
		},
		append: {
			type: Boolean,
			default: false,
		},
		tag: {
			type: String,
			default: 'a',
		},
		activeClass: {
			type: String,
			default: 'router-link-active',
		},
		exact: {
			type: Boolean,
			default: false,
		},
		event: {
			type: [String, Array],
			default: 'click',
		},
		exactActiveClass: {
			type: String,
			default: 'router-link-exact-active',
		},
		/* Button sizes:
			large
			normal
			small
			tiny
		*/
		size: {
			type: String,
			default: 'normal',
			required: false,
		},
		/* Button widths:
			stretch
			wide
			normal
			narrow
		*/
		width: {
			type: String,
			default: 'normal',
			required: false,
		},
		/* Button styles:
			primary
			secondary
		*/
		buttonStyle: {
			type: String,
			default: null,
		},
		/* Extra button styling properties */
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
		center: {
			type: Boolean,
			default: false,
			required: false,
		},
		dark: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	computed: {
		computedTo () {
			return this.$router.route(this.to);
		},
		buttonStyleClasses () {
			return [
				{
					'text-btn': this.buttonStyle,
					'_secondary': this.buttonStyle === 'secondary',
					'_center': this.center,
					'_disabled': this.disabled,
					'_dark': this.dark,
					'loading': this.loading,
				},
				this.size !== 'normal' ? '_' + this.size : null,
				this.width !== 'normal' ? '_' + this.width : null,
			];
		},
	},
};
</script>
