<template>
	<div class="member-nav col-md-3 p-0">
		<nav v-if="mq.current < mq.md" class="mobile-wrap">
			<ul class="nav">
				<li v-for="(group, index) in menuGroups" class="nav-item" :key="'mobile-member-nav-' + index">
					<dropdown>
						<a class="secondary-link" slot="label">
							<img
								class="member-icon"
								:src="convertToCdnUrl(group.icon)"
								:alt="group.name"
							>
							{{ group.name }}
							<span class="glyphicon glyphicon-triangle-bottom" />
						</a>
						<div class="member-menu-dropdown">
							<router-link
								v-for="item in group.items"
								class="secondary-link"
								:to="$router.route(item.route)"
								:key="item.name"
							>
								<img
									class="member-icon"
									:src="convertToCdnUrl(item.icon)"
									:alt="tm(item.label)"
								>
								{{ tm(item.label) }}
							</router-link>
						</div>
					</dropdown>
				</li>
				<li v-if="! seriousBusinessMode">
					<a class="secondary-link tutorial" @click.stop="showMemberTutorial">
						<span class="glyphicon glyphicon-question-sign"/>
					</a>
				</li>
			</ul>
		</nav>
		<nav v-else class="desktop-wrap">
			<div class="pr-3 pb-3">
				<div v-for="(group, index) in menuGroups" :key="'member-nav-' + index" class="mb-5">
					<h2 class="level-three-heading mb-3">{{ group.name }}</h2>
					<ul class="nav">
						<template v-for="item in group.items">
							<li
								v-if="! (item.name === 'member.supply-drop' && ! currentUser.rankLevel)"
								class="tile" :key="'item-' + item.label"
							>
								<router-link :to="$router.route(item.route)">
									<img
										class="member-icon"
										:src="convertToCdnUrl(item.icon)"
										:alt="tm(item.label)"
									>
									{{ tm(item.label) }}
								</router-link>
							</li>
						</template>
					</ul>
				</div>
			</div>
			<div class="mt-4" v-if="! seriousBusinessMode">
				<a href="#" class="secondary-link ml-3 semibold" @click.stop="showMemberTutorial">
					<span class="glyphicon glyphicon-question-sign"/> {{ tm('TUTORIAL') }}
				</a>
			</div>
		</nav>
		<product-list-filters class="p-4" v-if="$route.name === 'member.offers'" />
	</div>
</template>

<script>
import bus from 'eventbus';
import mq from 'mediaQuery';
import { t } from 'datastore/i18n';
import { convertToCdnUrl, memberRoute } from 'utils';
import memberMenuItems from 'memberMenuItems';

export default {
	data () {
		return {
			mq,
		};
	},
	props: ['user', 'currentUser'],
	methods: {
		tm: t.prefix('MEMBER'),
		showMemberTutorial() {
			bus.$emit('modal.component', 'member-tutorial', 'member-tutorial-modal');
		},
		convertToCdnUrl,
	},
	computed: {
		isCurrentUser () {
			return this.user.id === this.currentUser.id;
		},
		seriousBusinessMode () {
			return this.currentUser.settings.serious_business_mode;
		},
		menuGroups () {
			const groups = JSON.parse(JSON.stringify(memberMenuItems));

			for (let i = 0; i < groups.length; ++i) {
				const group = groups[i];
				for (let j = 0; j < group.items.length; ++j) {
					const item = group.items[j];
					if (item.isPersonal && ! this.isCurrentUser) {
						group.items.splice(j--, 1);
						continue;
					}
					item.route = memberRoute(this.user, item.name, item.label);
				}
				if (! group.items.length || (! group.isSeriousBusiness && this.seriousBusinessMode)) {
					groups.splice(i--, 1);
				}
			}

			return groups;
		},
	},
	watch: {
		$route () {
			bus.$emit('dropdown.close');
		},
	},
};
</script>