export default [
	{
		name: 'Min kundprofil',
		isSeriousBusiness: true,
		icon: '/img/icons/member/cart.svg',
		items: [
			{
				icon: '/img/icons/member/cart.svg',
				name: 'member.orders',
				label: 'ORDERS',
				isPersonal: true,
			},
			{
				icon: '/img/icons/member/user.svg',
				name: 'member.benefits',
				label: 'BENEFITS',
				isPersonal: true,
			},
			{
				icon: '/img/icons/member/erbjudande.svg',
				name: 'member.offers',
				label: 'OFFERS',
				isPersonal: true,
			},
			{
				icon: '/img/icons/member/heart.svg',
				name: 'member.wishlists',
				label: 'WISHLISTS',
				isPersonal: true,
			},
			{
				icon: '/img/icons/member/delbetalning.svg',
				name: 'member.wh-payments',
				label: 'PAYMENTS',
				isPersonal: true,
			},
			{
				icon: '/img/icons/member/installningar.svg',
				name: 'member.profile',
				label: 'PROFILE_SETTINGS',
				isPersonal: true,
			},
		],
	},
	{
		name: 'Medlemsfunktioner',
		isSeriousBusiness: false,
		icon: '/img/icons/member/achievements.svg',
		items: [
			{
				icon: '/img/icons/member/achievements.svg',
				name: 'member.achievements',
				label: 'ACHIEVEMENTS',
				isPersonal: false,
			},
			{
				icon: '/img/icons/member/avatar.svg',
				name: 'member.avatar',
				label: 'AVATAR',
				isPersonal: true,
			},
			{
				icon: '/img/icons/member/supplydrop.svg',
				name: 'member.supply-drop',
				label: 'SUPPLY_DROP',
				isPersonal: true,
			},
			{
				icon: '/img/icons/member/knighthood.svg',
				name: 'member.knighthood',
				label: 'KNIGHTHOOD',
				isPersonal: true,
			},
			{
				icon: '/img/icons/member/vanner.svg',
				name: 'member.friends',
				label: 'FRIENDS',
				isPersonal: true,
			},
			{
				icon: '/img/icons/member/topplistor.svg',
				name: 'member.toplist',
				label: 'TOPLIST',
				isPersonal: true,
			},
			{
				icon: '/img/icons/member/wow.svg',
				name: 'member.game',
				label: 'GAME',
				isPersonal: true,
			},
		],
	},
];
