<template>
	<footer id="footer">
		<div class="footer-wrapper">
			<div class="logo-info">
				<ximg :src="convertToCdnUrl('/img/logos/wh_logo_gold.svg')" class="footer-logo" ariaLabel="footer logo" role="img" />
				<p>{{ tf('DELIVERY_NOTICE', currencyName, showVat ? t('INCL_VAT') : t('EXCL_VAT')) }}</p>
				<dropdown-select
					v-model="vatOption"
					:items="vatOptions"
					:value="selectedVatOption"
					:placeholder="showVat ? 0 : 1"
					fullWidth
					dark
				/>
			</div>
			<hr class="_dark">
			<div class="footer-links">
				<div class="footer-links-child">
					<h2 class="level-three-heading">{{ ti('TITLE') }}</h2>
					<ul class="list-unstyled">
						<custom-footer-link
							v-for="(item, i) in footerLinks.footerInfo"
							:key="i"
							:linkTo="item.linkTo ? formatLink(item.linkTo) : null"
							:title="item.external ? item.linkTo.params.name : item.title"
							:external="item.external"
							:onClick="editCookies"
						/>
					</ul>
				</div>
				<div class="footer-links-child">
					<h2 class="level-three-heading">{{ ta('TITLE') }}</h2>
					<ul class="list-unstyled">
						<custom-footer-link
							v-for="(item, i) in footerLinks.footerAbout"
							:key="i"
							:linkTo="item.linkTo ? formatLink(item.linkTo) : null"
							:title="item.linkTo.params.name"
							:external="item.external"
						/>
					</ul>
				</div>
			</div>
			<hr class="_dark">
			<div class="contact">
				<a-link
					:to="standardRoute('info', {id: 7, name: 'Kontakta oss'})"
					buttonStyle="primary"
				>
					Kontakta oss
				</a-link>
				<div class="socials">
					<a-link
						v-for="(item, i) in footerLinks.footerSocialIcons"
						:to="standardRoute('info', {id: 23, name: 'Social media'})"
						:key="'social-logo-' + i"
					>
						<img
							:src="convertToCdnUrl(item.src)"
							:alt="item.alt"
							:style="'aspect-ratio:' + item.aspectRatio"
							loading="lazy"
						/>
					</a-link>
				</div>
				<hr class="_dark">
				<div v-if="! isLoggedIn || ! isSubscribedToNewsLetter" class="newsletter-subscription">
					<h2 class="level-three-heading">Webhallens nyhetsbrev</h2>
					<div
						:class="{'input-field-button-group subscription-input': ! isLoggedIn}"
					>
						<input-text
							v-if="! isLoggedIn"
							v-model="newsletterMail"
							class="_dark"
							name="newsletterEmail"
							checkoutValidation
							required
							:submitError="submitMailError"
							placeholder="Email"
							type="email"
						/>
						<text-button
							text="Skriv upp mig!"
							dark
							@click="subscribe"
						/>
					</div>
				</div><!--@TODO översätt-->
				<hr v-if="! isLoggedIn || ! isSubscribedToNewsLetter" class="_dark">
			</div>

			<div class="footer-icons">
				<div class="delivery-logos">
					<img
						v-for="(item, i) in footerLinks.footerDeliveryIcons"
						:src="convertToCdnUrl(item.src)"
						:key="'delivery-logo-' + i"
						:alt="item.alt"
						:style="'aspect-ratio:' + item.aspectRatio"
						loading="lazy"
					/>
				</div>
				<div class="footer-logos">
					<img
						v-for="(item, i) in footerLinks.footerLogoIcons"
						:src="convertToCdnUrl(item.src)"
						:key="'footer-logo-' + i"
						:alt="item.alt"
						:style="'aspect-ratio:' + item.aspectRatio"
						loading="lazy"
					/>
				</div>
			</div>

			<div class="copyright">
				{{ tf('COPYRIGHT', currentYear) }}
			</div>
		</div>
	</footer>
</template>

<script>
import alert from 'alert';
import bus from 'eventbus';
import store from 'datastore';
import livechat from 'livechat';
import { convertToCdnUrl, standardRoute } from 'utils';
import i18n, { t } from 'datastore/i18n';
import { mapGetters, mapState } from 'vuex';
import { footerLinks } from './footerLinks';

export default {
	store,
	data () {
		return {
			vatOption: '',
			livechat,
			subscribedToNewsletter: false,
			newsletterMail: '',
			submitMailError: {
				status: false,
			},
			vatOptions: [
				{
					value: 'inkvat',
					label: 'Visa priser inklusive moms',
					sort: '',
				},
				{
					value: 'exvat',
					label: 'Visa priser exklusive moms',
					sort: '',
				},
			],
			footerLinks,
			standardRoute,
		};
	},
	methods: {
		convertToCdnUrl,
		t,
		tf: t.prefix('FOOTER'),
		ti: t.prefix('FOOTER.INFO'),
		ta: t.prefix('FOOTER.ABOUT'),
		toggleVat () {
			this.$store.dispatch('user/setSetting', {
				name: 'show_prices_excluding_vat',
				value: this.showVat, // blir redan inverterad i computed-funktionen
			});
		},
		subscribe () {
			if (! this.newsletterMail && ! this.isLoggedIn) {
				this.submitMailError = {
					status: true,
					placeholder: 'Fyll i en mailadress'
				};
				alert('Fyll i en mailadress');
			} else if (this.isLoggedIn) {
				this.$store.dispatch('user/setSetting', {
					name: 'send_newsletter',
					value: true,
				});
			} else {
				if (this.newsletterMail) {
					waja.post('newsletter/subscribe/email/' + this.newsletterMail)
						.on('success', res => {
							alert('Din mailadress är nu tillagd!');
						})
						.on('error', e => {
							if (e.status === 403) {
								alert('Denna adress prenumererar redan på våra nyhetsbrev!');
							} else if (e.status === 402) {
								alert('Denna adress är ej giltig!');
							} else {
								alert('Något gick fel');
							}
						})
						.go();
				}
			}
		},
		editCookies () {
			bus.$emit('cookie.edit');
		},
		formatLink (link) {
			return standardRoute(link.name, { id: link.params.id, name: link.params.name });
		},
	},
	computed: {
		showVat () {
			return ! this.user.settings.show_prices_excluding_vat;
		},
		selectedVatOption () {
			return this.showVat ? 'inkvat' : 'exvat';
		},
		phone () {
			return '+4686736000';
		},
		currencyName () {
			// @TODO finns det något paket (som vi redan har) med namnen på dessa valutor?
			switch (i18n.locale) {
				case 'dk-DA':
					return 'danske kroner';
				case 'fi-FI':
					return 'euro';
				case 'sv-SE':
				default:
					return 'svenska kronor';
			}
		},
		currentYear () {
			return new Date().getFullYear();
		},
		isSubscribedToNewsLetter () {
			return (this.isLoggedIn && this.user.settings.send_newsletter);
		},
		...mapGetters({
			isLoggedIn: 'user/isLoggedIn',
		}),
		...mapState({
			user: 'user',
		}),
	},
	watch: {
		vatOption (to) {
			if(to !== this.selectedVatOption) {
				this.toggleVat();
			}
		},
		showVat () {
			this.vatOption = this.selectedVatOption;
		},
	},
};
</script>
