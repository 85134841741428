import tagHandler from 'tagHandler';
const tags = {};

const api = {
	set (name, content) {
		name = name.toLowerCase();
		tagHandler.set({
			id: name,
			type: 'meta',
			attributes: { name, content },
		});
	},
	get (name) {
		const tag = tagHandler.get('meta', name);
		if (! tag) {
			return undefined;
		}
		return tag.getAttribute('content');
	},
	delete (name) {
		return tagHandler.deleteTag('meta', name);
	},
	setDefault () {
		this.set('description', 'Webhallen, ledande återförsäljare av tech, gadgets, spel och mer. Låga priser och fri frakt för medlemmar!');
	},
};

if (process.env.NODE_ENV !== 'production') {
	window.meta = api;
}

export default api;