import store from '../index';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

function defaultState() {
	return {
		currentQuery: null,
		origin: 'ORGANIC',
		/** searchCategories is ALL the category data, it's saved separately so it isn't
		 * as heavy to build. searchTree can easily utilize searchCategories to build
		 * the current tree and only save the relevant data needed for filtering and such
		*/
		searchCategories: null,
		searchTree: null,
		// All filters, comes from backend
		attributes: null,
		// Sets which filters are expanded on load
		attributeShow: {},
		sortingOption: null,
		enabledFilters: [],
		// Base filters, ex type: search, value: mario
		initialFilters: [],
		// If leftCol filters are visible/enabled
		filterEnabled: true,
		totalProductCount: null,
		filteredProductCount: null,
	};
}

store.registerModule('productFilters', {
	namespaced: true,
	state: defaultState(),
	getters,
	mutations,
	actions,
});

export {
	defaultState,
};