<template>
	<div
		:class="['dropdown-select', dropdownClasses]"
		@click="handleClick"
	>
		<label
			v-if="slotLabel"
			class="dropdown-label"
			:for="name"
			@click.prevent
		>
			<slot name="label" />
		</label>
		<label
			v-else
			class="dropdown-label"
			:for="name"
			@click.prevent
		>
			<span>
				<template v-if="prefix">
					{{ formatter(prefix) }}
				</template>
					{{ staticLabel || formatLabel(currentItem) }}
				<template v-if="currentItem.sort">
					({{ formatSort(currentItem) }})
				</template>
			</span>
			<span v-if="currentItem.description" class="description-label">
				{{ formatDescription(currentItem) }}
			</span>
		</label>
		<select :name="name" :id="name" class="select" @change="handleChange">
			<option
				v-for="(item, index) in items"
				:value="index"
				:key="index"
				:selected="index == selected"
			>
				{{ formatLabel(item) }}
				<template v-if="item.sort" class="sort-label">
					({{ formatSort(item) }})
				</template>
			</option>
		</select>
		<div :class="['dropdown-list', {'dropdown-open': open}]">
			<slot name="tabs"></slot>
			<ul>
				<slot v-for="(item, index) in items" :item="item" :index="index">
					<li
						:class="['dropdown-item', {'selected-item': index == selected, 'first-cnet': index == firstCnet, 'item-description': item.description}]"
						@click="select(index)"
						:key="index"
					>
						<hr v-if="index == firstCnet" :key="'dropdown-divider-' + index" />
						<span class="list-label">
							{{ formatLabel(item) }}
							<span v-if="item.sort" class="sort-label">
								({{ formatSort(item) }})
							</span>
						</span>
						<template v-if="item.description">
							<span class="description-label">
							{{ formatDescription(item) }}
							</span>
							<hr />
						</template>
					</li>
				</slot>
			</ul>
		</div>
		<span class="dropdown-icon" />
	</div>
</template>

<script>
import { t } from 'datastore/i18n';

export default {
	data () {
		return {
			open: false,
			selected: null, // index number
			firstCnetIndex: null,
		};
	},
	props: {
		items: {
			type: Array,
			required: true,
		},
		placeholder: {
			type: [Number, String],
			default: 'Välj ett alternativ',
		},
		name: {
			type: String,
			required: false, //Ska bli ändrat till true
		},
		value: {},
		prefix: {
			type: String,
			default: '',
		},
		formatter: {
			type: Function,
			default: t,
		},
		center: {
			type: Boolean,
			default: false,
		},
		staticLabel: { //Overrides selected item in label, e.g. variant-selector on product-page.
			type: String,
			required: false,
		},
		slotLabel: { // If you want a label with a bit of special styling
			type: Boolean,
			default: false,
		},
		fullWidth: {
			type: Boolean,
			default: false,
		},
		filled: {
			type: Boolean,
			default: false,
		},
		dark: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		select (index) {
			this.selected = index;
			this.$emit('select', this.currentItem);
		},
		find (needle) {
			for (const i in this.items) {
				const item = this.items[i];
				if (item === needle || item.value === needle) {
					return i;
				}
			}
			return null;
		},
		getSortOrderLabel (item) {
			if (item.sort  !== '') {
				return this.formatter('SORT.' + item.sort);
			}
			return item.sort;
		},
		formatLabel (item) {
			if(item.label && item.label.indexOf('SORT.') > -1) {
				return this.formatter(item.label);
			}
			return item.label || item;
		},
		formatSort (item) {
			if(item.sort && item.sort.indexOf('SORT.') > -1) {
				return this.formatter(item.sort);
			}
			return item.sort || item;
		},
		formatDescription (item) {
			return item.description || '';
		},
		handleChange (event) {
			this.selected = event.target.value;
			this.$emit('select', this.currentItem);
		},
		handleClick (event) {
			if (this.open) {
				event.stopPropagation();
			}
			this.open = ! this.open;
		},
	},
	mounted () {
		this.selected = this.find(this.value);
	},
	computed: {
		currentItem () {
			let item = { label: this.placeholder, value: null };
			if (isNaN(this.selected) || ! this.items[this.selected]) {
				if (typeof this.placeholder === 'number' && this.items[this.placeholder]) {
					item = this.items[this.placeholder];
				}
			} else {
				item = this.items[this.selected];
			}
			if (typeof item === 'string') item = { label: item, value: item };
			return item;
		},
		firstCnet () {
			let i = null;
			this.items.some((el,index) => {
				if (el.sort && el.label.indexOf('SORT.') === -1) {
					return i = index;
				}
			});
			return i;
		},
		dropdownClasses () {
			return [
				{
					'dropdown-open': this.open,
					'full-width': this.fullWidth,
					'filled': this.filled,
					'dropdown-description': this.currentItem.description,
					'center': this.center,
					'_dark': this.dark,
					'disabled': this.disabled,
				},
			];
		},
	},
	watch: {
		items () {
			this.selected = this.find(this.value);
		},
		currentItem (to) {
			if (to.value || to.value === 0) {
				this.$emit('input', to.value);
				this.$emit('change', to.value);
			}
		},
		value (to) {
			this.selected = this.find(to);
		},
		open (to) {
			this.$emit('update:open', to);
			if (to) {
				document.addEventListener('click', this.handleClick);
			} else {
				document.removeEventListener('click', this.handleClick);
			}
		},
	},
};
</script>
