import { getType } from './utils';
function validate (val, arg, filterFunction, types, classes, values) {
	types = types === '' ? '' : types || [];
	classes = classes === '' ? '' : classes || [];
	values = values === '' ? '' : values || [];
	types = types instanceof Array ? types : [types];
	classes = classes instanceof Array ? classes : [classes];
	values = values instanceof Array ? values : [values];
	if (filterFunction) {
		types = types.filter((val) => val !== 'function');
		classes = classes.filter((val) => val !== Function);
	}
	for (const i in types) {
		if (typeof val === types[i]) return val;
	}

	for (const i in classes) {
		if (val instanceof classes[i]) return val;
	}

	for (const i in values) {
		if (val === values[i]) return val;
	}

	let all = types.concat(classes.map(val => val.name)).concat(values.map(val => getType(val) + '(\'' + val + '\')'));
	const last = all.pop() || 'nothing';
	all = all.length ? all.join(', ') + ' or ' : '';
	throw new Error('Invalid ' + (filterFunction !== undefined ? 'return value for ' : '') + (arg || 'argument') + '. Expected ' + all + last + '. Got ' + getType(val) + '(\'' + val + '\')');
};
validate.trigger = function (trigger, arg, resolving) {
	return validate(trigger, arg || 'trigger', resolving, ['string', 'function']);
};
validate.callback = function (callback, arg, resolving) {
	return validate(callback, arg || 'callback', resolving, 'function');
};
validate.url = function (url, arg, resolving) {
	return validate(url, arg || 'url', resolving, ['string', 'function']);
};
validate.method = function (method, arg, resolving) {
	return validate(method, arg || 'method', resolving, 'function', null, ['OPTIONS', 'GET', 'HEAD', 'POST', 'PUT', 'DELETE', 'TRACE', 'PATCH']);
};
validate.mode = function (mode, arg, resolving) {
	return validate(mode, arg || 'mode', resolving, 'function', null, ['same-origin', 'no-cors', 'cors', 'navigate']);
};
validate.cache = function (cache, arg, resolving) {
	return validate(cache, arg || 'cache', resolving, 'function', null, ['default', 'no-store', 'reload', 'no-cache', 'force-cache']);
};
validate.referrerPolicy = function (referrerPolicy, arg, resolving) {
	return validate(referrerPolicy, arg || 'referrerPolicy', resolving, 'function', null, [
		'no-referrer',
		'no-referrer-when-downgrade',
		'origin',
		'origin-when-cross-origin',
		'unsafe-url',
	]);
};
validate.pureString = function (string, arg, resolving) {
	return validate(string, arg || 'string', resolving, 'string');
};
validate.string = function (string, arg, resolving) {
	return validate(string, arg || 'string', resolving, ['string', 'function']);
};
validate.header = function (header, arg, resolving) {
	validate(header, arg || 'header', resolving, ['object']);
	for (const key in header) {
		validate.pureString(key, 'key in header', resolving);
		validate.pureString(header[key], 'value in header', resolving);
	}
	return header;
};
validate.data = function (data, arg, resolving) {
	return validate(data, arg || 'data', resolving, ['object', 'function']);
};

export default validate;
