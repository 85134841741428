import { render, staticRenderFns } from "./campaignHighlight.vue?vue&type=template&id=125f34fa&"
import script from "./campaignHighlight.vue?vue&type=script&lang=js&"
export * from "./campaignHighlight.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports