
function getInsuredRows (cart) {
	const insuredRows = [];
	for (let i = 0; i < cart.rows.length; ++i) {
		const row = cart.rows[i];
		if (row.extraData && row.extraData.insured && row.insurance) {
			insuredRows.push(row);
		}
	}
	return insuredRows;
}

/**
 * Sum of products.
 */
function getProductSum (cart) {
	let sum = 0;
	for (let i = 0; i < cart.rows.length; ++i) {
		const row = cart.rows[i];
		sum += row.unitPrice * row.quantity;
	}
	return sum;
}

function getInsuranceSum (cart) {
	let sum = 0;
	for (let i = 0; i < cart.rows.length; ++i) {
		const row = cart.rows[i];
		// Check if the product is insured.
		if (row.extraData && row.extraData.insured && row.insurance) {
			sum += row.insurance.price * row.quantity;
		}
	}
	return sum;
}

/**
 * Total sum of cart.
 */
function getCartSum (cart) {
	return getProductSum(cart) + getInsuranceSum(cart);
}

/**
 * Sum of products excluding VAT.
 */
function getProductSumExclVat (cart) {
	let sum = 0;
	for (let i = 0; i < cart.rows.length; ++i) {
		const row = cart.rows[i];
		sum += (row.unitPrice - row.unitVat) * row.quantity;
	}
	return sum;
}

/**
 * Total sum of cart excluding VAT.
 */
function getCartSumExclVat (cart) {
	// Insurances are excempt from VAT, which means that they always cost their full price.
	return getProductSumExclVat(cart) + getInsuranceSum(cart);
}

/**
 * Total VAT for cart
 */
function getCartTotalVat (cart, shipping) {
	let productVat = getProductSum(cart) - getProductSumExclVat(cart);
	let shippingVat = shipping - Math.round(0.8*shipping);
	return shippingVat + productVat;
}

function getMostExpensiveProduct (cart) {
	let maxPrice = -1;
	let mostExpensiveProduct = null;
	for (const row of cart.rows) {
		if (row.unitPrice > maxPrice) { // @TODO handle ties
			maxPrice = row.unitPrice;
			mostExpensiveProduct = row.product;
		}
	}
	return mostExpensiveProduct;
}

function hasUnreleasedProducts (cart) {
	const now = Date.now() / 1000;
	for (const row of cart.rows) {
		const release = row.product.release;
		if (release && release.timestamp > now) {
			return true;
		}
	}
	return false;
}

function getTotalDiscount (cart) {
	let sum = 0;
	for (let i = 0; i < cart.rows.length; ++i) {
		const row = cart.rows[i];
		sum += (row.product.regularPrice.price -row.unitPrice) * row.quantity;
	}
	return sum;
}

function getRegularPrice (cart) {
	let sum = 0;
	for (let i = 0; i < cart.rows.length; ++i) {
		const row = cart.rows[i];
		sum += (row.product.regularPrice.price) * row.quantity;
	}
	return sum;
}

export {
	getInsuredRows,
	getProductSum,
	getInsuranceSum,
	getCartSum,
	getProductSumExclVat,
	getCartSumExclVat,
	getCartTotalVat,
	getMostExpensiveProduct,
	hasUnreleasedProducts,
	getTotalDiscount,
	getRegularPrice,
};
