<template>
	<div class="panel-thin product-grid-item" :class="{ panel: showBorder }">
		<div :class="[{ 'show-buy-btn': showBuyBtn }, 'panel-body']">
			<div class="panel-top">
				<product-link
					:product="product"
					:pass="pass"
					:hidePass="hidePass"
					@click.native="handleLinkClick"
				>
					<splash
						v-if="product.splashes && showSplash"
						:splashes="product.splashes"
					/>
					<ximg :src="src" :ariaLabel="product.name" role="img">
						<price-bubble
							:product="product"
							:excludeVat="user.settings.show_prices_excluding_vat"
							:diameter="mq.current <= mq.sm ? '45px' : '55px'"
							size="medium"
							:font-size="mq.current <= mq.sm ? 14 : 16"
							:fyndwareClassFontSize="mq.current <= mq.sm ? 7 : 8"
						/>
					</ximg>

				</product-link>
				<template v-if="showTitleTwo">
					<fixed-lines :useEllipsis="false" :lines="3">
						<fixed-lines :useEllipsis="false" :lines="product.subTitle ? 2 : 3" class="product-list-item-title" :class="{ 'title-height': product.subTitle }">
							<product-link
								:product="product"
								:pass="pass"
								@click.native="handleLinkClick"
							>
								{{ product.mainTitle }}
							</product-link>
						</fixed-lines>

						<fixed-lines v-if="product.subTitle" :useEllipsis="false" :lines="2" class="product-list-item-subtitle">
							<span class="product-list-subtitle">{{ product.subTitle }}</span>
						</fixed-lines>
					</fixed-lines>
				</template>
				<template v-else>
					<fixed-lines :useEllipsis="false" :lines="2" >
						<product-link
							:product="product"
							:pass="pass"
							@click.native="handleLinkClick"
						>
							{{ product.name }}
						</product-link>
					</fixed-lines>
				</template>
				<div v-if="product.energyMarking" class="ap-t ap-fill-x" style="pointer-events: none; padding-top: 56.25%;">
					<modal label-style="pointer-events: auto;" root-style="pointer-events: auto;">
						<template slot="label">
							<!-- <energy-rating class="ap-tl mt-3" style="margin-left: -3px;" :rating="product.energyMarking.rating" :scale="product.energyMarking.scale" nofold /> -->
							<energy-rating class="ap-bl pt-3 mt-3" style="margin-left: -3px;" :rating="product.energyMarking.rating" :scale="product.energyMarking.scale" nofold />
							<eu-prod-info class="ap-br pt-3 pl-3 pr-3 pb-0 rtl-3" />
						</template>
						<energy-datasheet :marking="product.energyMarking" />
					</modal>
				</div>
				<hero-marker v-if="product.heroMarking" class="ap-tl mt-3" :style="product.energyMarking ? 'top: 25px !important;' : ''" style="height: 1.4em; margin-left: -3px;" :font-size="5" />
			</div>
			<div :class="['panel-bottom', { 'show-stock': showStock }]">
				<div v-if="hasAvgRating" class="product-tip-item-rating">
					<span v-if="showRating"> {{ rating }} </span>
					<span v-else-if="! showRating && showNrReviews && product.reviewCount">
						{{ product.reviewCount }} {{ displayStars ? 'recensioner' : 'hypes' }}
					</span>
					<stars v-if="displayStars" :stars="rating" />
					<flames v-else :flames="rating" />
					<span v-if="! showNrReviews && product.reviewCount" class="review-count">({{product.reviewCount}})</span>
				</div>
				<div v-else class="rating-filler" />
				<div v-if="product.resursPartPaymentPrice && product.resursPartPaymentPrice.durationMonths === 24" class="price-info">
					<span class="sub-title">
						({{ formattedPartPaymentPrice }} kr/mån)
					</span>
					<product-price :product="product" right />
				</div>
				<product-price v-else :product="product" right />
				<stock-modal v-if="showStock" :product="product" iconOnly :listType="'grid'"/>
			</div>
			<buy-button
				v-if="showBuyBtn"
				class="mt-4"
				:class="{'popup-buy-button': parentElement === 'Product accessory popup'}"
				:product="product"
				:insured="insured"
				:parentElement="parentElement"
				width="stretch"
				:secondaryBuyButton="parentElement !== 'Product accessory popup'"
				@triggerInsurance="triggerInsurance"
			/>
			<insurance-popup
				v-if="showInsurancePopup"
				:insurance="product.insurance"
				:icon="categoryIcon"
				:productCategories="product.categories"
				ref="insurancePopup"
			/>
		</div>
	</div>
</template>

<script>
import { isProductReleased, displayStarRating, getProductRating, convertToCdnUrl } from 'utils';
import i18n, { t } from 'datastore/i18n';
import mq from 'mediaQuery';
import { mapState } from 'vuex';
import bus from 'eventbus';


export default {
	data () {
		return {
			mq,
			insured: false,
		};
	},
	props: {
		product: {},
		small: {},
		showStock: {
			default: false,
		},
		pass: {},
		showBuyBtn: {
			type: Boolean,
			default: false,
		},
		hidePass: {
			default: false,
		},
		showBorder: {
			type: Boolean,
			default: true,
		},
		showRating: {
			type: Boolean,
			default: false,
		},
		showNrReviews: {
			type: Boolean,
			default: false,
		},
		categoryIcon: {
			type: String,
			default: '',
		},
		parentElement: {
			type: String,
			default: '',
		},
		index: {
			type: Number,
			default: null,
		},
		showSplash: {
			type: Boolean,
			default: true,
		},
	},
	mounted () {
		// Only emit for the first product of every page to avoid emit SPAM
		if (this.index === 0) {
			this.$emit('loaded');
		}
	},
	methods: {
		t,
		setInsured (insured) {
			this.insured = insured;
		},
		triggerInsurance () {
			if (this.showInsurancePopup) {
				this.$refs.insurancePopup.modalOpen = true;
			}
		},
		handleLinkClick () {
			this.$emit('handleLinkClick');
			bus.$emit('modal.close');
		}
	},
	computed: {
	    reviewCountStyle() {
		    if (mq.pageWidth < 500) {
		    	return 'margin-bottom: 15px;';
		    }
			return this.displayStars ? 'margin-bottom: 30px; margin-left: 62px;' : 'margin-bottom: 30px; margin-left: 70px;';
		},
		src () {
			return convertToCdnUrl('/images/product/' + this.product.id + '?trim&w=231');
		},
		hasAvgRating () {
			return this.product.averageRating !== null && this.product.averageRating !== undefined;
		},
		isReleased () {
			return isProductReleased(this.product.release);
		},
		displayStars () {
			return displayStarRating(this.product);
		},
		rating () {
			return getProductRating(this.product);
		},
		showTitleTwo () {
			return this.user.showTitleTwo;
		},
		showInsurancePopup () {
			return !! this.product.insurance && (parseInt(this.product.price.price)) > 300;
		},
		formattedPartPaymentPrice () {
			if (this.product.resursPartPaymentPrice) {
				const locale = i18n.locale;

				const priceFormatter = Intl.NumberFormat(locale, {
					minimumFractionDigits: 0,
				});
				return priceFormatter.format(this.product.resursPartPaymentPrice.monthlyCost);
			} else {
				return 0;
			}
		},
		...mapState({
			user: state => state.user,
		}),
	},
};
</script>
