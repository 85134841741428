function Tween (object, targetValues, duration, ease, startValues, options) {
	// Ensure that the parameters are set
	if (! options) {
		options = {};
	}
	if (! startValues) {
		startValues = {};
	}
	if (! duration) {
		duration = 1;
	}
	if (! ease) {
		ease = Tween.ease.outQuad;// TODO linear
	}
	if (! object) {
		console.error('You did not specify an object!');
		return;
	}
	this.currentTime = 0;
	this.duration = duration;
	this.object = object;
	this.options = options;

	// ensure that start values exist for all properties
	for (const k in targetValues) {
		if (startValues[k] === undefined) {
			startValues[k] = object[k] ? object[k] : 0;
		}
		targetValues[k] -= startValues[k];
	}

	for (const k in startValues) {
		if (object[k] instanceof Function) {
			object[k](startValues[k]);
		} else {
			object[k] = startValues[k];
		}
	}

	// TODO if a start property is set but not  the target property
	this.startValues = startValues;
	this.targetValues = targetValues;
	this.ease = ease;

	// If there's currently no tweening going on, start the update loop
	if (Tween.list.length === 0) {
		Tween.lastUpdate = Date.now();
		Tween.list.push(this);
		Tween.update();
	} else {
		Tween.list.push(this);
	}
}

Tween.prototype.then = function (callback) {
	this.callback = callback;
};

Tween.list = [];

Tween._config = {
	manualPolling: false,
};

Tween.config = function (obj) {
	for (const k in obj) {
		Tween._config[k] = obj[k];
	}
};

Tween.update = function () {
	const now = Date.now();
	const delta = (now - Tween.lastUpdate) * 0.001;

	for (let i in Tween.list) {
		const t = Tween.list[i];
		if ((t.currentTime += delta) >= t.duration) {
			Tween.list.splice(i--, 1);
			t.currentTime = t.duration;
		}
		for (const k in t.targetValues) {
			let val = t.ease(t.currentTime, t.startValues[k], t.targetValues[k], t.duration);
			if (t.options.round) {
				val = Math.round(val);
			}
			if (t.object[k] instanceof Function) {
				t.object[k](val);
			} else {
				t.object[k] = val;
			}
		}
		if (t.callback && t.currentTime >= t.duration) {
			t.callback();
		}
	}
	Tween.lastUpdate = now;
	if (Tween.list.length && ! Tween._config.manualPolling)		{
		window.requestAnimationFrame(Tween.update);
	}
};

Tween.destroy = function (obj) {
	for (let i = 0; i < Tween.list.length; i++) {
		if (Tween.list[i].object === obj) {
			Tween.list.splice(i--, 1);
		}
	}
};

Tween.lastUpdate = 0;

Tween.ease = {
	outQuad: function (t, b, c, d) {
		return -c * (t /= d) * (t - 2) + b;
	},
	outBack: function (t, b, c, d, s) {
		if (s === undefined) s = 2;
		return c * ((t = t / d - 1) * t * ((s + 1) * t + s) + 1) + b;
	}, outBounce: function (t, b, c, d) {
		if ((t /= d) < (1 / 2.75)) {
			return c * (7.5625 * t * t) + b;
		} else if (t < (2 / 2.75)) {
			return c * (7.5625 * (t -= (1.5 / 2.75)) * t + 0.75) + b;
		} else if (t < (2.5 / 2.75)) {
			return c * (7.5625 * (t -= (2.25 / 2.75)) * t + 0.9375) + b;
		} else {
			return c * (7.5625 * (t -= (2.625 / 2.75)) * t + 0.984375) + b;
		}
	}, inBack: function (t, b, c, d, s) {
		if (s === undefined) s = 1.70158;
		return c * (t /= d) * t * ((s + 1) * t - s) + b;
	}, inQuad: function (t, b, c, d) {
		return c * (t /= d) * t + b;
	}, sine: function (t, b, c, d) {
		return b + Math.sin((t / d) * Math.PI) * c;
	}, linear: function (t, b, c, d) {
		return c * t / d + b;
	}, inOutQuad: function (t, b, c, d) {
		if ((t /= d / 2) < 1) return c / 2 * t * t + b;
		return -c / 2 * ((--t) * (t - 2) - 1) + b;
	},
};

export default Tween;
