/* bundle: App
** Components needed throughout the page
*/
import aLink from 'components/helper/a-link';
import autoScroller from 'components/auto-scroller';
import background from 'components/background';
import bankidModal from 'components/bankid-modal';
import banner from 'components/banner';
import breadCrumbs from 'components/root/bread-crumbs';
import breadcrumbMarkup from 'components/markup/breadcrumb-markup';
import bubble from 'components/product/bubble';
import cart from 'components/cart/cart';
import cartLarge from 'components/cart/cart-large';
import categorySelector from 'components/category-selector';
import categoryTile from 'components/category-tile';
import cookieConsent from 'components/cookie-consent';
import customFooterLink from 'components/common/custom-footer-link';
import customXimg from 'components/common/custom-ximg';
import dropdown from 'components/helper/dropdown';
import dropdownSelect from 'components/input/dropdown-select';
import energyDatasheet from 'components/energy-datasheet';
import energyRating from 'components/energy-rating';
import euProdInfo from 'components/eu-prod-info';
import fixedLines from 'components/fixed-lines';
import flames from 'components/flames';
import globalModal from 'components/global-modal';
import hamburgerMenu from 'components/navigation/hamburger-menu';
import highlight from'components/highlight';
import highlightSection from 'components/highlightSection';
import homeComponentSelector from 'components/home-component-selector';
import iconButton from 'components/input/icon-button';
import inputText from 'components/input/input-text';
import leftCol from'components/root/left-col';
import livechat from 'components/livechat';
import loadingSpinner from 'components/loading-spinner';
import memberForm from 'components/member-form';
import memberNav from 'components/member-nav'; // Contains product-list-filters for certain pages so has to be loaded instantly
import memberWidget from 'components/member-widget';
import modal from 'components/helper/modal';
import newsletterPopup from 'components/newsletter-popup';
import popularCategories from 'components/popular-categories';
import priceBubble from 'components/product/price-bubble';
import productBrowser from 'components/product/product-browser'; // Must be loaded instantly
import productGridItem from 'components/product/product-grid-item';
import productLink from 'components/product-link';
import productListFilters from 'components/product-list-filters'; // Must be loaded instantly
import productPrice from'components/product/product-price';
import productTipSlider from 'components/product/product-tip-slider';
import progressBar from 'components/progress-bar';
import quickSearch from 'components/quick-search';
import quickSearchSelector from 'components/quick-search-selector';
import recentlyViewed from 'components/recently-viewed';
import sideNav from 'components/navigation/side-nav';
import siteFooter from'components/root/site-footer';
import siteHeader from 'components/root/site-header';
import siteMarkup from 'components/markup/site-markup';
import siteMessage from 'components/site-message';
import siteNav from 'components/navigation/site-nav';
import siteNavCategory from 'components/navigation/site-nav-category';
import siteNavSection from 'components/navigation/site-nav-section';
import siteUsp from 'components/site-usp';
import stars from 'components/stars';
import textButton from 'components/input/text-button';
import toggleElement from 'components/helper/toggle-element';
import topListSlider from 'components/top-list-slider';
import topPanel from 'components/top-panel';
import topReview from 'components/top-review';
import ximg from 'components/ximg';
import xpBar from 'components/xp-bar';

/* bundle: ProductPage
** Components needed while on single product
*/
const addToWishlistBtn = () => import(/* webpackChunkName: "ProductPage" */ 'components/add-to-wishlist-btn');
const answer = () => import(/* webpackChunkName: "ProductPage" */ 'components/questions/answer');
const deliveryNotice = () => import(/* webpackChunkName: "ProductPage" */ 'components/delivery-notice');
const fyndwareInfo = () => import(/* webpackChunkName: "ProductPage" */ 'components/product/fyndware-info');
const gallery = () => import(/* webpackChunkName: "ProductPage" */ 'components/gallery');
const imageSlideshow = () => import(/* webpackChunkName: "ProductPage" */ 'components/image-slideshow');
const preorderInfo = () => import(/* webpackChunkName: "ProductPage" */ 'components/product/preorder-info');
const product = () => import(/* webpackChunkName: "ProductPage" */ 'components/product/index.vue');
const productAside = () => import(/* webpackChunkName: "ProductPage" */ 'components/product/product-aside');
const productDescription = () => import(/* webpackChunkName: "ProductPage" */ 'components/product/product-description');
const productListVariantItem = () => import(/* webpackChunkName: "ProductPage" */ 'components/product/product-list-variant-item');
const productMarkup = () => import(/* webpackChunkName: "ProductPage" */ 'components/product/product-markup');
const productTipDisplay = () => import(/* webpackChunkName: "ProductPage" */ 'components/product/product-tip/display.vue');
const productTipProduct = () => import(/* webpackChunkName: "ProductPage" */ 'components/product/product-tip/product.vue');
const question = () => import(/* webpackChunkName: "ProductPage" */ 'components/questions/question');
const questionAnswerSection = () => import(/* webpackChunkName: "ProductPage" */ 'components/questions/question-answer-section');
const readMore = () => import(/* webpackChunkName: "ProductPage" */ 'components/helper/read-more');
const reviewSection = () => import(/* webpackChunkName: "ProductPage" */ 'components/review-section');
const reviewSectionInfo = () => import(/* webpackChunkName: "ProductPage" */ 'components/review-section-info');
const shippingPriceTable = () => import(/* webpackChunkName: "ProductPage" */ 'components/shipping-price-table');
const stockLegend = () => import(/* webpackChunkName: "ProductPage" */ 'components/stock-legend');
const stockRow = () => import(/* webpackChunkName: "ProductPage" */ 'components/stock-row');
const topReviewLarge = () => import(/* webpackChunkName: "ProductPage" */ 'components/top-review-large');
const variantPicker = () => import(/* webpackChunkName: "ProductPage" */ 'components/product/variant-picker');
const variantSelector = () => import(/* webpackChunkName: "ProductPage" */ 'components/product/variant-selector');

/* bundle: Cart
** Components needed while toggling cartbutton and processing order in cart
*/
const addition = () => import('components/addition');
const cartListLarge = () => import('components/cart/cart-list-large');
const cartListLargeRow = () => import('components/cart/cart-list-large-row');
const customerSurvey = () => import('components/customer-survey');
const freeShippingInfo = () => import('components/free-shipping-info');
const insuranceSelector = () => import('components/insurance-selector');
const mailOrderOnly = () => import('components/mail-order-only');
const productCheckoutAccessoryItem = () => import('components/product/product-checkout-accessory-item');
const productList = () => import('components/product/product-list');
const storePickupOnly = () => import('components/store-pickup-only');
const shareSaveCartDialog = () => import('components/share-save-cart-dialog');

/* bundle: Member
** Components for member page
*/
const achievement = () => import(/* webpackChunkName: "Member" */ 'components/achievement');
const achievementBar = () => import(/* webpackChunkName: "Member" */ 'components/achievement-bar');
const achievementModal = () => import(/* webpackChunkName: "Member" */ 'components/achievement-modal');
const achievementUpdateModal = () => import(/* webpackChunkName: "Member" */ 'components/achievement-update-modal');
const avatarItemSelector = () => import(/* webpackChunkName: "Member" */ 'components/avatar-item-selector');
const benefitRow = () => import(/* webpackChunkName: "Member" */ 'components/benefit-row');
const cheevoCounter = () => import(/* webpackChunkName: "Member" */ 'components/cheevo-counter');
const delivery = () => import(/* webpackChunkName: "Member" */ 'components/delivery');
const friendCard = () => import(/* webpackChunkName: "Member" */ 'components/friend-card');
const order = () => import(/* webpackChunkName: "Member" */ 'components/order');
const orderCancelForm = () => import(/* webpackChunkName: "Member" */ 'components/order-cancel-form');
const orderRow = () => import(/* webpackChunkName: "Member" */ 'components/order-row');
const orderRowInsuranceInfo = () => import(/* webpackChunkName: "Member" */ 'components/order-row-insurance-info');
const orderRowSubscriptions = () => import(/* webpackChunkName: "Member" */ 'components/order-row-subscriptions');
const orderStatusEntryList = () => import(/* webpackChunkName: "Member" */ 'components/order-status-entry-list.vue');
const orderStoreChanger = () => import(/* webpackChunkName: "Member" */ 'components/order-store-changer');
const perks = () => import(/* webpackChunkName: "Member" */ 'components/perks');
const productListSimpleItem = () => import(/* webpackChunkName: "Member" */ 'components/product/product-list-simple-item.vue');
const serviceCase = () => import(/* webpackChunkName: "Member" */ 'components/service-case.vue');
const storeSelector = () => import(/* webpackChunkName: "Member" */ 'components/store-selector');
const storeSelectorStockRow = () => import(/* webpackChunkName: "Member"  */ 'components/store-selector-stock-row');
const textareaText = () => import(/* webpackChunkName: "Member" */'components/input/textarea-text');
const userLog = () => import(/* webpackChunkName: "Member" */ 'components/user-log');
const workshopServiceReport = () => import(/* webpackChunkName: "Member" */ 'components/workshop-service-report');
const xform = () => import(/* webpackChunkName: "Member" */ 'components/xform');

/* bundle: Admin
** Components for admin on this index-page
*/
const adminHamburgerMenu = () => import(/* webpackChunkName: "Admin" */ 'components/navigation/admin-hamburger-menu');
const adminPanel = () => import(/* webpackChunkName: "Admin" */ 'components/root/admin-panel');
const toggleSwitch = () => import(/* webpackChunkName: "Admin" */'components/input/toggle-switch');

/*
** Lazy Loading
*/
const author = () => import ('components/author');
const buyButton = () => import('components/input/buy-button');
const checkbox = () => import('components/input/checkbox');
const countdownTimer = () => import('components/countdown-timer');
const counter = () => import('components/counter');
const filterBox = () => import('components/filter-box');
const filterCategory = () => import('components/filter-category');
// const flashMessages = () => import('components/flash-messages'); // we dont want this right now
const flashSale = () => import('components/flash-sale');
const googleMap = () => import('components/google-map');
const help = () => import('components/help');
const heroMarker = () => import('components/hero-marker');
const htmlDescription = () => import('components/html-description');
const hypeGuidelines = () => import('components/hype-guidelines');
const insurancePopup = () => import('components/insurance-popup');
const shipFromStorePopup = () => import('components/pages/checkout/ship-from-store-popup');
const inputNumber = () => import('components/input/input-number');
const isStoreOpen = () => import('components/is-store-open');
const knighthoodIcon = () => import('components/knighthood-icon');
const knighthoodModal = () => import('components/knighthood-modal');
const knighthoodCard = () => import('components/knighthood-card');
const locationSearch = () => import('components/location-search');
const loginForm = () => import('components/login-form');
const loop54FilterBox = () => import('components/loop54-filter-box');
const memberTutorial = () => import('components/member-tutorial');
const newsletterModal = () => import('components/newsletter-modal');
const paginatorButton = () => import('components/paginator-button');
const paginatorButtons = () => import('components/paginator-buttons');
const productAccessoriesPopup = () => import('components/product-accessories-popup');
const productDelivery = () => import('components/product/product-delivery');
const productDeliveryTest = () => import('components/product/product-delivery-test');
const productListCompactItem = () => import('components/product/product-list-compact-item');
const productListItem = () => import('components/product/product-list-item');
const productListPage = () => import('components/product/product-list-page');
const productListRecentItem = () => import('components/product/product-list-recent-item');
const questionAnswerForm = () => import('components/questions/question-answer-form');
const questionGuidelines = () => import('components/question-guidelines');
const quickSearchListItem = () => import('components/quick-search-list-item');
const radioPicker = () => import('components/input/radio-picker');
const registerForm = () => import('components/register-form');
const review = () => import('components/review');
const reviewForm = () => import('components/review-form');
const reviewGuidelines = () => import('components/review-guidelines');
const siteNavLink = () => import('components/navigation/site-nav-link');
const shareCartLinkForm = () => import('components/share-cart-link-form');
const slider = () => import('components/input/slider');
const splash = () => import('components/splash');
const stockIcon = () => import('components/stock-icon');
const stockModal = () => import('components/stock-modal');
const storeInfoContainer = () => import('components/store-info-container');
const storeListItem = () => import('components/store-list-item');
const storeMarkup = () => import('components/markup/store-markup');
const storesMap = () => import('components/stores-map');
const streamWrapper = () => import('components/stream-wrapper');
const toggleButton = () => import('components/input/toggle-button');
const unsubscribeAbandonCartMailModal = () => import('components/unsubscribe-abandon-cart-mail-modal.vue');

export default {
	/* Prio loading */
	memberNav,
	productBrowser,
	productListFilters,

	/* App bundle */
	aLink,
	autoScroller,
	background,
	bankidModal,
	banner,
	breadCrumbs,
	breadcrumbMarkup,
	bubble,
	cart,
	cartLarge,
	categorySelector,
	categoryTile,
	cookieConsent,
	customFooterLink,
	customXimg,
	dropdown,
	dropdownSelect,
	energyDatasheet,
	energyRating,
	euProdInfo,
	fixedLines,
	flames,
	globalModal,
	hamburgerMenu,
	highlight,
	highlightSection,
	homeComponentSelector,
	iconButton,
	inputText,
	leftCol,
	livechat,
	loadingSpinner,
	memberForm,
	memberWidget,
	modal,
	newsletterPopup,
	popularCategories,
	priceBubble,
	productGridItem,
	productLink,
	productPrice,
	productTipSlider,
	progressBar,
	quickSearch,
	quickSearchSelector,
	recentlyViewed,
	sideNav,
	siteFooter,
	siteHeader,
	siteMarkup,
	siteMessage,
	siteNav,
	siteNavCategory,
	siteNavSection,
	siteUsp,
	stars,
	textButton,
	toggleElement,
	topListSlider,
	topPanel,
	topReview,
	ximg,
	xpBar,

	/* Product bundle */
	addToWishlistBtn,
	answer,
	deliveryNotice,
	fyndwareInfo,
	gallery,
	imageSlideshow,
	preorderInfo,
	product,
	productAside,
	productDescription,
	productListVariantItem,
	productMarkup,
	productTipDisplay,
	productTipProduct,
	question,
	questionAnswerSection,
	readMore,
	reviewSection,
	reviewSectionInfo,
	shippingPriceTable,
	stockLegend,
	stockRow,
	topReviewLarge,
	variantPicker,
	variantSelector,

	/* Cart bundle */
	addition,
	cartListLarge,
	cartListLargeRow,
	customerSurvey,
	freeShippingInfo,
	insuranceSelector,
	mailOrderOnly,
	productCheckoutAccessoryItem,
	productList,
	storePickupOnly,
	shareSaveCartDialog,


	/* Member bundle */
	achievement,
	achievementBar,
	achievementUpdateModal,
	achievementModal,
	avatarItemSelector,
	benefitRow,
	cheevoCounter,
	delivery,
	friendCard,
	order,
	orderCancelForm,
	orderRow,
	orderRowInsuranceInfo,
	orderRowSubscriptions,
	orderStatusEntryList,
	orderStoreChanger,
	perks,
	productListSimpleItem,
	serviceCase,
	storeSelector,
	storeSelectorStockRow,
	textareaText,
	userLog,
	workshopServiceReport,
	xform,

	/* Admin bundle */
	adminHamburgerMenu,
	adminPanel,
	toggleSwitch,

	/* Lazy bundle */
	author,
	buyButton,
	checkbox,
	countdownTimer,
	counter,
	filterBox,
	filterCategory,
	// flashMessages,
	flashSale,
	googleMap,
	help,
	heroMarker,
	htmlDescription,
	hypeGuidelines,
	insurancePopup,
	inputNumber,
	isStoreOpen,
	knighthoodIcon,
	knighthoodModal,
	knighthoodCard,
	locationSearch,
	loginForm,
	loop54FilterBox,
	memberTutorial,
	newsletterModal,
	paginatorButton,
	paginatorButtons,
	productAccessoriesPopup,
	productDelivery,
	productDeliveryTest,
	productListCompactItem,
	productListItem,
	productListPage,
	productListRecentItem,
	questionAnswerForm,
	questionGuidelines,
	quickSearchListItem,
	radioPicker,
	registerForm,
	review,
	reviewForm,
	reviewGuidelines,
	siteNavLink,
	shareCartLinkForm,
	shipFromStorePopup,
	slider,
	splash,
	stockIcon,
	stockModal,
	storeInfoContainer,
	storeListItem,
	storeMarkup,
	storesMap,
	toggleButton,
	streamWrapper,
	unsubscribeAbandonCartMailModal,
};
