<template>
	<toggle-element v-model="active" :checkId="checkId" :label-style="labelStyle" :inlineBlock="inlineBlock">
		<template slot="label"><slot name="label" /></template>
		<portal v-if="usePortal" to="modal-destination">
			<div :class="['modal-root', rootClass]" :style="rootStyle" ref="root" @click.capture="click">
				<div :class="computedClass" style="pointer-events: auto;" :style="containerStyle">
					<slot :checkId="checkId" />
					<icon-button
						v-if="! hideCloseButton"
						variant="close"
						@click="close"
					/>
				</div>
			</div>
		</portal>
		<div v-else :class="['modal-root', rootClass]" :style="rootStyle" ref="root" @click.capture="click">
			<div :class="computedClass" style="pointer-events: auto;" :style="containerStyle">
				<icon-button
					v-if="! hideCloseButton"
					variant="close"
					@click="close"
				/>
				<slot :checkId="checkId" />
			</div>
		</div>
	</toggle-element>
</template>

<script>
import bus from 'eventbus';
import store from 'datastore';
import { mapGetters } from 'vuex';

export default {
	store,
	data () {
		return {
			width: null,
			offsetWidth: null,
			scrollWidth: null,
			active: this.value,
		};
	},
	props: {
		'value': {
			type: Boolean,
			default: false,
		},
		'labelStyle': [String, Object, Array],
		'containerStyle': [String, Object, Array],
		'rootStyle': [String, Object, Array],
		'checkId': {
			type: String,
			default () {
				return '_modal-' + this._uid;
			},
		},
		name: {
			type: String,
			default: '',
		},
		'containerClass': {
			type: String,
		},
		'rootClass': {
			type: String,
		},
		inlineBlock: {
			type: Boolean,
			default: false,
		},
		/**
		 * If set to false, the modal will be instantiated in place.
		 */
		usePortal: {
			type: Boolean,
			default: true,
		},
		/**
		 * If true, the modal can be closed by clicking on the modal root element
		 * (greyed out area outside of the modal).
		 */
		canRootClose: {
			type: Boolean,
			default: true,
		},
		/**If set to true, the modal will hide the red cross at the top right,
		 * assuming you will handle the closing elsewhere.
		 */
		hideCloseButton: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		$route (to, from) {
			if (to.name !== from.name) {
				this.active = false;
			}
		},
		active (to) {
			if (to) {
				store.commit('modal/increment');
			} else {
				store.commit('modal/decrement');
			}
			// If there are still other modals open, then don't enable scrolling
			// just yet.
			if (! this.nrModalsOpen) {
				this[to ? 'disableScroll' : 'enableScroll']();
			}
			this.$emit('input', to);
			this.$emit('change', to);
		},
		value (to) {
			this.active = to;
		},
		width (width) {
			this.setElementWidth(width);
		},
	},
	mounted () {
		bus.$on('modal.close', name => {
			if (! name || name === this.name) {
				this.close();
			}
		});
		bus.$on('keepModalopen', modalName => {
			if (this.name === modalName) {
				this.active = true;
			}
		});
	},
	beforeDestroy () {
		if (this.active) {
			this.enableScroll();
			this.setElementWidth(null);
		}
		bus.$off('modal.close', this.close);
		bus.$off('keepModalopen');
	},
	methods: {
		close () {
			this.active = false;
		},
		click (event) {
			if (this.canRootClose && event.target === this.$refs.root) {
				event.preventDefault();
				event.stopPropagation();
				this.close();
			};
		},
		disableScroll () {
			window.addEventListener('resize', this.recompute);
			const html = document.getElementsByTagName('html')[0];
			const body = document.getElementsByTagName('body')[0];
			this.width = body.offsetWidth;
			this.offsetWidth = 0;
			this.$nextTick(() => { this.offsetWidth = this.viewportWidth - body.offsetWidth; });
			html.classList.add('noscroll');
			body.classList.add('noscroll');
		},
		enableScroll () {
			const html = document.getElementsByTagName('html')[0];
			const body = document.getElementsByTagName('body')[0];
			this.width = null;
			html.classList.remove('noscroll');
			body.classList.remove('noscroll');
			window.removeEventListener('resize', this.recompute);
		},
		recompute () {
			this.width = this.viewportWidth - this.offsetWidth;
		},
		setElementWidth (width) {
			const body = document.getElementsByTagName('body')[0];
			const bg = document.getElementById('background');
			const header = document.getElementById('main-header-wrapper');

			if (width === null) {
				body && (body.style.width = '');
				bg && (bg.style.width = '');
				header && (header.style.width = '');
				return;
			}
			body.style.width = width + 'px';
			bg && (bg.style.width = width + 'px');
			header && (header.style.width = width + 'px');
		},
	},
	computed: {
		computedClass () {
			return ['modal-container', this.containerClass];
		},
		viewportWidth: {
			get () {
				return Math.min(document.documentElement.clientWidth, window.innerWidth || null);
			},
			cache: false,
		},
		...mapGetters ({
			nrModalsOpen: 'modal/getNrModalsOpen',
		}),
	},
};
</script>
