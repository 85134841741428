<template>
	<toggle-element tag="div" check-id="livechat-menu" :label-class="['button-dropdown',labelClass]" :label-style="labelStyle" v-model="open" ref="dropdown" :keep="false" style="z-index: 9001;">
		<template slot="label">
			<div v-if="mobileButton && showButton" class="livechat-button-mobile-container">
				<div class="inner-container">
					<div class="button">
						<svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="lc-1fxdxzb e5ibypu0">
							<defs></defs>
							<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
								<g id="agentonline" fill="#ffffff">
									<path d="M14,25.5 C12.4,25.5 10.8,25.2 9.4,24.7 L4.5,27.5 L4.5,21.9 C2,19.6 0.5,16.5 0.5,13 C0.5,6.1 6.5,0.5 14,0.5 C21.5,0.5 27.5,6.1 27.5,13 C27.5,19.9 21.5,25.5 14,25.5 L14,25.5 Z M9,11.5 C8.2,11.5 7.5,12.2 7.5,13 C7.5,13.8 8.2,14.5 9,14.5 C9.8,14.5 10.5,13.8 10.5,13 C10.5,12.2 9.8,11.5 9,11.5 L9,11.5 Z M14,11.5 C13.2,11.5 12.5,12.2 12.5,13 C12.5,13.8 13.2,14.5 14,14.5 C14.8,14.5 15.5,13.8 15.5,13 C15.5,12.2 14.8,11.5 14,11.5 L14,11.5 Z M19,11.5 C18.2,11.5 17.5,12.2 17.5,13 C17.5,13.8 18.2,14.5 19,14.5 C19.8,14.5 20.5,13.8 20.5,13 C20.5,12.2 19.8,11.5 19,11.5 L19,11.5 Z" id="Shape">
									</path>
								</g>
							</g>
						</svg>
					</div>
				</div>
			</div>
			<div v-else-if="showButton" class="livechat-button-container">
				<div class="inner-container">
					<div class="button">
						<p>Kundtjänst</p>
						<svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="lc-bs6m0 e5ibypu0">
							<defs></defs>
							<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
								<g id="agentonline" fill="#ffffff">
									<path d="M14,25.5 C12.4,25.5 10.8,25.2 9.4,24.7 L4.5,27.5 L4.5,21.9 C2,19.6 0.5,16.5 0.5,13 C0.5,6.1 6.5,0.5 14,0.5 C21.5,0.5 27.5,6.1 27.5,13 C27.5,19.9 21.5,25.5 14,25.5 L14,25.5 Z M9,11.5 C8.2,11.5 7.5,12.2 7.5,13 C7.5,13.8 8.2,14.5 9,14.5 C9.8,14.5 10.5,13.8 10.5,13 C10.5,12.2 9.8,11.5 9,11.5 L9,11.5 Z M14,11.5 C13.2,11.5 12.5,12.2 12.5,13 C12.5,13.8 13.2,14.5 14,14.5 C14.8,14.5 15.5,13.8 15.5,13 C15.5,12.2 14.8,11.5 14,11.5 L14,11.5 Z M19,11.5 C18.2,11.5 17.5,12.2 17.5,13 C17.5,13.8 18.2,14.5 19,14.5 C19.8,14.5 20.5,13.8 20.5,13 C20.5,12.2 19.8,11.5 19,11.5 L19,11.5 Z" id="Shape">
									</path>
								</g>
							</g>
						</svg>
					</div>
				</div>
			</div>
			<!-- <div v-if="showButton" style="position: fixed; bottom: 0; left: 0; display: block; width: 268px; height: 40px; background-color: red; z-index: 999999999999999999">
				Kundservice
			</div> -->
		</template>
		<div v-if="!chatActive" class="livechat-container">
			<div class="livechat-panel">
				<div class="livechat-panel-inner">
					<div class="header-wrapper">
						<div class="header-text">
							Välkommen till vår kundtjänst
						</div>
						<div class="minimize-button" @click="minimize">
							<svg fill="#ffffff" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" class="lc-ogye1v e5ibypu0">
								<path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z">
								</path>
							</svg>
						</div>
					</div>
					<div class="content">
						<div>
							<router-link :to="{ name: 'info', params: { id: 1, name: 'Kundservice' } }">
								<strong @click="minimize()">
									Hjälpsidor och information
								</strong>
							</router-link>
							<br>
							<span class="secondary">
								All info du behöver för ett tryggt och smidigt köp.
							</span>
						</div>
						<div style="margin-top: 20px">
							<router-link :to="{ name: 'info', params: { id: 7, name: 'Kontakta-oss' } }">
								<strong @click="minimize()">
									Kontakta oss via mail eller telefon
								</strong>
							</router-link>
							<br>
							<span class="secondary">
								Vi besvarar mail inom 48 timmar under vardagar.
							</span>
							<br>
							<span :class="[openHours ? 'text-success' : 'text-secondary']">
								{{ t('PHONE_HOURS') }}
							</span>
						</div>
						<div class="content-footer">
							<text-button
								text="Chatta med oss"
								width="stretch"
								size="large"
								:disabled="!livechatIsLoaded"
								@click="openLivechat"
							/>
							<span class="secondary">
								{{ t('CHAT_HOURS') }}
							</span>
						</div>
					</div>

					<div class="footer" />
				</div>
			</div>
		</div>
	</toggle-element>

</template>

<script>
import bus from 'eventbus';
import mq from 'mediaQuery';
import { t } from 'datastore/i18n';

export default {
	data () {
		return {
			mq,
			open: this.value,
			showButton: true,
			chatActive: false,
			livechatIsLoaded: false,
		};
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		tag: {
			type: String,
			default: 'div',
		},
		labelClass: [String, Object, Array],
		labelStyle: [String, Object, Array],
	},
	computed: {
		mobileButton () {
			return mq.current < mq.sm;
		},
		openHours () {
			const date = new Date();
			let open = false;
			if (date.getDay() > 0 && date.getDay() < 6) { //vardagar
				open = date.getHours() >= 9 && date.getHours() < 17;
			}

			return open;
		}
	},
	mounted () {
		bus.$on('chat-menu:show', this.onShow);
		bus.$on('livechat:loaded', this.onLivechatLoad);
	},
	beforeDestroy () {
		bus.$off('chat-menu:show', this.onShow);
		bus.$off('livechat:loaded', this.onLivechatLoad);
	},
	methods: {
		t,
		t: t.prefix('LIVECHAT'),
		onLivechatLoad () {
			this.livechatIsLoaded = true;
		},
		onShow () {
			if (this.showButton) {
				// Our menu should be opened.
				this.open = true;
			} else {
				// The real livechat should be opened.
				bus.$emit('livechat:open');
			}
		},
		minimize () {
			this.open = false;
			this.showButton = true;
		},
		openLivechat () {
			this.showButton = false;
			this.chatActive = true;
			bus.$emit('livechat:open');
		},
	},
	watch: {
		open (open) {
			if (open) {
				this.showButton = false;
				bus.$emit('livechat:chatbutton');
			}
		},
	},
};
</script>