<template>
	<transition name="fast-fade">
		<div
			v-if="shouldShow"
			class="product-list-progress-container"
			:style="{ width: widthPx, position: onLastPage }"
		>
			<a
				@click="scrollToTop"
			>
			 	Gå till toppen<span class="hidden-xs"> av sidan</span>
			</a>
			<span class="secondary">Visat {{ shownProducts }} av {{ browser.totalProductCount }} produkter i listan</span>
			<progress-bar text="" :value="progress" />
		</div>
	</transition>
</template>

<script>
import mq from 'mediaQuery';
import { scrollToY } from 'utils';

export default {
	data () {
		return {
			mq,
		};
	},
	props: {
		browser: {  // product-browser
			required: true,
		},
		width: {
			required: true,
		},
	},
	computed: {
		shownProducts () {
			return Math.min(this.browser.totalProductCount, this.browser.page * this.browser.productsPerPage);
		},
		shouldShow () {
			if (this.browser.page === 1) {
				return false;
			}
			return true;
		},
		widthPx () {
			return this.width + 'px';
		},
		progress () {
			return this.shownProducts / this.browser.totalProductCount;
		},
		onLastPage () {
			const isOnLastPage = this.browser.page >= this.browser.pageCount;
			if (isOnLastPage) {
				return 'initial';
			}
			return 'fixed';
		},
	},
	methods: {
		scrollToTop () {
			scrollToY(0, 0.5);
		},
	},
};
</script>