import bus from 'eventbus';
import store from 'datastore';
import { isEmpty } from 'lodash';

/*
* Tråkig kod, men vänta med att refaktorera tills vi vet vad vi vill ha ut av detta.
* Det lär efterfrågas fler funktioner, om vi inte t.o.m. byter leverantör
*/
const data = {
	hasLoaded: false,
	isEnabled: false,
	hasStartedLoad: false,
	chatButtonPressed: false,
	updateCustomVariables () {
		if (! this.hasLoaded) {
			console.log('Tried to update custom vars but LC is not loaded!');
			return;
		}

		const vars = [
			{
				name: 'Kundnummer',
				value: store.state.user.id,
			},
		];
		window.LC_API.set_custom_variables(vars);
		console.log('Updated LC custom vars!', vars);
	},
};

bus.$on('livechat:chatbutton', activate);
bus.$on('livechat:open', startPuzzelChat);

function activate () {
	loadPuzzelChat();
}

const config = {
	customerKey: '400524',
	configId: '1c8db57a-3711-422a-95ad-4bb80b093736',
};

let whenReady,
	loader,
	chat;

function loadEUWA() {
	// Instantiate the EUWA Loader
	loader = new EUWALoader(config);
	(async () => {
		// Wait for the EUWA loader to run + Start the bootstrapping sequence
		whenReady = await loader.load();

		// Get the chat application context
		chat = await loader.getApplication('PuzzelChatClient');
		const chatClosed = isEmpty(chat.api);

		if (whenReady && ! chatClosed) {
			bus.$emit('livechat:loaded');
		}
	})();
}

async function startPuzzelChat() {
	await whenReady;
	const { isConnected } = chat.api.getState();

	if (! isConnected) chat.api.startChat();
	bus.$emit('livechat:active');
}

function loadPuzzelChat () {
	(function (doc, onload) {
		const loader = doc.createElement('script');
		loader.src = 'https://euwa.puzzel.com/loader/index.js';
		loader.onload = onload;
		doc.body.append(loader);
	})(document, loadEUWA)
}

export default data;
