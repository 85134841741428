import syncedEvents from 'synced-events';

const namespace = 'localSessionStorage:';

/*
	LocalStorage delas mellan alla flikar. Detta låter oss synka ändringar mellan alla flikar med vår site.
	SessionStorage delas inte mellan flikarna men tas bort när fliken stängs.
	Genom att spara i sessionStorage och synka mellan flikarna med synced-events (som använder localstorage för att skicka events mellan flikar)
	så kan vi nyttja båda dessa funktioner. Datan sparas i localSessionStorage, delat över samtliga flikar
	och tas bort när den sista fliken stängs.
	Detta kan användas för t.ex. sparade kunduppgifter i kassan där vi inte vill ha kvar grejer i localstorage
	(går inte att sätta expiry på något sätt alls i localstorage, så om kund stänger fliken så ligger datan kvar permanent på datorn, vilket kan vara en säkerhetsrisk)
*/

let acceptsUpdate = true;

syncedEvents.on(namespace + 'update', res => {
	if (acceptsUpdate) {
		const newData = JSON.parse(res);
		for (const key in newData) {
			sessionStorage.setItem(key, newData[key]);
		}

		// Ingen mening med att uppdatera flera gånger på rak (om flera flikar har kontaktat oss)
		acceptsUpdate = false;
		setTimeout(() => {
			acceptsUpdate = true;
		}, 0);
	}
});

syncedEvents.on(namespace + 'fetch', res => {
	emitData();
});

syncedEvents.emit(namespace + 'fetch', false);

// @TODO se till att bara emita en gång om man kör flera på raken
let hasQueuedEmit = false;
function emitData() {
	if (hasQueuedEmit) {
		return;
	}
	hasQueuedEmit = true;
	setTimeout(() => {
		hasQueuedEmit = false;
		const data = {};
		for (const key of Object.keys(sessionStorage)) {
			if (key.indexOf(namespace) !== 0) {
				continue;
			}
			data[key] = sessionStorage.getItem(key);
		}
		syncedEvents.emit(namespace + 'update', JSON.stringify(data), false);
	});
}

const api = {
	set (key, value) {
		sessionStorage.setItem(namespace + key, value);
		emitData();
	},
	get (key, defaultValue) {
		const val = sessionStorage.getItem(namespace + key);
		if (val === 'false') {
			return false;
		}
		if (val === 'true') {
			return true;
		}
		return val;
	},
};

export default api;