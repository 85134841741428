import { convertToCdnUrl } from 'utils';
import store from 'datastore';

const currentUser = store.state.user;

const methods = {
	getBackgroundUrl (user) {
		if (currentUser.id && currentUser.settings.serious_business_mode) {
			return null;
		} else {
			const d = new Date();
			if (! (user && user.avatar && user.avatar.class)) {
				if (d.getMonth() === 11 || (d.getMonth() === 0 && d.getDate() < 11)) {
					return 'url(' + convertToCdnUrl('/img/member/avatar/background-1-xmas.gif') + ')';
				}
				return 'url(' + convertToCdnUrl('/img/member/avatar/background-1.gif') + ')';
			}

			let avatarUrl = 'background-' + user.avatar.class.id + (user.rankLevel > 8 ? '-gold-plus.gif' : '.gif');
			if (d.getMonth() === 11 || (d.getMonth() === 0 && d.getDate() < 11)) {
				avatarUrl = 'background-' + user.avatar.class.id + '-xmas.gif';
			}
			return 'url(' + convertToCdnUrl('/img/member/avatar/' + avatarUrl) + ')';
		}
	},
	avatarStyle (post) {
		const backgroundSize = 'cover';
		return {
			background: this.getBackgroundUrl(post.user),
			backgroundSize,
		};
	},
	avatarUrl (post) {
		if (currentUser.id && currentUser.settings.serious_business_mode) {
			return '/api/dynimg/icon/user/58585A';
		} else {
			if (post.user && ! post.isAnonymous) {
				return '/api/avatar/' + post.user.id;
			}
			return '/img/member/noface.png';
		}
	},
};

export default methods;
