<template>
	<div class="panel panel-product">
		<router-link :to="path" style="display: block; line-height: 0">
			<ximg
				:class="{ cover }"
				:src="imageUrl"
				:ariaLabel="highlight.product.name + ' product highlight'"
				role="img"
				:viewportMargin="1"
				@inViewport="$emit('inViewport')"
			>
				<!--<div v-if="energyLabel" class="ap-fill">
					<energy-rating class="ap-tl mt-3" style="height: 1.4em; margin-left: -3px;" :rating="energyLabel.rating" :scale="energyLabel.scale" />
					<eu-prod-info class="ap-bl rtr-3 pt-3 pl-3 pr-3 pb-0" />
				</div>
				<buy-button size="small" :product="highlight.product" class="ap-br m-4" />-->
			</ximg>
		</router-link>
		<router-link :to="path" class="p-3 highlight-info relative">
			<div class="highlight-text mr-3">
				<span class="fake-link" :class="nameClasses">{{ highlight.product.name }}</span><br/>
				<span class="highlight-subtitle">{{ highlight.description || '&nbsp;' }}</span>
			</div>
			<!--<product-price right :product="highlight.product" />-->
		</router-link>
	</div>
</template>

<script>
import { routeFromHighlight } from 'utils';

export default {
	name: 'productHighlight',
	props: {
		highlight: {
			type: Object,
			required: true,
		},
		cover: {
			type: Boolean,
			default: true,
		},
	},
	data () {
		return {
			routeFromHighlight,
		};
	},
	computed: {
		path () {
			return routeFromHighlight(this.highlight);
		},
		nameClasses () {
			if (! this.highlight.product.regularPrice) {
				return [
					'mx-3',
					'full-width-title',
				];
			}
		},
		imageUrl () {
			if (this.highlight.imageUrl) {
				return this.highlight.imageUrl + '?w=500';
			}
			return '/img/icons/no-image.svg';
		},
		previewUrl () {
			if (this.highlight.product.previewUrl) {
				return this.highlight.product.previewUrl;
			}
			return null;
		},
	},
};
</script>
