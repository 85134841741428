<template>
	<div class="fake-link energy-prod-info">{{ tp('DATASHEET') }}</div>
</template>

<script>
import { t } from 'datastore/i18n';

export default {
	props: {
	},
	computed: {
	},
	methods: {
		tp: t.prefix('PRODUCT'),
	},
};
</script>
