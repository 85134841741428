<template>
	<div v-if="showModule">
		<modal
			v-model="showModule"
			hideCloseButton
			containerStyle="width: 500px; max-width:100%; padding-bottom:20px;"
		>
			<div class="newsletter-popup">
				<h2>Webhallens Nyhetsbrev</h2>
				<p>Vill du ha de senaste produktnyheterna och medlemserbjudanden via vårt nyhetsbrev?</p>
				<div class="newsletter-settings">
					<text-button
						text="Ja, keep me posted!"
						@click="subscribeToNewsletter(true)"
					/>
					<text-button
						text="Nej, jag kör på Inbox Zero"
						variant="secondary"
						@click="subscribeToNewsletter(false)"
					/>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>

import alert from 'alert';
import bus from 'eventbus';
import store from 'datastore';
export default {
	data () {
		return {
			formData: null,
			showModule: false,
		};
	},
	mounted() {
		bus.$on('modal.newsletter-popup', this.toggleShowModule);
	},
	methods: {
		setSetting (name, value) {
			store.dispatch('user/setSetting', {
				name,
				value,
			});
		},
		toggleShowModule() {
			this.showModule = ! this.showModule;
		},
		subscribeToNewsletter(subscribe) {
			if (subscribe) {
				waja.get('profile-settings')
					.on('success', res => {
						let formData = res.data.settings;
						formData.shouldReceiveNewsletters = true;
						this.submit(formData);
					})
					.on('401', res => {
						next(error('404'));
					}).go();
			}
			this.showModule = false;
			this.setSetting('show_newsletter_popup', false);
		},
		submit (formData) {
			waja.post('profile-settings')
				.data(formData)
				.on('success', res => {
					this.$store.dispatch('user/refresh');
					alert('Ändringarna har sparats!');
				})
				.catch(res => {
					alert('Misslyckades med sparning!');
				})
				.go();
		},
	},
};
</script>
