<template>
	<div class="flames" :style="{ 'vertical-align': align ? 'text-top' : 'baseline' }" :title="title">
		<div :class="flamesContentClass" class="flames-content" :style="flamesContentStyle" />
	</div>
</template>

<script>
import { t } from 'datastore/i18n';
const maxFlames = 5;

export default {
	props: {
		flames: {
			type: [Number, String],
		},
		align: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		t,
	},
	computed: {
		title () {
			if (this.flames === null) {
				return this.t('REVIEW.NOT_ENOUGH');
			}
			return this.flames + ' / ' + maxFlames;
		},
		flamesContentStyle () {
			return {
				width: ((this.flames || 0) / maxFlames) * 100 + '%',
			};
		},
		flamesContentClass () {
			const flames = this.flames;
			if (flames <= 1) {
				return 'flames-content-1';
			} else if (flames > 1 && flames <= 2) {
				return 'flames-content-2';
			} else if (flames > 2 && flames <= 3) {
				return 'flames-content-3';
			} else if (flames > 3 && flames <= 4) {
				return 'flames-content-4';
			} else {
				return 'flames-content-5';
			}
		},
	},
};
</script>
