<template>
	<div class="panel panel-product" :title="highlight.description">
		<router-link :to="path" style="display: block; line-height: 0">
			<ximg
				:class="{ cover }"
				:src="imageUrl"
				:ariaLabel="highlight.campaign.name + ' campaign highlight'"
				role="img"
				:viewportMargin="1"
				@inViewport="$emit('inViewport')"
			/>
		</router-link>
		<router-link :to="path" class="p-3 highlight-info relative">
			<div class="highlight-text mr-3">
				<span class="fake-link">{{ highlight.campaign.name }}</span><br/>
				<span class="highlight-subtitle">{{ highlight.description || '&nbsp;' }}</span>
			</div>
		</router-link>
	</div>
</template>

<script>
import { routeFromHighlight, convertToCdnUrl } from 'utils';

export default {
	name: 'campaignHighlight',
	props: {
		highlight: {
			type: Object,
			required: true,
		},
		cover: {
			type: Boolean,
			default: true,
		},
	},
	data () {
		return {
			routeFromHighlight,
			convertToCdnUrl,
		};
	},
	computed: {
		path () {
			return routeFromHighlight(this.highlight);
		},
		imageUrl () {
			if (this.highlight.imageUrl) {
				return convertToCdnUrl(this.highlight.imageUrl + '?w=500');
			}
			return convertToCdnUrl('/img/icons/no-image.svg');
		},
	},
};
</script>
