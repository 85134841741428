import store from './index';

function defaultState () {
	return {
		isWritingComment: false,
		hoveredComment: null,
		lastHoveredComment: null,
	};
}

store.registerModule('comments', {
	namespaced: true,
	state: defaultState(),
	actions: {
		hoverComment ({ commit, state }, comment) {
			commit('setHoveredComment', comment);
		},
		deHoverComment ({ commit, state }, comment) {
			// If statement used to prevent the leave event of a prevoius comment,
			// which sometimes triggers after the mouseover event of the currently
			// hovered comment, from setting the hovered comment to null.
			if (state.hoveredComment && state.hoveredComment.id === comment.id) {
				commit('setHoveredComment', null);
			}
		},
	},
	mutations: {
		canWriteComment (state) {
			state.isWritingComment = false;
		},
		cantWriteComment (state) {
			state.isWritingComment = true;
		},
		setHoveredComment (state, comment) {
			state.hoveredComment = comment;
		},
	},
	getters: {
		canWriteComment (state) {
			return ! state.isWritingComment;
		},
		isWritingComment (state) {
			return state.isWritingComment;
		},
		getHoveredComment (state) {
			if (! state.isWritingComment) {
				return state.hoveredComment;
			}
			return null;
		},
	},
});
