export const footerLinks = {
	footerInfo: [
		{ linkTo: { name: 'info', params: { id: 8, name: 'Köpvillkor' } }, title: 'PURCHASE_TERMS', external: true },
		{ linkTo: { name: 'info', params: { id: 9, name: 'Leveransvillkor' } }, title: 'DELIVERY_TERMS', external: true },
		{ linkTo: { name: 'info', params: { id: 16, name: 'Garantivillkor' } }, title: 'WARRANTY_TERMS', external: true },
		{ linkTo: { name: 'info', params: { id: 15, name: 'Ångerrätt och öppet köp' } }, title: 'WITHDRAWAL', external: true },
		{ linkTo: { name: 'info', params: { id: 17, name: 'Reklamation' } }, title: 'COMPLAINT', external: true },
		{ linkTo: { name: 'info', params: { id: 25, name: 'Personuppgifter' } }, title: 'PERSONAL_DATA', external: true },
		{ linkTo: { name: 'info', params: { id: 26, name: 'Integritetspolicy' } }, title: 'INTEGRITY', external: true },
		{ title: 'Cookieinställningar', external: false },
		{ linkTo: { name: 'campaign', params: { id: 7866, name: 'Black Friday' } }, title: 'Black Friday', external: true },
	],
	footerAbout: [
		{ linkTo: { name: 'info', params: { id: 5, name: 'Våra butiker' } }, title: 'STORES', external: true },
		{ linkTo: { name: 'info', params: { id: 20, name: 'Om Webhallen' } }, title: 'ABOUT', external: true },
		{ linkTo: { name: 'info', params: { id: 35, name: 'Miljöpolicy' } }, title: 'ENVIRONMENTAL_POLICY', external: true },
		{ linkTo: { name: 'info', params: { id: 21, name: 'Lediga tjänster' } }, title: 'JOBS', external: true },
		{ linkTo: { name: 'info', params: { id: 34, name: 'Tillverkare' } }, title: 'MANUFACTURERS', external: true },
		{ linkTo: { name: 'campaign', params: { id: 8827, name: 'Webhallen Företag' } }, title: 'CORPORATE', external: true },
		{ linkTo: { name: 'info', params: { id: 28, name: 'Köp presentkort' } }, title: 'GIFT_CARDS', external: true },
		{ linkTo: { name: 'info', params: { id: 6, name: 'Medlemsförmåner' } }, title: 'MEMBER_PERKS', external: true },
	],
	footerDeliveryIcons: [
		{ src: '/img/logos/budbee.svg', aspectRatio: '401 / 100', alt: 'Budbee' },
		{ src: '/img/logos/new_instabox.svg', aspectRatio: '503 / 100', alt: 'Instabox' },
		{ src: '/img/logos/postnord.svg', aspectRatio: '133 / 25', alt: 'Postnord' },
		{ src: '/img/logos/airmee_footer_white.svg', aspectRatio: '430 / 100', alt: 'Airmee' },
	],
	footerLogoIcons: [
		{ src: '/img/logos/swish_white.svg', aspectRatio: '213 / 100', style: 'min-width: 80px; height: 100%;', w: '72', h: '40', alt: 'Swish' },
		{ src: '/img/logos/paypal_white.svg', aspectRatio: '61 / 15', style: 'min-width: 80px; height: 100%;', w: '72', h: '40', alt: 'PayPal' },
		{ src: '/img/logos/klarna.svg', aspectRatio: '9 / 5', style: 'min-width: 80px; height: 100%;', w: '72', h: '40', alt: 'Klarna' },
		{ src: '/img/logos/visa.png', aspectRatio: '343 / 100', style: 'min-width: 145px; height: 100%;', w: '137', h: '40', alt: 'Visa' },
		{ src: '/images/511502-trygg-ehandel', aspectRatio: '1 / 1', style: 'min-width: 48px; height: 100%;', w: '40', h: '40', alt: 'Trygg ehandel' },
		{ src: '/img/logos/prisjakt_onlinevaruhus_2017.svg', aspectRatio: '139 / 100', style: 'min-width: 66px; height: 100%;', w: '56', h: '40', alt: 'Prisjakt onlinevaruhus 2017' },
		{ src: '/images/656483-Webhallen-Main-version-MOTYbadge1x?raw', aspectRatio: '292 / 483', style: 'min-width: 48px; height: 100%;"', w: '40', h: '40', alt: 'PriceRunner Årets Ehandlare 2021' },
	],
	footerSocialIcons: [
		{ src: '/api/dynimg/socialmedia/facebook/ffffff', aspectRatio: '1 / 2', alt: 'Facebook' },
		{ src: '/api/dynimg/socialmedia/instagram/ffffff', aspectRatio: '1 / 1', alt: 'Instagram' },
		{ src: '/api/dynimg/socialmedia/tiktok/ffffff', aspectRatio: '26 / 30', alt: 'TikTok' },
		{ src: '/api/dynimg/socialmedia/twitch/ffffff', aspectRatio: '19 / 20', alt: 'Twitch' },
		{ src: '/api/dynimg/socialmedia/youtube/ffffff', aspectRatio: '29 / 20', alt: 'Youtube' },
		{ src: '/api/dynimg/socialmedia/discord/ffffff', aspectRatio: '131 / 100', alt: 'Discord' },
	],
};
