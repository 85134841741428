// Glöm inte att uppdatera i config/business_variables.php med!

export default {
	minimumPurchaseForFreeShipping: {
		SEK: 500,
	}, // @TODO andra valutor
	// @TODO läs från någon config som är delad mellan php och js?
	// Available values: voyadoElevate, supersearch, loop54.
	overrideEngine: {
		engine: 'voyadoElevate',
	}
};