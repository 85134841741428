<template>
	<aside class="left-col sticky-column" id="page-content-col-1">
		<side-nav />
		<div id="product-list-filter-anchor">
			<product-list-filters
				v-if="productListFiltersActive"
				v-show="showProductListFilters"
			/>
		</div>
	</aside>
</template>

<script>
import mq from 'mediaQuery';
import i18n from 'datastore/i18n';
import { prerender } from 'staticData';
import stickyColumn from 'vue-mixins/sticky-column';

export default {
	mixins: [stickyColumn],
	data () {
		return {
			mq,
			i18n,
			prerender,
		};
	},
	computed: {
		productListFiltersActive () {
			// @TODO bättre att styra detta från sidorna själva
			if (! (this.$route.name === 'campaigns' ||
				this.$route.name === 'category' ||
				this.$route.name === 'campaign' ||
				this.$route.name === 'search' ||
				this.$route.name === 'manufacturer' ||
				this.$route.name === 'manufacturer-page' ||
				this.$route.name === 'toplist' ||
				this.$route.name === 'article')) {
				return false;
			}

			return true;
		},
		showProductListFilters () {
			return ! prerender;
		},
		showPoll () {
			return this.$route.name === 'index' || this.$route.name === 'section';
		},
	},
};
</script>
