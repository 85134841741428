const container = document.getElementById('alert-bar');

function alert (message) {
	const wrapper = document.createElement('div');
	const el = document.createElement('div');
	wrapper.appendChild(el);
	el.classList.add('vanished');
	el.classList.add('toast-alert');
	el.innerText = message;
	const toastAlert = document.getElementsByClassName('toast-alert');
	const isToastZero = toastAlert.length === 0;
	const isMessageDiffrent = ! [...toastAlert].find(t => t.innerText === message);
	if (isToastZero || isMessageDiffrent) {
		container.appendChild(wrapper);
	}

	const removeAlert = () => {
		setTimeout(() => {
			if (wrapper.parentNode) {
				wrapper.parentNode.removeChild(wrapper);
			}
		}, 200);
		el.classList.add('vanished');
	};

	setTimeout(() => {
		el.classList.remove('vanished');
		el.addEventListener('click', removeAlert);
		setTimeout(removeAlert, 4000);
	}, 1);
}

export default alert;
