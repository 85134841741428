<template>
	<div
		style="z-index: 1"
		:class="['price-bubble', size !== 'large' ? '_condensed' : null]"
	>
		<bubble v-if="product.price.type === 'priceList'"
			:class="priceBubbleClass"
			:diameter="diameter"
			:border-width="borderWidth"
		>
			<img
				v-if="size === 'medium'"
				slot="over"
				class="price-icon"
				:src="iconSrc"
				alt="price icon"
			/>
			<div class="price-bubble-content" :style="size === 'small' ? 'margin-top: -4px' : null">
				<span class="price-savings" :style="'font-size: 20px;'">Unikt pris</span>
			</div>
		</bubble>
		<bubble v-else-if="product.isFyndware && (size !== 'small' || (priceSavings && priceSavings.percentage > 0))"
			class="_fyndware"
			:diameter="diameter"
			:border-width="borderWidth"
		>
			<img
				v-if="size === 'medium'"
				slot="over"
				class="price-icon"
				:src="priceTagIconMiniSrc"
				alt="price icon"
			/>
			<strong v-if="priceSavings && priceSavings.percentage > 0" :style="priceSavingsStyle" style="display: block">{{ formattedPriceSavings }}</strong>
			<div style="margin-left: -4px;" v-if="size === 'large'">
				<img :src="priceTagIconSrc" class="price-icon" style="width: 12px; height: 12px" alt="price icon" />
				<strong class="ml-2" style="font-size: 12px">FYNDVARA</strong>
			</div>
			<div v-if="size !== 'small'">
				<strong v-if="product.fyndwareClass" :style="{ fontSize: fyndwareClassFontSize + 'px' }" :class="fyndwareClass">{{ fyndwareClassText }}</strong>
			</div>
		</bubble>
		<!-- Releases today bubble -->
		<bubble v-else-if="daysUntilRelease !== undefined && daysUntilRelease >= -14 && daysUntilRelease <= 0"
			class="_release"
			:diameter="diameter"
			:border-width="borderWidth"
		>
			<template v-if="daysUntilRelease === 0">
				<div class="release-bubble-content" v-if="size !== 'small'">
					<span class="releases">SLÄPPT</span>
					<br>
					<span class="today">IDAG!</span>
				</div>
				<div class="release-bubble-content mt-0" v-else :style="{ fontSize: fontSize + 'px' }">
					NY!
				</div>
			</template>
			<template v-else>
				<div class="release-bubble-content" v-if="size === 'large'">
					<span class="new">NYHET!</span>
				</div>
				<div class="release-bubble-content mt-0" v-else :style="{ fontSize: fontSize + 'px' }">
					NY!
				</div>
			</template>
		</bubble>
		<!-- Savings bubble -->
		<bubble v-else-if="priceObject.comparisonPrice && formattedPriceSavings"
			:class="priceBubbleClass"
			:diameter="diameter"
			:border-width="borderWidth"
		>
			<img slot="over" v-if="size === 'medium'" class="price-icon" :src="iconSrc" alt="price icon" />
			<div class="price-bubble-content" :style="size === 'small' ? 'margin-top: -4px' : null">
				<span class="price-savings" :style="priceSavingsStyle">{{ formattedPriceSavings }}</span>
				<div class="price-type" v-if="size === 'large'">
					<img class="price-icon" :src="iconSrc" alt="price icon" />
					<span class="ml-2" :style="priceTypeStyle">{{ priceTypeText }}</span>
				</div>
			</div>
		</bubble>
		<bubble v-else-if="!! product.levelOnePrice && formattedPriceSavings"
			:class="priceBubbleClass"
			:diameter="diameter"
			:border-width="borderWidth"
		>
			<img slot="over" v-if="size === 'medium'" class="price-icon" :src="iconSrc" alt="price icon" />
			<div class="price-bubble-content" :style="size === 'small' ? 'margin-top: -4px' : null">
				<span class="price-savings" :style="priceSavingsStyle">{{ formattedPriceSavings }}</span>
				<div class="price-type" v-if="size === 'large'">
					<img class="price-icon" :src="iconSrc" alt="price icon" />
					<span class="ml-2" :style="priceTypeStyle">{{ priceTypeText }}</span>
				</div>
			</div>
		</bubble>
	</div>
</template>

<script>
import { formatPrice, getFyndwareCssClass, convertToCdnUrl, daysUntilRelease, getPriceObject, getPriceSavings } from 'utils';

export default {
	props: {
		product: {
			type: Object,
			default: () => null,
		},
		diameter: {
			type: String,
			default: '110px',
		},
		size: {
			type: String,
			default: 'large',
		},
		fontSize: {
			type: Number,
			default: 30,
		},
		fyndwareClassFontSize: {
			type: Number,
			default: 10,
		},
		excludeVat: {
			type: Boolean,
			default: false,
		},
	},
	data () {
		return {

		};
	},
	computed: {
		daysUntilRelease () {
			if (! this.product.release) {
				return undefined;
			}
			return daysUntilRelease(this.product.release);
		},
		icon () {
			const suffix = this.size !== 'large' ? '_mini' : '_white';
			let icon = 'campaign' + suffix;

			if (this.product.price.type === 'member'
			|| (this.product.levelOnePrice && ! this.isLoggedIn && this.product.price.type === null)) {
				icon = 'user_mini';
			} else if (this.product.price.type === 'adjustment') {
				icon = 'previous' + suffix;
			}

			return icon;
		},
		iconSrc () {
			return convertToCdnUrl('/img/icons/price/' + this.icon + '.svg');
		},
		priceTagIconSrc () {
			return convertToCdnUrl('/img/icons/categories/prislapp.svg');
		},
		priceTagIconMiniSrc () {
			return convertToCdnUrl('/img/icons/price/fyndware_mini.svg');
		},
		priceBubbleClass () {
			if (this.product.price.type === 'member'
			|| (this.product.levelOnePrice && ! this.isLoggedIn && this.product.price.type === null)) {
				return '_member';
			}
			return null;
		},
		priceTypeText () {
			// @TODO do we need adjustment?
			let typeText = 'KAMPANJ!';

			if (this.product.price.type === 'member'
			|| (this.product.levelOnePrice && ! this.isLoggedIn && this.product.price.type === null)) {
				typeText = 'MEDLEMSPRIS';
			} else if (this.product.price.type === 'adjustment') {
				typeText = 'PRISSÃNKT';
			}

			return typeText;
		},
		fyndwareClassText () {
			if (this.product.fyndwareClass) {
				if (this.size !== 'large' && this.product.fyndwareClass.shortName) {
					return this.product.fyndwareClass.shortName.toUpperCase();
				}
				return this.product.fyndwareClass.name.toUpperCase();
			}
			return '';
		},
		fyndwareClass () {
			return getFyndwareCssClass(this.product);
		},
		priceTypeStyle () {
			return {
				fontSize: (this.product.price.type === 'campaign' ? 12 : 11) + 'px',
			};
		},
		priceObject () {
			const prices = getPriceObject(this.product);

			/** If there is a visible levelOnePrice, the price bubble will instead
			 * use that as currentPrice and the currentPrice for comparisonPrice in
			 * order to calculate the priceSavings correctly
			 */
			if (prices.levelOnePrice && prices.levelOnePrice.price !== prices.currentPrice.price) {
				return {
					currentPrice: prices.levelOnePrice,
					comparisonPrice: prices.currentPrice,
				};
			} else {
				return prices;
			}
		},
		priceSavings () {
			return getPriceSavings(this.priceObject, this.excludeVat);
		},
		formattedPriceSavings () {
			if (this.priceSavings) {
				if ((this.priceObject.comparisonPrice?.price < 1000 || this.size === 'small') && this.priceSavings.percentage >= 10) {
					return '-' + this.priceSavings.percentage + '%';
				}
				if (this.priceObject.comparisonPrice?.price >= 1000
					&& (this.priceSavings.savings >= 80 && this.priceSavings.percentage >= 2 || this.priceSavings.savings >= 500)
					&& this.size !== 'small') {
					return '-' + formatPrice(this.priceSavings.savings, this.priceObject.currentPrice.currency) + '';
				}
			}
			return null;
		},
		priceSavingsStyle () {
			let size = this.fontSize;
			let scale = 0.8;
			if (this.formattedPriceSavings) {
				if (this.formattedPriceSavings.length <= 4) {
					scale = 1;
				} else if (this.formattedPriceSavings.length >= 9) {
					scale = 0.75;
				}
			}

			return {
				'font-size': Math.round(size * scale) + 'px',
			};
		},
		borderWidth () {
			if (this.size === 'small') {
				return 1;
			}
			if (this.size === 'medium') {
				return 2;
			}
			return 3;
		},
	},
};
</script>
