import app from 'app';
import { prerender } from 'staticData';
import waja from 'waja';

const initialVer = window.versionId.split('.');
let lastKnownVersion = initialVer;

const MAJOR = 0;
const MINOR = 1;
const PATCH = 2;

function checkUpdated (v1, v2) {
	// @TODO det är inte omöjligt att versionen på servern är lägre än på klienten
	if (v1[MAJOR] > v2[MAJOR]) {
		return MAJOR;
	} else if (v1[MAJOR] === v2[MAJOR]) {
		if (v1[MINOR] > v2[MINOR]) {
			return MINOR;
		} else if (v1[MINOR] === v2[MINOR] && v1[PATCH] > v2[PATCH]) {
			return PATCH;
		}
	}
	return false;
}

let shouldReload = false;

waja.addResponseInterceptor(res => {
	if (res.headers.get('Cache-Control') === 'public') {
		return;
	}
	const isAtCheckout = app.$route.name === 'checkout';

	if (shouldReload && ! isAtCheckout) {
		window.location.reload();
	}

	const verString = res.headers.get('Version');
	if (! verString) {
		return;
	}
	const ver = verString.split('.');

	const updated = checkUpdated(ver, lastKnownVersion);

	if (updated === false) {
		return;
	}

	lastKnownVersion = ver;

	switch (updated) {
		case MAJOR:
			if (isAtCheckout) { // Ladda inte om när kund är i kassan
				shouldReload = true;
			} else {
			// Prerendering server will manage reloading by itself.
				if (! prerender) {
					window.location.reload();
				}
			}
			break;
		case MINOR:
			console.log('Minor updated');
			// @TODO sätt flagga. föreslå för kund att reloada browsern.
			// om flaggan är satt och något blir knas så kan vi tvinga en reload
			break;
		case PATCH:
			console.log('Patch updated');
			// @TODO reloada om det behövs typ. kanske :)
			break;
	}
});

export function getInitialBuildNumber () {
	return initialVer[2];
};
export function getLastKnownMajorVersion () {
	return lastKnownVersion[MAJOR];
};
