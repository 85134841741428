import waja from 'waja';
import router from 'router';
! function() {
	function e() {
		waja.post('achievement/doge').go();
		function e() {
			0 > f && (a.drawImage(d, r - 350 + (s ? -0 : 0), l - 175), f = .25), m.style.left = r - 225 + 'px', m.style.top = l - 270 + 'px', p.style.left = r - 275 + 'px', p.style.top = l - 300 + 'px';
			var n = Date.now(),
				o = .001 * (n - h);
			f -= o, h = n;
			var c = o * (s ? -1024 : 1024);
			return r += c, (s ? -256 > r : r > i.width + 256) && (s = ! s, s ? m.classList.remove('mirrored') : m.classList.add('mirrored'), l += 310, l > i.height + 256) ? void t() : void window.requestAnimationFrame(e)
		}

		function t() {
			var e = document.createElement('div');
			e.style.position = 'fixed';
			e.style.top = '50%';
			e.style.left = '50%';
			e.style.zIndex = 1008, o.appendChild(e);

			var t = document.createElement('img');
			t.id='spinner';
			t.style.transition = 'width 2s';
			t.style.marginLeft = '-50%';
			t.style.marginTop = '-50%';
			t.width=0;
			e.appendChild(t);
			t.src='http://images.webhallen.com/files/doge_bg2.png',
			t.width = 2 * Math.max(window.innerWidth, window.innerHeight),

			m.style.left = i.width / 2 - 225 + 'px',
			m.style.top = i.height / 2 - 270 + 'px';

			var n, a = setInterval(function() {
				n = ! n, t.style.transform = n ? 'rotate(45deg)' : '';
			}, 200);
			setTimeout(function() {
				clearInterval(a), m.remove(), p.remove(), e.remove(), i.remove();
				router.go();
			}, 1e4);
		}
		document.head.innerHTML += '<style>.mirrored {-moz-transform:scaleX(-1);-o-transform: scaleX(-1);-webkit-transform: scaleX(-1);transform: scaleX(-1);filter: FlipH;-ms-filter: "FlipH";}</style>';
		var i = document.createElement('canvas');
		i.width = window.innerWidth, i.height = window.innerHeight;
		var n = i.style;
		n.zIndex = 1007, n.padding = 0, n.position = 'fixed', n.top = '0px', n.left = '0px';
		var o = document.body;
		o.appendChild(i);
		var a = i.getContext('2d'),
			r = -256,
			l = 128,
			s = ! 1,
			d = new Image();
		d.onload = function() {
			e();
		}, d.src = 'http://images.webhallen.com/files/doge_bite.png';
		var m = document.createElement('img');
		m.src = 'http://images.webhallen.com/files/doge_eats.gif', m.style.zIndex = 1009, o.appendChild(m);
		var p = document.createElement('img');
		p.src = 'http://images.webhallen.com/files/doge_text.gif', m.style.position = p.style.position = 'fixed', p.style.zIndex = 1010, o.appendChild(p);
		var h = Date.now(),
			f = 0;
		m.classList.add('mirrored');
	}
	var t = [], i = [65, 66, 39, 37, 39, 37, 40, 40, 38, 38];
	document.addEventListener('keydown',(n) => {
		if (i) {
			t.unshift({
				key: n.keyCode,
				time: Date.now(),
			});
			const o = t.length - i.length;
			if (o >= 0) {
				o > 0 && t.slice(0, i.length);
				for (var a = Date.now(), r = ! 0, l = 0; l < i.length; l++) {
					const s = t[l];
					(s.key !== i[l] || a - s.time > 5e3) && (r = ! 1);
				}
				r && (e(), i = null);
			}
		}
	});
}();