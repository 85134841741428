<template>
	<div class="ximg">
		<img
			:class="['ximg-content', { 'hide-content': ! source, 'loading': ! hasLoaded }]"
			:src="source"
			:alt="ariaLabel"
		/>
		<slot />
	</div>
</template>

<script>
import { prerender } from 'staticData';
import store from 'datastore';
import { mapState } from 'vuex';
import inViewport from 'vue-mixins/in-viewport';
import { convertToCdnUrl } from 'utils';

export default {
	mixins: [inViewport],
	store,
	props: {
		src: {
			type: String,
			required: false,
		},
		loadingSrc: {
			type: String,
			default: convertToCdnUrl('/img/loading_light.svg'),
		},
		fallback: {
			type: String,
			default: convertToCdnUrl('/img/no-image.svg'),
		},
		lazy: {
			type: Boolean,
			default: true,
		},
		previewUrl: {
			type: String,
			required: false,
		},
		noUpscale: {
			default: false,
		},
		align: {
			default: null,
		},
		eager: {
			type: Boolean,
			default: false,
		},
		ariaLabel: {
			type: [String, Object, Number],
			default: '',
			required: true,
		},
		role: {
			type: String,
			default: 'img',
			required: true,
		},
		viewportMargin: {
			type: Number,
			default: 128,
		}
	},
	data () {
		return {
			hasLoaded: false,
			isLoading: false,
			failed: false,
			transition: false,
			loadStart: null,
			hideLoadingSpinner: false,
			imageData: null,
			hasEmittedViewport: false,
		};
	},
	mounted () {
		if (this.isEager) {
			this.removeInViewportHandlers();
			this.$emit('inViewport'); // Prep for promotion tracking to only track when loaded
			this.hasEmittedViewport = true;
			return;
		}
		this.hideLoad();
		if (this.isLazy) {
			if (! this.inViewport) {
				this.inViewportMargin = this.viewportMargin;
				return;
			}
		} else {
			this.removeInViewportHandlers();
		}
		this.loadImg();
	},
	methods: {
		hideLoad () {
			// @TODO dela timeouts för att undvika onödigt overhead
			this.hideLoadingSpinner = true;
			setTimeout(() => {
				this.hideLoadingSpinner = false;
			}, 2000);
		},
		loadImg () {
			if (this.hasLoaded || ! this.src) {
				return;
			}
			this.isLoading = true;
			this.failed = false;
			const src = this.computedSrc;
			const img = new Image();
			this.loadStart = Date.now();
			this.imageData = null;
			const loadComplete = () => {
				if (this.computedSrc === src) {
					this.hasLoaded = true;
					this.failed = false;
					this.isLoading = false;
					this.removeInViewportHandlers();
				}
			};
			img.onload = function () {
				loadComplete();
			};
			img.onerror = () => {
				this.hasLoaded = true;
				this.failed = true;
				this.isLoading = false;
				this.removeInViewportHandlers();
			};
			img.src = src;
		},
	},
	bustCache (url) {
		const map = store.state.syncedStorage.ximgCacheBustMap || {};
		map[url] = Date.now();
		store.commit('syncedStorage/set', { key: 'ximgCacheBustMap', value: map });
	},
	computed: {
		isEager () {
			return this.eager !== false;
		},
		isLazy () {
			// Lazy is default so if eager is true, then it cancells lazy
			return this.eager === false && this.lazy !== false;
		},
		...mapState({
			cacheBustMap: state => (state.syncedStorage.ximgCacheBustMap || {}),
		}),
		source () {
			/*
			 The prerendering server will block image requests, so we want the
			 source to be the true img source and not the fallback src. That
			 way the receiver of the prerendered page will see the correct image,
			 but the prerendering server will avoid unnecessary loading times of the images.
			 */
			if (prerender) {
				return this.computedSrc;
			}

			if (this.isEager) {
				return this.computedSrc;
			}
			if (! this.hasLoaded && ! prerender) {
				if (this.previewUrl && this.inViewport) {
					return convertToCdnUrl(this.previewUrl);
				}
				if (this.hideLoadingSpinner) {
					return null;
				}
				return this.loadingSrc;
			}
			const computedSrc = this.computedSrc;
			return this.failed ? this.fallback : computedSrc;
		},
		computedSrc () {
			let src = this.src;
			const cacheBust = this.cacheBustMap[src];
			if (cacheBust) {
				const parameterChar = src.indexOf('?') > 0 ? '&' : '?';
				src += parameterChar + cacheBust;
			}
			return convertToCdnUrl(src);
		},
		readyForTracking () {
			return ! this.isEager && this.inViewport && this.hasLoaded && ! this.isLoading;
		}
	},
	watch: {
		src () {
			this.hideLoad();
		},
		source (to, frm) {
			if (frm === this.previewUrl && to === this.src && this.loadStart && (Date.now() - this.loadStart) > 50) {
				this.transition = true;
			} else {
				this.transition = false;
			}
		},
		computedSrc () {
			if (this.isLazy) {
				this.addInViewportHandlers();
			}
			this.hasLoaded = false;
			this.loadImg();
		},
		readyForTracking (to, frm) {
			if (! this.hasEmittedViewport && to) {
				this.$emit('inViewport'); // Prep for promotion tracking to only track when loaded
				this.hasEmittedViewport = true;
			}
		},
		inViewport (visible) {
			if (visible && ! this.isLoading && this.isLazy) {
				this.loadImg();
			}
		},
	},
};
</script>
