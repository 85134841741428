<template>
	<dropdown v-if="isLoggedIn && user.id" class="member-widget-dropdown" :check-id="checkid" tag="div" :label-class="['member-widget']" v-model="memberDropdown">
		<template slot="label">
			<div class="profile-picture-wrap">
				<span v-if="user.notificationCounter > 0" class="avatar-notification" />
				<ximg
					class="profile-picture pixelated r-2"
					:src="avatarImage"
					:style="avatarBackground"
					:ariaLabel="user.username"
					role="img"
				/>
			</div>
			<div class="member-logged-in-text">
				<div class="username">
					<router-link :to="memberPath('member.orders', 'ORDERS')" title="Medlemssidan">
						{{ user.username }}
					</router-link>
				</div>
				<xp-bar class="_small" :user="user" />
				<div class="member-level-text">
					lvl {{ user.rankLevel }}
					<span class="rank-name" :class="rankGroup.class" :data-text="rankGroup.dataText">{{ rankGroup.name }}</span>
				</div>
				<div class="user-links">
					<a @click.stop.prevent="logout">{{ tm('LOG_OUT') }}</a>
					<a v-if="user.isEmployee && ! user.showAdminPanel" class="admin" @click.prevent="$store.dispatch('user/toggleAdminPanel');">Visa admin</a>
				</div>
			</div>
			<div class="down-arrow _white" />
		</template>
		<label
			:for="checkid"
			class="cover-view"
		/>
		<!-- <div class="arrow-wrap">
			<div class="header-dropdown-arrow member-widget-dropdown-arrow"/>
		</div> -->

		<div class="header-dropdown p-5 mt-0 rt-0" style="z-index: 102">
			<div style="display: flex; justify-content: space-between;">
				<h2 class="username level-one-heading">
					<router-link :to="memberPath('member.orders', 'ORDERS')" title="Medlemssidan" class="secondary-link">
						{{ user.username }}
					</router-link>
				</h2>
				<icon-button
					variant="close"
					@click="close"
				/>
			</div>

			<template v-if="notificationSettings > 0">
				<div class="list-settings">
					<toggle-button
						v-model="filter"
						activeValue="member-menu"
						text="Mina sidor"
						sticky
					/>
					<toggle-button
						v-model="filter"
						activeValue="notifications"
						text="Aviseringar"
						:counter="user.notificationCounter"
						sticky
					/>
				</div>

				<div v-if="filter === 'notifications'" class="notification-wrapper">
					<div v-if="showOrderSection">
						<h3 class="level-two-heading">Aktiva beställningar</h3>
						<div
							v-for="(entry, index) in user.orderFeed"
							class="notification-card tile"
							:key="index + 'user-log' + entry.id"
							@click="patchPath(entry.path)"
						>
							<img
								:src="convertToCdnUrl('/img/member/feed_' + entry.avatarName + '.gif')"
								class="notification-img"
								:alt="entry.name"
							>
							<div>
								<div class="sub-title">{{ entry.name }}</div>
								<div class="semibold card-title">{{ entry.title }}</div>
							</div>
							<div class="right-column">
								<div class="sub-title ">{{ getEntryTime(entry.occuredAt) }}</div>
								<div :class="['notification-dot', { 'hidden': entry.dismissed }]" />
							</div>
						</div>
						<a-link :to="memberPath('member.orders', 'ORDERS')" center>
							Visa alla beställningar
						</a-link>
					</div>

					<div v-if="notificationSettings === 2">
						<h3 class="level-two-heading">Aviseringar</h3>
						<div
							v-for="(entry, index) in user.notificationFeed"
							class="notification-card tile"
							:key="index + 'user-log' + entry.id"
							@click="patchPath(entry.path)"
						>
							<img
								:src="convertToCdnUrl('/img/member/feed_' + entry.avatarName + '.gif')"
								class="notification-img"
								:alt="entry.name"
							>
							<div>
								<div class="sub-title">{{ entry.name }}</div>
								<div class="semibold card-title">{{ entry.title }}</div>
							</div>
							<div class="right-column">
								<div class="sub-title ">{{ getEntryTime(entry.occuredAt) }}</div>
								<div :class="['notification-dot', { 'hidden': entry.dismissed }]" />
							</div>
						</div>
					</div>
				</div>
			</template>

			<div v-if="filter === 'member-menu'">
				<div v-for="(group, index) in menuGroups" :key="'user-menu-' + index">
					<h3 class="mb-2">
						{{ group.name }}
					</h3>
					<ul class="list-unstyled">
						<li class="tile" v-for="(item, index) in group.items" :key="item.name + '-' + index">
							<router-link :to="item.route">
								<img
									:src="convertToCdnUrl(item.icon)"
									class="member-list-item-icon"
									:alt="tm(item.label)"
								>
								{{ tm(item.label) }}
							</router-link>
						</li>
					</ul>
				</div>
				<ul class="list-unstyled">
					<li class="tile">
						<a @click="logout">
							<img
								:src="convertToCdnUrl('/img/icons/member/loggaut.svg')"
								class="member-list-item-icon"
								:alt="tm('LOG_OUT')">
							{{ tm('LOG_OUT') }}
						</a>
					</li>
				</ul>
				<xp-bar :user="user" />
				<div class="clearfix"></div>
				<a class="secondary-link pull-right semibold" href="#" @click.stop="showMemberTutorial">
					<span class="glyphicon glyphicon-question-sign"/> {{ tm('TUTORIAL') }}
				</a>
				<div class="user-exp-text"><span>{{ formatNumber(user.experiencePoints) }}</span><span v-if="staticData.xpToNextRankLevel[user.rankLevel]" > / {{ formatNumber(staticData.xpToNextRankLevel[user.rankLevel]) }}</span> xp</div>
			</div>
		</div>
	</dropdown>

	<dropdown class="member-widget-dropdown" :check-id="checkid" v-model="loginOpen" v-else>
		<div slot="show" class="d-flex align-items-center" @click="toggleLoginMode()">
			<div class="icon-button-wrapper">
				<img class="icon-button" :src="convertToCdnUrl('/api/dynimg/icon/user/ffffff')" alt="login-toggle" />
			</div>
			<div class="member-widget-options">
				<a class="d-block" @click="toggleLoginMode('login')"><strong>{{ tm('LOG_IN') }}</strong></a>
				<a class="d-block" @click="toggleLoginMode('register')">{{ tm('CREATE_ACCOUNT') }}</a>
			</div>
		</div>
		<label
			v-if="loginMode !== 'miniPrompt'"
			:for="checkid"
			class="cover-view"
		/>
		<div class="arrow-wrap">
			<div class="header-dropdown-arrow member-widget-dropdown-arrow"/>
		</div>
		<member-form
			v-model="loginMode"
			:showCloseButton="loginMode !== 'miniPrompt'"
			@triggerMemberForm="(val) => toggleLoginMode(val)"
		/>
	</dropdown>
</template>

<script>
import bus from 'eventbus';
import store from 'datastore';
import { t } from 'datastore/i18n';
import * as staticData from 'staticData';
import memberUtils from 'utils/member';
import { mapState, mapGetters } from 'vuex';
import memberMenuItems from 'memberMenuItems';
import { convertToCdnUrl, formatNumber, urlFormat, standardRoute, memberRoute, getProductPath } from 'utils';
import input from 'input';
import waja from 'waja';
import isSameDay from 'date-fns/is_same_day';
import format from 'date-fns/format';
import svLocale from 'date-fns/locale/sv';

export default {
	store,
	props: {
		dissmissLoginPrompt: {
			type: Boolean,
			default: false,
		},
	},
	data () {
		return {
			loginMode: null,
			memberDropdown: false,
			triggeredDropdown: false,
			staticData,
			filter: 'member-menu',
			urlFormat,
		};
	},
	mounted () {
		bus.$on('open:login', () => { this.toggleLoginMode('login'); });
		bus.$on('open:register', () => { this.toggleLoginMode('register'); });
		bus.$on('keydown', this.onKeyDown);

		if (this.isLoggedIn) {
			this.showWelcomeMessages();
		}
		if (this.$route.query.email && this.$route.query.email != '' &&
			this.$route.query.orderId && this.$route.query.orderId != '' &&
			this.$route.query.secret && this.$route.query.secret != ''
		) {
			this.toggleLoginMode('register');
		}
	},
	beforeDestroy () {
		bus.$off('open:login');
		bus.$off('open:register');
		bus.$off('keydown', this.onKeyDown);
	},
	methods: {
		convertToCdnUrl,
		tm: t.prefix('MEMBER'),
		formatNumber: formatNumber,
		logout () {
			this.$store.dispatch('user/logout');
			setTimeout(function(){ bus.$emit('userLoggedInOrOut:loadProduct'); }, 700);
		},
		close () {
			bus.$emit('dropdown.close');
		},
		toggleLoginMode (mode) {
			if (this.triggeredDropdown) return;
			this.triggeredDropdown = true;
			setTimeout(() => (this.triggeredDropdown = false), 0);
			if (mode === undefined) this.loginMode = (this.loginMode ? null : 'login');
			else this.loginMode = mode !== this.loginMode ? mode : null;
		},
		showWelcomeMessages () {
			if (this.user.settings.show_member_tutorial) {
				this.$store.dispatch('user/setSetting', {
					name: 'show_member_tutorial',
					value: false,
				});
				this.showMemberTutorial();
			} else if (this.user.isCorporateCustomer) {
				this.loadAndShowFlashMessages();
			}
			if ((this.user.settings.show_newsletter_popup && ! this.user.settings.send_newsletter) || (this.$route.query.newsletterpopup && this.isLoggedIn && ! this.user.settings.send_newsletter)) {
				bus.$emit('modal.newsletter-popup');
			}
		},
		showMemberTutorial () {
			bus.$emit('modal.component', 'member-tutorial', 'member-tutorial-modal');
		},
		/**
		 * Shows flash messages for B2B customers.
		 */
		loadAndShowFlashMessages () {
			waja.get('flash-message')
				.on('success', res => {
					if (res.data.flashMessages.length) {
						const props = {
							flashMessages: res.data.flashMessages,
						};
						bus.$emit('modal.component', 'flash-messages', 'welcome-modal', props);

					}
				}).go();
		},
		onKeyDown(ev) {
			if (input.isKeyDown(16) && input.isKeyDown(18)) {
				this.$store.dispatch('user/toggleAdminPanel');
			}
		},
		getEntryTime (timestamp) {
			const today = new Date();
			const date = new Date(timestamp * 1000);
			if (isSameDay(today, date)) {
				return format(date, 'HH:mm');
			}
			return format(date, 'D MMM', {
				locale: svLocale,
			});
		},
		patchPath (path) {
			if (! path) return '';
			const name = path.name;
			const params = path.params || {};
			let route;

			if (name.indexOf('member') === 0) {
				if (params.id === undefined) {
					route = memberRoute(this.user, name);
				}
			}
			if (name.indexOf('product') === 0) {
				route = getProductPath(params.product, null, false);
			}

			return this.$router.push(route);
		},
		memberPath (page, label) {
			return this.$router.route(memberRoute(this.user, page, label));
		},
	},
	computed: {
		notificationSettings () {
			if (this.user.settings.notification_settings_level === 0) {
				this.filter = 'member-menu';
			}
			return this.user.settings.notification_settings_level;
		},
		seriousBusinessMode () {
			return this.user.id && this.user.settings.serious_business_mode;
		},
		menuGroups () {
			const groups = JSON.parse(JSON.stringify(memberMenuItems));

			for (let i = 0; i < groups.length; ++i) {
				const group = groups[i];
				if (this.seriousBusinessMode && ! group.isSeriousBusiness) {
					groups.splice(i--, 1);
					continue;
				}
				for (let j = 0; j < group.items.length; ++j) {
					const item = group.items[j];

					item.route = this.$router.route(memberRoute(this.user, item.name, item.label));
				}
			}

			return groups;
		},
		avatarBackground () {
			const background = memberUtils.getBackgroundUrl(this.user);
			if (! background) {
				return;
			}
			return {
				background,
				'background-size': 'cover',
			};
		},
		avatarImage () {
			if (this.seriousBusinessMode) {
				return '/api/dynimg/icon/user/FFFFFF';
			} else {
				return '/api/avatar/' + this.user.id;
			}
		},
		checkid () {
			return 'member-widget-' + this._uid;
		},
		rankGroup () {
			return staticData.rankGroups[staticData.rankGroupMap[this.user.rankLevel]];
		},
		dropdownIcon () {
			return this.memberDropdown ? 'glyphicon-triangle-top' : 'glyphicon-triangle-bottom';
		},
		showOrderSection () {
			return this.notificationSettings >= 1 && this.user.orderFeed.length > 0;
		},
		loginOpen: {
			get () {
				return !! this.loginMode;
			},
			set (val) {
				if (! val) this.loginMode = null;
			},
		},
		...mapState({
			user: state => state.user,
		}),
		...mapGetters({
			isLoggedIn: 'user/isLoggedIn',
		}),
	},
	watch: {
		$route () {
			this.memberDropdown = false;
			this.loginMode = null;
		},
		memberDropdown (to) {
			this.$emit('input', to);
			this.$emit('change', to);
		},
		loginOpen (to) {
			this.$emit('input', to);
			this.$emit('change', to);
			if (! to) {
				// When closing, remove all lastpass icons
				const lastpassIcons = document.querySelectorAll('*[id^="__lpform_input"]');
				for (const icon of lastpassIcons) {
					icon.remove();
				}

				if (! this.dissmissLoginPrompt && ! to) {
					this.$emit('update:dissmissLoginPrompt', true);
				}
			}
		},
		isLoggedIn (isLoggedIn) {
			this.memberDropdown = false;
			this.loginMode = null;

			if (this.$route.name !== 'member-offers' && isLoggedIn) {
				this.showWelcomeMessages();
			}
		},
		filter (to, from) {
			if (from === 'notifications' && to === 'member-menu') {
				store.dispatch('user/updateFeed');
			}
		},
		dissmissLoginPrompt (to, frm) {
			if (! this.isLoggedIn && ! to && frm === null) {
				setTimeout(() => { this.toggleLoginMode('miniPrompt') }, 2000);
			} else if (to && frm !== null) {
				this.toggleLoginMode();
			}
		},
	},
};
</script>
