import mediaQuery from 'mediaQuery';
import { isTouchDevice } from 'staticData';
import ResizeObserver from 'resize-observer-polyfill'; // @TODO ladda bara när det behövs

const listeners = [];

let lastScrollY = 0;

let updateQueued = false;

function doScrollUpdate () {
	updateQueued = false;
	lastRunAt = Date.now();

	if (! listeners.length) {
		return;
	}
	const delta = window.scrollY - lastScrollY;
	lastScrollY = window.scrollY;
	if (delta === 0) {
		return;
	}
	for (const i in listeners) {
		listeners[i].update(delta);
	}
}

let lastRunAt = 0;
let throttleLimit = 100;

function onScroll () { // Throttling för doScrollUpdate
	if (! updateQueued) {
		const now = Date.now();
		const timeSinceLastRun = now - lastRunAt;
		let needFastUpdates = false;
		for (const l of listeners) {
			if (! l.isLocked) {
				needFastUpdates = true;
				break;
			}
		}
		if (timeSinceLastRun >= throttleLimit || needFastUpdates) {
			doScrollUpdate();
			return;
		}
		updateQueued = true;
		setTimeout(doScrollUpdate, throttleLimit);
	}
}

// @TODO interval = let interval = null;

function addListener (instance) {
	if (listeners.indexOf(instance) >= 0) {
		return;
	}
	if (listeners.length === 0) {
		lastScrollY = window.scrollY;
		window.addEventListener('scroll', onScroll);
		// @TODO interval = setInterval(onScroll, 3000);
	}
	listeners.push(instance);
}

function removeListener (instance) {
	const index = listeners.indexOf(instance);
	if (index < 0) {
		return;
	}
	listeners.splice(index, 1);
	if (listeners.length === 0) {
		window.removeEventListener('scroll', onScroll);
		// @TODO interval = clearInterval(interval);
	}
}

export default {
	data () {
		return {
			mediaQuery,
			resizeObserver: null,
			elementHeight: 0,
			isLocked: false,
			lockedAtBottom: true,
			margin: 8,
			smallHeaderSpacing: 59,
			largeHeaderSpacing: 72, // @TODO hämta dynamiskt?
			siteNavHeight: 39,
			navbarMinWidth: 1052, // Minimum allowed window width for large mediaquery topnav
			elementId: null,
		};
	},
	mounted () {
		// @TODO check for compatibility?
		if (mediaQuery.current < mediaQuery.lg || isTouchDevice) {
			// Vi vill bara ha denna funktion på desktop
			return;
		}
		addListener(this);
		this.resizeObserver = new ResizeObserver((entries, obs) => {
			this.elementHeight = entries[0].contentRect.height;
		});
		this.$nextTick(() => {
			this.elementId = this.$el.id;

			let elementClassList = [ ...this.$el.classList];
			let sticky = elementClassList.includes('sticky-column');
			if (! sticky) {
				this.$el.classList.add('sticky-column');
			}
			this.elementHeight = this.$el.clientHeight;
			this.resizeObserver.observe(this.$el);
		});
	},
	beforeDestroy () {
		removeListener(this);
	},
	methods: {
		update (delta) { // Delta kommer aldrig vara 0
			if (this.isLocked) {
				if ((delta < 0) === this.lockedAtBottom) {
					this.unlock();
				}
			} else {
				const rect = this.$el.getBoundingClientRect();
				const wWidth = window.innerWidth;
				let navSpacing = 0;
				let headerSpacing = this.smallHeaderSpacing

				if (wWidth >= this.navbarMinWidth) {
					navSpacing = this.siteNavHeight;
					headerSpacing = this.largeHeaderSpacing;
				}

				if (delta < 0) {
					if (rect.top >= this.margin + headerSpacing + navSpacing) {
						this.lock(false);
					}
				} else {
					const wHeight = window.innerHeight;
					let availableHeight = wHeight - this.margin * 2 - headerSpacing - navSpacing;

					if (availableHeight > rect.height) {
						// Hela elementet får plats i viewport så vi vill hålla koll på toppen istället för botten
						if (rect.top <= this.margin) {
							this.lock(true);
						}
					} else {
						const distanceFromBottom = rect.bottom - wHeight;
						if (distanceFromBottom <= this.margin) {
							this.lock(true);
						}
					}
				}
			}
		},
		lock (atBottom) {
			this.lockedAtBottom = atBottom;
			this.isLocked = true;
			this.$el.style.position = '';
			this.$el.style.top = this.stickyTop + 'px';
		},
		unlock () {
			this.$el.style.top = this.$el.offsetTop + 'px';
			this.$el.style.position = 'relative';
			this.isLocked = false;

			updateQueued = false; // Detta tvingar nästa scrollevent att köra omedelbart så slipper vi vänta på någon timeout
		},
	},
	computed: {
		stickyTop () {
			let topMargin = this.margin;
			let bottomMargin = this.margin;

			if(this.elementId === 'admin-panel') {
				topMargin = 0;
				bottomMargin = 20;

				//Edge-case for pages smaller than admin-panel.
				const adminPanelHeight = this.elementHeight + bottomMargin + this.largeHeaderSpacing + this.smallHeaderSpacing + this.siteNavHeight;
				if (this.mediaQuery.pageHeight < adminPanelHeight) {
					this.$el.parentElement.style.minHeight = adminPanelHeight + 'px';
				}
			}
			if (! this.resizeObserver) {
				return null;
			}
			if (! this.lockedAtBottom) {
				const wWidth = window.innerWidth;

				let availableHeight = topMargin + this.smallHeaderSpacing;

				if (wWidth >= this.navbarMinWidth) {
					availableHeight = topMargin + this.largeHeaderSpacing + this.siteNavHeight;
				}
				return availableHeight;
			}
			return Math.min(topMargin, this.mediaQuery.pageHeight - this.elementHeight - bottomMargin);
		},
	},
	watch: {
		$route (to, frm) {
			if (to.name !== frm.name || JSON.stringify(to.params) !== JSON.stringify(frm.params)) {
				// "lås" positionen på skärmen för att förhindra att kolumnen håller sidan längre än innehållet
				this.lock(false);
			}
		},
		stickyTop (st) {
			if (this.isLocked) {
				this.$el.style.top = st + 'px';
			}
		},
	},
};
