import { getLastKnownMajorVersion } from 'apiVersionHeaderHandler';
import app from 'app';
import store from 'datastore';

// Callback function that will be notified when the current route requested by the
// prerendering server has finished loading.
let prerenderCallback = null;

// Page load status (State that must be managed by a page).
let pageLoaded = false;

// Menu load status (Managed by the root component).
let navLoaded = false;

// Data to send to prerendering server when navigation was successful or unsuccessful.
const data = {
	status: null,
	preMajorVersion: getLastKnownMajorVersion(),
	postMajorVersion: getLastKnownMajorVersion(),
	relativePath: '',
};

const status = {
	success: 'success',
};

/**
 * The prerendering API acts as an interface between the prerendering server and
 * the Vue application.
 */
const prerenderAPI = {
	status,
	/**
	 * Navigates the application to a url. When the navigation is successful
	 * and the page has been fully loaded, the callback function will be called.
	 *
	 * @param {string} url - The url to navigate to.
	 * @param {function} callback - A callback called when the naviation is successful.
	 */
	navigate (url, callback, viewport = null) {
		console.log('Prerender server navigating to: ', url);
		// @TODO move on nav loaded logic to datastore.
		navLoaded = store.getters['navStatus/isMenuLoaded'];

		// Reset navigation data.
		data.status = null;
		// Site version before and after navigation is complete.
		data.preMajorVersion = getLastKnownMajorVersion();
		data.postMajorVersion = getLastKnownMajorVersion();
		// Relative url.
		data.relativePath = '';

		prerenderCallback = callback;
		store.dispatch('prerender/goToUrl', url);
	},
	/**
	 * Tells the prerenderer that the nav has been rendered.
	 */
	onNavLoad () {
		navLoaded = true;
		data.status = status.success;
		notifyPrerenderer();
	},
	/**
	 * Notify that the prerenderer that the page has finished loading, or loaded
	 * with an error.
	 */
	onPageLoad () {
		pageLoaded = true;

		data.status = status.success;
		data.relativePath = app.$route.fullPath;

		notifyPrerenderer();
	},
	onNavigationError (status) {
		data.status = status;
		notifyPrerenderer();
	},
	/**
	 * The api major version was updated. The prerendering server will be notified
	 * of this.
	 */
	onMajorVersionUpdate () {
		// Set the new version.
		data.postMajorVersion = getLastKnownMajorVersion();
	},
	getSiteMajorVersion () {
		return getLastKnownMajorVersion();
	},
};

function notifyPrerenderer () {
	if (! prerenderCallback) {
		return;
	}
	if (data.status === status.success) {
		if (pageLoaded && navLoaded) {
			console.log('Notifying server of successful navigation.');
			prerenderCallback(data);
		}
	} else {
		console.log('Navigation error: ', data.status);
		prerenderCallback(data);
	}
	prerenderCallback = null;
}

export default prerenderAPI;
