import Vue from 'vue';
import store from 'datastore';


const bus = new Vue();
// @TODO vi kanske kan använda root-komponenten ist.

// @TODO focus event
function getBrowserPrefix () {
	// Check for the unprefixed property.
	if ('hidden' in document) {
		return null;
	}

	for (const prefix of ['moz', 'ms', 'o', 'webkit']) {
		if ((prefix + 'Hidden') in document) {
			return prefix;
		}
	}

	return false; // Not supported
}

// Get Browser Prefix
const prefix = getBrowserPrefix();
const hidden = prefix ? prefix + 'Hidden' : 'hidden';

document.addEventListener((prefix || '') + 'visibilitychange', function () {
	if (! document[hidden]) {
		bus.$emit('window.focus');
		console.log('%cWindow focused!', 'color: green');

		const value = JSON.parse(localStorage.getItem('isLoggedIn'));

		if (value == true) {
			store.dispatch('user/refresh', true);
		} else {
			store.commit('user/updateIsLoggedIn', false);
			store.commit('userSettings/starredStores', []);
		}
	}
});

export default bus;
