import { cloneDeep } from 'lodash';
import { formatPrice } from 'utils';
import { getLastRequestDone } from './actions';

import {
	getInsuredRows,
	getProductSum,
	getInsuranceSum,
	getCartSum,
	getProductSumExclVat,
	getCartSumExclVat,
	getMostExpensiveProduct,
	hasUnreleasedProducts
} from 'utils/cart';

export default {
	count: state => state.rows.reduce((sum, row) => sum + row.quantity, 0),
	numRows: state => state.rows.length,
	lastRequestDone: state => getLastRequestDone(),
	events: state => state.events,
	staticCopy (state, getters) {
		return {
			id: state.id,
			name: state.name,
			currency: state.currency,
			rows: cloneDeep(state.rows),
			count: getters.count,
		};
	},
	insuredRows (state) {
		return getInsuredRows(state);
	},
	/**
	 * Sum of products.
	 */
	sumProducts (state) {
		return getProductSum(state);
	},
	/**
	 * Sum of insurances.
	 */
	sumInsurances (state) {
		return getInsuranceSum(state);
	},
	/**
	 * Total sum of cart.
	 */
	sum (state) {
		return getCartSum(state);
	},
	/**
	 * Sum of products excluding VAT.
	 */
	sumProductsExclVat (state) {
		return getProductSumExclVat(state);
	},
	/**
	 * Total sum of cart excluding VAT.
	 */
	sumExclVat (state) {
		return getCartSumExclVat(state);
	},
	/**
	 * Formatted values.
	 */
	sumFormatted (state) {
		return formatPrice(getCartSum(state), state.currency);
	},
	sumFormattedProducts (state) {
		return formatPrice(getProductSum(state), state.currency);
	},
	sumFormattedInsurances (state) {
		return formatPrice(getInsuranceSum(state), state.currency);
	},
	sumFormattedExclVat (state) {
		return formatPrice(getCartSumExclVat(state), state.currency);
	},
	sumFormattedProductsExclVat (state) {
		return formatPrice(getProductSumExclVat(state), state.currency);
	},
	mostExpensiveProduct (state) {
		return getMostExpensiveProduct(state);
	},
	hasUnreleasedProducts (state) {
		return hasUnreleasedProducts(state);
	},
};
