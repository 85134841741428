<template>
	<div :class="['input-text', baseClass]">
		<input
			:class="['input-field', inputClass]"
			:style="increaseHeight ? 'height: 48px;' : ''"
			v-model="internalValue"
			:name="name"
			:id="name"
			:disabled="disabled"
			:readonly="readonly"
			:required="required"
			:type="type"
			:pattern="pattern"
			:inputmode="inputmode"
			novalidate
			@click.stop.prevent ref="input"
			@focus="active = true"
			@blur="active = false"
			@keypress="keyChange($event)"
		/>
		<label class="input-placeholder" :class="{disabled}" v-if="placeholder" :for="name">
			<span v-if="!error">{{ placeholder }}</span>
			<span v-if="error">{{ errorMessage }}</span>
		</label>
		<div class="input-marker" v-if="valid && useValidation">
			<span class="glyphicon glyphicon-ok" />
		</div>
		<div class="input-marker" v-if="error && useValidation">
			<span class="glyphicon glyphicon-remove" />
		</div>
		<div
			v-if="glyphicon"
			:class="[ 'glyphicon', 'glyphicon-' + glyphicon  ]"
			:style="increaseHeight ? 'top: 13%;' : ''"
		/>
	</div>
</template>

<script>
import { debounce } from 'lodash';

export default {
	data () {
		return {
			active: false,
			internalValue: this.value,
			error: false,
			valid: true,
			errorMessage: '',
		};
	},
	props: {
		value: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: 'text',
		},
		placeholder: {
			type: String,
			required: false,
		},
		icon: { // Använder klasser
			type: String,
			required: false,
		},
		glyphicon: { // Använder bootstraps glyphicons
			type: String,
			required: false,
		},
		validate: {
			type: Function,
			required: false,
		},
		name: {
			type: String,
			required: false,
		},
		pattern: {
			type: String,
			required: false,
		},
		inputmode: {
			type: String,
			required: false,
		},
		hideValid: {
			type: Boolean,
			required: false,
			default: true,
		},
		noValidation: {
			type: Boolean,
			default: true,
		},
		autofocus: {
			type: Boolean,
			default: false,
		},
		inputClasses: {
			type: Object,
			default: null,
		},
		checkoutValidation: {
			type: Boolean,
			required: false,
			default: false,
		},
		emailComparison: { // Om man vill confirm/verify email
			type: String,
			default: null,
			required: false,
		},
		passwordComparison: { // Om man vill confirm/verify password
			type: String,
			default: null,
			required: false,
		},
		/* this.submitMailError = {
			status: Bool, Sets error = true to inputfields upon unsuccessful submit
			placeholder: String, Sets specific error placeholder if needed
		}; */
		submitError: {
			type: Object,
			required: false,
		},
		triggerFocus: {
			type: Boolean,
			required: false,
			default: false,
		},
		increaseHeight: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	computed: {
		debouncedEmit () {
			//@TODO: Kolla på alternativ, eftersom vi inte vill spara i localstorage varje value change.
			return debounce(this.emitInternalValue, 1);
		},
		useValidation () {
			return this.noValidation === false;
		},
		baseClass () {
			return [
				{
					'has-icon': !! (this.icon || this.glyphicon),
					'has-placeholder': !! this.placeholder,
					'active': this.active,
					'has-error': this.error && ! this.active,
					'has-feedback': (this.error || this.valid) && ! this.active,
					'disabled': this.disabled,
				},
				this.icon ? 'icon-' + this.icon : null,
			];
		},
		inputClass () {
			const obj = {
				nonempty: !! this.internalValue,
			};
			if (this.inputClasses) {
				Object.assign(obj, this.inputClasses);
			}
			return obj;
		},
	},
	methods: {
		keyChange (evt) {
			// Disables certain characters for specific input types
			if (this.name === 'ssn' && this.internalValue.length >= 12) {
				evt.preventDefault();
			}
			if (this.type === 'number' || this.type === 'tel') {
				const charCode = (evt.which) ? evt.which : event.keyCode;
				if (charCode > 31 && (charCode < 48 || charCode > 57)) {
					evt.preventDefault();
				}
				return true;
			}
			if (this.type === 'email') {
				const charCode = (evt.which) ? evt.which : event.keyCode;
				if (charCode === 32) {
					evt.preventDefault();
				}
				return true;
			}
		},
		emitInternalValue () {
			this.$emit('input', this.internalValue);
		},
		blurValidation () {
			if (! this.internalValue && this.required ) {
				this.error = true;
				this.valid = false;
				this.errorMessage = this.placeholder;
				// @TODO: om man vill ha specifika felmeddelanden för tomma fält -> gör en funktion
			} else if (this.internalValue) {
				switch (this.type) {
					case 'email':
						this.validateEmail();
						break;
					case 'tel':
						this.validatePhone();
						break;
				}

				switch (this.name) {
					case 'first-name':
					case 'last-name':
					case 'firstName':
					case 'lastName':
						this.validateName();
						break;
					case 'zip':
					case 'zipCode':
					case 'delivery_zip':
						this.validateZip();
						break;
					case 'username':
						this.validateUsername();
						break;
					case 'corpNumber':
						this.validateCorpNumber();
						break;
					case 'verifypassword':
					case 'newPasswordRepeat':
						this.comparePassword();
						break;
					case 'ssn':
						this.validateSsn();
						break;
					case 'invoice-reference':
						this.validateReference();
						break;
				}
			}
		},
		focus () {
			this.$refs.input.focus();
		},
		// @TODO: As more components use the blurValidation, check if this should be updated or deleted
		doValidate (value) {
			if (value === '' && ! this.required) {
				this.error = false;
				this.valid = false;
			} else if (! this.validate) {
				this.error = false;
				this.valid = ! this.hideValid;
			} else if (this.validate(value)) {
				this.error = false;
				this.valid = ! this.hideValid;
			} else {
				this.errorMessage = this.placeholder;
				this.error = true;
				this.valid = false;
			}
		},
		formatPlaceholder () {
			return this.placeholder.charAt(0).toLowerCase() + this.placeholder.slice(1);
		},
		validateEmail () {
			if (! this.internalValue.match(/^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*"|(".+"))@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/gi)) {
				this.error = true;
				this.valid = false;
				this.errorMessage = 'Ogiltig e-postadress';
			} else if (this.emailComparison) {
				this.compareEmail();
			}
		},
		compareEmail () {
			if (this.internalValue === this.emailComparison) {
				this.error = false;
				this.valid = true;
			} else {
				this.error = true;
				this.valid = false;
				this.errorMessage = 'E-postadresserna matchar inte!';
			}
		},
		validatePhone () {
			// @TODO: Kontrollera regex
			if (! this.internalValue.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g)) {
				this.error = true;
				this.valid = false;
				this.errorMessage = 'Ogiltigt telefonnummer';
			} else if (this.internalValue.length < 10  && (this.name === 'mobile' || this.name === 'delivery_phone')) {
				this.error = true;
				this.valid = false;
				this.errorMessage = 'Ange ett tiosiffrigt mobilnummer';
			}
		},
		validateName () {
			if (! this.internalValue.match(/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]*$/g)) {
				this.error = true;
				this.valid = false;
				this.errorMessage = 'Ogiltigt ' + this.formatPlaceholder();
			}
		},
		validateZip () {
			if (this.internalValue.length < 5) {
				this.error = true;
				this.valid = false;
				this.errorMessage = 'Ogiltigt ' + this.formatPlaceholder();
			}
		},
		validateUsername () {
			if (this.internalValue.length < 4) {
				this.error = true;
				this.valid = false;
				this.errorMessage = this.placeholder + ' behöver vara minst 4 tecken';
			} else if (! this.internalValue.match(/^[a-zA-Z0-9_.åäöæøÅÄÖÆØ-]{4,50}$/g)) {
				this.error = true;
				this.valid = false;
				this.errorMessage = 'Ogiltiga tecken i användarnamnet';
			}
		},
		validateCorpNumber () {
			if (! this.internalValue.match(/^[0-9_ -]+$/g)) {
				this.error = true;
				this.valid = false;
				this.errorMessage = 'Ogiltigt ' + this.formatPlaceholder();
			}
		},
		comparePassword () {
			if (this.internalValue === this.passwordComparison) {
				this.error = false;
				this.valid = true;
			} else {
				this.error = true;
				this.valid = false;
				this.errorMessage = 'Lösenorden matchar inte';
			}
		},
		validateSsn () {
			if (this.internalValue.length !== 10 && this.internalValue.length !== 12) {
				this.error = true;
				this.valid = false;
				this.errorMessage = 'Ogiltigt ' + this.formatPlaceholder();
			}
			// If a 12 digit ssn is entered, check if its from a valid decade
			if (this.internalValue.length === 12) {
				const decade = this.internalValue.substring(0, 2);
				if (decade != 19 && decade != 20 && decade != 21) {
					this.error = true;
					this.valid = false;
					this.errorMessage = 'Ogiltigt ' + this.formatPlaceholder();
				}
			}
		},
		validateReference() {
			const trimmed = this.internalValue.replace(/\s+/g, '');
			if (trimmed.length < 3) {
				this.error = true;
				this.valid = false;
				this.errorMessage = 'Referens på fakturan';
			}
		},
	},
	mounted () {
		let error = false;
		let valid = false;

		// Checks if pre-filled inputs have errors
		if (this.internalValue && this.checkoutValidation) {
			this.blurValidation();
			error = this.error;
			valid = this.valid;
		}

		// Checks non required fields
		if (! this.required && this.checkoutValidation) {
			this.blurValidation();
			error = this.error;
			valid = this.valid;
		}

		if (this.internalValue) this.doValidate(this.internalValue);
		if (this.autofocus !== false) {
			this.$nextTick(() => {
				if (this.$refs.input) {
					this.$refs.input.focus();
				}
			});
		}
		this.error = error;
		this.valid = valid;

		// Emits if inputs are valid or not on mount
		this.$emit('inputValid', {valid:this.valid, name:this.name});
	},
	watch: {
		value (to) {
			this.internalValue = to;
		},
		internalValue (to) {
			if (to === '' && this.required) {
				this.errorMessage = this.placeholder;
				this.error = true;
				this.valid = false;
			} else {
				this.error = false;
				this.valid = true;

				if (! this.checkoutValidation) {
					this.doValidate(to);
				}
			}

			// Failsafe for some mobiledevices that allows non numerical keypresses for number inputs
			if (this.internalValue && this.internalValue.match(/[^0-9]/g) && (this.type === 'number' || this.type === 'tel')) {;
				this.internalValue = this.internalValue.replace(/[^0-9]/g, '');
				this.$emit('input', this.internalValue);
			} else {
				this.debouncedEmit();
			}
		},
		active (to) {
			if (! to) {
				this.$emit('change', to);
				// Can be changed to this.required if all components are gonna use this
				if (this.checkoutValidation) {
					this.blurValidation();
				}
				this.$emit('inputValid', {valid:this.valid, name:this.name});
			}
		},
		// Watches value so it recognizes if data in first email field is updated to match second field
		emailComparison (to) {
			if (this.internalValue && this.emailComparison) {
				this.compareEmail();
			}
		},
		// Watches value so it recognizes if data in first password field is updated to match second field
		passwordComparison (to) {
			if (this.internalValue && this.passwordComparison) {
				this.comparePassword();
			}
		},
		valid (to) {
			this.$emit('inputValid', {valid:to, name:this.name});
		},
		// Triggered when switching between costumer types in registration
		name (to) {
			if (! this.value) {
				this.error = false;
				this.valid = false;
			} else {
				if (this.checkoutValidation){
					this.blurValidation();
				}
			}
			this.$emit('inputValid', {valid:this.valid, name:this.name});
		},
		submitError: {
			handler (to) {
				if (to) {
					// Checks if there is an error on submit, true = error
					if (to.status) {
						this.error = true;
						this.valid = false;
						this.errorMessage = this.placeholder;
						if (to.placeholder) {
							this.errorMessage = to.placeholder;
						} else {
							this.errorMessage = this.placeholder;
							if (this.checkoutValidation){
								this.blurValidation();
							}
						}
					} else {
						this.error = to.status;
						if (this.internalValue && this.checkoutValidation) {
							this.blurValidation();
						}
					}
				}
			},
			deep: true,
		},
		triggerFocus (to) {
			if (to) {
				this.focus();
			}
		},
	},
};
</script>
