const Symbol = require('es6-symbol');
const baseURL = '/api/';

const reauthLevel = {
	alwaysWithPW: Symbol('secure'),
	always: Symbol('always'),
	preferred: Symbol('exists'),
	userSpecified: Symbol('user'),
	never: Symbol('never'),
};

const exceptionSymbol = Symbol('Exception');

const scopes = {};

export {
	reauthLevel,
	exceptionSymbol,
	scopes,
	baseURL,
};
