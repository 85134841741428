import getters from './getters';
import store from '../index';
import actions from './actions';
import mutations from './mutations';

function defaultState () {
	return {
		id: null,
		name: null,
		currency: 'SEK',
		rows: [],
	};
}

store.registerModule('cart', {
	namespaced: true,
	state: Object.assign({
		events: [],
		analytics: null,
		lastUpdate: 0,
	}, defaultState()),
	getters,
	mutations,
	actions,
});

import bus from 'eventbus';
bus.$on('window.focus', () => {
	// const secondsSinceLastUpdate = (Date.now() - store.state.cart.lastUpdate) / 1000;
	//if (secondsSinceLastUpdate >= 180) {
	// @TODO Måste ha gått minst tre (antal minuter ej skrivet i sten) minuter
	// för att det ska vara värt att ladda om kundvagnen
	// MEN, innan vi kan införa denna begränsning behöver kundvagnsändringar
	// synkas mellan flikar (just nu funkar detta istället för synkning, som kan göras utan api-anrop med synced-events)
	// https://repo.webhallen.com/dev/site-3-0/issues/125

	if (window.location.pathname != '/se/checkout') {
		store.dispatch('cart/refresh');
	}

});

/**
 * Do not remove! Creates a delay of 10ms when the tab has focus and allows the DOM to finish loading before executing this request
 * Works for hard reload
 */
setTimeout(() => { store.dispatch('cart/refresh'); }, 0);

export {
	defaultState,
};