<template>
	<div v-if="showSideNav" class="side-nav-wrap">
		<!-- Side nav header -->
		<!-- Section / category page header -->
		<div
			v-if="showCategories"
			class="side-nav-header"
			@click="click"
		>
			<div class="nav-path">
				<router-link :to="formatBackPath" class="back-arrow">
					<template v-if="currentPath.length > 1">
						{{ currentPath[1].name }}
					</template>
					<template v-else>
						{{ t('NAV.HOME') }}
					</template>
				</router-link>
			</div>
			<h2>
				<router-link :to="formatHeadPath">
					{{ currentPath[0].name }}
				</router-link>
			</h2>
		</div>
		<!-- Home page / campaigns page -->
		<div v-else class="side-nav-header">
			<div v-if="onCampaignPage" @click="click">
				<div class="nav-path">
					<router-link :to="formatBackPath" class="back-arrow">
						{{ t('NAV.HOME') }}
					</router-link>
				</div>
				<h2>
					<router-link :to="campaignListPath(null)">
						Kampanjer
					</router-link>
				</h2>
			</div>
			<h2 v-else>
				{{ t('NAV.CATEGORIES') }}
			</h2>
		</div>
		<!-- Side nav content (sections / categories) -->
		<!-- When the current page is a section / category  -->
		<ul v-if="showCategories" class="nav">
			<template v-if="onManufacturerPage">
				<li
					v-for="category in currentPath[0].subCategories || currentPath[0].categories"
					class="tile"
					:key="category.id"
					@click="click"
				>
					<router-link
						:to="manufacturerPagePath(category, $route.params)"
						:class="{ active: currentCategoryId === category.id }"
					>
						<ximg
							v-if="category.icon"
							class="mr-2"
							:src="iconUrl(category.icon)"
							ariaLabel="campaign icon"
							role="img"
						/>
						{{ category.name }}
						<div class="bg ap-fill"></div>
					</router-link>
				</li>
			</template>
			<template v-else>
					<li
						v-for="category in currentPath[0].subCategories || currentPath[0].categories"
						class="tile"
						:key="category.id"
						@click="click"
					>
						<router-link
							:to="navigationPath('category', category)"
							:class="{ active: currentCategoryId === category.id }">
							<ximg
								v-if="category.icon"
								:src="iconUrl(category.icon)"
								ariaLabel="campaign icon"
								role="img"
							/>
							{{ category.name }}
						</router-link>
					</li>
					<li
						v-for="link in staticLinks"
						class="tile"
						:key="link.to.id"
					>
					<!-- Hard coded links -->
						<a-link :to="link.to">
							<ximg
								v-if="link.icon"
								class="mr-2"
								:src="iconUrl(link.icon)"
								ariaLabel="link icon"
								role="img"
							/>
							{{ link.name }}
						</a-link>
					</li>
					<!-- Link to the campaign page for the active section (hidden in the fyndware section). -->
					<li
						v-if="currentPath.length === 1 && navStatus.section.id !== sectionIds.fyndwares"
						class="tile"
					>
						<router-link
							:class="{ active: onCampaignPage }"
							:to="campaignListPath(navStatus.section)"
						>
							<ximg
								:src="campaignIconUrl"
								ariaLabel="campaign icon"
								role="img"
							/>
							Kampanjer
						</router-link>
					</li>
				<!-- </li> -->
			</template>
		</ul>
		<!-- When the current page is the home page / campaign list page -->
		<ul class="nav" v-else-if="showSections">
			<template v-for="section in sections">
				<li
					v-if="onManufacturerPage"
					class="tile"
					@click="click"
					:key="'manufacturer-campaign-list-' + section.id"
				>
					<router-link
						:to="manufacturerPagePath(section, $route.params)"
						:class="{ active: navStatus.section && navStatus.section.id == section.id }"
					>
						<ximg
							v-if="section.icon"
							class="mr-2"
							:src="iconUrl(section.icon)"
							ariaLabel="section icon"
							role="img"
						/>
						{{ section.name }}
					</router-link>
				</li>
				<li
					v-else-if="! onCampaignPage"
					class="tile"
					@click="click"
					:key="'campaign-list-' + section.id"
				>
					<router-link :to="navigationPath('section', section)">
						<ximg
							v-if="section.icon"
							class="mr-2"
							:src="iconUrl(section.icon)"
							ariaLabel="section icon"
							role="img"
						/>
						{{ section.name }}
					</router-link>
				</li>
				<li
					v-else-if="section.id !== sectionIds.fyndwares"
					class="tile"
					:key="'section-campaign-list-' + section.id"
					@click="click"
				>
					<router-link
						:to="campaignListPath(section)"
						:class="{ active: navStatus.section && navStatus.section.id == section.id }"
					>
						<ximg
							v-if="section.icon"
							:src="iconUrl(section.icon)"
							ariaLabel="section icon"
							role="img"
						/>
						{{ section.name }}
					</router-link>
					<!-- @TODO dropdown for categories in a section, does not seem to be used -->
				</li>
			</template>
			<li v-if="! onCampaignPage && ! onManufacturerPage" class="tile" @click="click">
				<router-link :to="campaignListPath(null)">
					<ximg
						class="mr-2"
						src="/api/dynimg/icon/campaign/1A1A1D"
						ariaLabel="campaign icon"
						role="img"
					/>
					Kampanjer
				</router-link>
			</li>
		</ul>
		<div v-else>
			<loading-spinner v-if="navStatus.menu.status === 0" />
			<h2 v-else-if="navStatus.menu.status === -1">{{ navStatus.menu.msg }}</h2>
		</div>
	</div>
</template>

<script>
import router from 'router';
import store from 'datastore';
import { mapState } from 'vuex';
import { t } from 'datastore/i18n';
import { sectionIds } from 'staticData';
import { sectionLinks } from 'staticNavLinks';
import { categoryIconUrl, manufacturerPagePath, headPath, backPath, standardRoute } from 'utils';
import { trackEvent } from 'gtm';

export default {
	store,
	name: 'side-nav',
	data () {
		return {
			sectionIds,
		};
	},
	computed: {
		showSideNav () {
			return this.$route.name !== 'search' &&
				this.$route.name !== 'campaign' &&
				this.$route.name !== 'toplist';
		},
		sections () {
			if (this.onManufacturerPage && this.navStatus.menu.manufacturerTree) {
				return this.navStatus.menu.manufacturerTree;
			}
			return this.navStatus.menu.staticTree || [];
		},
		staticLinks () {
			if (! this.showCategories) {
				return [];
			}

			if (this.$route.name === 'section') {
				if (sectionLinks[this.navStatus.section.id]) {
					return sectionLinks[this.navStatus.section.id];
				}
			}

			return [];
		},
		displayCategories () {
			if (this.onCampaignPage) {
				return false;
			}
			return true;
		},
		showSections () {
			if (this.navStatus.menu.status === 1 && ! this.currentPath.length) {
				return true;
			}
			return false;
		},
		showCategories () {
			return this.navStatus.menu.status === 1 && this.currentPath.length;
		},
		campaignIconUrl () {
			return categoryIconUrl('kampanjer');
		},
		onCampaignPage () {
			return this.$route.name === 'campaigns';
		},
		onManufacturerPage () {
			return this.$route.name === 'manufacturer' || this.$route.name === 'manufacturer-page';
		},
		formatBackPath () {
			const item = {
				currentPath: this.currentPath,
				sectionName: this.navStatus?.section?.name ?? null,
				path: this.navStatus.categories,
			};

			return backPath(item, this.onManufacturerPage, this.$route.params);
		},
		formatHeadPath () {
			const item = {
				currentPath: this.currentPath,
				sectionName: this.navStatus?.section?.name ?? null,
				path: this.navStatus.categories,
			};

			return headPath(item, this.onManufacturerPage, this.$route.params);
		},
		currentCategoryId () {
			const nav = this.navStatus;
			if (! nav.categories.length) {
				return null;
			}
			return nav.categories[nav.categories.length - 1].id;
		},
		currentPath () {
			if (! this.navStatus.section) {
				return [];
			}
			let children = [];
			const path = [];
			for (const section of this.sections) {
				if (section.id == this.navStatus.section.id) {
					path.push(section);
					children = section.categories;
					break;
				}
			}

			for (let pathCategoryIndex = 0; pathCategoryIndex < this.navStatus.categories.length; pathCategoryIndex++) {
				const needle = this.navStatus.categories[pathCategoryIndex].id;
				for (const category of children) {
					if (category.id === needle) {
						if (! category.subCategories.length) {
							break;
						}
						path.push(category);
						children = category.subCategories;
						break;
					}
				}
			}
			return path.reverse();
		},
		...mapState({
			navStatus: state => state.navStatus,
		}),
	},
	methods: {
		t,
		manufacturerPagePath,
		style (section) {
			return { 'border-left-color': section.color };
		},
		iconUrl (icon) {
			return categoryIconUrl(icon);
		},
		navigationPath (name, item) {
			// Added extra data to handle category paths
			const extraDataItem = {
				sectionName: this.navStatus.section?.name ?? item.name,
				path: this.navStatus.categories,
				...item,
			}
			return router.route(standardRoute(name, extraDataItem));
		},
		campaignListPath (section) {
			if (section) {
				return router.route(standardRoute('campaigns', section));
			}

			return router.route(standardRoute('campaigns', { name: 'campaigns' }));
		},
		click (event) {
			trackEvent('gaEvent', 'Interactions', 'Menu Click', 'Side Menu');
		},
	},
};
</script>
