const rankGroups = [
	{
		name: 'Bronze',
		iconName: 'bronze.svg',
		toLevel: 4,
		class: 'rank-bronze',
	},
	{
		name: 'Silver',
		iconName: 'silver.svg',
		toLevel: 8,
		class: 'rank-silver',
	},
	{
		name: 'Gold',
		iconName: 'gold.svg',
		toLevel: 12,
		class: 'rank-gold',
	},
	{
		name: 'White',
		iconName: 'white.svg',
		toLevel: 16,
		class: 'rank-white',
	},
	{
		name: 'Black',
		iconName: 'black.svg',
		toLevel: 20,
		class: 'rank-black',
	},
	{
		name: 'V O I D',
		iconName: 'void.svg',
		toLevel: 24,
		class: 'rank-void',
		dataText: 'V O I D',
	},
	{
		name: 'LEGEND',
		iconName: 'legend.svg',
		toLevel: 25,
		class: 'rank-legend',
		dataText: 'LEGEND',
	},
];

const rankGroupMap = rankGroups.reduce((map, group, index) => {
	for (let i = map.length; i <= group.toLevel; i++) {
		map[i] = index;
	}
	return map;
}, []);

const xpToNextRankLevel = [
	0, // Lv 1
	3500, // Lv 2
	7500, // Lv 3
	12000, // Lv 4
	17500, // Lv 5
	33000, // Lv 6
	48750, // Lv 7
	64500, // Lv 8
	80000, // Lv 9
	120000, // Lv 10
	160000, // Lv 11
	200000, // Lv 12
	250000, // Lv 13
	320000, // Lv 14
	390000, // Lv 15
	460000, // Lv 16
	525000, // Lv 17
	625000, // Lv 18
	725000, // Lv 19
	825000, // Lv 20
	925000, // Lv 21
	1025000, // Lv 22
	1125000, // Lv 23
	1225000, // Lv 24
	2345678, // Lv 25
];

const achievementsToNextRankLevel = [
	0, // Lv 1
	0, // Lv 2
	0, // Lv 3
	0, // Lv 4
	0, // Lv 5
	0, // Lv 6
	0, // Lv 7
	0, // Lv 8
	0, // Lv 9
	0, // Lv 10
	0, // Lv 11
	0, // Lv 12
	0, // Lv 13
	0, // Lv 14
	0, // Lv 15
	0, // Lv 16
	0, // Lv 17
	0, // Lv 18
	0, // Lv 19
	0, // Lv 20
	200, // Lv 21
	250, // Lv 22
	350, // Lv 23
	500, // Lv 24
	700, // Lv 25
];

// Hardcoded ids for sections.
const sectionIds = {
	gaming: 13,
	apple: 14,
	fyndwares: 18,
	generic: 20,
	campaigns: 21,
};

const fyndwareClasses = {
	one: 1,
	two: 2,
	three: 3,
	four: 4,
	five: 5,
	bulk: 10,
	refurb: 13,
	damagedContainer: 11,
};

// Customer service form mail fields.
const mailFields = {
	firstName: 1,
	lastName: 2,
	phone: 3,
	email: 4,
};

const redirectTypes = {
	CAMPAIGN: {
		id: 1,
		name: 'Campaign',
	},
	CATEGORY: {
		id: 2,
		name: 'Category',
	},
	SECTION: {
		id: 3,
		name: 'Section',
	},
	SUB_SECTION: {
		id: 4,
		name: 'Subsection',
	},
	HOME: {
		id: 5,
		name: 'Start',
	},
	MANUFACTURER: {
		id: 7,
		name: 'Manufacturer',
	},
};

const customerSurveyTypes = {
	NPS: 1,
};

const prerenderUserAgent = 'WebhallenSSR';	// User agent of our own prendering server
const prerender = window.prerender = navigator.userAgent.toLowerCase().indexOf(prerenderUserAgent.toLowerCase()) !== -1;

const googleBot = window.prerender = navigator.userAgent.toLowerCase().includes('googlebot') || navigator.userAgent.toLowerCase().includes('bingbot');

const isTouchDevice = 'ontouchstart' in document.documentElement;
const defaultProductListMode = 2;
const giftCardProductId = 290553;

const friendStatus = {
	SENT:		0,
	RECEIVED:	1,
	ACCEPTED:	2,
	DENIED: 	3,
	REMOVED:	4,
};

// List of recurring campaigns for the /campaigns page
const recurringCampaigns = [
	{
		id: 7866,
		name: 'Black-Friday',
		title: 'Black friday',
	},
	{
		id: 9504,
		name: 'Cyber-Monday',
		title: 'Cyber monday',
	},
	{
		id: 7648,
		name: 'Singles-Day',
		title: 'Singles day',
	},
	{
		id: 7648,
		name: 'Singles-Day',
		title: 'Webhallen festival',
	},
	{
		id: 8317,
		name: 'Webhallen-Festival',
		title: 'Mellandagsrea',
	},
	{
		id: 7894,
		name: 'Mellandagsrea',
		title: 'Adventskalender',
	},
	{
		id: 7736,
		name: 'Januarirea-Its-a-new-dawn',
		title: 'Januarirea',
	},
	{
		id: 9342,
		name: 'Gamers-Unite',
		title: 'Gamers unite',
	},
	{
		id: 7537,
		name: 'Back-to-School',
		title: 'Back to school',
	},
	{
		id: 9384,
		name: 'Sommarrea',
		title: 'Sommarrea',
	},
	{
		id: 7619,
		name: 'Streamhjalpen',
		title: 'Streamhjälpen',
	},
	{
		id: 8759,
		name: 'E-Sportlov',
		title: 'E-sportlov',
	},
	{
		id: 6604,
		name: 'May-the-4th',
		title: 'May the 4th',
	},
	{
		id: 7713,
		name: 'Julklappstips',
		title: 'Julklappar',
	},
];

export {
	defaultProductListMode,
	isTouchDevice,
	prerender,
	googleBot,
	rankGroups,
	rankGroupMap,
	xpToNextRankLevel,
	achievementsToNextRankLevel,
	fyndwareClasses,
	sectionIds,
	mailFields,
	giftCardProductId,
	redirectTypes,
	customerSurveyTypes,
	friendStatus,
	recurringCampaigns,
};
