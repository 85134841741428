import { exceptionSymbol } from './constants';

function shouldTrigger (handler, res, handlers) {
	if (! res || ! handler) return false;
	let trigger = handler.trigger;
	if (typeof handler.trigger === 'function') {
		trigger = handler.trigger(res);
	}
	if (typeof trigger !== 'string') return !! trigger;
	const status = res.status + '';
	const regexp = new RegExp('[' + status[0] + 'x][' + status[1] + 'x][' + status[2] + 'x]');
	if (trigger.match(regexp)) return true;

	if (trigger === 'success' && '2xx'.match(regexp)) {
		const hasPageRedirectHandler = hasHandler('page-redirect', handlers);
		// Only trigger success handler when there is no redirect header present
		// or when it is present but the redirect handler is missing.
		return ! res.headers.has('X-Page-Redirect') || ! hasPageRedirectHandler;
	}
	if (trigger === 'page-redirect' && '2xx'.match(regexp)) {
		// Page redirect is only triggered when X-Page-Redirect header is present.
		return res.headers.has('X-Page-Redirect');
	}

	if (trigger === 'error' && ! '2xx'.match(regexp)) return true;
	if (trigger === 'all') return true;
	return false;
}

export default function handleRequest (request, self, handlers) {
	let isBlocked = false;
	let requestIsHandled = false;
	let res;

	request = request.then((_res) => { res = _res; return _res; }, (err) => { throw res = err; });

	for (const key in handlers) {
		const handler = handlers[key];
		if (handler.trigger === exceptionSymbol) {
			request = request.catch((err) => {
				if (isBlocked) throw err;
				const _fail = { message: '', code: NaN, failed: false };
				let retval;
				const fail = function (message, code) {
					_fail.failed = true;
					_fail.message = message || '';
					_fail.code = code === undefined ? -1 : code;
				};
				handler.callback(err, fail);
				err = retval !== undefined ? retval : err;
				if (_fail.failed) {
					const error = new Error('Response blocked. Reason: \'' + _fail.message + '\'');
					error.code = _fail.code;
					error.data = err && err.data;
					throw error;
				}
				if (retval !== undefined) return retval;
				isBlocked = true;
			});
			continue;
		}
		request = request.then((_res) => {
			if (isBlocked) return _res;
			if ((! requestIsHandled || handler.override) && shouldTrigger(handler, _res, handlers)) {
				const _fail = { message: '', code: NaN, failed: false };
				const fail = function (message, code) {
					_fail.failed = true;
					_fail.message = message || '';
					_fail.code = code === undefined ? -1 : code;
				};
				let isBlocking = true;
				const pass = function (reset) {
					isBlocking = false;
					if (reset) {
						requestIsHandled = false;
					}
				};
				const retval = handler.callback(_res, pass, fail);
				_res = retval !== undefined ? retval : _res;
				requestIsHandled = isBlocking || requestIsHandled;
				if (_fail.failed) {
					const error = new Error('Response blocked. Reason: \'' + _fail.message + '\'');
					error.code = _fail.code;
					error.data = _res;
					throw error;
				}
			}
			// We returned a new request! Makee a promise to reslolve this.
			if (_res && _res.constructor === self.constructor) {
				const promise = new Promise((resolve, reject) => { _res.on('all', resolve, true).catch(reject); });
				_res.go();
				return promise;
			}
			return _res;
		});
	}
	request = request.catch((err) => { err.response = err.response !== undefined ? err.response : res; throw err; });
	return request;
}

function hasHandler(name, handlers) {
	for (const handler of handlers) {
		if (typeof handler.trigger !== 'string') {
			continue;
		}
		if (handler.trigger === name) {
			return true;
		}
	}
}