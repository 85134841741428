/**
* Functions / Data for AB-tests
*/

import { sendGoogleOptimizeEvent } from 'utils';
import localSessionStorage from 'localSessionStorage';

const data = {
	paymentSwap: 0,
	shorterReviews: 0,
	showDeliveryInfo: 0,
};

// This lets us pick one of several tests at random
const testTriggerValues = ['paymentSwap', 'shorterReviews', 'showDeliveryInfo'];

window.paymentSwap = function (show) {
	Vue.set(data, 'paymentSwap', show);
};

window.shorterReviews = function (show) {
	Vue.set(data, 'shorterReviews', show);
};

window.showDeliveryInfo = function (show) {
	Vue.set(data, 'showDeliveryInfo', show);
};

if (testTriggerValues.length > 0) {
	const key = 'ab-trigger';
	let testTriggerValue = localSessionStorage.get(key);
	if (! testTriggerValue || testTriggerValues.indexOf(testTriggerValue) === -1) {
		testTriggerValue = testTriggerValues[Math.floor(Math.random() * testTriggerValues.length)];
		localSessionStorage.set(key, testTriggerValue);
	}
	sendGoogleOptimizeEvent(key + ':' + testTriggerValue);
}

export default data;