<template>
	<script type="application/json+ld">
		{{ json }}
	</script>
</template>
<script>
import router from 'router';

export default {
	props: {
		breadcrumbs: {
			type: Array,
			required: true,
		},
	},
	computed: {
		json () {
			const itemListElement = [];

			for (let i = 0; i < this.breadcrumbs.length; ++i) {
				const crumb = this.breadcrumbs[i];
				if (! crumb.clickable || ! crumb.route) {
					continue;
				}
				itemListElement.push({
					'@type': 'ListItem',
					position: i + 1,
					name: crumb.title,
					item: router.routeToUrl(crumb.route),
				});
			}

			return JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'BreadcrumbList',
				itemListElement,
			});
		},
	},
};
</script>