<template>
	<ximg
		:class="customClass ? 'popular-categories-img' : 'relative mr-2'"
		:style="customClass ? '' : 'top: 2px;'"
		:src="src"
		:w="customClass ? '' : '1em'"
		:h="customClass ? '' : '1em'"
		lazy
		:ariaLabel="ariaLabel"
		role="img"
		@inViewport="emitTracking"
	/>
</template>

<script>
export default {
	props: {
		src: {
			type: String,
			required: true,
		},
		lazy: {
			type: Boolean,
			required: false,
		},
		customClass: {
			type: Boolean,
			required: false,
		},
		ariaLabel: {
			type: [String, Object, Number],
			default: '',
			required: true,
		},
	},
	methods: {
		emitTracking() {
			this.$emit('emitTracking'); // Prep for promotion tracking to only track when loaded
		},
	},
}
</script>