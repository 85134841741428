import { reauthLevel, scopes } from './constants';

function parseJson (res, pass) {
	pass();
	return res.text().then((_res) => { res.data = JSON.parse(_res); res.raw = _res; return res; });
}

function defineScope (name, level) {
	let foundLevel = false;
	for (const k in reauthLevel) {
		const lvl = reauthLevel[k];
		if (lvl === level) {
			foundLevel = true;
			break;
		}
	}
	if (! foundLevel) throw new Error('Unknown level: ' + level);
	scopes[name] = level;
};

function resolve (obj, validator) {
	if (typeof obj === 'function') return (validator || (val => val))(obj.apply(null, arguments.slice(1), null, true));
	return obj;
}

function getType (obj) {
	try {
		return obj.constructor.name;
	} catch (e) {
		return typeof obj;
	}
};

export {
	parseJson,
	defineScope,
	resolve,
	getType,
};
