<template>
	<div :class="['header-dropdown member-form', { 'mini-prompt': value === 'miniPrompt', 'cart-items': cartCount }]">
		<icon-button
			v-if="showCloseButton"
			variant="close"
			@click="close"
		/>
		<template v-if="tab === 'miniPrompt'">
			<div class="popup-flex">
				<text-button
					width="stretch"
					:text="tm('LOG_IN')"
					@click="triggerMemberForm('login')"
				/>
				<span class="sub-title pop-top">
					<img alt="toplist" :src="convertToCdnUrl('/img/icons/memberpopup-topplista.svg')">
					Samla XP till ditt konto
				</span>
				<span class="sub-title pop-cart">
					<img alt="cart" :src="convertToCdnUrl('/img/icons/memberpopup-cart.svg')">
					Spara kvitton digitalt
				</span>
				<span class="sub-title">
					Inte medlem?
					<a @click="triggerMemberForm('register')">{{ tm('CREATE_ACCOUNT') }}</a>
				</span>
			</div>
		</template>
		<template v-else-if="tab === 'register'">
			<h2 v-if="specialHeading">{{ specialHeading }}</h2>
			<h2 v-else>{{ tm('CREATE_ACCOUNT') }}</h2>
			<register-form
				:username.sync="username"
				:email="email"
				:orderId="parseInt(orderId)"
				:secret="secret"
				:openedViaModal="openedViaModal"
				@keepModalOpen="emitModal"
			/>
			<div class="register-footer">
				Redan medlem? <a @click="tab='login'">Logga in</a>
			</div>
		</template>
		<template v-else-if="tab === 'login'">
			<h2>{{ tm('LOG_IN') }}</h2>
			<login-form :username.sync="username" />
			<div class="login-footer">
				<a @click="tab='register'">Bli medlem</a>
				<router-link :to="passwordResetRoute">Glömt lösenord?</router-link>
			</div>
			<transition name="fade">
				<div
					v-if="alreadyMember"
					class="already-member alert alert-danger"
				>
					Du har redan blivit medlem!
				</div>
			</transition>
		</template>
	</div>
</template>

<script>
import { t } from 'datastore/i18n';
import bus from 'eventbus';
import { convertToCdnUrl } from 'utils';
import { mapGetters } from 'vuex';

export default {
	props: {
		value: {
			type: String,
			default: 'login',
		},
		showCloseButton: {
			type: Boolean,
			default: true,
		},
		// When a non member tries to open a modal intended for users only it is saved in this prop
		openedViaModal: {
			type: String,
			default: null,
		},
		specialHeading: {
			type: String,
			default: null,
		},
	},
	data () {
		return {
			username: '',
			tab: this.value,
			email: '',
			orderId: '',
			secret: '',
			alreadyMember: false,
		};
	},
	mounted () {
		if(this.$route.query) {
			this.email = this.$route.query.email;
			this.orderId = this.$route.query.orderId;
			this.secret = this.$route.query.secret;

			if(this.orderId && this.orderId != '' && this.secret && this.secret != '') {
				this.canBecomeMember();
			}
		}
	},
	computed: {
		passwordResetRoute () {
			const route = {
				name: 'reset-password',
			};
			if (this.username) {
				route.query = {
					username: this.username,
				};
			}
			return this.$router.route(route);
		},
		...mapGetters ({
			cartCount: 'cart/count',
		}),
	},
	methods: {
		convertToCdnUrl,
		tm: t.prefix('MEMBER'),
		close () {
			bus.$emit('dropdown.close');
		},
		canBecomeMember () {
			waja.get('register/check/' + this.orderId + '/' + this.secret)
				.on('success', res => {
					if (res.data.member) {
						this.tab = 'register';
					} else {
						this.tab = 'login';
						this.alreadyMember = true;
					}
				})
				.on('error', e => {
					this.tab = 'register';
				})
				.go();
		},
		emitModal (name) {
			bus.$emit('keepModalopen', name);
		},
		triggerMemberForm (val) {
			this.$emit('triggerMemberForm', val);
		},
	},
	watch: {
		value (to) {
			this.tab = to;
		},
	},
};
</script>
