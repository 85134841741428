<template>
	<div v-if="currentMessage" class="site-message">
		<transition name="fade" mode="out-in">
			<div class="current-message" :key="currentMessage.id">
				<div v-if="avatar" :style="avatar" class="site-message-avatar pixelated">
					&nbsp;
				</div>
				<span v-html="currentMessage.message" />
			</div>
		</transition>
		<portal v-if="siteMessages.length > 1" to="site-message-navigation">
			<div class="message-paginator">
				<span @click.prevent="index--" class="glyphicon glyphicon-chevron-up" />
				<span>{{ currentIndex + 1 }}/{{ siteMessages.length }}</span>
				<span @click.prevent="index++" class="glyphicon glyphicon-chevron-down" />
			</div>
		</portal>
	</div>
</template>

<script>
import waja from 'waja';
import { mapGetters } from 'vuex';
import { convertToCdnUrl } from 'utils';

export default {
	data () {
		return {
			index: 0,
			siteMessages: [],
		};
	},
	methods: {
		sortMessages () {
			const errors = [];
			const info = [];
			const ok = [];
			for (let i = 0; i < this.siteMessages.length; i++) {
				const element = this.siteMessages[i];

				switch (element.type) {
					case 'ok':
						ok.push(element);
						break;
					case 'info':
						info.push(element);
						break;
					case 'error':
						errors.push(element);
						break;
					default:
						info.push(element);
						break;
				}
			}

			//Sortera efter nyast först.
			const sortFn = (a,b) => b.id - a.id;
			ok.sort(sortFn);
			info.sort(sortFn);
			errors.sort(sortFn);

			this.siteMessages = [...errors, ...info, ...ok];
		},
		fetchAndInitMessages () {
			waja.get('site-message/' + this.regionCode + '/' + this.languageCode)
				.useCdn(true)
				.on('success', res => {
					this.initializeMessages(res.data.messages);
				}).on('error', res => {
					this.initializeMessages([]);
				}).go();
		},
		initializeMessages (messages) {
			this.siteMessages = messages;

			if (this.siteMessages.length <= 0) {
				return;
			}

			this.sortMessages();
		},
	},
	computed: {
		avatar () {
			if (this.currentMessage.avatarId === false) {
				return null;
			}

			const avatars = [
				convertToCdnUrl('/img/member/feed/inkopschef_brompis.gif'),
				convertToCdnUrl('/img/member/feed/inkopschef_kvinna.gif'),
				convertToCdnUrl('/img/member/feed/admin_holdt.gif'),
				convertToCdnUrl('/img/member/feed/kundtjanst.gif'),
				convertToCdnUrl('/img/member/feed/lager_man.gif'),
				convertToCdnUrl('/img/member/feed/lager_kvinna.gif'),
				convertToCdnUrl('/img/member/feed/flygaren.gif'),
			];

			const avatar_id = (this.currentMessage.avatarId-1) % avatars.length;
			return 'background-image: url("' + avatars[avatar_id] + '");';
		},
		currentMessage () {
			return this.siteMessages[this.currentIndex];
		},
		currentIndex () {
			return Math.abs(this.index % this.siteMessages.length);
		},
		...mapGetters({
			languageCode: 'i18n/languageCode',
			regionCode: 'i18n/regionCode',
		}),
	},
	mounted () {
		this.fetchAndInitMessages();
		// Re-fetch the messages every five minutes
		setInterval(this.fetchAndInitMessages, 1000 * 60 * 5);
	},
};
</script>
