export default {
	setCurrentQuery(state, currentQuery) {
		state.currentQuery = currentQuery;
	},
	setOrigin(state, origin) {
		state.origin = origin;
	},
	setAttributes(state, attributes) {
		state.attributes = attributes;
	},
	setAttributeShow(state, attributeShow) {
		state.attributeShow = attributeShow;
	},
	setEnabledFilters(state, enabledFilters) {
		state.enabledFilters = enabledFilters;
	},
	setFilterEnabled(state, filterEnabled) {
		state.filterEnabled = filterEnabled;
	},
	setInitialFilters(state, initialFilters) {
		state.initialFilters = initialFilters;
	},
	setSortingOption(state, sortingOption) {
		state.sortingOption = sortingOption;
	},
	setSearchTree(state, searchTree) {
		state.searchTree = searchTree;
	},
	setSearchCategories(state, searchCategories) {
		state.searchCategories = searchCategories;
	},
	setTotalProductCount(state, totalProductCount) {
		state.totalProductCount = totalProductCount;
	},
	setFilteredProductCount(state, filteredProductCount) {
		state.filteredProductCount = filteredProductCount;
	},
};