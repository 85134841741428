<template>
	<div
		v-if="this.$route.name !== 'search' && this.$route.name !== 'campaign'"
		:class="['category-selector', {'panel panel-thick-blue': ! tiles}]"
	>
		<template v-if="! tiles">
			<!-- Side nav header -->
			<div class="panel-heading p-0" v-if="showCategories" style="position: relative">
				<!-- Section / category page header -->
				<div
					@click="click"
					class="pt-4 pb-3 px-4 side-nav-header"
					:style="{ borderColor: navStatus.section.color }">
					<router-link :to="formatBackPath">
						<span class="back-arrow" />
						<div style="font-size: 12px; font-weight: normal; color: rgba(255,255,255,.85)">
							<template v-if="currentPath.length > 1">
								{{ currentPath[1].name }}
							</template>
							<template v-else>
								{{ t('NAV.HOME') }}
							</template>
						</div>
					</router-link>
					<router-link
						:to="formatHeadPath"
						class="small">
						<ximg
							v-if="currentPath[0].icon"
							:src="iconUrl(currentPath[0].icon)"
							lazy
							:ariaLabel="currentPath[0].name + ' ikon'"
							role="img"
						/>
						{{ currentPath[0].name }}
					</router-link>
				</div>
			</div>
			<!-- Home page / Campaigns page -->
			<div v-else class="panel-heading p-0">
				<div class="side-nav-header pt-4 pb-3 px-4" style="border: none;">
					<div v-if="onCampaignPage" @click="click">
						<router-link :to="formatBackPath">
							<span class="back-arrow" />
							<div style="font-size: 12px; font-weight: normal; color: rgba(255,255,255,.85)">
								{{ t('NAV.HOME') }}
							</div>
						</router-link>
						<router-link :to="campaignListPath(null)" style="color: #fff">
							<ximg
								:src="campaignIconUrl"
								lazy
								:ariaLabel="'campaign'"
								role="img"
							>
							</ximg>Kampanjer
						</router-link>
					</div>
					<div v-else>
						<div class="m-2">
							{{ t('NAV.CATEGORIES') }}
						</div>
					</div>
				</div>
			</div>
		</template>
		<!-- Tiles-->
		<h2 v-else>Utforska produkter efter kategorier</h2>

		<!-- Side nav content (sections / categories) -->
		<div :class="tiles ? 'tiles-body' : 'panel-body'">
			<!-- When the current page is a section / category  -->
			<ul :class="{
					'popular-categories-tile icon-tiles': tiles,
					'nav nav-side': ! tiles,
				}"  v-if="showCategories">
                <li
					v-for="(category, index) in currentPath[0].subCategories || currentPath[0].categories"
					:key="category.id"
					@click="click"
				>
					<router-link
						:to="onManufacturerPage ? manufacturerPagePath(category, $route.params) : pathLink('category', category)"
						:class="{ active: currentCategoryId === category.id }"
					>
						<ximg
							v-if="category.icon && ! tiles"
							:src="darkIconUrl(category.icon)"
							lazy
							:ariaLabel="category.name + ' ikon'"
							role="img"
						/>
						<ximg
							v-else-if="category.icon && tiles"
							:class="['popular-categories-img', { 'dark': isDark }]"
							:src="darkIconUrl(category.icon)"
							:ariaLabel="category.name + ' ikon'"
							role="img"
							:viewportMargin="1"
							@inViewport="promotionTracking('category', category.name, category.id, index)"
						/>
						{{ category.name }}
					</router-link>
				</li>
				<li v-if="! onManufacturerPage && currentPath.length === 1 && navStatus.section.id !== sectionIds.fyndwares">
					<router-link :class="{ active: onCampaignPage }" :to="campaignListPath(navStatus.section)">
						<ximg
							v-if="tiles"
							:class="['popular-categories-img', { 'dark': isDark }]"
							:src="darkIconUrl('kampanjer')"
							ariaLabel="kampanj ikon"
							role="img"
							:viewportMargin="1"
							@inViewport="promotionTracking('campaigns', 'Kampanjer')"
						/>
						<ximg
							v-else
							:src="darkIconUrl('kampanjer')"
							lazy
							role="img"
							ariaLabel="kampanj ikon"
						/>
						Kampanjer
					</router-link>
				</li>
			</ul>
			<!-- When the current page is the home page / campaign list page -->
			<ul :class="{
					'popular-categories-tile icon-tiles': tiles,
					'nav nav-side': ! tiles,
				}"
				v-else-if="showSections"
			>
				<li v-for="(section, index) in sections" :key="section + '_' + index" @click="click">
                    <router-link :to="sectionLink(section)" :class="sectionClass(section)">
						<ximg
							v-if="section.icon && ! tiles"
							:src="darkIconUrl(section.icon)"
							lazy
							role="img"
							:ariaLabel="section.name + ' ikon'"
						/>
						<ximg
							v-else-if="section.icon && tiles"
							class="popular-categories-img"
							:src="darkIconUrl(section.icon)"
							lazy
							:ariaLabel="section.name + ' ikon'"
							role="img"
							:viewportMargin="1"
							@inViewport="promotionTracking('section', section.name, section.id, index)"
						/>
                        <span :class="{'popular-categories-title': tiles}">
							{{ section.name }}
						</span>
                        <div v-if="! tiles" class="bg ap-fill"></div>
					</router-link>
				</li>
				<li v-if="! onCampaignPage && ! onManufacturerPage" @click="click">
					<router-link :to="campaignListPath(null)">
						<ximg
							v-if="tiles"
							class="popular-categories-img"
							:src="darkIconUrl('kampanjer')"
							lazy
							ariaLabel="kampanj ikon"
							role="img"
							:viewportMargin="1"
							@inViewport="promotionTracking('campaigns', 'Kampanjer')"
						/>
						<ximg
							v-else
							:src="darkIconUrl('kampanjer')"
							lazy
							role="img"
							ariaLabel="kampanj ikon"
						/>
						Kampanjer
					</router-link>
				</li>
			</ul>
			<div v-else>
				<loading-spinner v-if="navStatus.menu.status === 0" />
				<h2 v-else-if="navStatus.menu.status === -1">{{ navStatus.menu.msg }}</h2>
			</div>
		</div>
	</div>
</template>

<script>
import router from 'router';
import store from 'datastore';
import { mapState, mapGetters } from 'vuex';
import { t } from 'datastore/i18n';
import { sectionIds } from 'staticData';
import { sectionLinks } from 'staticNavLinks';
import { convertToCdnUrl, categoryIconUrl, manufacturerPagePath, headPath, backPath, standardRoute } from 'utils';
import { trackEvent, trackPromotionImpression } from 'gtm';

export default {
	store,
	props: {
		// Layout option, default is list
		tiles: {
			type: Boolean,
			default: false,
		},
		sectionIndex: {
			type: Number,
			required: false,
		},
	},
	data () {
		return {
			sectionIds,
		};
	},
	computed: {
		sections () {
			if (this.onManufacturerPage && this.navStatus.menu.manufacturerTree) {
				return this.navStatus.menu.manufacturerTree;
			}
			return this.navStatus.menu.staticTree || [];
		},
		staticLinks () {
			if (! this.showCategories) {
				return [];
			}

			if (this.$route.name === 'section') {
				if (sectionLinks[this.navStatus.section.id]) {
					return sectionLinks[this.navStatus.section.id];
				}
			}

			return [];
		},
		displayCategories () {
			if (this.onCampaignPage) {
				return false;
			}
			return true;
		},
		showSections () {
			if (this.navStatus.menu.status === 1 && ! this.currentPath.length) {
				return true;
			}
			return false;
		},
		showCategories () {
			return this.navStatus.menu.status === 1 && this.currentPath.length;
		},
		campaignIconUrl () {
			return categoryIconUrl('kampanjer');
		},
		onCampaignPage () {
			return this.$route.name === 'campaigns';
		},
		onManufacturerPage () {
			return this.$route.name === 'manufacturer' || this.$route.name === 'manufacturer-page';
		},
		formatBackPath () {
			const item = {
				currentPath: this.currentPath,
				sectionName: this.navStatus?.section?.name ?? null,
				path: this.navStatus?.categories ?? [],
			};

			return backPath(item, this.onManufacturerPage, this.$route.params);
		},
		formatHeadPath () {
			const item = {
				currentPath: this.currentPath,
				sectionName: this.navStatus?.section?.name ?? null,
				path: this.navStatus?.categories ?? [],
			};

			return headPath(item, this.onManufacturerPage, this.$route.params);
		},
		currentCategoryId () {
			const nav = this.navStatus;
			if (! nav.categories.length) {
				return null;
			}
			return nav.categories[nav.categories.length - 1].id;
		},
		currentPath () {
			if (! this.navStatus.section) {
				return [];
			}
			let children = [];
			const path = [];
			for (const section of this.sections) {
				if (section.id == this.navStatus.section.id) {
					path.push(section);
					children = section.categories;
					break;
				}
			}

			for (let pathCategoryIndex = 0; pathCategoryIndex < this.navStatus.categories.length; pathCategoryIndex++) {
				const needle = this.navStatus.categories[pathCategoryIndex].id;
				for (const category of children) {
					if (category.id === needle) {
						if (! category.subCategories.length) {
							break;
						}
						path.push(category);
						children = category.subCategories;
						break;
					}
				}
			}
			return path.reverse();
		},
		...mapState({
			navStatus: state => state.navStatus,
		}),
		...mapGetters({
			isDark: 'user/isDark',
		}),
	},
	methods: {
		t,
		manufacturerPagePath,
		style (section) {
			return { 'border-left-color': section.color };
		},
		iconUrl (icon) {
			return categoryIconUrl(icon);
		},
		darkIconUrl (icon) {
			// @DARKMODE TODO: Temporary turn off dark theme / darkmode until dark theme / darkmode project
			// return convertToCdnUrl('/api/dynimg/category/'+ icon + (this.isDark ? '/39485D' : '/333333'));
			return convertToCdnUrl('/api/dynimg/category/' + icon + '/1A1A1D');
		},
		pathLink (name, item) {
			return router.route(standardRoute(name, item));
		},
		campaignListPath (section) {
			if (section) {
				return router.route({
					name: 'campaigns',
					params: {
						id: section.id,
					},
				});
			}

			return router.route({
				name: 'campaigns',
			});
		},
		sectionLink (section) {
			if (this.onManufacturerPage) {
				return manufacturerPagePath(section, this.$route.params);
			} else if (! this.onCampaignPage) {
				return this.pathLink('section', section);
			} else if (section.id !== this.sectionIds.fyndwares) {
				return this.campaignListPath(section);
			}
		},
		sectionClass (section) {
			if (this.onManufacturerPage && (this.navStatus.section && this.navStatus.section.id == section.id)) {
			    return 'active';
			} else if (! this.onCampaignPage) {
			    return '';
			} else if (section.id !== this.sectionIds.fyndwares && (this.avStatus.section && this.navStatus.section.id == section.id)) {
			    return 'active';
			}
		},
		promotionTracking (pageType, name = '', id = 0, position = null) {
			let data = {};

			if (pageType === 'campaigns') {
				let campaignsPosition = 0;
				if (this.showCategories) {
					campaignsPosition = this.currentPath[0].subCategories?.length || this.currentPath[0].categories?.length;
				} else if (this.showSections) {
					campaignsPosition = this.sections.length;

				}
				data = {
					id: 0,
					name: name,
					type: pageType,
					location: this.$route.name === 'index' ? 'startpage' : this.$route.name,
					index: this.sectionIndex,
					position: campaignsPosition,
				};
			} else {
				data = {
					id: id,
					name: name,
					type: pageType,
					location: this.$route.name === 'index' ? 'startpage' : this.$route.name,
					index: this.sectionIndex,
					position: position,
				};
			}

			trackPromotionImpression(data, 'tile');
		},
		click (event) {
			trackEvent('gaEvent', 'Interactions', 'Menu Click', 'Side Menu');
		},
	},
};
</script>
