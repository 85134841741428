<template>
	<div class="panel panel-product">
		<router-link :to="path" style="display: block; line-height: 0">
			<ximg
				:class="{ cover }"
				:src="imageUrl"
				:ariaLabel="highlight.title"
				role="img"
				:viewportMargin="1"
				@inViewport="$emit('inViewport')"
			/>
		</router-link>
		<router-link :to="path" class="p-3 highlight-info relative">
			<div class="highlight-text mr-3">
				<span class="fake-link">{{ highlight.title }}</span><br/>
				<span class="highlight-subtitle">{{ highlight.description || '&nbsp;' }}</span>
			</div>
		</router-link>
	</div>
</template>

<script>

export default {
	name: 'basicHighlight',
	props: {
		highlight: {
			type: Object,
			required: true,
		},
		cover: {
			type: Boolean,
			default: true,
		},
	},
	data () {
		return {
		};
	},
	computed: {
		path () {
			return this.highlight.path || '';
		},
		imageUrl () {
			return this.highlight.imageUrl || '/img/icons/no-image.svg';
		},
	},
};
</script>
