<template>
	<div id="background" :style="{ backgroundColor }" :class="{admin : ifAdmin}" v-if="showBackground || ifAdmin">
			<transition name="slow-fade">
				<router-link class="takeover-link" @click.native="clickedTakeover" v-if="leftStyle" :to="path" :style="leftStyle">
					<div class="takeover-image" :style="leftImageStyle" />
				</router-link>
			</transition>
			<transition name="slow-fade">
				<router-link class="takeover-link" @click.native="clickedTakeover" v-if="leftStyle" :to="path" :style="rightStyle">
					<div class="takeover-image" :style="rightImageStyle" />
				</router-link>
			</transition>
		<div v-if="leftStyle" style="position: absolute; width: 10px; right: 0; top: 0; bottom: 0;" />
	</div>
</template>

<script>
import bus from 'eventbus';
import mq from 'mediaQuery';
import { routeFromHighlight, convertToCdnUrl } from 'utils';
import { trackPromotionImpression } from 'gtm';
import store from 'datastore';
import { mapGetters } from 'vuex';

// @TODO delay load, vi vill inte slösa bandbredd på bakgrundsbilden (om det är
// special. defaulten är, iaf nu, så liten att det inte spelar någon roll) innan
// kund har laddat det viktiga på sidan

const defaultBackgroundColor = '#F6F6F6';
const defaultDarkBackgroundColor = '#222B3B';

export default {
	store,
	data () {
		return {
			background: defaultBackgroundColor,
			takeoverPosition: 0,
			scrolling: false,
			mq,
			containerWidth: 0,
			hasTracked: false,
		};
	},
	mounted () {
		this.updateBackground();
		this.containerWidth = this.mq.mainContainerWidth;

		bus.$on('takeover:update', this.updateBackground);
	},
	beforeDestroy () {
		bus.$off('takeover:update', this.updateBackground);
	},
	methods: {
		updateBackground (background) {
			if (background && background.imageUrl && this.showTakeover) {
				const img = new Image();
				img.onload = () => {
					this.background = background;
					if (background.campaign) {
						if (! this.hasTracked) {
							const data = {
								id: background.campaign.id,
								name: background.campaign.name,
								type: 'campaign',
								location: this.$route.name === 'index' ? 'startpage' : this.$route.name,
								index: 0,
							};

							trackPromotionImpression(data, 'takeover');
							this.hasTracked = true;
						} else {
							this.hasTracked = false;
						}
					}
				};
				img.src = this.backgroundImageUrl(background);
			} else {
				this.background = defaultBackgroundColor;
			}
		},
		clickedTakeover () {
			const background = this.background;
			if (background && background.campaign) {
				const data = {
					id: background.campaign.id,
					name: background.campaign.name,
					type: 'campaign',
					location: this.$route.name === 'index' ? 'startpage' : this.$route.name,
					index: 0,
				};

				trackPromotionImpression(data, 'takeover', true);
			}
		},
		backgroundImageUrl (background) {
			let url = background.imageUrl;
			if (url.indexOf('?') === -1) {
				url += '?';
			} else {
				url += '&';
			}
			url += 'h=' + this.takeoverHeight;
			return convertToCdnUrl(url);
		},
	},
	computed: {
		takeoverHeight () {
			// @TODO men kanske onödigt att uppdatera vid resize..
			let height = Math.round(this.mq.pageHeight * window.devicePixelRatio * .5) * 2;

			// några fasta storlekar för att maximera cacheträffar.
			// @TODO eventuellt vill vi bara ha detta vid ex black friday?
			const sizes = [
				720,
				1080,
				1440,
				2160,
			];
			let sizeFound = false;
			for (const size of sizes) {
				if (size >= height) {
					height = size;
					sizeFound = true;
					break;
				}
			}
			if (! sizeFound) {
				height = sizes[sizes.length - 1];
			}

			return ~~height;
		},
		showTakeover () { // Ingen mening med att ladda tunga bilder om man inte kan se dom
			return this.sideWidth > 290;
		},
		showBackground () {
			return this.sideWidth > 8;
		},
		path () {
			return routeFromHighlight(this.background) || '';
		},
		// @DARKMODE TODO: Temporary turn off darkmode background until dark theme / darkmode project
		backgroundColor () {
			const isDark = this.$store.getters['user/isDark'];
			if (typeof this.background === 'object') {
				// return (isDark ? defaultDarkBackgroundColor : defaultBackgroundColor);
				return defaultBackgroundColor;
			} else if (this.sideWidth > 16) {
				if (this.background === defaultBackgroundColor && isDark) {
					// return defaultDarkBackgroundColor;
					return defaultBackgroundColor;
				}
				return this.background;
			}
			return false;
		},
		sideWidth () {
			return Math.ceil((this.mq.pageWidth - this.containerWidth) * .5);
		},
		leftStyle () {
			if (typeof this.background !== 'object' || ! this.showTakeover) {
				return false;
			}
			return {
				left: 0,
				width: this.sideWidth + 'px',
			};
		},
		rightStyle () {
			if (typeof this.background !== 'object' || ! this.showTakeover) {
				return false;
			}
			return {
				right: 0,
				width: this.sideWidth + 'px',
			};
		},
		leftImageStyle () {
			const o = this.background;
			return {
				backgroundImage: 'url(' + this.backgroundImageUrl(o) + ')',
				left: 0,
			};
		},
		rightImageStyle () {
			const o = this.background;
			return {
				backgroundImage: 'url(' + this.backgroundImageUrl(o) + ')',
				right: 0,
			};
		},
		...mapGetters({
			isLoggedIn: 'user/isLoggedIn',
		}),
		ifAdmin () {
			return this.$route.fullPath.indexOf('/admin') === 0;
		},
	},
	watch: {
		$route (to, frm) {
			if (to.path === frm.path || ! frm.matched.length || ! to.matched.length) return;
			for (let i = to.matched.length - 1; i >= 0; i--) {
				const component = to.matched[i].components.default;
				if (component.handleTakeovers) {
					return;
				}
			}
			this.updateBackground();
		},
	},
};
</script>
