<template>
	<nav
		v-if="! hidden"
		role="navigation"
		id="navbar-top"
		:class="['navbar hidden-navbar',{ 'hover-enabled': hoverEnabled }]"
		@mouseleave="block"
		@mouseover="unblock"
	>
		<div class="header-container d-table p-0 navbar-top-container">
			<ul class="nav navbar-nav">
				<template v-for="(section, index) in navStatus.menu.staticTree">
					<site-nav-section :section="section" :key="index" />
				</template>
				<site-nav-section
					useIcon
					:section="campaignSection"
				/>
			</ul>
		</div>
	</nav>
</template>

<script>
import alert from 'alert';
import bus from 'eventbus';
import store from 'datastore';
import { mapState } from 'vuex';
import { setRegionAndLanguage, t } from 'datastore/i18n';

import { sectionIds, prerender } from 'staticData';

const HOVER_TIMEOUT = 200;

export default {
	store,
	props: {
		dissmissLoginPrompt: {
			type: Boolean,
			default: false,
		},
	},
	data () {
		return {
			hover: {},
			blockingTimeout: null,
			hoverEnabled: prerender,
			unblockingTimeout: null,
			sectionIds,
		};
	},
	methods: {
		setRegionAndLanguage (region, language) {
			if (region !== 'se' || language !== 'sv') {
				alert('I nuläget är sidan tyvärr bara tillgänglig på svenska!');
				return;
			}
			setRegionAndLanguage(region, language);
		},
		t: t.prefix('NAV'),
		unblock () {
			if (this.unblockingTimeout) {
				clearTimeout(this.unblockingTimeout);
				this.unblockingTimeout = null;
			}
			if (this.blockingTimeout) clearTimeout(this.blockingTimeout);
			this.blockingTimeout = setTimeout(() => {
				this.hoverEnabled = true;
				this.blockingTimeout = null;
			}, HOVER_TIMEOUT);
		},
		block () {
			if (this.blockingTimeout) {
				clearTimeout(this.blockingTimeout);
				this.blockingTimeout = null;
			}
			if (this.hoverEnabled) {
				if (this.unblockingTimeout) clearTimeout(this.unblockingTimeout);
				this.unblockingTimeout = setTimeout(() => {
					this.hoverEnabled = false;
					this.unblockingTimeout = null;
				}, HOVER_TIMEOUT);
			}
		},
	},
	computed: {
		sections () {
			return this.navStatus.menu.staticTree;
		},
		hidden () {
			return this.$route.name === 'checkout';
		},
		campaignSection () {
			return {
				id: 21,
				name: this.isBlackWeek ? 'Black Week' : 'Kampanjer',
				color: '#273143',
				icon: 'kampanjer',
				iconColor:'D50855',
			};
		},
		isBlackWeek () {
			const now = new Date();
			return now >= new Date('2024/11/11 00:00:01') && now <= new Date('2024/12/02 23:59:59');
		},
		...mapState({
			navStatus: state => state.navStatus,
		}),
	},
	watch: {
		$route (to, frm) {
			bus.$emit('close-top-nav');
		},
		sections (sections) {
			if (sections) {
				Vue.nextTick(() => {
					// Announce that the nav has been rendered.
					this.$emit('loaded');
				});
			}
		},
		hoverEnabled (to) {
			if (to && ! this.dissmissLoginPrompt) {
				this.$emit('update:dissmissLoginPrompt', true);
			}
		},
	},
};
</script>
