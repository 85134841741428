<template>
    <li v-if="external"><a-link :to="linkTo">{{ title }}</a-link></li>
    <li v-else><a href="#" @click="onClick">{{ title }}</a></li>
</template>

<script>
export default {
    props: {
        external: {
            type: Boolean,
            required: false,
        },
        linkTo: {
            type: Object,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        onClick: {
            type: Function,
            required: false,
        }
    },
}
</script>