const tags = {};

/**
 * Simple API for adding tags to the HTML head.
 */
const api = {
	/**
	 * Appends a new tag to the head tag. If the tag already exists, its
	 * attributes will be updated.
	 * 
	 * @param {Object} tag An object that describes the tag to add.
	 * 
	 * It must be of this form:
	 *
	 * {
	 * 	id: <id>,
	 * 	type: <tag-type>,
	 * 	attributes: {
	 * 		<name-of-attribute>: <attribute-value>
	 * 		...
	 * 	}
	 * }
	 * 
	 * The id is used as an identifier to be able to remove it later.
	 * 
	 * The attributes property can contain any number of attributes that should
	 * be set on the tag.
	 */
	set (tag) {
		const type = tag.type;
		const id = tag.id;
		if (! tags[type]) {
			// Create an empty object to store the tag in.
			tags[type] = {};
		}
		let el = null;
		if (tags[type][id]) {
			// Tag already exists.
			el = tags[type][id];
		} else {
			el = document.createElement(type);
			document.getElementsByTagName('head')[0].appendChild(el);
			tags[type][id] = el;
		}
		if (tag.attributes) {
			for (const attribute in tag.attributes) {
				el.setAttribute(attribute, tag.attributes[attribute]);
			}
		}
	},
	get (type, id) {
		if (! (tags[type] && tags[type][id])) {
			return undefined;
		}
		return tags[type][id];
	},
	deleteTag(type, id) {
		if (tags[type] && tags[type][id]) {
			document.head.removeChild(tags[type][id]);
			delete tags[type][id];
			return true;
		}
		return false;
	},
	deleteAll() {
		for (const type in tags) {
			for (const id in tags[type]) {
				document.head.removeChild(tags[type][id]);
				delete tags[type][id];
			}
			delete tags[type];
		}
	},
};

export default api;