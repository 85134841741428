
export default {
	data () {
		return {
			active: this.value,
		};
	},
	props: {
		'value': {
			type: Boolean,
			default: false,
		},
		'keep': {
			type: Boolean,
			default: false,
		},
		'tag': {
			type: String,
			default: 'div',
		},
		'labelClass': [String, Object, Array],
		'labelStyle': [String, Object, Array],
		'checkId': {
			type: String,
			default () {
				return '_toggle-' + this._uid;
			},
		},
		inlineBlock: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		changeHandler (event) {
			this.active = event.target.checked;
		},
	},
	watch: {
		value (val) {
			this.$refs.checkbox.checked = val;
			this.active = val;
		},
		active (to) {
			this.$emit('input', to);
			this.$emit('change', to);
		},
	},
	render (c) {
		let children = [
			this.$slots['show-pre'] || (
				this.$scopedSlots['show-pre'] &&
				this.$scopedSlots['show-pre']({ checkId: this.checkId, active: this.active })
			),
			c('label',
				{
					'attrs': {
						'for': this.checkId,
					},
					'class': ['toggle-label', this.labelClass],
					'style': this.labelStyle,
				},
				this.$slots.label || (
					this.$scopedSlots.label &&
					this.$scopedSlots.label({ checkId: this.checkId, active: this.active })
				)
			),
			this.$slots.show || (
				this.$scopedSlots.show &&
				this.$scopedSlots.show({ checkId: this.checkId, active: this.active })
			),
			c('input',
				{
					'class': {
						'hidden': true,
						'toggle-checkbox': true,
					},
					'attrs': {
						'type': 'checkbox',
						'id': this.checkId,
						'checked': this.value,
					},
					'on': {
						'change': this.changeHandler,
					},
					'ref': 'checkbox',
				}
			),
		];
		if (this.keep || this.active) {
			if (this.$scopedSlots.default) {
				children = children.concat(this.$scopedSlots.default({ checkId: this.checkId, active: this.active }));
			} else if (this.$slots.default) {
				children = children.concat(this.$slots.default);
			}
		}
		const style = Object.assign({}, this.style);

		if (this.inlineBlock !== false) {
			style.display = 'inline-block';
		}

		return c(this.tag,
			{
				'class': [this.class, 'toggle-wrapper'],
				style,
			},
			children
		);
	},
};
