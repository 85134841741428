const namespace = 'synced-events:';
const listeners = {};

function handleEvent (eventName, data) {
	if (! listeners[eventName]) {
		return;
	}
	for (const i in listeners[eventName]) {
		listeners[eventName][i](data);
	}
}

window.addEventListener('storage', (ev) => {
	// @TODO funkar detta på tableten???
	if (ev.key && ev.key.indexOf(namespace) === 0 && ev.newValue) {
		const eventName = ev.key.slice(namespace.length);
		const data = JSON.parse(ev.newValue).data;
		handleEvent(eventName, data);
	}
});

const handler = {
	on (eventName, callback) {
		if (! listeners[eventName]) {
			listeners[eventName] = [];
		}
		listeners[eventName].push(callback);
	},
	off (eventName, callback) {
		if (! listeners[eventName]) {
			return;
		}
		for (let i = 0; i < listeners[eventName].length; i++) {
			if (callback === listeners[eventName][i]) {
				listeners[eventName].splice(i--, 1);
			}
		}
	},
	emit (eventName, data, applyToThisTab = true) {
		if (applyToThisTab) {
			handleEvent(eventName, data);
		}
		try {
			window.localStorage.setItem(namespace + eventName, JSON.stringify({ data }));
			window.localStorage.removeItem(namespace + eventName);
		} catch (e) {
			console.error('localStorage exception, synced-event not sent', e);
		}
	},
};

export default handler;
