import { prerender } from 'staticData';
import handleRequest from './handleRequest';
import store from 'datastore';
import bus from 'eventbus';
import { scopes, baseURL, reauthLevel, exceptionSymbol } from './constants';
import { resolve } from './utils';
import validate from './validate';
import { getRouteFromHeader } from 'utils/redirect';
import router from 'router';

const CDN_ENABLED = true;

if (process.env.NODE_ENV !== 'production' && ! prerender && navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
	// eslint-disable-next-line no-var
	var WajaNotExecutedWarning = function (obj) {
		const err = Error.apply(this, arguments);
		err.obj = this.obj = obj;
		err.message = this.message = '';
		Object.defineProperty(err, 'message', {
			get: function () {
				return 'Waja object did not execute \'go()\', URL: \'%s\'';
			},
		});
		Object.defineProperty(this, 'stack', {
			get: function () {
				return err.stack;
			},
			set: function (newStack) {
				err.stack = newStack;
			},
		});
		err.name = this.name = 'WajaNotExecutedWarning';
	};
	WajaNotExecutedWarning.prototype.constructor = Error.constructor;
}

const interceptors = { request: [], response: [] };
const scopedRequests = {};
const activeRequests = [];
let requestCounter = 0;
const dataAsURLParam = ['HEAD', 'GET'];
let onceCleanCallbacks = [];

function objectToRequestData (dob, rd = {}, fob = null, p = '') {
	const otrd = objectToRequestData;

	function isObj (dob, fob, p) {
		if (typeof dob === 'object' && dob !== null) {
			if (!! dob && dob.constructor === Array) {
				for (let i = 0; i < dob.length; i++) {
					const auxFob = fob ? fob[i] : fob;
					isObj(dob[i], auxFob, p + '[' + i + ']');
				}
			} else {
				otrd(dob, rd, fob, p);
			}
		} else {
			const value = fob || dob;
			rd.append(p, value);
		}
	}
	for (const prop in dob) {
		const auxP = p === '' ? prop : `${p}[${prop}]`;
		const auxFob = fob ? fob[prop] : fob;
		isObj(dob[prop], auxFob, auxP);
	}
	return rd;
}

function newRequest () {
	const _self = {
		id: requestCounter++,
		handlers: [],
		url: '',
		headers: {},
		mode: 'cors',
		cache: 'default',
		referrerPolicy: 'origin-when-cross-origin',
		referrer: undefined,
		data: null,
		files: null,
		name: null,
		reauth: null,
		useBase: true,
		useCdn: false,
		execute (reauthData, returnObj) {
			returnObj = returnObj || { done: false, error: false };
			const name = resolve(_self.name, validate.string);
			if (typeof name === 'string') {
				if (! (name in scopedRequests)) {
					scopedRequests[name] = [];
				}
				scopedRequests[name].push(_self);
			}
			let url = resolve(_self.url, validate.url);
			let options = {
				method: resolve(_self.method, validate.method),
				headers: _self.headers,
				mode: resolve(_self.mode, validate.mode),
				cache: resolve(_self.cache, validate.cache),
				referrerPolicy: resolve(_self.referrerPolicy, validate.referrerPolicy),
				referrer: resolve(_self.referrer, validate.string),
				credentials: 'same-origin',
				redirect: 'follow',
			};

			if (_self.data || reauthData || _self.files) {
				if (dataAsURLParam.includes(_self.method)) {
					const data = new URLSearchParams();
					if (_self.data) objectToRequestData(_self.data, data);
					if (reauthData) {
						for (const key in reauthData) {
							data.append(key, reauthData[key]);
						}
					}
					url += url.indexOf('?') ? '?' : '&';
					url += data.toString();
				} else {
					let formData = null;

					if (_self.files) {
						formData = new FormData();
						for (const file of _self.files) {
							formData.append('files[]', file);
						}
						
						options.body = formData;
					}

					if (_self.data || reauthData) {
						// Data is an object.
						const data = {};
						if (_self.data) {
							Object.assign(data, _self.data);
						}
						if (reauthData) {
							Object.assign(data, reauthData);
						}

						if (! formData) {
							options.headers = new Headers({
								'content-type': 'application/json',
							});
							// Stringify the json data.
							options.body = JSON.stringify(data);
						} else {
							formData.append('data', JSON.stringify(data));
						}
					}
				}
			}
			const retArr = runRequestInterceptors(url, options, _self.useCdn);

			url = retArr[0];
			options = retArr[1];

			url = (_self.useBase ? baseURL : '') + url;

			if (CDN_ENABLED && _self.useCdn && process.env.NODE_ENV === 'production') {
				// @TODO flytta till delad fil
				url = '//cdn.webhallen.com' + url;
			}
			let request = fetch(url, options);
			request = request.then(runResponseInterceptors);
			request.then(res => {
				// Check if the request has responded with an X-Page-Redirect
				// header which means that we should automagically redirect to
				// that page.
				if (res.headers.has('X-Page-Redirect')) {
					let hasRedirectHandler = false;
					for (const handler of _self.handlers) {
						if (handler.trigger === 'page-redirect') {
							// A custom page-redirect handler has been set. This
							// overrides the automatic redirect behavior. 
							hasRedirectHandler = true;
						}
					}
					if (! hasRedirectHandler) {
						// No page-redirect handler has been assigned, proceed
						// with the redirect.
						redirect(res.headers.get('X-Page-Redirect'));
						return;
					}
				}
				// Fire handlers.
				if (! isAborted(_self)) handleRequest(request, self, _self.handlers);
			}, () => {
				if (! isAborted(_self)) handleRequest(request, self, _self.handlers);
			});
			request.then((res) => {
				removeRequest(_self);
				returnObj.done = true;
				returnObj.error = false;
				return res;
			}, (res) => {
				removeRequest(_self);
				returnObj.done = true;
				returnObj.error = true;
				throw res;
			});
			return returnObj;
		},
	};
	activeRequests.push(_self);
	const self = {
		url (url) {
			if (url === undefined) return _self.url;
			_self.url = validate.url(url);
			return self;
		},
		name (name) {
			if (name === undefined) return _self.name;
			_self.name = validate.string(name, name);
			return self;
		},
		method (method) {
			if (method === undefined) return _self.method;
			_self.method = validate.method(method);
			return self;
		},
		mode (mode) {
			if (mode === undefined) return _self.mode;
			_self.mode = validate.mode(mode);
			return self;
		},
		header (header, append) {
			if (typeof header === 'string') return _self.header.getAll(header);
			validate.header(header);
			for (const key in header) {
				_self.header[append ? 'append' : 'set'](key, header[key]);
			}
			return self;
		},
		on (trigger, callback, force) {
			if (trigger instanceof Array) {
				trigger.forEach((val) => self.on(val, callback));
				return self;
			}
			trigger = validate.trigger(trigger);
			callback = validate.callback(callback);
			_self.handlers.push({
				trigger,
				callback,
				override: !! force,
			});
			return self;
		},
		catch (callback) {
			callback = validate.callback(callback);
			_self.handlers.push({
				trigger: exceptionSymbol,
				callback,
			});
			return self;
		},
		data (data) {
			Object.apply(_self.data, validate.data(resolve(data)));
			_self.data = validate.data(resolve(data));
			return self;
		},
		files (files) {
			_self.files = files;
			return self;
		},
		reauth (scope, timeout) {
			if (scope === reauthLevel.configured) {
				throw new Error('Cannot use configured level without scope.');
			}
			if (scopes.hasOwnProperty(scope)) {
				_self.reauth = scopes[scope];
			} else if (Object.values(reauthLevel).includes(scope)) {
				_self.reauth = {
					name: null,
					level: scope,
					timeout: 0,
				};
			} else {
				throw new Error('Unknown scope or level: ' + scope);
			}
			_self.reauth = scope !== undefined ? scope : true;
			return self;
		},
		useBase (useBase) {
			_self.useBase = useBase;
			return self;
		},
		useCdn (useCdn) {
			_self.useCdn = useCdn;
			return self;
		},
		go () {
			return _self.execute();
		},
	};
	self.constructor = newRequest;

	if (process.env.NODE_ENV !== 'production' && ! prerender && navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
		const sprintf = require('sprintf-js').sprintf;
		const mapStackTrace = require('sourcemapped-stacktrace').mapStackTrace;
		const error = new WajaNotExecutedWarning();
		const stackArray = error.stack.replace(new RegExp(window.location.origin, 'g'), '').split('\n');
		stackArray.splice(1, 2);
		error.stack = stackArray.join('\n');
		const header = stackArray[0];
		const timeoutAlert = function () {
			mapStackTrace(error.stack, function (stack) { console.warn(sprintf([header].concat(stack).join('\n').replace(/:\/\/\//g, ':///./'), self.url() || 'undefined')); });
		};
		const timeout = setTimeout(timeoutAlert, 0);
		const go = self.go.bind(self);
		self.go = function () { clearTimeout(timeout); return go(); };
	}

	return self;
}

function runRequestInterceptors (url, options, useCdn) {
	function _url (newUrl) {
		return (url = newUrl || url);
	}
	function _options (newOptions) {
		return (options = newOptions || options);
	}
	for (const i in interceptors.request) {
		interceptors.request[i](_url, _options, !! useCdn);
	}
	return [url, options];
}

function isAborted (request) {
	if (! request.name) return false;
	if (request.name in scopedRequests) {
		const requests = scopedRequests[request.name];
		if (requests.includes(request))	{
			requests.splice(requests.indexOf(request), 1);
			if (requests.length === 0) delete scopedRequests[request.name];
			return false;
		}
	}
	return true;
}

function removeRequest (request) {
	if (! activeRequests.includes(request)) return;

	activeRequests.splice(activeRequests.indexOf(request), 1);
	if (! activeRequests.length) {
		onceCleanCallbacks.forEach((a) => a());
		onceCleanCallbacks = [];
	}
}

function runResponseInterceptors (res) {
	for (const i in interceptors.response) {
		const _block = { message: '', code: NaN, blocked: false };
		const block = function (message, code) {
			_block.blocked = true;
			_block.message = message || '';
			_block.code = code === undefined ? -1 : code;
		};
		const set = function (val) { res = val; };
		interceptors.response[i](res, set, block);
		if (_block.blocked) {
			const error = new Error('Response blocked. Reason: \'' + _block.message + '\'');
			error.code = _block.code;
			error.response = res;
			throw error;
		}
	}
	return res;
}

function onceClean (callback) {
	if (activeRequests.length) onceCleanCallbacks.push(callback);
	else callback();
};

function abort (name) {
	delete scopedRequests[name];
};

function addRequestInterceptor (interceptor) {
	interceptors.request.push(interceptor);
}

function addResponseInterceptor (interceptor) {
	interceptors.response.push(interceptor);
}

/**
 * Some API endpoints for pages (campaigns atm) will respond with an
 * X-Page-Redirect header. Such pages should be redirected immediately.
 * 
 */
function redirect (redirectHeader) {
	const route = getRouteFromHeader(redirectHeader);
	if (prerender) {
		store.dispatch('prerender/redirect', route);
	} else {
		router.replace(route);
	}
}

export { newRequest, onceClean, abort, addRequestInterceptor, addResponseInterceptor };
