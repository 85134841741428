<template>
	<div
		v-if="(topListsLoaded && selectedTopList) || (singleTopListLoaded && selectedTopList)"
		:class="['top-list-slider-wrap', {'top-list-banner' : selectedTopList && bannerImage}]"
	>
		<banner
			v-if="showBanner && bannerImage"
			class="banner-link"
			:banner="banner"
			:bannerWidth="bannerWidth"
			:listLink="topListLink"
			:analyticsData="analyticsData"
		/>

		<h2>{{ selectedTopList.name }}</h2>
		<span v-if="selectedTopList.description && bannerImage && showDescription" class="description secondary small">{{ selectedTopList.description }}</span>

		<product-tip-slider
			:showReviews="reviews && selectedTopList.whereToShowReviews === 1"
			:products="filteredProducts"
			:showBuyBtn="false"
			:loop="false"
			:showStock="false"
			:showFlashSales="selectedTopList.showFlashSales"
			:showNrReviews="selectedTopList.showReviewCount"
			:showRating="selectedTopList.showScore"
			:toplist="selectedTopList"
			:topListLink="topListLink"
			:showSplash="true"
			showProgressBar
		/>

		<div v-if="reviews && selectedTopList.whereToShowReviews === 2" class="top-list-reviews">
			<top-review v-if="topReviews[0]" :review="topReviews[0]" :product="topReviews[0].product" :showRating="selectedTopList.showScore" />
			<top-review v-if="topReviews[1]" :review="topReviews[1]" :product="topReviews[1].product" :showRating="selectedTopList.showScore" />
		</div>

		<a-link
			v-if="topListLink"
			:to="topListLink"
			buttonStyle="secondary"
			center
		>
			{{ linkText }}
		</a-link>
	</div>
	<loading-spinner v-else />
</template>

<script>
import { mapGetters } from 'vuex';
import mq from 'mediaQuery';
import { standardRoute } from 'utils';

export default {
	props: {
		id: {
			type: Number,
			required: true,
		},
		showBanner: {
			type: Boolean,
			default: false,
		},
		reviews: {
			type: Boolean,
			default: false,
		},
		singleToplist: {
			type: Boolean,
			default: false
		},
		showDescription: {
			type: Boolean,
			default: true
		},
		sectionIndex: {
			type: Number,
			required: false,
		},
	},
	data () {
		return {
			selectedTopList: null,
			filteredProducts: [],
			singleTopListLoaded: false,
		};
	},
	mounted () {
		if (! this.selectedTopList && this.topListsLoaded) {
			this.setTopList();
		}
		if (this.singleToplist) {
			this.getSingleToplist();
		}
	},
	methods: {
		setTopList () {
			this.selectedTopList = this.topLists.find(element => element.id == this.id);
		},
		filterProducts () {
			if (this.selectedTopList && this.selectedTopList.products.length) {
				this.filteredProducts = this.selectedTopList.products.slice(0, 12);
			} else {

				this.filteredProducts = [];
			}
		},

		getSingleToplist () {
			waja.get('top-list/' + this.id + '?products=true' + '&limit=12' )
				.on('success', res => {
					this.selectedTopList = res.data.toplist;
					this.singleTopListLoaded = true;
				}).on('error', res => {
					res.json().then(json => {
						alert(json.error);
					});
				}).go();
		}
	},
	watch: {
		selectedTopList (to) {
			if (to === undefined) {
				this.$emit('hide');
			} else {
				this.filterProducts();
			}
		},
		topLists (to) {
			if (to) {
				this.setTopList();
			}
		},
	},
	computed: {
		linkText () {
			return this.selectedTopList && this.selectedTopList.linkText ? this.selectedTopList.linkText : 'Visa fler';
		},
		banner () {
			return {
				id: this.selectedTopList.id,
				name: this.selectedTopList.internalName,
				bannerImage: this.bannerImage,
			};
		},
		bannerImage () {
			if (mq.current > mq.sm) {
				return this.selectedTopList.normalBanner;
			} else {
				return this.selectedTopList.mobileBanner;
			}
		},
		bannerWidth () {
			if (mq.current > mq.sm) {
				return 1128;
			} else {
				return 730;
			}
		},
		topReviews () {
			const reviews = [];
			this.selectedTopList.products.forEach(product => {
				if(product.reviewHighlights) {
					reviews.push(...product.reviewHighlights);
				}
			});
			const uniqueReviews = [];
			reviews.forEach(review => {
				const i = uniqueReviews.findIndex(x => x.id == review.id);
				if(i === -1 && review.text) {
					uniqueReviews.push(review);
				}
			});

			if (uniqueReviews.length < 2) {
				if (uniqueReviews.length === 0) {
					return [];
				}
				return [uniqueReviews[0]];
			}

			uniqueReviews.sort((a, b) => {
				const aVotes = a.upvotes - a.downvotes;
				const bVotes = b.upvotes - b.downvotes;
				if (aVotes > bVotes) {
					return -1;
				}
				if (aVotes < bVotes) {
					return 1;
				}
				return 0;
			});

			return [
				uniqueReviews[0],
				uniqueReviews[1],
			];
		},
		topListLink () {
			if (this.selectedTopList.listTypeName && this.selectedTopList.listId) {
				let link = '';
				const item = {
					id: this.selectedTopList.listId,
					name: this.selectedTopList.listName,
					...(this.selectedTopList.siteTitle && { title : this.selectedTopList.siteTitle }),
				};

				const listType = this.selectedTopList.listTypeName.toLowerCase();

				link = standardRoute(listType, item);

				/* If there is a string of filters from the toplist tool
				** this splits it into a query for vue router */
				if (this.selectedTopList.listFilter) {
					const splitFilters = this.selectedTopList.listFilter.split('&');
					const query = {};
					for (const item of splitFilters) {
						const singleFilter = item.split('=');
						query[singleFilter[0]] = singleFilter[1];
					}
					link.query = query;
				}

				return this.$router.route(link);
			} else if (this.selectedTopList.linkText && this.selectedTopList.link) {
				return this.selectedTopList.link;
			}

			return false;
		},
		analyticsData () {
			let data = {};
			if (this.selectedTopList.listTypeName && this.selectedTopList.listId) {
				data = {
					id: this.selectedTopList.listId,
					name: this.selectedTopList.listName,
					type: this.selectedTopList.listTypeName.toLowerCase(),
					location: this.$route.name === 'index' ? 'startpage' : this.$route.name,
					index: this.sectionIndex,
				};
			}
			return data;
		},
		...mapGetters({
			topLists: 'productTips/topLists',
			topListsLoaded: 'productTips/topListsLoaded',
		}),
	},
};
</script>
