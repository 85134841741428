<template>
	<nav class="hamburger-menu header-dropdown">
		<icon-button
			variant="close"
			@click="close"
		/>
		<div data-nav v-if="navStatus.menu.status === 1">
			<!-- Breadcrumbs -->
			<div ref="breadcrumbs" class="breadcrumb" v-if="current.id">
				<a class="secondary-link" @click="goToIndex(0)">Hem</a>
				<template v-for="(loc, index) in current.previous.slice(1)">
					<a
						class="secondary-link"
						:key="'hamburger-go-to-' + index"
						@click="goToIndex(index + 1)"
					>
						{{ loc.title }}
					</a>
				</template>
			</div>

			<!-- Header -->
			<div
				ref="header"
				id="#hamburger-header"
				:class="['hamburger-header', current.route ? 'category' : 'home']"
			>
				<div
					v-if="currentPath.length > 0"
					class="clickable"
					@click="goToIndex(currentPath.length - 1)"
				>
					<ximg
						:src="url('/img/icons/tillbakapil.svg')"
						ariaLabel="back arrow"
						role="img"
					/>
				</div>
				<h2 :class="{'current-route': current.route}">
					<a-link
						v-if="current.route"
						:to="current.route"
						class="secondary-link"
						@click.native="close"
					>
						{{ current.title }}
					</a-link>
					<template v-else>
						{{ current.title }}
					</template>
				</h2>
				<div
					v-if="currentPath.length > 0"
					class="clickable"
					@click="goToIndex(0)"
				>
					<ximg
						:src="url('/api/dynimg/icon/house/1A1A1D')"
						ariaLabel="home icon"
						role="img"
					/>
				</div>
				<div v-else class="pull-right px-3">
					<div style="width: 1em; height: 1em;" />
				</div>
			</div>

			<!-- Kategorier -->
			<ul class="burger-nav">
				<li v-if="current.route" class="tile">
					<a-link
						:to="current.route"
						:class="['ml-5 current-route, secondary-link', { hover: hoverId === 'top' }]"
						@mouseover.native="hoverId = 'top'"
						@mouseleave.native="hoverId = hoverId === 'top' ? null : hoverId"
						@click.native="hoverId = null;close();"
					>
						<span class="semibold">
							{{ currentPath.length === 1 ? 'Gå till' : 'Visa alla i' }} {{ current.title }}
						</span>
					</a-link>
				</li>
				<li v-for="(item, index) in current.next" class="tile" :key="item.id">
					<a-link
						v-if="! item.hasNext || prerender"
						:to="item.route"
						@click.native="close"
						:class="{ active: (currentCategoryId || currentSectionId) === item.id, hover: hoverId === item.id }"
						@mouseover.native="hoverId = item.id"
						@mouseleave.native="hoverId = hoverId === item.id ? null : hoverId"
					>
						<ximg
							v-if="item.icon"
							:src="iconUrl(item.icon)"
							:ariaLabel="item.title + ' icon'"
							role="img"
						/>
						<span>{{ item.title }}</span>
					</a-link>
					<a
						v-else
						:class="[{ active: (currentCategoryId || currentSectionId) === item.id, hover: hoverId === item.id }, 'has-next']"
						@click="hoverId = null;currentPath.push(index);"
						@mouseover="hoverId = item.id"
						@mouseleave="hoverId = hoverId === item.id ? null : hoverId"
					>
						<ximg
							v-if="item.icon"
							:src="iconUrl(item.icon)"
							:ariaLabel="item.title + ' icon'"
							role="img"
						/>
						<span>{{ item.title }}</span>
					</a>
				</li>

				<!-- Link to campaigns page is hidden for fyndware section. -->
				<site-nav-link
					v-if="currentPath.length <= 1 && this.current.id !== sectionIds.fyndwares"
					class="tile"
				>
					<template slot-scope="props">
						<a-link
							:to="campaignsPath"
							:class="['campaign-link', { hover: props.hover }]"
						>
							<ximg
								:src="campaignIconUrl"
								ariaLabel="campaign icon"
								role="img"
							/>
							{{ isBlackWeek ? 'Black Week' : 'Kampanjer' }}
						</a-link>
					</template>
				</site-nav-link>
			</ul>

			<!-- Footer -->
			<ul class="burger-nav _bottom">
				<li class="tile">
					<a-link :to="formatBottomLink('info', 1, 'Kundservice')" @click.native="close">
						<ximg
							:src="url('/api/dynimg/icon/info_i/1A1A1D')"
							ariaLabel="info icon"
							role="img"
						/>
						<span>{{ tn('CUSTOMER_SERVICE') }}</span>
					</a-link>
				</li>
				<li class="tile">
					<a-link :to="formatBottomLink('info', 5, 'Våra butiker')" @click.native="close">
						<ximg
							:src="url('/api/dynimg/icon/geopin_black/1A1A1D')"
							ariaLabel="geopin icon"
							role="img"
						/>
						<span>{{ tn('STORES') }}</span>
					</a-link>
				</li>
				<li class="tile">
					<a-link :to="formatBottomLink('campaign', 8827, 'Webhallen Företag')" @click.native="close">
						<ximg
							:src="url('/api/dynimg/icon/business/1A1A1D')"
							ariaLabel="b2b icon"
							role="img"
						/>
						<span>{{ tn('B2B') }}</span>
					</a-link>
				</li>
			</ul>
		</div>
		<div class="loading-data" data-loading v-else>
			<loading-spinner v-if="navStatus.menu.status === 0" />
			<span v-else-if="navStatus.menu.status === -1">{{ navStatus.menu.msg }}</span>
		</div>
	</nav>
</template>

<script>
import store from 'datastore';
import { prerender, sectionIds } from 'staticData';
import { t } from 'datastore/i18n';
import { mapState } from 'vuex';
import { categoryIconUrl, findCurrentPath, current, convertToCdnUrl, standardRoute } from 'utils';


export default {
	store,
	data () {
		return {
			prerender,
			sectionIds,
			categoryIconUrl,
			convertToCdnUrl,
			hoverId: null,
			showStatic: false,
			currentPath: findCurrentPath(),
		};
	},
	computed: {
		current () {
			return current(this.currentPath, this.showStatic);
		},
		campaignsPath () {
			if (this.isBlackWeek) {
				return standardRoute('campaign', { id: 7866, name: 'Black Friday' });
			}
			const routeItem = {
				name: this.current.id ? this.current.title : 'campaigns',
				...(this.currentPath.length === 1 && { id : this.current.id }),
			};
			return standardRoute('campaigns', routeItem);
		},
		campaignIconUrl () {
			return categoryIconUrl('kampanjer', false, 'D50855');
		},
		currentCategoryId () {
			const nav = this.navStatus;
			if (! nav.categories.length || ! this.current.route /* is huvudkategorier */) {
				return null;
			}
			return nav.categories[nav.categories.length - 1].id;
		},
		currentSectionId () {
			const nav = this.navStatus;
			if (! nav.section || this.current.route /* is not huvudkategorier */ || nav.categories.length /* är i en kategori, inte sektion */) {
				return null;
			}
			return nav.section.id;
		},
		isBlackWeek () {
			const now = new Date();
			return now >= new Date('2024/11/11 00:00:01') && now <= new Date('2024/12/02 23:59:59');
		},
		...mapState({
			navStatus: state => state.navStatus,
		}),
	},
	methods: {
		url (link) {
			return convertToCdnUrl(link);
		},
		findCurrentPath () {
			return findCurrentPath();
		},
		goToIndex (index) {
			this.currentPath = this.currentPath.slice(0, index);
			if (this.currentPath.length === 0)
				this.showStatic = true;
		},
		close () {
			this.$emit('close');
		},
		iconUrl (icon) {
			return categoryIconUrl(icon, true);
		},
		tn: t.prefix('NAV'),
		formatBottomLink (page, id, name ) {
			return standardRoute(page, { id, name });
		},
	},
	watch: {
		current () {
			Vue.nextTick(() => {
				let el = this.$refs.breadcrumbs;
				if (! el) {
					el = this.$refs.header;
				}
				const rect = el.getBoundingClientRect();
				if (rect.top <= 0) {
					const xPos = window.scrollX || window.pageXOffset;
					window.scrollTo(xPos, window.pageYOffset + rect.top);
				}
			});
		},
		$route () {
			this.close();
		},
		navStatus: {
			handler (to, frm) {
				// Recompute current path as the nav status might not have been
				// loaded correctly when the burger was mounted.
				if (to) {
					this.currentPath = this.findCurrentPath();
				}
			},
			deep: true,
		},
	},
};
</script>
