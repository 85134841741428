<template>
	<div :style="{ width: sizeData.width, height: sizeData.height }">
		<svg version="1.1" v-if="! nofold" :class="flipped ? 'ap-r' : 'ap-l'" style="width: 5%; height: 100%; overflow: visible" baseProfile="full" :viewBox="foldData.viewBox" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:ev="http://www.w3.org/2001/xml-events">
			<g :transform="foldData.transform">
				<polygon :points="foldData.points" :fill="color" :transform="transform" />
			</g>
		</svg>
		<svg v-if="this.scale" version="1.1" :height="sizeData.height" :width="sizeData.width" style="filter: drop-shadow(0px 1px 1.5px rgba(0, 0, 0, .3) );" baseProfile="full" viewBox="0 0 104 38" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:ev="http://www.w3.org/2001/xml-events">
		 	<g transform="translate(52, 19)">
				<polygon points="52,0 32,19 -52,19 -52,-19 32,-19" :fill="color" :transform="transform" />
				<text :x="flipped?-30:-48" dy="13" fill="white"  font-size="34" font-weight="bold">{{letter}}</text>
				<text v-if="extra" :x="flipped?-9:-27" dy="6" fill="white" font-size="28" font-weight="bold">{{extra}}</text>
		 	</g>
		</svg>
		<svg v-else version="1.1" :height="sizeData.height" :width="sizeData.width" style="" baseProfile="full" :viewBox="flipped ? '0 0 50 38' : '30 0 104 38'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:ev="http://www.w3.org/2001/xml-events">
		 	<g transform="translate(52, 18)">
				<polygon points="52,0 32,19 -52,19 -52,-17 32,-17" :fill="color" :transform="transform" stroke-width="1%" stroke="black"/>
				<text :x="flipped ? -25 : 4" y="11" fill="white"  font-size="30" font-weight="bold" stroke-width="1%" stroke="black">{{letter}}</text>
				<rect width="21" height="35.5" :x="flipped ? 4 : -23" fill="white" y="-16.7"></rect>
				<line :x1="flipped ? 25 : -22" y1="-17" :x2="flipped ? 25 : -22" y2="19" stroke="black" stroke-width="1%"></line>
				<text :x="flipped ? 10 : -17" y="-6" fill="black" font-size="12" font-weight="bold">A</text>
				<text :x="flipped ? 14 : -13" :y="arrowPosition['y']" text-anchor='middle' font-family='calibri' fill='black' font-size='12' font-weight='bold' stroke-width="1%" stroke="black">↑</text>
				<text :x="flipped ? 10 : -17" y="16" fill="black" font-size="12" font-weight="bold">G</text>
		 	</g>
		</svg>
	</div>
</template>

<script>
const colors = [
	'#098f47',
	'#4da246',
	'#c4ca3b',
	'#f9e244',
	'#f8b435',
	'#ea6131',
	'#e11b2c',
];
const scales = {
	'A': {
		'A+++': 0,
		'A++': 0,
		'A+': 0,
		'A': 0,
		'B': 1,
		'C': 2,
		'D': 3,
		'E': 4,
		'F': 5,
		'G': 6,
	},
	'A+': {
		'A+++': 0,
		'A++': 0,
		'A+': 0,
		'A': 1,
		'B': 2,
		'C': 3,
		'D': 4,
		'E': 5,
		'F': 6,
		'G': 6,
	},
	'A++': {
		'A+++': 0,
		'A++': 0,
		'A+': 1,
		'A': 2,
		'B': 3,
		'C': 4,
		'D': 5,
		'E': 6,
		'F': 6,
		'G': 6,
	},
	'A+++': {
		'A+++': 0,
		'A++': 1,
		'A+': 2,
		'A': 3,
		'B': 4,
		'C': 5,
		'D': 6,
		'E': 6,
		'F': 6,
		'G': 6,
	},
};
export default {
	props: {
		nofold: {
			type: Boolean,
			default: false,
		},
		flipped: {
			type: Boolean,
			default: false,
		},
		scale: {
			required: true,
			default: null,
			validator: scale => typeof scale === 'string' || scale === null
		},
		rating: {
			type: String,
			required: true,
			default: 'A',
		},
		foldSize: {
			type: Number,
			required: false,
			default: 3,
		},
		height: {
			type: Number,
			default: 1.4,
		},
		unit: {
			type: String,
			default: 'em',
		},
		aspectRatio: {
			type: Number,
			default: 2.75,
		},
	},
	computed: {
		sizeData () {
			return {
				width: (this.height * this.aspectRatio) + this.unit,
				height: this.height + this.unit,
			};
		},
		color () {
			const scale = scales[this.scale || 'A'];
			if (! scale) return colors[0];
			return colors[scale[this.rating] || 0];
		},
		transform () {
			return this.flipped ? 'scale(-1,1)' : 'scale(1,1)';
		},
		letter () {
			const matches = this.rating.match(/^([A-Z]+)([^A-Z]*)$/);
			if (matches) return matches[1];
			return '';
		},
		extra () {
			const matches = this.rating.match(/^([A-Z]+)([^A-Z]*)$/);
			if (matches) return matches[2];
			return '';
		},
		foldData () {
			const size = this.foldSize * 1.04;
			return {
				points: '-' + size / 2 + ',38 ' + size / 2 + ',' + (38 + size) + ' ' + size / 2 + ',38',
				transform: 'translate(' + size / 2 + ', 0)',
				viewBox: '0 0 ' + size + ' 38',
			};
		},
		// Check wich browser user is using to return correct x and y positions.
		arrowPosition () {
			var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
			if (isOpera) {
				return this.flipped ? {'x': 10, 'y': 6} : {'x': -17, 'y': 5};
			}

			var isFirefox = typeof InstallTrigger !== 'undefined';
			if (isFirefox) {
				return this.flipped ? {'x': 9, 'y': 5} : {'x': -18, 'y': 4};
			}

			var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
			if (isSafari) {
				return this.flipped ? {'x': 10, 'y': 5} : {'x': -17, 'y': 5};
			}

			var isIE = /*@cc_on!@*/false || !!document.documentMode;
			var isEdge = !isIE && !!window.StyleMedia;
			if (isEdge || navigator.appVersion.indexOf('Edg') > -1) {
				return this.flipped ? {'x': 11, 'y': 4} : {'x': -16, 'y': 4};
			} else if (isIE) {
				return this.flipped ? {'x': 11, 'y': 4} : {'x': -16, 'y': 4};
			}

			var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
			var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
			if (isEdgeChromium) {
				return this.flipped ? {'x': 10, 'y': 5} : {'x': -16, 'y': 4};
			} else if (isChrome) {
				return this.flipped ? {'x': 11, 'y': 4} : {'x': -16, 'y': 4};
			}
			var isChromium = !!window.chrome;
			if (isChromium) {
				return this.flipped ? {'x': 11, 'y': 4} : {'x': -16, 'y': 4};
			}

			return this.flipped ? {'x': 10, 'y': 4} : {'x': -17, 'y': 5};
		}
	},
};
</script>
