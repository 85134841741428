<template>
</template>

<script>
import bus from 'eventbus';

export default {
	methods: {
		searchCallback (searchString) {
			bus.$emit('quick-search.run-standard-search');
		},
	},
	mounted () {
		bus.$on('quick-search.search', this.searchCallback);
	},
	beforeDestroy () {
		bus.$off('quick-search.search', this.searchCallback);
	},
};
</script>