import waja from 'waja';
import { getSections } from 'utils';
import store from './index';

function defaultState () {
	return {
		section: null,
		categories: [],
		custom: null,
		/**
		 * Callback that will be called when the nav has been loaded.
		 */
		onLoad: null,
		menu: {
			msg: '',
			status: 0,
			staticTree: null,
			burgerOpen: false,
			manufacturerTree: null,
		},
	};
}

function recursiveNavCategoryMapper (unmappedCategoies, mappedCategories, searchCategories) {
	if (! (searchCategories.length && unmappedCategoies.length)) {
		return;
	}
	const currentCategory = unmappedCategoies.pop();
	const foundCategory = searchCategories.find(category => currentCategory.id == category.id);
	if (foundCategory) {
		mappedCategories.push(foundCategory);
		foundCategory.open = true;
		searchCategories = foundCategory.subCategories;
	} else {
		mappedCategories.push(currentCategory);
	}
	recursiveNavCategoryMapper(unmappedCategoies, mappedCategories, searchCategories);
}

store.registerModule('navStatus', {
	namespaced: true,
	state: defaultState(),

	mutations: {
		onLoad (state, callback) {
			state.onLoad = callback;
		},
		clearCurrentNav (state) {
			state.custom = null;
			state.section = null;
			state.categories.forEach(category => {
				category.open = false;
			});

			state.categories = [];
		},
		section (state, section) {
			state.section = section;
		},
		categories (state, categories) {
			const sections = getSections();
			const menuSection = sections.find(searchSection => {
				return searchSection.id === state.section.id;
			});

			if (! (menuSection && categories)) return;

			recursiveNavCategoryMapper(
				categories.reverse(),
				state.categories,
				menuSection.categories
			);
		},
		menuStatus (state, { status, msg }) {
			state.menu.status = status;
			state.menu.msg = msg;
		},
		menu (state, { staticTree, manufacturerTree }) {
			state.menu.status = 1;
			state.menu.msg = null;
			if (staticTree) {
				state.menu.staticTree = staticTree;
			}
			if (manufacturerTree) {
				state.menu.manufacturerTree = manufacturerTree;
			}
		},
		customNav (state, customNav) {
			state.custom = customNav;
		},
		burgerOpen (state, open){
			state.menu.burgerOpen = open;
		},
	},

	actions: {
		setSectionAndCategories ({ commit, dispatch, state }, { section, categories }) {
			if (state.menu.status !== 1) {
				commit('onLoad', () => dispatch('setSectionAndCategories', { section, categories }));
				return;
			}
			categories = categories.slice(0);
			commit('clearCurrentNav');
			if (section) {
				section = Object.assign({}, section);
				commit('section', section);
				commit('categories', categories);
			}
		},
		loadNavTree ({ commit, state }, languageCode) {
			commit('menuStatus', { status: 0, msg: null });

			waja.get('section/tree/' + languageCode)
				.useCdn(true)
				.on('success', res => {
					commit('menu', { staticTree: res.data });
					if (state.onLoad) {
						state.onLoad();
						commit('onLoad', null);
					}
				}).on('error', () => {
					// TODO: Better staff
					commit('menuStatus', { status: -1, msg: 'Fel vid laddning av menyn.' });
				})
				.go();
		},
		loadManufacturerTree ({ commit, state }, manufacturer) {
			commit('menuStatus', { status: 0, msg: null });

			const data = { manufacturer: manufacturer.id };
			waja.get('searchtree').data(data)
				.on('success', res => {
					//Formatterar om searchtree för navigation.
					let tree = JSON.parse(JSON.stringify(res.data.children)
						.replace(/"children":/g, '"subCategories":'));
					tree.forEach(section => { section.categories = section.subCategories; });
					tree = [{ name: manufacturer.name, id: manufacturer.id, color: '#FFF', icon: 'tillverkarsupport', categories: tree }];
					commit('menu', { manufacturerTree: tree });
					if (state.onLoad) {
						state.onLoad();
						commit('onLoad', null);
					}
				}).go();
		},
		setCustomNav ({ commit }, { customPath, section, categories }) {
			commit('clearCurrentNav');
			commit('customNav', customPath);

			if (section) {
				section = Object.assign({}, section);
				commit('section', section);
				commit('categories', categories);
			}
		},
	},
	getters: {
		isMenuLoaded (state) {
			if (state.menu.status === 1) {
				return true;
			}
			return false;
		},
		getSectionById (state) {
			return id => {
				const sections = getSections();
				if (! sections) {
					return null;
				}
				return sections.find(section => {
					return section.id === id;
				});
			};
		},
		staticTree (state) {
			if (! state.menu.staticTree) {
				return null;
			}
			return state.menu.staticTree;
		},
		manufacturerTree (state) {
			if (! state.menu.manufacturerTree) {
				return null;
			}
			return state.menu.manufacturerTree;
		},
	},
});

function loadNavTree (languageCode) {
	store.dispatch('navStatus/loadNavTree', languageCode);
}

function setCustomNav (customNav) {
	store.dispatch('navStatus/setCustomNav', customNav);
}

function clearCurrentNav () {
	store.commit('navStatus/clearCurrentNav');
}

function loadManufacturerTree (manufacturerId) {
	store.dispatch('navStatus/loadManufacturerTree', manufacturerId);
}


function setSectionAndCategories (section, categories) {
	store.dispatch('navStatus/setSectionAndCategories', { section, categories });
}

export {
	loadNavTree,
	setCustomNav,
	clearCurrentNav,
	loadManufacturerTree,
	setSectionAndCategories,
};
