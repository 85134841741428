<template>
	<div class="bread-crumbs">
		<breadcrumb-markup v-if="categoryPath.length && ! navStatus.custom" :breadcrumbs="breadcrumbs" />
		<ol class="breadcrumb" v-if="breadcrumbs && breadcrumbs.length">
			<li v-for="(crumb, index) in breadcrumbs" :key="'breadcrumb-' + index">
				<router-link
					v-if="crumb.clickable"
					:class="{active: crumb.active}"
					:to="crumb.route"
				>
					{{ crumb.title }}
				</router-link>
				<span v-else>
					{{ crumb.title }}
				</span>
			</li>
		</ol>
	</div>
</template>

<script>
import { manufacturerPagePath, standardRoute } from 'utils';
import { mapState } from 'vuex';

export default {
	computed: {
		breadcrumbs () {
			const breadcrumbs = [];

			if (this.section && this.categoryPath.length && ! this.navStatus.custom) {
				const name = ! this.onManufacturerPage ? 'section' : 'manufacturer';
				const crumb = {
					title: this.section.name,
					clickable: this.section.active,
					route: this.$router.route(standardRoute(name, this.section)),
					active: ! this.onManufacturerPage,
				};
				breadcrumbs.push(crumb);

				for (let i = 0; i < this.categoryPath.length; ++i) {
					const category = {
						path: this.categoryPath,
						sectionName: this.section.name,
						...this.categoryPath[i],
					};

					let clickable = category.active || category.open;
					if (i === this.categoryPath.length - 1
						&& (this.$route.name === 'category' || this.onManufacturerPage)) {
						clickable = false;
					}

					let route;
					if (! this.onManufacturerPage) {
						route = this.$router.route(standardRoute('category', category));
					} else {
						route = manufacturerPagePath(category, this.$route.params);
					}
					const crumb = {
						title: category.name,
						clickable,
						route: route,
					};

					breadcrumbs.push(crumb);
				}
			} else if (this.navStatus.custom) {
				for (const custom of this.navStatus.custom) {
					const crumb = {
						title: custom.title,
						clickable: custom.route ? true : false,
						route: custom.route ? this.$router.route(standardRoute(custom.route.name, { id: custom.route.params.id, name: custom.route.params.name })) : null,
					};
					if (custom.hasOwnProperty('clickable')) {
						crumb.clickable = custom.clickable;
					}
					if (custom.hasOwnProperty('active')) {
						crumb.active = custom.active;
					}
					breadcrumbs.push(crumb);
				}
			}

			return breadcrumbs;
		},
		onManufacturerPage () {
			return this.$route.name === 'manufacturer' || this.$route.name === 'manufacturer-page';
		},
		categoryPath () {
			return this.navStatus.categories;
		},
		section () {
			return this.navStatus.section;
		},
		...mapState({
			navStatus: state => state.navStatus,
		}),
	},
};
</script>
