<template>
	<progress-bar class="progress-level" text="" :value="levelProgress" :grej="uhm"/>
</template>

<script>
import { xpToNextRankLevel } from 'staticData';

export default {
	props: ['user'],
	computed: {
		levelProgress () {
			const xp = xpToNextRankLevel[this.user.rankLevel - 1];
			const xpNext = xpToNextRankLevel[this.user.rankLevel] - xp;
			const progress = xpToNextRankLevel[this.user.rankLevel] ? (this.user.experiencePoints - xp) / xpNext : null;
			return progress;
		},
		uhm() {
			return !xpToNextRankLevel[this.user.rankLevel];
		},
	},
};
</script>
