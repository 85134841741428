import bus from 'eventbus';
import Vue from 'vue';

const keyStates = {};

function onKeyEvent(ev) {
	const isKeyDown = ev.type == 'keydown';
	Vue.set(keyStates, ev.keyCode + '', isKeyDown);
	if (isKeyDown) {
		bus.$emit('keydown', ev.keyCode);
	}
}

bus.$on('window.focus', () => {
	for (const key in keyStates) {
		Vue.set(keyStates, key, false);
	}
});

window.addEventListener('keydown', onKeyEvent);
window.addEventListener('keyup', onKeyEvent);

export default {
	isKeyDown (keyCode) {
		return !! keyStates[keyCode + ''];
	},
};