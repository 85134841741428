import { redirectTypes } from 'staticData';

export function getRouteFromHeader (xPageRedirectHeader) {
	const parts = xPageRedirectHeader.split(':');
	const redirectTypeName = parts[0];
	let destinationId = null;
	if (parts[1]) {
		destinationId = ~~parts[1];
	}
	const redirect = { destinationId, destinationType: { name: redirectTypeName } };
	return getRouteFromRedirect(redirect);
};

/**
 * Builds a route object from an entity redirect.
 */
export function getRouteFromRedirect (redirect) {
	let routeName = null;
	let useIdParam = true;

	switch (redirect.destinationType.name) {
	case redirectTypes.CAMPAIGN.name:
		routeName = 'campaign';
		break;
	case redirectTypes.SUB_SECTION.name:
	case redirectTypes.CATEGORY.name:
		routeName = 'category';
		break; 
	case redirectTypes.MANUFACTURER.name:
		routeName = 'manufacturer';
		break;
	case redirectTypes.SECTION.name:
		routeName = 'section';
		break;
	case redirectTypes.HOME.name:
		routeName = 'index';
		useIdParam = false;
	}

	if (! routeName) {
		return null;
	}
	const route = {
		name: routeName,
	};
	if (useIdParam) {
		route.params = {
			id: redirect.destinationId,
		};
	}

	return route;
}