<template>
	<button
		:class="[
			'text-btn',
			buttonClasses,
		]"
		:aria-label="ariaLabel"
		:type="type"
		@click="handleClick"
	>
		<template v-if="loading" />
		<slot v-else-if="useSlot" />
		<template v-else>
			<span
				v-if="glyphicon"
				:class="['glyphicon', glyphicon]"
			/>
			<img
				v-if="leftIcon"
				:src="formatIconUrl(leftIcon)"
				class="icon"
				alt="left"
			>
			<span>
				{{ text }}
			</span>
			<img
				v-if="rightIcon"
				:src="formatIconUrl(rightIcon)"
				class="icon"
				alt="right"
			>
			<counter
				v-if="counter"
				:counter="counter"
			/>
		</template>
	</button>
</template>

<script>
import { convertToCdnUrl } from 'utils';

export default {
	props: {
		/* Button variants:
			destructive
			secondary
			cart
		*/
		variant: {
			type: String,
			default: null,
			required: false,
		},
		/* Standard types for buttons eg. submit */
		type: {
			type: String,
			default: 'button',
			required: false,
		},
		text: {
			type: String,
			default: null,
			required: false,
		},
		/* For glyphicons, enter name of icon ex. glyphicon-gift*/
		glyphicon: {
			type: String,
			default: null,
			required: false,
		},
		/* Only send icon name ex "plus" */
		leftIcon: {
			type: String,
			default: null,
			required: false,
		},
		rightIcon: {
			type: String,
			default: null,
			required: false,
		},
		counter: {
			type: Number,
			default: null,
			required: false,
		},
		/* Button sizes:
			large
			normal
			small
			tiny
		*/
		size: {
			type: String,
			default: 'normal',
			required: false,
		},
		/* Button widths:
			stretch
			wide
			normal
			narrow
		*/
		width: {
			type: String,
			default: 'normal',
			required: false,
		},
		/* If needed you can use slot function for button instead of predefined elements */
		useSlot: {
			type: Boolean,
			default: false,
			required: false,
		},
		/* Extra styling properties */
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
		center: {
			type: Boolean,
			default: false,
			required: false,
		},
		dark: {
			type: Boolean,
			default: false,
			required: false,
		},
		loading: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	data () {
		return {
			convertToCdnUrl,
		};
	},
	computed: {
		ariaLabel () {
			switch (this.type) {
				case 'add':
					return 'Lägg till en';
				case 'subtract':
					return 'Ta bort en';
				case 'more':
					return 'Visa mer';
				case 'next':
					return 'Gå frammåt';
				case 'previous':
					return 'Backa';
				case 'remove':
					return 'Ta bort';
			}
		},
		buttonClasses () {
			return [
				{
					'_center': this.center,
					'_disabled': this.disabled,
					'_dark': this.dark,
					'loading': this.loading,
				},
				this.variant ? '_' + this.variant : null,
				this.size !== 'normal' ? '_' + this.size : null,
				this.width !== 'normal' ? '_' + this.width : null,
			];
		},
		iconColor () {
			if (this.variant === 'secondary') {
				return '1A1A1D';
			} else {
				return 'FFFFFF';
			}
		},
	},
	methods: {
		handleClick (event) {
			this.$emit('click', event);
		},
		formatIconUrl (icon) {
			return convertToCdnUrl('/api/dynimg/icon/' + icon + '/' + this.iconColor);
		},
	},
};
</script>
