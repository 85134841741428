import Vue from 'vue';
import waja from 'waja';
import store from './index';
import livechat from 'livechat';
import localSessionStorage from 'localSessionStorage';
import { trackEvent } from 'gtm';
import { addMinutes } from 'date-fns';
import format from 'date-fns/format';
import svLocale from 'date-fns/locale/sv';

function defaultState () {
	const isLoggedIn = window.localStorage['isLoggedIn'] == undefined ? false : JSON.parse(window.localStorage['isLoggedIn']);

	return {
		id: null,
		ssn: null,
		email: null,
		settings: {},
		rankLevel: 0,
		isAdmin: false,
		username: null,
		firstName: null,
		storeWeights: [],
		useTwofactor: {},
		isEmployee: false,
		phoneNumber: null,
		zipCode: null,
		loadedAdmin: false, // started loading
		hasTwofactor: false,
		deliveryDates: {},
		earliestDeliveryDate: null,
		storeSuggestions: [],
		adminHasLoaded: false, // finished loading
		showAdminPanel: false,
		blockBundlePopup: localSessionStorage.get('blockBundlePopup'),
		isCorporateCustomer: false,
		showTitleTwo: false,
		isLoggedIn,
		// User feed related
		feed: [],
		newEntries: [],
		orderFeed: [],
		notificationFeed: [],
		notificationCounter: null,
	};
}
store.registerModule('user', {
	namespaced: true,
	state: defaultState(),
	mutations: {
		settings (state, payload) { // @TODO ska detta verkligen vara i mutations @dobby?
			Vue.set(state.settings, payload.key, payload.value);
			waja.put('setting/' + payload.key)
				.data({ value: payload.value })
				.go();
		},
		updateSingleSetting (state, setting) {
			Vue.set(state.settings, setting.name, setting.value);
		},
		data (state, data) {
			Object.assign(state, data || defaultState());
			livechat.updateCustomVariables();
		},
		loadedAdmin (state) {
			state.loadedAdmin = true;
		},
		setAdminPanelVisibility (state, display) {
			state.showAdminPanel = display;
		},
		setBundlePopupVisibility (state, block) {
			state.blockBundlePopup = block;
			localSessionStorage.set('blockBundlePopup', block);
		},
		removeFeedEntry (state, id) {
			for (let i = 0; i < state.feed.length; i++) {
				if (state.feed[i].id === id) {
					state.feed.splice(i, 1);
					return;
				}
			}
		},
		setDeliveryDates (state, { data, fetched }) {
			state.deliveryDates = { ...data, fetched };
		},
		updateEarliestDeliveryDate (state, payload) {
			const sortable = [];

			// Sort days in descending order/dates in ascending order, slice array in order to return one value for latest time or earliest date
			Object.entries(payload).forEach(([key, methods]) => {
				if (key !== 'later') {
					sortable[key] = Object.values(methods).sort((a, b) =>
						b.localeCompare(a)).slice(0, 1);
				} else {
					sortable[key] = Object.values(methods).sort((a, b) =>
						a.localeCompare(b)).slice(0, 1);
				}
			});

			// 5 min buffer time
			const time = addMinutes(new Date(), 5);
			const now = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
			const today = sortable['today']?.toString() || '';
			const tomorrow = sortable['tomorrow']?.toString() || '';
			const options = { weekday: 'long', day: 'numeric', month: 'short'};
			const later = sortable['later'] ? new Date(sortable['later']).toLocaleDateString('sv-SE', options) : '';

			switch (true) {
				case now < today:
					state.earliestDeliveryDate = 'ikväll.';
					break;
				case now < tomorrow:
					state.earliestDeliveryDate = 'imorgon.';
					break;
				default:
					state.earliestDeliveryDate = later;
			}
		},
		updateIsLoggedIn(state, value) {
			state.isLoggedIn = value;
			window.localStorage['isLoggedIn'] = value;
		},
	},
	getters: {
		currentUserId (state) {
			return state.id > 0 ? state.id : null;
		},
		isLoggedIn (state) {
			return state.isLoggedIn;
		},
		hasZipCode (state) {
			return state.zipCode;
		},
		isAdmin (state) {
			return state.isAdmin;
		},
		isEmployee (state) {
			return state.isEmployee;
		},
		rankLevel (state) {
			return state.rankLevel;
		},
		shippingDiscount (state) {
			/*const rankLevel = state.rankLevel;
			let shippingPriceDiscount = false; // @TODO hämta från db...
			if (rankLevel > 0) {
				shippingPriceDiscount = true;
			}
			return shippingPriceDiscount;*/
		},
		isDark: (state, getters, rootState) => {
			// @DARKMODE
			return false;
			// return state.settings.darkTheme || (rootState.navStatus.section && rootState.navStatus.section.id === 13);
		},
		isAdminPanelShowing: (state) => {
			return state.showAdminPanel;
		},
		isBundlePopupBlocked: (state) => {
			return state.blockBundlePopup;
		},
	},
	actions: {
		adminHasLoaded ({ state, commit }) {
			state.adminHasLoaded = true;
			if (state.settings && state.settings.auto_open_admin_panel) {
				commit('setAdminPanelVisibility', true);
			}
		},
		login ({commit}, payload) {
			const payloadData = {
				username: payload.username,
				password: payload.password,
				twofactorcode: payload.twofactorcode,
			};
			if (payload.orderId != '' && payload.secret != '') {
				payloadData.orderId = payload.orderId;
				payloadData.secret = payload.secret;
			}
			return new Promise((resolve, reject) => {
				waja.post('login')
					.data(payloadData)
					.on('2xx', () => {
						commit('updateIsLoggedIn', true);
						trackEvent('gaEvent', 'Logga in', 'Success', '', true);
						store.dispatch('user/refresh', payload.successHandler);
						resolve();
					})
					.on('401', res => {
						trackEvent('gaEvent', 'Logga in', 'Fail', 'Twofactor authentication failed', false);
						reject(401);
					})
					.on('403', res => {
						trackEvent('gaEvent', 'Logga in', 'Fail', 'Användarnamnet och/eller lösenordet är fel!', false);
						reject(403);
					})
					.go();
			});
		},
		logout ({ commit }) {
			waja.delete('login')
				.on('success', res => {
					commit('updateIsLoggedIn', false);
					commit('data', null);
					store.dispatch('user/refresh');
					store.dispatch('cart/wasDestroyed');
					store.commit('userSettings/starredStores', []);
				}).go();
		},
		setSetting ({ commit }, setting) {
			waja.put('setting/' + setting.name)
				.data({ value: setting.value })
				.on('success', res => {
					if (res.data.status === 'ok') {
						commit('updateSingleSetting', {
							name: setting.name,
							value: res.data.value,
						});
					}
				}).go();
		},
		refresh ({ state, commit, getters }, payload) {
			// @TODO vi kanske vill synka denna ändring mellan flikar med synced-events?
			waja.get('me')
				.on('2xx', res => {
					if (payload === true) {
						commit('updateIsLoggedIn', true);
						trackEvent('gaEvent', 'Logga in', 'Success', '', true);
					}
					commit('data', res.data.user);
					store.commit('userSettings/starredStores', res.data.starredStores);

					if (getters.isLoggedIn && state.isEmployee) {
						store.dispatch('user/attemptAdminLoad');
					}
					if (getters.isLoggedIn && getters.hasZipCode) {
						store.dispatch('user/getDeliveryDates', getters.hasZipCode);
					}
					if (getters.isLoggedIn) {
						store.dispatch('user/getNotifications');
					}
				}).go();
		},
		register ({ state, commit, getters, dispatch }, payload) {
			return new Promise((resolve, reject) => {
				waja.post('register')
					.data(payload)
					.on('2xx', res => {
						commit('data', res.data.user);
						trackEvent('gaEvent', 'Bli medlem', 'Success', '', true);
						dispatch('setSetting', {
							name: 'show_member_tutorial',
							value: true,
						});
						if (getters.isLoggedIn && state.isEmployee) {
							store.dispatch('user/attemptAdminLoad');
						}
						resolve(res);
					}).on('error', res => {
						res.text().then(message => {
							reject({ code: ~~res.status, message });
						});
					})
					.go();
			});
		},
		attemptAdminLoad ({ commit, state, getters }) {
			if (! state.loadedAdmin && getters.isLoggedIn && state.isEmployee) {
				commit('loadedAdmin');
				require.ensure(['admin/app'], () => {
					require('admin/app');
				}, 'admin/app');
			}
		},
		removeFeedEntry ({ commit, state }, id) {
			for (const i in state.feed) {
				if (state.feed[i].id === id) {
					waja.delete('user/feed/' + id)
						.on('success', res => {
							commit('removeFeedEntry', id);
						}).go();
					return;
				}
			}
		},
		toggleAdminPanel ({ commit, state, getters }) {
			if (state.loadedAdmin && getters.isLoggedIn && state.isEmployee) {
				commit('setAdminPanelVisibility', ! state.showAdminPanel);
			}
		},
		toggleBundlePopup ({ commit, state }) {
			commit('setBundlePopupVisibility', ! state.blockBundlePopup);
			// Vue.$ga.event('BundleOffer', 'BlockedPopup', state.blockBundlePopup);
		},
		getDeliveryDates({ commit }, zipcode) {
			return new Promise((resolve, reject) => {
				waja.get(`delivery-dates/${zipcode}`)
					.on('success', res => {
						const data = res.data;
						const date = Date.now();
						const fetched = format(date, 'HH', {
							locale: svLocale,
						});
						commit('setDeliveryDates', {
							data,
							fetched,
						});
						commit('updateEarliestDeliveryDate', data);
						resolve(res);
					}).on('error', res => {
						res.text().then(message => {
							reject({ code: ~~res.status, message });
						});
					}).go();
			});
		},
		updateFeed ({ state }) {
			for (let i = 0; i < state.notificationFeed.length; i++) {
				state.notificationFeed[i].dismissed = true;
			}
			for (let i = 0; i < state.orderFeed.length; i++) {
				state.orderFeed[i].dismissed = true;
			}
			state.notificationCounter = 0;
		},
		dismissEntries ({ state }) {
			state.newEntries.forEach(entry => {
				store.dispatch('user/postEntry', entry.id);
			});
		},
		postEntry ({}, id) {
			waja.post(`user/feed/${id}/dismiss`)
				.on('success', res => {
				}).go();
		},
		getNotifications ({ state }) {
			waja.get('user/feed?all')
				.on('success', res => {
					const feed = res.data.entries;
					const orderFeed = [];
					const notificationFeed = [];
					const newEntries = [];
					for (const notification of feed){
						if (notification.name === 'Beställning') {
							orderFeed.push(notification);
						} else {
							notificationFeed.push(notification);
						}
						if (! notification.dismissed) {
							newEntries.push(notification);
						}
					}
					state.orderFeed = orderFeed;
					state.notificationFeed = notificationFeed;
					state.newEntries = newEntries;
					state.notificationCounter = state.newEntries.length;
					store.dispatch('user/dismissEntries');

				}).go();
		},
	},
});

/**
 * Do not remove! Creates a delay of 10ms when the tab has focus and allows the DOM to finish loading before executing this request
 * Works for hard reload
 */
setTimeout(() => { store.dispatch('user/refresh'); }, 0);
