<template>
	<!-- Component that displays a top rated review. Mainly used for the product-tip component -->
	<div v-if="review" :class="['top-review', reviewClass]">
		<ximg
			:src="avatarUrl"
			:style="avatarStyle"
			class="pull-left pixelated avatar"
			lazy
			ariaLabel="avatar"
			role="img"
		/>
		<product-link v-if="review.text" :product="product" class="text-box">
			<fixed-lines
				v-if="! showRating"
				lineHeight="1.4em"
				:lines="3"
				:bleedHeight="0.2"
			>
				<span class="username">{{ reviewName }}:</span>
				{{ this.review.text }}
			</fixed-lines>

			<template v-else>
				<div class="top-review-rating">
					<span v-if="product" class="username">
						{{ reviewName }}
					</span>
					<span class="review-verified purchase" :title="tr('VERIFIED_PURCHASE')" style="margin-bottom: 1px"/>
					<stars v-if="displayStars" :stars="review.rating" class="mr-3" />
					<flames v-else :flames="review.rating" class="mr-3" />
				</div>
				<fixed-lines :lines="2" :lineHeight="'1.4em'" :bleedHeight="0.2" v-if="showRating">
					{{ this.review.text }}
				</fixed-lines>
			</template>
		</product-link>
	</div>
</template>

<script>
import store from 'datastore';
import { mapGetters } from 'vuex';
import { t } from 'datastore/i18n';
import memberUtils from 'utils/member';
import { displayStarRating } from 'utils';

export default {
	store,
	props: {
		review: {
			type: Object,
		},
		isHype: {
			type: Boolean,
			default: false,
		},
		showRating: {
			type: Boolean,
			default: false,
		},
		/**
		 * Product that this review belongs to.
		 */
		product: {
			type: Object,
			default: null,
		},
	},
	data () {
		return {
			isUpvoted: false,
			isDownvoted: false,
			// Nr of chars in the computed first line.
			firstLineCount: 0,
			lineLength: 26,
			displayStarRating,
		};
	},
	methods: {
		tr: t.prefix('PRODUCT.REVIEW'),
	},
	computed: {
		avatarStyle () {
			return memberUtils.avatarStyle(this.review);
		},
		avatarUrl () {
			return memberUtils.avatarUrl(this.review);
		},
		reviewClass () {
			if (! (this.review.user && this.review.user.avatar)) {
				return {
					'anonymous': true,
				};
			}

			return {};
		},
		userPath () {
			return this.$router.route({
				name: 'member.achievements',
				params: {
					id: this.review.user.id,
					name: this.review.user.username,
				},
			});
		},
		displayStars () {
			return displayStarRating(this.product);
		},
		reviewName () {
			if (this.product) {
				return (this.review.user && this.review.user.username && ! this.review.isAnonymous) ? this.review.user.username : 'No-Face';
			} else if (this.review.user && this.review.user.username) {
				return this.review.user.username;
			} else {
				return 'Anonym';
			}
		},
		...mapGetters({
			languageCode: 'i18n/languageCode',
			isDark: 'user/isDark',
		}),
	},
};
</script>
