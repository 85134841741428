<template>
	<div class="old-flex">
		<!-- container -->
		<div id="site-container" class="d-sm-flex flex-column align-items-end px-0 site-container" :class="{ 'serious-business-mode': seriousBusinessMode }">
			<site-markup />
			<site-header />
			<div :class="['outer-main-wrap', { admin: showAdminPanel }]">
				<main class="container relative pb-5" :class="mainClass">
					<div class="row">
						<div class="clearfix" id="header-spacer" :class="[isCheckoutRoute ? 'checkout-spacer' : 'header-spacer']"/>
						<site-usp v-if="! isCheckoutRoute" />
						<div class="page-spacer clearfix" />
						<div class="wrap hide-right">
							<left-col
								v-show="layoutOptions.leftCol"
							/>
							<div :class="['main-col', { 'no-overflow': isProductRoute }]">
								<div class="main-container" id="main-container">
									<!-- @TODO Add this warning when we want support real users running without javascript -->
									<!-- <div class="alert alert-warning mx-5" v-if="prerender">
										<span id="outdated-browser-warning"></span>
										<noscript>Du verkar ha javascript avaktiverat.</noscript>
									</div> -->
									<bread-crumbs v-show="! error" />
									<router-view class="child-view" v-show="! error" />
									<page-error :error="error" class="child-view" v-if="error" />
								</div>
							</div>
							<div class="clearfix" />
						</div>
					</div>
					<newsletter-modal v-if="subscribeEmail != ''" :email="subscribeEmail" />
					<newsletter-popup></newsletter-popup>
					<unsubscribe-abandon-cart-mail-modal v-if="userId != ''" :userId="userId" :mailCase="mailCase" />
				</main>
				<admin-panel v-if="showAdminPanel" />
			</div>
			<site-footer v-if="! isCheckoutRoute" />
			<portal-target name="modal-destination" slim />
		</div>
		<div id="livechat-wrap" class="livechat-wrap" v-if="! isCheckoutRoute && ! isInfoRoute">
			<!-- Fixed content in the bottom of the screen -->
			<livechat id="livechat-container" class="pull-right d-inline-block"  />
			<div class="clearfix" />
		</div>
		<cookie-consent v-if="! prerender" />
	</div>
</template>

<script>
import bus from 'eventbus';
import store from 'datastore';
import { mapState } from 'vuex';
import { prerender } from 'staticData';
import mediaQuery from 'mediaQuery';
import abtest from 'abTest';

const defaultLayoutOptions = {
	leftCol: false,
};

export default {
	store,
	data () {
		return {
			prerender,
			error: null,
			layoutOptions: {
				leftCol: false,
			},
			mediaQuery,
			subscribeEmail: '',
			userId: '',
			mailCase: '',
			loadTrackingScripts: false,
		};
	},
	methods: {
		onError (error) {
			this.layoutOptions = Object.assign({}, defaultLayoutOptions);
			this.error = error;
		},
		handleLayout (to) {
			this.layoutOptions = Object.assign({}, defaultLayoutOptions);

			to.matched.forEach(route => {
				const component = route.components.default;

				if (component) {
					// Komponenten är en funktion som returnerar promise om den har lazy loading så detta behöver hanteras
					if (typeof component === 'function') {
						component().then(c => {
							if (c && c.layoutOptions) {
								this.layoutOptions = Object.assign({}, this.layoutOptions, c.layoutOptions);
							} else if (c.default && c.default.layoutOptions) {
								this.layoutOptions = Object.assign({}, this.layoutOptions, c.default.layoutOptions);
							}
						});
					} else if (component && component.layoutOptions) {
						this.layoutOptions = Object.assign({}, this.layoutOptions, component.layoutOptions);
					} else if (component.default && component.default.layoutOptions) {
						this.layoutOptions = Object.assign({}, this.layoutOptions, component.default.layoutOptions);
					}
				}
			});
		},
	},
	mounted () {
		//store.dispatch('user/mountAdminPanel');
		bus.$on('error', this.onError);
		bus.$on('page.load', to => {
			this.error = null;
			// Campaigns will update the layout manually.
			if (to.name === 'campaign') {
				return;
			}
			this.handleLayout(to);
		});
		// React to layout update events.
		bus.$on('layout:update', layoutOptions => {
			this.layoutOptions = Object.assign({}, this.layoutOptions, layoutOptions);
		});
		this.handleLayout(this.$route);
		mediaQuery.forceUpdate();

		if (this.$route.query && this.$route.query.subscribe) {
			this.subscribeEmail = this.$route.query.subscribe;
		}
		if (this.$route.query && this.$route.query.unsubscribe && this.$route.query.mailCase) {
			this.mailCase = this.$route.query.mailCase;
			this.userId = this.$route.query.unsubscribe;
		}
		bus.$emit('livechat:init');
		this.loadTrackingScripts = true;
	},
	beforeDestroy () {
		bus.$off('error', this.onError);
		bus.$off('page.load', this.onError);
		bus.$off('layout:update', this.onError);
	},
	computed: {
		seriousBusinessMode () {
			return this.user.id && this.user.settings.serious_business_mode;
		},
		isCheckoutRoute () {
			return this.$route.name === 'checkout';
		},
		isInfoRoute () {
			return this.$route.name === 'info';
		},
		isProductRoute () {
			return this.$route.name === 'product';
		},
		mainClass () {
			const section = this.$store.state.navStatus.section;
			const isDark = this.$store.getters['user/isDark'];
			return [section ? 'section-' + section.id : null, isDark ? 'dark' : null];
		},
		showAdminPanel () {
			return this.user.isEmployee && this.user.showAdminPanel && this.mediaQuery.gt('md');
		},
		...mapState({
			user: state => state.user,
		}),
	},
};
</script>
