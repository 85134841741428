<template>
	<div class="top-panel" id="topPanel">
		<banner
			ref="topBanner"
			class="top-panel-banner"
			:banner="banner"
			:bannerWidth="1128"
			:analyticsData="analyticsData"
			topPanel
			@bannerLoaded="bannerLoaded"
		>
			<countdown-timer
				v-if="showBannerCountdown"
				class="banner-countdown"
				:targetTime="countdownEnd"
				lineHeight="1.0"
				@countdownEnded="(val) => countdownEnded = val"
			/>
		</banner>
		<div v-if="! md && backupProducts" class="top-panel-right-section">
			<recently-viewed topPanel :backupProducts="backupProducts" :campaign="banner.campaign" />
		</div>
	</div>
</template>

<script>
import { t } from 'datastore/i18n';
import mq from 'mediaQuery';

export default {
	data () {
		return {
			edit: false,
			panelElement: null,
			bannerHeight: null,
			countdownEnd: new Date('2024/11/15 00:00:01'),
			countdownEnded: false,
		};
	},
	props: {
		banner: {
			type: Object,
			required: true,
		},
		campaignProducts: {
			type: [Object, Array],
			required: false,
			default: () => {},
		},
	},
	mounted () {
		this.$nextTick(function () {
			this.panelElement = document.getElementById('topPanel');
		});

		window.addEventListener('resize', this.getBannerHeight);
	},
	beforeDestroy () {
		window.removeEventListener('resize', this.getBannerHeight);
		this.panelElement = null;
		this.bannerElement = null;
	},
	computed: {
		showBannerCountdown () {
			const now = new Date();
			const countDownStart = new Date('2024/11/11 00:00:00');
			return now <= this.countdownEnd && this.banner.campaign && this.banner.campaign.id === 7866 && ! this.countdownEnded && now > countDownStart;
		 },
		panelStyle () {
			return { height: this.bannerHeight + 'px' };
		},
		md () {
			return mq.current < mq.md;
		},
		backupProducts () {
			if (this.campaignProducts && this.banner.campaign && this.campaignProducts[this.banner.campaign.id]) {
				return this.campaignProducts[this.banner.campaign.id].slice(0,3);
			} else if (this.banner.product) {
				return [this.banner.product];
			}
			return null;
		},
		analyticsData () {
			let data = {};
			if (this.banner.campaign) {
				data = {
					id: this.banner.campaign.id,
					name: this.banner.campaign.name,
					type: 'campaign'
				};
			}
			return {
					...data,
					location: this.$route.name === 'index' ? 'startpage' : this.$route.name,
					index: 0,
			};
		},
	},
	methods: {
		bannerLoaded(val) {
			if (val) {
				this.getBannerHeight();
			}
		},
		getBannerHeight() {
			if (this.$refs.topBanner) {
				this.bannerHeight = this.$refs.topBanner.$el.getBoundingClientRect().height;
			} else {
				this.bannerHeight = 250;
			}
		},
		t: t.prefix('NAV'),
	},
	watch: {
		panelElement (to) {
			this.getBannerHeight();
		},
	},
};
</script>
