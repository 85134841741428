import waja from 'waja';
const sprintf = require('sprintf-js').sprintf;
import { loadNavTree } from './navStatus';
import store from './index';
import bus from 'eventbus';
import syncedEvents from 'synced-events';

let hasWarnedAboutLanguagePackNotBeingLoaded = false;

function defaultState () {
	return {
		phrases: null,
		languageCode: null,
		regionCode: null,
		/**
		 * Store map (maps store ids to store objects).
		 */
		stores: {},
		/**
		 * Store array.
		 */
		storeList: [],
	};
}

store.registerModule('i18n', {
	namespaced: true,
	state: defaultState(),

	mutations: {
		phrases (state, phrases) {
			state.phrases = phrases;
		},
		regionAndLanguage (state, { regionCode, languageCode }) {
			state.regionCode = regionCode;
			state.languageCode = languageCode;
		},
		region (state, regionCode) {
			state.regionCode = regionCode;
		},
		language (state, languageCode) {
			state.languageCode = languageCode;
		},
		stores (state, stores) {
			state.stores = {};
			state.storeList = [];
			stores.forEach(store => {
				state.storeList.push(store);
				state.stores[store.id] = store;
			});
			state.stores[9999] = { name: 'Butiker' };// @TODO
		},
	},

	getters: {
		locale (state, getters) {
			return getters.languageCode.toLowerCase() +
				'-' +
				getters.regionCode.toUpperCase();
		},
		languageCode (state) {
			return state.languageCode || 'sv';
		},
		regionCode (state) {
			return state.regionCode || 'se';
		},
		rawRegionCode (state) {
			return state.regionCode;
		},
		stores (state) {
			return state.stores;
		},
	},

	actions: {
		loadStores ({ commit }) {
			waja.get('store/se') // @TODO läs regionskod dynamiskt
				.useCdn(true)
				.on('success', res => {
					// @TODO Prevent double commit from syncedEvents
					commit('stores', res.data.stores);
					syncedEvents.emit('storesLoaded', res.data);
				}).go();
		},
		loadLanguage ({ commit, dispatch }, callback) {
			waja.get('localization/pack')
				.useCdn(true)
				.on('success', res => {
					commit('phrases', res.data.languagePack);
					commit('regionAndLanguage', res.data);

					syncedEvents.emit('langLoaded', {
						phrases: res.data.languagePack,
						languageCode: res.data.languageCode,
						regionCode: res.data.regionCode,
					});

					// loadNavTree(res.data.languageCode); @TODO lägg till om vi lägger till fler språk
					dispatch('loadStores');

					if (callback) {
						callback();
					}
				}).go();
			loadNavTree('sv'); // @TODO ta bort om vi lägger till fler språk
		},
		setRegionAndLanguage ({ commit, dispatch }, { regionCode, languageCode }) {
			regionCode = regionCode.trim().toLowerCase();
			languageCode = languageCode.trim().toLowerCase();
			if (regionCode === instance.regionCode && languageCode === instance.languageCode) {
				console.error('Du har redan rätt språk/region');
				return;
			}
			waja.post('localization/pack')
				.data({
					regionCode,
					languageCode,
				})
				.on('success', res => {
					dispatch('loadLanguage', () => {
						bus.$emit('localization:locale-updated', languageCode);
					});
				}).go();
		},
	},
});

syncedEvents.on('langLoaded', data => {
	const currentRegion = store.getters['i18n/regionCode'];
	const currentLanguage = store.getters['i18n/languageCode'];
	if (data.regionCode === currentRegion && data.languageCode === currentLanguage) {
		// console.log('Språket som ska laddas är redan laddat.');
		return;
	}
	store.commit('i18n/phrases', data.phrases);
	store.commit('i18n/regionAndLanguage', data);
});

syncedEvents.on('storesLoaded', data => {
	store.commit('i18n/stores', data.stores);
});

let hasWarnedAboutPhraseFinderMode = false;
const instance = {
	setRegionAndLanguage (regionCode, languageCode) {
		store.dispatch('i18n/setRegionAndLanguage', { regionCode, languageCode });
	},
	loadLanguage (callback) {
		store.dispatch('i18n/loadLanguage', callback);
	},
	setPhraseFinderMode (phraseFinderMode) {
		store.commit('syncedStorage/set', { key: 'phraseFinderMode', value: phraseFinderMode });
	},
	get locale () {
		return store.getters['i18n/locale'];
	},
	get phraseFinderMode () {
		return store.state.syncedStorage.phraseFinderMode;
	},
	get rawRegionCode () {
		return store.getters['i18n/rawRegionCode'];
	},
	get regionCode () {
		return store.getters['i18n/regionCode'];
	},
	get languageCode () {
		return store.getters['i18n/languageCode'];
	},
	t (phrase, ...args) {
		const state = store.state.i18n;
		if (state.phrases === null) {
			if (! hasWarnedAboutLanguagePackNotBeingLoaded) {
				console.error('Språkpaketet har inte laddat klart ännu');
				hasWarnedAboutLanguagePackNotBeingLoaded = true;
			}
			return '';
		}
		const i18nPhrase = state.phrases[phrase];
		const phraseFinderMode = store.state.syncedStorage.phraseFinderMode;
		if (i18nPhrase && phraseFinderMode && ! hasWarnedAboutPhraseFinderMode) {
			hasWarnedAboutPhraseFinderMode = true;
			console.warn('Phrase finder mode is activated!');
		}
		if (! i18nPhrase || phraseFinderMode) {
			if (! phraseFinderMode) console.error(new Error('Phrase "' + phrase + '" not found').stack);
			return phrase;
		}
		if (! args.length) {
			return i18nPhrase;
		}
		args.unshift(i18nPhrase);
		return sprintf.apply(null, args);
	},
};
const t = instance.t;
t.prefix = function (prefix) {
	prefix += '.';
	const func = function () {
		arguments.length && (arguments[0] = prefix + arguments[0]);
		return t.apply(null, arguments);
	};
	return func;
};

const setRegionAndLanguage = instance.setRegionAndLanguage;

function storeName (id) {
	if (! (id in store.state.i18n.stores)) {
		// console.error('Missing store with id ', id);
		return '';
	}
	return store.state.i18n.stores[id].name;
}

function getStore (id) {
	return store.state.i18n.stores[id];
}

setTimeout(() => { instance.loadLanguage(); }, 0);

waja.addRequestInterceptor((url, options, useCdn) => {
	if (! useCdn) {
		options().headers.Region = instance.rawRegionCode || null;
	}
});

export default instance;
export {
	t,
	storeName,
	getStore,
	setRegionAndLanguage,
};
