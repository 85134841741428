<template>
	<dropdown v-if="! atCheckout" v-model="largeOpen" :check-id="checkid" class="static cart-dropdown" :label-style="labelStyle" label-class="d-block cart-bg">
		<div
			v-if="count"
			ref="smallCart"
			:class="['cart-header d-flex align-items-center', { fixed }]"
			slot="label"
			:style="{ top }"
			style="z-index: 4"
		>
			<div class="cart-icon-wrapper">
				<img :class="[classes, 'cart-icon']" :src="convertToCdnUrl('/img/icons/cart.svg')" alt="cart" />
				<div v-if="count" :class="{ 'cart-counter': true, max: count > 99 }">
					{{ count < 100 ? count : '99+' }}
				</div>
			</div>
			<div class="cart-widget-info">
				<strong>{{ t('CART.TITLE') }}</strong><br/>
				<span>{{ cartSum }}</span>
			</div>
			<div class="down-arrow _white" />
		</div>
		<div ref="smallCart" class="cart-header d-flex align-items-center _empty" slot="label" v-else>
			<div class="cart-icon-wrapper">
				<img class="cart-icon" :src="convertToCdnUrl('/img/icons/cart.svg')" alt="cart"/>
			</div>
		</div>
		<label :for="checkid" class="cover-view" style="background-color: rgba(0,0,0,.4)"></label>
		<div class="relative" style="z-index: 102">
			<div class="header-dropdown-arrow cart-dropdown-arrow"></div>
		</div>
		<cart-large @link-click="largeOpen = false;"/>
	</dropdown>
</template>

<script>
import Tween from 'tween';
import bus from 'eventbus';
import store from 'datastore';
import { mapState } from 'vuex';
import { t } from 'datastore/i18n';
import { convertToCdnUrl } from 'utils';

export default {
	store,
	data () {
		window.cartComp = this;
		return {
			oldCount: this.count,
			_classes: {
				'mobile-cart-icon': true,
				'new': false,
			},
			largeOpen: this.value,
			timeout: { up: null },
			fixed: false,
			targetTop: 0,
		};
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		minTop: {
			type: [Number, Boolean],
			default: false,
		},
	},
	mounted () {
		bus.$on('cart:open', () => {
			bus.$emit('header.show');
			this.largeOpen = true;
		});
	},
	methods: {
		t,
		convertToCdnUrl,
		up () {
			const rect = this.$el.getBoundingClientRect();
			const height = rect.bottom - rect.top;
			this.timeout.up = null;
			Tween.destroy(this);
			new Tween(this, { targetTop: -height }, 0.2).then(() => {
				this.fixed = false;
			});
		},
	},
	computed: {
		count () {
			const count = this.$store.getters['cart/count'];
			return count;
		},
		checkid () {
			return 'cart-' + this._uid;
		},
		atCheckout () {
			const atCheckout = this.$route.name === 'checkout';
			if (! atCheckout) {
				this.largeOpen = false;
			}
			return atCheckout;
		},
		classes () {
			if (this.$data.oldCount !== this.count) {
				if (this.$data.oldCount < this.count) {
					this.$data._classes.new = true;
					setTimeout(() => {
						if (! this.$data._classes.new) return;
						this.$data._classes.new = false;
						this.$forceUpdate();
					}, 10);
				}
				this.$data.oldCount = this.count;
			}
			return this.$data._classes;
		},
		cartSum () {
			if (this.excludeVat) {
				return this.$store.getters['cart/sumFormattedExclVat'];
			} else {
				return this.$store.getters['cart/sumFormatted'];
			}
		},
		top () {
			if (! this.fixed) return 0;
			return Math.max(this.minTop, this.targetTop) + 'px';
		},
		labelStyle () {
			if (! this.fixed || ! this.$refs.smallCart) {
				return null;
			}
			this.count;
			const { width, height } = this.$refs.smallCart.getBoundingClientRect();
			return {
				width: width + 'px',
				height: height + 'px',
			};
		},
		excludeVat () {
			return this.user.settings.show_prices_excluding_vat;
		},
		...mapState({
			user: 'user',
		}),
	},
	beforeDestroy () {
		window.removeEventListener('scroll', this.updateMinTop);
	},
	watch: {
		value (to) {
			this.largeOpen = to;
		},
		largeOpen (to) {
			if (this.relative && to) {
				to = this.largeOpen = false;
			}
			if (to) {
				this.$store.dispatch('cart/refresh', {checkCart: true});
			}
			if (to) {
				bus.$emit('btn.update');
			}
			this.$emit('input', to);
			this.$emit('change', to);
		},
		count (to, frm) {
			if (to > frm && ! this.largeOpen) {
				if (this.minTop === false) {
					bus.$emit('header.show');
					return;
				}
				const rect = this.$el.getBoundingClientRect();
				const height = rect.bottom - rect.top;
				Tween.destroy(this);
				if (this.fixed) {
					new Tween(this, { targetTop: 0 }, 0.2 * this.targetTop / -height);
				} else {
					this.targetTop = -height;
					this.fixed = true;
					new Tween(this, { targetTop: 0 }, 0.2);
				}
				if (this.timeout.up) clearTimeout(this.timeout.up);
				this.timeout.up = setTimeout(this.up, 2000);
			}
		},
	},
};
</script>
