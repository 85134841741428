<template>
	<div>
		<loading-spinner v-if="tiles.length === 0" />
		<template v-else>
			<h2>{{ popularCategories.name }}</h2>
			<ul class="popular-categories-tile">
				<category-tile v-for="(tile, index) in tiles"
					:key="index + '-popular-category-tile-' + tile.product.id"
					:category="tile.category"
					:product="tile.product"
					:style="tileStyling"
					:position="index"
					:sectionIndex="sectionIndex"
				/>
			</ul>
			<a-link v-if="route" :to="route" buttonStyle="secondary">Se alla kategorier</a-link>
		</template>
 	</div>
</template>

<script>
import { mapGetters } from 'vuex';

const entityTypes = {
	1: 'section',
	2: 'category',
	3: 'manufacturer',
};

export default {
	props: {
		popularCategories: {
			type: Object,
			required: true,
		},
		simulateSectionId: {
		    type: String,
			required: false,
		},
		inAdminTool: {
			type: Boolean,
			required: false,
		},
		sectionIndex: {
			type: Number,
			required: false,
		},
	},
	data () {
		return {
			tiles: [],
			entityTypes,
			tileStyling: null,
			currentRoute: null,
		};
	},
	beforeMount () {
		const data =  {
			id: this.popularCategories.id,
			entityId: this.popularCategories.entityId,
			entityType: this.popularCategories.entityType,
			level: this.popularCategories.level,
			sectionId: this.sectionIdToSend || 0,
		};

		if (this.inAdminTool) {
			data.filters = this.popularCategories.filters;
		}

		waja.get('category/tiles')
			.data(data)
			.on('success', res => {
				res.data.forEach(tile => {
					if (tile.product && this.tiles.length < 8) {
						this.tiles.push(tile);
						this.manufacturer = tile.product.manufacturer;
					}
				});
			}).go();
	},
	mounted() {
		this.currentRoute = this.$route.name;
	},
	computed: {
	    sectionIdToSend() {
	        if (this.simulateSectionId)  {
	            return this.simulateSectionId;
			} else {
	            return this.$route.params.id;
			}
		},
		route () {
			if (
				this.popularCategories.entityType === 5 ||
				(this.popularCategories.entityType === 1 && this.popularCategories.entityId === parseInt(this.$route.params.id))
			) {
				return false;
			}
			if (this.popularCategories.entityType === 4) {
				return {
					name: 'category',
					params: {
						id: 's' + this.popularCategories.entityId,
					},
				};
			}

			return {
				name: this.entityTypes[this.popularCategories.entityType],
				params: {
					id: this.popularCategories.entityId,
				},
			};
		},
		...mapGetters({
			isLoggedIn: 'user/isLoggedIn',
		}),
	},
	watch: {
		tiles (to) {
			/** Checks number of tiles to change <li> flex basis if neccesary*/
			if (to.length > 4 && to.length !== 8) {
				const flexBasis = Math.floor(100 / Math.ceil(this.tiles.length / 2));
				this.tileStyling = {
					flexBasis: flexBasis + '%',
					maxWidth: flexBasis + '%',
				};
			}
		},
	},
};
</script>
