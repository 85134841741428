<template>
	<div class="stars" :style="{ 'vertical-align': align ? 'middle' : 'baseline' }" :title="title">
		<div class="stars-content stars-content-bg" :style="starsContentStyle" />
	</div>
</template>

<script>
import { t } from 'datastore/i18n';
const maxStars = 5;

export default {
	props: {
		stars: {
			type: [Number, String],
		},
		align: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		t,
	},
	computed: {
		title () {
			if (this.stars === null) {
				return this.t('REVIEW.NOT_ENOUGH');
			}
			return this.stars + ' / ' + maxStars;
		},
		starsContentStyle () {
			return {
				width: ((this.stars || 0) / maxStars) * 100 + '%',
			};
		},
	},
};
</script>
