import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

function defaultState () {
	return {
		lastRoute: null,
	};
}

const store = window.store = new Vuex.Store({
	state: defaultState(),
	mutations: {
		lastRoute (state, route) {
			state.lastRoute = route;
		},
	},
	modules: {
		admin: {
			namespaced: true,
		},
	},
});
export default store;
