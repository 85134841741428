<template>
	<div v-if="state !== cookie.ACCEPTED" class="cookie-consent">
		<div
			:class="['container', stateCookieSettings ? 'cookie-settings' : 'cookie-info']"
			id="cookie-banner"
		>
			<template v-if="stateCookieSettings">
				<p class="mb-5">
					Vi använder cookies och pixlar för att anpassa och personalisera din
					upplevelse på vår hemsida och i vår marknadsföring.
				</p>
				<checkbox
					name="necessary"
					label="Nödvändiga cookies"
					value
					disabled
					dark
				/>
				<label class="checkbox-description" for="necessary">
					För att du ska kunna använda vår hemsida, lägga beställningar m.m.
					måste vi sätta ett antal funktionscookies.
					Vi använder också dessa för att underhålla sidan så att den fungerar tekniskt.
				</label>
				<checkbox
					v-model="marketingChanged"
					name="marketing"
					label="Marknadsföringscookies och pixlar"
					dark
					@input="updateMarketing"
				/>
				<label class="checkbox-description" for="marketing">
					Vi använder cookies/pixlar för att kunna nå dig med annonser som
					är relevanta för just dig. Dessa annonser kan utgå från produkter
					du köpt eller tittat på samt vilka kategorier du främst
					intresserat dig för t.ex.
				</label>
				<checkbox
					v-model="personalisationChanged"
					name="personalisation"
					label="Cookies för personalisering"
					dark
					@input="updatePersonalisation"
				/>
				<label class="checkbox-description" for="personalisation">
					Vi använder cookies för att personalisera upplevelsen på vår
					hemsida. På så vis kan vi visa t.ex. produktförslag som passar
					dina behov bättre.
				</label>
				<a-link :to="cookieInfoLink" class="white-link">
					Läs mer om cookies och pixlar på Webhallen här
				</a-link>
				<text-button
					text="Spara inställningar"
					size="large"
					dark
					@click="accept(marketingChanged, personalisationChanged)"
				/>
			</template>
			<template v-else>
				<p class="cookie-consent-description">
					Vi använder cookies för att förbättra din upplevelse på vår hemsida,
					för webbanalys och till att anpassa marknadsföring. Genom att klicka
					på ”Okej, jag förstår” godkänner du användandet av cookies.
					Du kan när som helst justera användningen av cookies i inställningarna i din webbläsare.
				</p>
				<div class="cookie-buttons">
					<text-button
						text="Okej, jag förstår"
						size="large"
						width="stretch"
						@click="accept(marketingChanged=true, personalisationChanged=true)"
					/>
					<text-button
						text="Cookie-inställningar"
						size="large"
						variant="secondary"
						dark
						width="stretch"
						@click="state = cookie.SETTINGS"
					/>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import bus from 'eventbus';
import { mapGetters, mapState } from 'vuex';
import { pageview } from 'gtm';

const cookie = {
	ACCEPTED: 0,
	NOT_ACCEPTED: 1,
	SETTINGS: 2,
};

export default {
	data () {
		return {
			cookie,
			marketingChanged: this.$store.getters['cookieConsent/marketing'],
			personalisationChanged: this.$store.getters['cookieConsent/personalisation'],
			state: cookie.NOT_ACCEPTED,
		};
	},
	mounted () {
		if (this.$store.getters['cookieConsent/necessary']) {
			this.state = this.cookie.ACCEPTED;
		}

		bus.$on('cookie.edit', () => this.state = cookie.SETTINGS);
	},
	methods: {
		updateMarketing (value) {
			this.marketingChanged = value;
		},
		updatePersonalisation (value) {
			this.personalisationChanged = value;
		},
		accept (marketing, personalisation) {
			this.$store.commit('cookieConsent/updateNecessary', true);
			this.$store.commit('cookieConsent/updateMarketing', marketing);
			this.$store.commit('cookieConsent/updatePersonalisation', personalisation);
			this.state = this.cookie.ACCEPTED;

			const cookies = {
				necessary: true,
				marketing: marketing,
				personalisation: personalisation,
			}
			pageview(document.title, window.location.href, this.isLoggedIn, cookies);
		},
	},
	computed: {
		cookieInfoLink () {
			return {
				name: 'info',
				params: {
					id: 26,
					name: 'Integritetspolicy',
				},
			};
		},
		stateCookieSettings () {
			return this.state === this.cookie.SETTINGS;
		},
		...mapState({
			necessary: state => state.cookieConsent.necessary,
			marketing: state => state.cookieConsent.marketing,
			personalisation: state => state.cookieConsent.personalisation,
		}),
		...mapGetters({
			isLoggedIn: 'user/isLoggedIn',
		}),
	},
};
</script>