<template>
	<div class="p-5 d-flex flex-wrap justify-content-center align-items-center">
		<template v-if="isCustom">
			<img v-if="marking.labelImageUrl" :src="marking.labelImageUrl" alt="energy icon" style="float: left;top: 0;width: 250px;height: 560px;border: none;" />
			<div v-else v-html="marking.labelContent" style="float: left;top: 0;width: 250px;height: 560px;border: none;" />
			<div style="float: left;top: 0;width: 500px;max-width: 100%; height: 750px;margin-left: 10px;border: none;">
				<h2>Produktblad</h2>
				<div v-for="(row, index) in customDataTable" class="row" :key="'energy-datasheet-row-' + index">
					<div class="col-md-5" style="border-top: 1px solid #ddd; font-weight: bold;">
						{{ row[0] }}
					</div>
					<div class="col-md-7" style="border-top: 1px solid #ddd">
						{{ row[1] }}
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<iframe :src="energyUrl" style="float: left;top: 0;width: 250px;height: 560px;border: none;" />
			<iframe :src="dataUrl" style="float: left;top: 0;width: 500px;height: 750px;margin-left: 10px;border: none;" />
		</template>
	</div>
</template>

<script>
import i18n from 'datastore/i18n';
export default {
	props: {
		marking: {
			type: Object,
			required: true,
		},
	},
	computed: {
		isCustom () {
			return ! this.marking.manufacturer;
		},
		energyUrl () {
			console.log(i18n.languageCode);
			return 'https://ws.cs.1worldsync.com/9cccecca/page/d8dfd50bfd/LLogoService?logoid=11&style=standard&mf=' +
			encodeURIComponent(this.marking.manufacturer) + '&pn=' +
			encodeURIComponent(this.marking.itemCode) +
			'&lang=' + encodeURIComponent(i18n.languageCode);
		},
		dataUrl () {
			return 'https://ws.cs.1worldsync.com/9cccecca/page/d8dfd50bfd/LProductFiche?mode=specs&mf=' +
			encodeURIComponent(this.marking.manufacturer) + '&pn=' +
			encodeURIComponent(this.marking.itemCode) +
			'&lang=' + encodeURIComponent(i18n.languageCode) +
			'&market=' + encodeURIComponent(i18n.regionCode);
		},
		customDataTable () {
			const items = this.marking.productSheetContent.split(/[|\n]+/);
			const table = [];
			let row = [];
			for (let i = 0; i < items.length; ++i) {
				row.push(items[i].trim());
				if (i & 1) { // is odd, new row
					table.push(row);
					row = [];
				}
			}
			return table;
		},
	},
};
</script>
