function statusesReduce (statuses) {
	let acc = true;
	for (let i = 0; i < statuses.length; i++) {
		acc = acc === undefined || statuses[i] === undefined ? undefined : statuses[i] && acc;
	}
	return acc;
}
function multiRequest (requests, success, error) {
	const responses = [];
	responses.length = requests.length;
	const statuses = [];
	statuses.length = requests.length;
	function onResponse (index, res, status) {
		responses[index] = res;
		statuses[index] = status;
		const allStatus = statusesReduce(statuses);
		if (allStatus !== undefined) {
			if (allStatus && success) success(responses);
			else if (error) error(responses);
		}
	}
	for (let i = 0; i < requests.length; i++) {
		const request = requests[i];
		request
			.on('success', res => onResponse(i, res, true), true)
			.on('error', res => onResponse(i, res, false), true)
			.catch(res => onResponse(i, res, false))
			.go();
	}
}

export { multiRequest };
