import store from 'datastore';

function defaultState () {
	return {
		/**
		 * Number of modals currently opened.
		 */
		modalsOpen: 0,
	};
}

store.registerModule('modal', {
	namespaced: true,
	state: defaultState(),
	getters: {
		getNrModalsOpen (state) {
			return state.modalsOpen;
		},
	},
	mutations: {
		increment (state) {
			state.modalsOpen++;
		},
		decrement (state) {
			state.modalOpen--;
		},
	},
});