import Vue from 'vue';
import bus from 'eventbus';

const connection = {
	isOnline: true,
};
setInterval(() => {
	if (navigator.onLine !== connection.isOnline) {
		Vue.set(connection, 'isOnline', navigator.onLine);
		const ev = connection.isOnline ? 'connection.connected' : 'connection.disconnected';
		console.log(ev);
		bus.$emit(ev);
	}
}, 5000);

export default connection;
