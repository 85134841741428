<template>
	<div class="header-dropdown cart-large">
		<icon-button
			variant="close"
			@click="close"
		/>
		<div class="cart-wrap px-5 pb-4">
			<h2 class="level-one-heading" >{{ tc('TITLE') }}</h2>
			<template v-if="cartCount">
				<div v-if="showTopCheckoutButton" class="to-checkout">
					<a-link :to="{name:'checkout'}" buttonStyle="primary" size="large" center width="stretch">
						{{tc('TO_CHECKOUT')}}
					</a-link>
				</div>
				<cart-list-large @cartChanged="fixLinks" />
				<div class="cart-footer">
					<div class="cart-total">
						<span>
							{{ tc('SUM')}}:
						</span>
						<span class="sum-ex-inkvat">
							<template v-if="excludeVat">
								{{ cartSumExclVat }}
								<span class="small">
									{{ cartSumFormatted }} inkl moms
								</span>
							</template>
							<template v-else>
								{{ cartSumFormatted }}
								<span class="small">
									{{ cartSumExclVat }} exkl moms
								</span>
							</template>
						</span>
					</div>
				</div>

				<!-- Kvar till fri frakt -->
				<free-shipping-info v-if="cartSum > 0" class="free-shipping" :isCart="true" />

				<!-- Till kassan -->
				<div class="to-checkout">
					<a-link :to="{name:'checkout'}" buttonStyle="primary" size="large" center width="stretch">
						{{tc('TO_CHECKOUT')}}
					</a-link>
				</div>
				<div class="clearfix" />
			</template>
			<template v-else>
				<h3 class="level-two-heading mt-5">{{ tc('IS_EMPTY') }}</h3>
			</template>
			<hr/>

			<modal v-if="cartCount">
				<small class="fake-link pull-left" slot="label">
					Spara/dela varukorgen
				</small>
				<share-save-cart-dialog />
			</modal>
			<small v-else-if="isLoggedIn" class="pull-left">
				<router-link :to="wishlistRoute">
					Mina önskelistor
				</router-link>
			</small>
			<small v-if="cartCount" class="fake-link pull-right" @click="clearCart">
				{{ tc('CLEAR') }}
			</small>
			<div class="clearfix" />
		</div>
	</div>
</template>

<script>
import store from 'datastore';
import { t } from 'datastore/i18n';
import { mapGetters, mapState } from 'vuex';

export default {
	store,
	data () {
		return {
			called: 0,
			showTopCheckoutButton: false,
		};
	},
	methods: {
		t,
		tc: t.prefix('CART'),
		close () {
			this.$emit('link-click');
		},
		emitClicked () {
			this.$emit('link-click');
		},
		fixLinks () {
			if (! (this.$el)) {
				if (this.active && this.called < 10) {
					this.called++;
					this.$nextTick(this.fixLinks);
				} else if (this.called >= 10) {
					console.warn('Failed to fix modal links.');
				}
				return;
			}
			this.called = 0;
			const links = this.$el.getElementsByTagName('a');
			for (let i = 0; i < links.length; i++) {
				links[i].addEventListener('click', this.emitClicked);
			}
		},
		clearCart () {
			const gtmProducts = this.cart.rows.map(row => {
				let rowObject = {
					...row.product,
					isInsured: row.extraData.insured,
					insurance: row.insurance,
					qty: row.quantity,
					unitPrice: row.unitPrice,
				}
				return rowObject;
			});
			this.$store.dispatch('cart/clear', {
				product: gtmProducts,
				trackingData: {
					event: 'removeFromCart',
					source: 'Cart',
					coupon: true,
				}
			});
		},
	},
	mounted () {
		this.showTopCheckoutButton = this.cartNumRows > 5;
		this.$nextTick(this.fixLinks);
	},
	computed: {
		wishlistRoute () {
			return {
				name: 'member.wishlists',
				params: {
					id: this.user.id,
					name: this.user.username,
				},
			};
		},
		excludeVat () {
			return this.user.settings.show_prices_excluding_vat;
		},
		...mapState({
			user: state => state.user,
			cart: state => state.cart,
		}),
		...mapGetters({
			cartSum: 'cart/sum',
			cartSumFormatted: 'cart/sumFormatted',
			cartSumExclVat: 'cart/sumFormattedExclVat',
			cartCount: 'cart/count',
			cartRows: 'cart/count',
			cartNumRows: 'cart/numRows',
			cartMostExpensiveProduct: 'cart/mostExpensiveProduct',
			isLoggedIn: 'user/isLoggedIn',
		}),
	},
	watch: {
		cartNumRows (to, frm) {
			// @TODO vi testar utan först.. blir så många requests this.getOffers();
		},
	},
};
</script>
