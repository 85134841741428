<template>
	<div :class="[priceClasses,{'ex-vat':excludeVat && large}]">
		<span v-if="(product.price || priceOverride || subscriptionProduct) && ! row">
			{{ formatProductPrice(priceObject.currentPrice, excludeVat) }}
			<div v-if="excludeVat && large" class="price-explainer ex-vat">
				(exkl.moms)
			</div>
			<div
				v-if="levelOneMember && ! isLoggedIn && ! priceObject.comparisonPrice"
				class="level-one-membership price-explainer small"
			>
				<div class="price-type">
					<img class="price-icon" :src="iconSrc" alt="price icon" />
					<span class="level-one-price-text">
						{{ priceTypeText }}
					</span>
					<span class="highlight">
						{{ formatProductPrice(levelOneMember, excludeVat) }}
					</span>
				</div>
				<div class="small member-form">
					<a @click="login">Logga in</a> / <a @click="register">Bli Medlem</a>
				</div>
			</div>
			<div v-if="priceObject.comparisonPrice && large" class="price-explainer">
				<div :class="['price-type', { 'hide-type': ! priceSavings}]">
					<img class="price-icon" :src="iconSrc" alt="price-icon" />
					{{ priceTypeText }}
				</div>
				<div class="regular-price">
					{{ regularPriceText }}:
					<span class="regular-price-value">{{ formatProductPrice(priceObject.comparisonPrice, excludeVat) }}</span>
				</div>
			</div>
			<span :class="regularClasses" v-if="priceSavings && priceObject.comparisonPrice && ! large">
				{{ formatProductPrice(priceObject.comparisonPrice, excludeVat) }}
			</span>
			<div class="clearfix" />
		</span>
		<div v-if="row">
			<span>
				{{ formatProductPrice(priceObject.currentPrice, excludeVat) }}
			</span>
			<span :class="regularClasses" v-if="priceSavings && priceObject.comparisonPrice && ! large">
				{{ formatProductPrice(priceObject.comparisonPrice, excludeVat) }}
			</span>
			<div class="clearfix" />
		</div>
	</div>
</template>

<script>
import store from 'datastore';
import { mapGetters, mapState } from 'vuex';
import { formatProductPrice, formatPrice, convertToCdnUrl, getPriceObject, getPriceSavings } from 'utils';
import bus from 'eventbus';

export default {
	store,
	props: {
		product: {
			type: Object,
			required: false,
		},
		below: {
			type: Boolean,
			default: false,
		},
		right: {
			type: Boolean,
			default: false,
		},
		center: {
			type: Boolean,
			default: false,
		},
		large: {
			type: Boolean,
			default: false,
		},
		priceOverride: { // Används för override av produktens nuvarande pris i kundvagn, där vi istället går på cart rows unit price
			type: [String, Number],
			default: null,
		},
		priceOverrideVat: {
			type: [String, Number],
			default: null,
		},
		alwaysIncludeVat: {
			type: Boolean,
			default: false,
		},
		row: {
			type: Boolean,
			default: false,
		},
		subscriptionProduct: {
			type: Boolean,
			default: false,
		}
	},
	methods: {
		formatPrice,
		formatProductPrice,
		login () {
			bus.$emit('header.show');
			bus.$emit('open:login');
		},
		register () {
			bus.$emit('header.show');
			bus.$emit('open:register');
		},
	},
	computed: {
		excludeVat () {
			return this.user.settings.show_prices_excluding_vat && ! this.alwaysIncludeVat;
		},
		levelOneMember () {
			if (this.product.levelOnePrice && parseFloat(this.product.levelOnePrice.price) < parseFloat(this.priceObject.currentPrice.price)) {
				return this.product.levelOnePrice;
			}
			return null;
		},
		priceClasses () {
			return {
				'_campaign': !! this.priceSavings,
				'price-value': true,
				'_large': this.large,
				'_below': this.below,
				'_right': this.right,
				'_center': this.center,
				'_row': this.row,
			};
		},
		regularClasses () {
			return {
				'price-value': true,
				'_regular': true,
			};
		},
		iconSrc () {
			const isDark = this.$store.getters['user/isDark'];
			const icon = this.icon + (isDark ? '_white' : '');
			return convertToCdnUrl('/img/icons/price/' + icon + '.svg');
		},
		icon () {
			let iconType = 'campaign';
			if (this.product.price.type === 'member' || (this.product.levelOnePrice && ! this.isLoggedIn && this.product.price.type === null)) {
				iconType = 'member';
			} else if (this.product.price.type === 'adjustment') {
				iconType = 'previous';
			} else if (this.product.isFyndware) {
				iconType = 'fyndware_mini';
			}
			return iconType;
		},
		priceTypeText () {
			// @TODO t
			if (this.product.price.flashSale) {
				return 'Flash-sale!';
			}
			if (this.product.levelOnePrice && ! this.isLoggedIn && this.product.price.type === null) {
				return 'Medlemspris!';
			}
			switch (this.product.price.type) {
				case 'member':
					return 'Medlemspris!';
				case 'adjustment':
					return 'Nyligen prissänkt!';
				case 'priceList':
					return 'Unikt pris!';
			}
			if (this.product.isFyndware) {
				return 'Fyndvarupris!';
			}
			return 'Kampanjpris!';
		},
		priceObject () {
			return getPriceObject(this.product, this.priceOverride, this.priceOverrideVat, this.subscriptionProduct);
		},
		priceSavings () {
			return getPriceSavings(this.priceObject, this.excludeVat);
		},
		regularPriceText () {
			if (this.product.price.type === 'adjustment') {
				return 'Tidigare pris';
			}
			return 'Tidigare lägsta pris 30 dagar';
		},
		...mapState({
			user: 'user',
		}),
		...mapGetters ({
			isLoggedIn: 'user/isLoggedIn',
		}),
	},
};
</script>
