import alert from 'alert';
import { trackImpression } from 'gtm';
import { throttle, isFunction } from 'lodash';
import waja from 'waja';
import store from '../index';

const Methods = {
	RELATIVE: 'relative',
	ABSOLUTE: 'absolute',
	UPDATE_ROW: 'updateRow',
	CLEAR: 'clear',
	UPDATE: 'update',
};

let lastKnownCart = '';

const clean = {
	promise: null,
	resolve: null,
};

let lastRequestDone = true;

const eventSender = throttle(function ({ commit, getters, dispatch }) {
	if (! lastRequestDone) {
		eventSender({ commit, getters, dispatch });
		return;
	}

	if (getters.events.length) {
		const serverEvents = [];
		getters.events.forEach((event) => {
			const serverEvent = {
				method: event.method,
			};
			if (event.key) serverEvent.key = event.key;
			if (event.value !== undefined) serverEvent.value = event.value;
			if (event.extraData) serverEvent.extraData = event.extraData;
			if (event.product) serverEvent.productId = event.product.id;
			if (event.amount !== undefined) serverEvent.quantity = event.amount;
			if (event.price !== undefined) serverEvent.unitPrice = event.price;
			if (event.pass) serverEvent.pass = event.pass;
			serverEvents.push(serverEvent);
		});
		const retry = () => {
			lastRequestDone = false;
			waja.get('cart')
				.on('all', (res, pass) => {
					pass();
					lastRequestDone = true;
				})
				.catch((res) => {
					lastRequestDone = true;
					throw res;
				})
				.on('success', response => dispatch('update', { response }))
				.on('error', () => dispatch('restore'))
				.catch(() => dispatch('restore'))
				.go();
		};
		lastRequestDone = false;
		waja.post('cart')
			.data({
				events: serverEvents,
			})
			.on('all', (res, pass) => {
				pass();
				lastRequestDone = true;
			})
			.catch((res) => {
				lastRequestDone = true;
				throw res;
			})
			.on('success', response => dispatch('update', { response }))
			.on('error', retry)
			.catch(retry)
			.go();
		commit('clearEvents');
	} else {
		lastRequestDone = false;
		waja.get('cart')
			.on('all', (res, pass) => {
				pass();
				lastRequestDone = true;
			})
			.catch((res) => {
				lastRequestDone = true;
				throw res;
			})
			.on('success', response => dispatch('update', { response }))
			.go();
	}
}, 1000);

function resolveCartClean () {
	if (clean.resolve) {
		store.dispatch('cart/isClean').then((isClean) => {
			if (isClean) {
				clean.resolve();
				clean.promise = null;
				clean.resolve = null;
			}
		});
	}
}

function handleGtmTracking ({ product, extraData, trackingData }) {
	const arrayifiedTrackingProducts = product.length ? product : [product];
	const eventData = {
		addToCart: {
			event: 'add_to_cart',
			isInsurance: trackingData?.isInsurance,
			insurance: trackingData?.insurance,
			isGiftCard: extraData?.isGiftCard,
			giftCard: extraData?.giftCard,
			rating: trackingData?.rating,
			stock: trackingData?.stock,
			coupon: trackingData?.coupon,
			qty: arrayifiedTrackingProducts.length ? trackingData?.quantity ?? 1 : null,
		},
		removeFromCart: {
			event: 'remove_from_cart',
			isInsurance: trackingData?.isInsurance,
			insurance: trackingData?.insurance,
			isGiftCard: extraData?.isGiftCard,
			giftCard: extraData?.giftCard,
			rating: trackingData?.rating,
			stock: trackingData?.stock,
			coupon: trackingData?.coupon,
			qty: arrayifiedTrackingProducts.length ? trackingData?.quantity ?? 1 : null,
		},
	}
	trackImpression(arrayifiedTrackingProducts, trackingData?.source, eventData[trackingData.event]);
}

window.addEventListener('beforeunload', () => {
	eventSender.flush();
});

function getLastRequestDone () { return lastRequestDone; }
export { Methods };
export { getLastRequestDone };
export default {
	queueEvent ({ commit, getters, dispatch }, { method, product, amount, price, vat, pass, extraData, key, value, trackingData }) {
		const event = {
			extraData,
			method,
			product,
			amount,
			price,
			vat,
			pass,
			key,
			value,
			trackingData,
		};
		if (method === Methods.CLEAR) commit('clearEvents');
		if (method !== Methods.UPDATE) {
			//commit('predictRows', event);
			commit('addEvent', event);
		}
		eventSender({ commit, getters, dispatch });
		if (trackingData) {
			handleGtmTracking(event);
		}
	},
	refresh ({ dispatch }, payload) {
		let callback;
		let onError;
		let shouldCheckCart = false;
		if (isFunction(payload)) {
			callback = payload;
			shouldCheckCart = true;
		} else if (payload) {
			callback = payload.onSuccess;
			onError = payload.onError;
			shouldCheckCart = true;
		}
		let checkCart = {checkCart: false};
		if (shouldCheckCart) {
			checkCart.checkCart = true;
		}
		const req = waja.get('cart')
			.data(checkCart)
			.on('success', response => {
				dispatch('update', { response, callback });
			});

		if (onError) {
			req.on('error', onError);
		}

		req.go();
	},
	update ({ commit, getters }, { response, callback }) {
		const cart = response.data.cart;
		if (response.data.errors) {
			for (const error of response.data.errors) {
				alert(error);
			}
		}
		if (response.data.corrections) {
			// @TODO ska vi skriva ut dom på annat vis i kassan?
			for (const correction of response.data.corrections) {
				if (correction.type === 'MAX_X_PER_CUSTOMER') {
					alert('Din kundvagn har korrigerats, du får max köpa ' + correction.value + ' st ' + correction.name + '.');
				} else if (correction.type === 'OFFER_ORIGIN_MISSING') {
					alert('Din kundvagn har korrigerats, produkten som behövs för att få erbjudandepriset ' + correction.value + ' kr saknas från din kundvagn.');
				} else if (correction.type === 'INSURED_TOGGLE') {
					alert('Alla exemplar av produkten har ' + (correction.value ? 'försäkrats' : 'av-försäkrats'));
				}
			}
		}
		lastKnownCart = response.raw;
		if (lastKnownCart) commit('replace', cart);
		// @TODO region + valuta
		commit('fastForward');

		resolveCartClean();

		if (callback) {
			callback(response.data);
		}
	},
	restore ({ commit, getters }) {
		if (lastKnownCart) commit('replace', JSON.parse(lastKnownCart).cart);
		resolveCartClean();
	},
	onceClean ({ dispatch }) {
		clean.promise = clean.promise || new Promise(resolve => (clean.resolve = resolve));

		setTimeout(() => {
			store.dispatch('cart/isClean').then((isClean) => {
				resolveCartClean();
			});
		}, 0);

		return clean.promise;
	},
	flushThrottledEvents () {
		eventSender.flush();
	},
	add({ dispatch }, { product, amount, price, doAlert, extraData, trackingData }) {
		if (doAlert || doAlert === undefined) {
			alert('Du har lagt till en produkt i varukorgen');
		}
		amount = amount !== undefined ? amount : 1;
		price = price || product.price.price;
		const vat = product.price ? product.price.vat : null;
		const event = {
			method: Methods.RELATIVE,
			trackingData,
			extraData,
			product,
			amount,
			price,
			vat,
		};
		if (product.price && product.price.pass) {
			event.pass = product.price.pass;
		}
		dispatch('queueEvent', event);
	},
	addMany ({ dispatch }, { products, extraData, trackingData }) {
		let sum = 0;
		const trackingProducts = [];
		for (let i = 0; i < products.length; i++) {
			sum += products[i].amount || 1;
			dispatch('add', { doAlert: false, ...products[i], extraData });
			let productTracking = {
				...products[i].product,
				qty: products[i].amount,
			}
			trackingProducts.push(productTracking);
		}
		if (trackingData) {
			handleGtmTracking({ product: trackingProducts, trackingData });
		}
		if(sum !== 0) {
			alert('Du har lagt till ' + sum + ' produkt(er) i varukorgen');
		}
	},
	set ({ state, dispatch }, { product, amount,  price, pass, extraData, trackingData }) {
		amount = amount !== undefined ? amount : 1;
		price = price !== undefined && product.price !== undefined ? price || product.price.price : 0;
		const vat = product.price ? product.price.vat : null;
		const event = {
			method: Methods.ABSOLUTE,
			extraData,
			product,
			amount,
			price,
			vat,
			trackingData,
		};
		if (pass) {
			event.pass = pass;
		}
		dispatch('queueEvent', event);
	},
	setMany ({ dispatch }, products) {
		for (let i = 0; i < products.length; i++) {
			dispatch('set', products[i]);
		}
	},
	updateRow ({ commit, dispatch }, { product, price, key, value, extraData, pass, trackingData }) {
		const event = {
			method: Methods.UPDATE_ROW,
			extraData,
			product,
			price,
			value,
			key,
			trackingData,
		};

		if (pass) {
			event.pass = pass;
		}

		dispatch('queueEvent', event);
	},
	remove ({ dispatch }, { product, price, pass, extraData, trackingData }) {
		dispatch('set', { product, amount: 0, price, pass, extraData, trackingData });
	},
	clear ({ dispatch }, { product, trackingData }) {
		const event = {
			method: Methods.CLEAR,
			product,
			trackingData,
		};
		dispatch('queueEvent', event);
	},
	wasDestroyed ({ commit }) {
		commit('replace', {});
	},
	isClean ({ getters }) {
		const events = getters.events;
		return lastRequestDone && events.length === 0;
	},
};
