import { Methods } from './actions';
import { getCartRowKeyByProduct, getCartRowKey } from 'utils';
import { defaultState } from './index';
import bus from 'eventbus';
import alert from 'alert';
import { t } from 'datastore/i18n';

let pendingTempIndex = 0;

function getKey (product, price, extraData) {
	if (price === undefined) {
		if (product.product !== undefined) {
			price = product.unitPrice;
			product = product.product;
		} else {
			price = product.price.price;
		}
	}
	let key = (product.id || product) + '@' + price * 1;
	if (product.price && product.price.pass) {
		key += '#' + product.price.pass;
	}
	return key;
}

const mutations = {
	predictRows (state, event) {
		if (event.method === Methods.CLEAR) {
			state.rows = [];
			return;
		};

		key = getCartRowKeyByProduct(event.product, event.price, event.extraData || undefined);
		let index;
		for (let i = 0; i < state.rows.length; i++) {
			const rowKey = getCartRowKey(state.rows[i]);
			if (rowKey === key) {
				index = i;
				break;
			}
		}

		if (event.method === Methods.UPDATE_ROW) {
			// Update row method updates extra data.
			if (index !== undefined) {
				state.rows[index].extraData = event.toRow.extraData;
			}
		} else {
			// Other methods update quantity.
			let quantity;
			// Calcualte new quantity.
			if (event.method === Methods.ABSOLUTE || index === undefined) {
				quantity = event.amount;
			} else {
				quantity = state.rows[index].quantity + event.amount;
			}
			// If quantity is less than zero, then remove the row.
			if (quantity <= 0) {
				if (index !== undefined) state.rows.splice(index, 1);
				return;
			}

			if (index !== undefined) {
				state.rows[index].quantity = quantity;
			} else {
				state.rows.push({
					product: event.product,
					unitPrice: event.price,
					unitVat: event.vat,
					quantity,
					extraData: [],
					id: 'pendingTempId' + ++pendingTempIndex,
				});
			}
		}
	},

	replace (state, cart) {
		Object.assign(state, defaultState(), cart);
		state.lastUpdate = Date.now();
	},
	/**
	 * When there are multiple events in the event queue, we want to predict
	 * the results of the next response from the cart endpoint so the user
	 * gets visual feedback in checkout, even thought the cart controller
	 * might have slow response times.
	 *
	 */
	fastForward (state) {
		state.events.forEach((event) => mutations.predictRows(state, event));
		bus.$emit('cart.update');
	},
	addEvent (state, event) {
		const restrictedProducts = [
			366713,
			375341,
			375369,
			375370,
			375371,
			375372,
			375373,
		];
		if (restrictedProducts.find((id) => id === event.product.id) && event.amount > 1) {
			alert(t('PRODUCT.MAX_PURCHASE_LIMIT'));
			return;
		}
		state.events.push(event);
	},
	clearEvents (state) {
		state.events = [];
	},
	storeAnalytics (state) {
		state.analytics = {
			rows: state.rows,
			currency: state.currency,
		};
	},
	clearAnalytics (state) {
		state.analytics = null;
	},
};

export default mutations;
