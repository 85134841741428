import Vue from 'vue';
import { throttle } from 'lodash';

const sizes = {
	xs: 1,
	sm: 2,
	md: 3,
	lg: 4,
};

const data = {
	current: null,
	pageWidth: 1920,
	pageHeight: 1080,
	//mainContentWidth = content without aside, mainContainerWidth = main with asides
	mainContentWidth: 779,
	mainContainerWidth: 1168,
	get xs () {
		return sizes.xs;
	},
	get sm () {
		return sizes.sm;
	},
	get md () {
		return sizes.md;
	},
	get lg () {
		return sizes.lg;
	},
	eq (mq) {
		return data.current === sizes[mq];
	},
	neq (mq) {
		return data.current !== sizes[mq];
	},
	gt (mq) {
		return data.current > sizes[mq];
	},
	lt (mq) {
		return data.current < sizes[mq];
	},
	gte (mq) {
		return data.current >= sizes[mq];
	},
	lte (mq) {
		return data.current <= sizes[mq];
	},
	get screenSize () {

	},
	forceUpdate () {
		getCurrentMQ();
	},
};

function getCurrentMQ () {
	const str = window.getComputedStyle(document.body, ':after').getPropertyValue('content');
	// vissa browsers ger "mq-md", vissa (host host safari) ger mq-md (utan citattecken)
	const mq = str[0] === '"' ? str.substr(4, 2) : str.substr(3, 2);
	Vue.set(data, 'current', data[mq]);
	// console.log('MQ:', mq, 'strlen: ', str.length);
	Vue.set(data, 'pageWidth', window.innerWidth || document.documentElement.clientWidth);
	Vue.set(data, 'pageHeight', window.innerHeight || document.documentElement.clientHeight);

	// @TODO uppdatera även denna efter att vänsterkol döljs/visas?
	const mainContainer = document.getElementById('main-container');
	if (mainContainer) {
		Vue.set(data, 'mainContentWidth', mainContainer.getBoundingClientRect().width);
	}
}

export function isDesktop () {
	return data.current > data.md;
};

window.addEventListener('resize', throttle(getCurrentMQ, 75));

Vue.nextTick(getCurrentMQ);

export default data;
