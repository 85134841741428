import app from 'app';
import store from 'datastore';
import prerenderAPI from 'prerenderAPI';
import router from 'router';
import { prerender } from 'staticData';

function defaultState () {
	return {
		error: null,
		toRoute: null,
		unsuccessfulRoutingAttempts: 0,
	};
}

function sameRoute (to, frm) {
	if (to.name !== frm.name || (to.params.id && frm.params.id &&
		~~to.params.id !== ~~frm.params.id)) {
		return false;
	}
	return true;
}

store.registerModule('prerender', {
	namespaced: true,
	state: defaultState(),
	actions: {
		/**
		 * Navigates to a site 3.0 URL.
		 */
		goToUrl ({ state, commit, dispatch }, url) {
			const resolved = router.resolve(url);
			if (! resolved.route.matched.length) {	
				prerenderAPI.onNavigationError(404);
				return;
			}
			let route = {
				name: resolved.route.name,
				query: resolved.route.query,
				params: {},
			};
			if (resolved.route.params.id) {
				route.params.id = resolved.route.params.id;
			}
			if (resolved.route.params.name) {
				route.params.name = resolved.route.params.name;
			}
			route = router.route(route);
			dispatch('goToRoute', route);
		},
		goToRoute({ state, commit, dispatch }, route) {
			commit('toRoute', route);
			router.replace(route, () => {
				// Upon success do nothing (success is negotiated when a page
				// sets its pageLoaded status to true), or when the router handles
				// it.
				commit('unsuccessfulRoutingAttempts', 0);
				commit('error', null);
			}, () => {
				if (sameRoute(app.$route, route)) {
					// The navigation is aborted when we try to navigate to exactly the same route.
					if (state.error) {
						// If the current page contains some error.
						dispatch('handleError');
						return;
					}
					dispatch('pageLoaded');
				} else {
					if (state.unsuccessfulRoutingAttempts >= 1) {
						// Maximum number of retries, 
						commit('unsuccessfulRoutingAttempts', 0);
						return;
					}
					
					console.error('Got undefined routing error when navigating for the prerenderer.');
					// Undefined routing error, try to navigate again.
					commit('unsuccessfulRoutingAttempt');
					Vue.nextTick(() => {
						dispatch('goToRoute', route);
					});
					return;
				}
			});
		},
		/**
		 * Called when a page needs to redirect to another page.
		 */
		redirect ({ commit, dispatch }, route) {
			commit('unsuccessfulRoutingAttempts', 0);
			commit('error', null);

			dispatch('goToRoute', route);
		},
		handleError ({ state }) {
			prerenderAPI.onNavigationError(state.error);
		},
		pageError ({ commit, dispatch }, error) {
			commit('error', error);
			dispatch('handleError');
		},
		pageLoaded ({ commit, state }) {
			if (! prerender || ! state.toRoute) {
				return;
			}
			if (! sameRoute(state.toRoute, app.$route)) {
				console.error('Routing error, loaded the wrong page when prerendering');
				return;
			}
			prerenderAPI.onPageLoad();
		},
	},
	mutations: {
		unsuccessfulRoutingAttempt(state) {
			state.unsuccessfulRoutingAttempts++;
		},	
		unsuccessfulRoutingAttempts(state, attempts) {
			state.unsuccessfulRoutingAttempts = attempts;
		},
		error (state, error) {
			state.error = error;
		},
		toRoute (state, route) {
			state.toRoute = route;
		},
	},
});