<template>
	<li :class="computedClass" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
		<router-link
			:to="path"
			@click.native="click"
			@touchend.native="doubleTouch"
		>
			<ximg
				v-if="useIcon"
				:src="sectionIconUrl"
				ariaLabel="section icon"
				role="img"
			/>
			{{ section.name }}
		</router-link>
		<div v-if="! isCampaignSection" class="dropdown-menu dropdown-section" :style="{ display: forceHide ? 'none' : null }">
			<div class="section-heading">
				<span class="section-icon-wrap">
					<ximg
						v-if="section.icon"
						:src="iconUrl(section.icon)"
						:ariaLabel="section.name"
						role="img"
					/>
				</span>
				<h2 class="level-one-heading">{{ section.name }}</h2>
				<router-link
					class="d-inline fake-link underlined-link"
					:to="sectionUrl"
				>
					Gå till kategori
				</router-link>
			</div>
			<div class="line-breaks" v-for="index in 4" :style="{ left: (20*index)+'%' }" :key="`line-breaks-${index}`" />
			<ul class="list-unstyled section-categories masonry" :style="fyndwareStyle" ref="container">
				<site-nav-category
					v-for="(category, index) in sectionCategories"
					:key="`category-${category.id}-${index}`"
					:category="category"
					@click="isHovering = false" />
			</ul>
			<ul v-if="! isFyndwareSection" class="list-unstyled section-categories" style="width: 19%;" ref="container">
				<site-nav-category
					v-for="(category, index) in rightColumn"
					:key="`category-campaign-${category.id}-${index}`"
					:category="category"
					@click="isHovering = false" />
			</ul>
			<div class="hover-saver _top _careful"></div>
			<div class="hover-saver _right"></div>
			<div class="hover-saver _bottom"></div>
			<div class="hover-saver _left"></div>
		</div>
	</li>
</template>

<script>
import { categoryIconUrl, standardRoute } from 'utils';
import bus from 'eventbus';
import store from 'datastore';
import { mapState } from 'vuex';
import { sectionIds } from 'staticData';
import { sectionLinks } from 'staticNavLinks';
import { trackEvent } from 'gtm';

export default {
	props: {
		section: {
			type: Object,
			required: true,
		},
		useIcon: {
			type: Boolean,
			default: false,
		},
	},
	store,
	data () {
		return {
			categoryIconUrl,
			hover: false,
			forceHide: false,
			isHovering: false,
			kampanjer: { name: 'Kampanjer', id: this.section.id, subCategories: [] },
		};
	},
	beforeDestroy () {
		window.removeEventListener('touchend', this.removeTouch);
		bus.$off('close-top-nav', this.close);
	},
	mounted () {
		bus.$on('close-top-nav', this.close);

		if (! this.isCampaignSection) {
			this.section.categories = this.section.categories.concat(this.staticLinks);
			this.section.highlights.forEach((item) => {
				if (item.campaign && item.campaign.id && item.campaign.name) {
					this.kampanjer.subCategories.push({ id: item.campaign.id, name: item.campaign.name, isCampaign: true });
				}
			});
		}
	},
	computed: {
		staticLinks () {
			if (sectionLinks[this.section.id]) {
				return sectionLinks[this.section.id];
			}

			return [];
		},
		path () {
			if (this.isCampaignSection) {
				if (this.isBlackWeek) {
					return this.$router.route(standardRoute('campaign', { id: 7866, name: 'Black Friday' }));
				}
				return this.$router.route(standardRoute('campaigns', {name: 'campaigns'}));
			}

			return this.$router.route(standardRoute('section', this.section));
		},
		rightColumn () {
			if (this.isCampaignSection) {
				return;
			}
			const rightColumn =  this.section.categories.reduce((accumulator, cat, index) => {
				if((index === 4 && ! this.isFyndwareSection) || cat.name === 'Nya produkter') {
					accumulator.push({
						sectionName: this.section.name,
						path: [cat],
						...cat
					});
				}
				return accumulator;
			}, []);

			rightColumn.push(this.kampanjer);

			return rightColumn;
		},
		sectionCategories () {
			if (this.isCampaignSection) {
				return;
			}
			return this.section.categories.reduce((accumulator, cat) => {
				if(! this.rightColumn.some(rightColItem => rightColItem.id === cat.id)) {
					accumulator.push({
						sectionName: this.section.name,
						path: [cat],
						...cat
					});
				}
				return accumulator;
			}, []);
		},
		selected () {
			return this.navStatus.section &&
				this.section.id === this.navStatus.section.id;
		},
		computedClass () {
			return [
				'root section',
				'dropdown',
				{
					selected: this.selected,
					hover: this.hover,
				},
			];
		},
		isFyndwareSection () {
			return this.section.id === sectionIds.fyndwares;
		},
		isCampaignSection () {
			return this.section.id === sectionIds.campaigns;
		},
		linkStyle () {
			const style = {};
			if ((this.selected || this.isHovering) && this.isFyndwareSection) {
				style.color = '#333333';
				style.textShadow = 'none';
			}
			return style;
		},
		style () {
			const section = this.section;
			return {
				'border-bottom-color': section.color,
				'background-color': section.color,
			};
		},
		fyndwareStyle() {
			if (! this.isFyndwareSection) return;

			return {
				'grid-template-columns': 'repeat(auto-fill, 20%) !important',
				'width': 'calc(100% + 15px)',
			};
		},
		sectionUrl () {
			return this.$router.route(standardRoute(this.path.name, this.section));
		},
		sectionIconUrl () {
			return categoryIconUrl(this.section.icon, false, this.section.iconColor);
		},
		isBlackWeek () {
			const now = new Date();
			return now >= new Date('2024/11/11 00:00:01') && now <= new Date('2024/12/02 23:59:59');
		},
		...mapState({
			navStatus: state => state.navStatus,
		}),
	},
	methods: {
		removeTouch (event) {
			if (! this.$el.contains(event.target) && this.$el !== event.target) {
				this.hover = false;
				window.removeEventListener('touchend', this.removeTouch);
			}
		},
		mouseEnter () {
			this.isHovering = true;
			this.forceHide = false;
		},
		mouseLeave () {
			this.isHovering = false;
			if (this.forceHide) {
				this.forceHide = false;
			}
		},
		iconUrl (icon) {
			return categoryIconUrl(icon, false, '1A1A1D');
		},
		close () {
			if (this.isHovering) {
				this.forceHide = true;
				this.isHovering = false;
			}
			this.hover = false;
		},
		click (event) {
			trackEvent('gaEvent', 'Interactions', 'Menu Click', 'Top Menu');
			if (! event.ctrlKey) {
				event.preventDefault();
			}
		},
		doubleTouch (event) {
			event.preventDefault();
			if (! this.hover) {
				this.hover = true;
				this.forceHide = false;
				window.addEventListener('touchend', this.removeTouch);
			} else {
				window.removeEventListener('touchend', this.removeTouch);
				bus.$emit('close-top-nav');
				console.log('TOUCH', this.path);
				this.$router.push(this.path);
			}
		},
	},
};
</script>
