import Vue from 'vue';
import { throttle } from 'lodash';

const listeners = [];

const onScroll = throttle(() => {
	if (! listeners.length) {
		return;
	}
	for (const i in listeners) {
		listeners[i].recalculateInViewport();
	}
}, 150);

let interval = null;

function addListener (instance) {
	if (listeners.indexOf(instance) >= 0) {
		return;
	}
	if (listeners.length === 0) {
		window.addEventListener('scroll', onScroll);
		window.addEventListener('resize', onScroll);
		interval = setInterval(onScroll, 3000);
	}
	listeners.push(instance);
}

function removeListener (instance) {
	const index = listeners.indexOf(instance);
	if (index < 0) {
		return;
	}
	listeners.splice(index, 1);
	if (listeners.length === 0) {
		window.removeEventListener('scroll', onScroll);
		window.removeEventListener('resize', onScroll);
		clearInterval(interval);
	}
}

export default {
	data () {
		return {
			inViewport: false,
			inViewportMargin: 0,
		};
	},
	mounted () {
		this.$on('inViewport:recalculate', this.recalculateInViewport);
		addListener(this);

		setTimeout(() => {
			this.recalculateInViewport();
		}, 100);
	},
	beforeDestroy () {
		this.$off('inViewport:recalculate', this.recalculateInViewport);
		removeListener(this);
	},
	methods: {
		removeInViewportHandlers () {
			removeListener(this);
		},
		addInViewportHandlers () {
			addListener(this);
		},
		recalculateInViewport () {
			this.inViewport = this.checkInViewport(this.$el.getBoundingClientRect(), this.inViewportMargin);
		},
		checkInViewport (rect, margin) {
			return (
				rect.right > -margin &&
				rect.bottom > -margin &&
				rect.top < (window.innerHeight || document.documentElement.clientHeight) + margin &&
				rect.left < (window.innerWidth || document.documentElement.clientWidth) + margin
			);
		},
	},
};
