import store from './index';
import waja from 'waja';
import businessVariables from 'businessVariables';

function defaultState () {
	return {
		lowestShippingPrice: 0,
		priceList: [],
	};
}

store.registerModule('shippingPrices', {
	namespaced: true,
	state: defaultState(),
	actions: {
		getShippingMethods ({ dispatch, commit }, { product, rankLevel }) {
			/** First check if there is a product to get shipping methods
			 * for, otherwise just set lowestShippingPrice to 0 since
			 * user probably isn't on a product page
			 */
			if (product) {
				waja.get('shipping-method')
					.on('success', res => {
						dispatch('getPriceList', { product, rankLevel, shippingMethods: res.data.shipping_methods });
					}).go();
			} else {
				commit('setLowestShippingPrice', 0);
				commit('setPriceList', []);
			}
		},
		getPriceList ({ commit }, { product, rankLevel, shippingMethods }) {
			const result = [];
			for (const shippingMethod of shippingMethods) {
				if (shippingMethod.id === 20 && product.price && product.price.price < businessVariables.minimumPurchaseForFreeShipping[product.price.currency]) { // Frakt "standard" (gratis över x kr)
					continue;
				}
				const method = {
					id: shippingMethod.id,
					name: shippingMethod.name,
				};
				for (const { price, shippingMethodId, isFixedPrice } of product.shippingClass.prices) {
					if (shippingMethodId === method.id) {
						if (
							shippingMethod.maximumPackageSizeId !== null
							&& product.packageSizeId !== null
							&& product.packageSizeId > shippingMethod.maximumPackageSizeId
						) {
							break;
						}

						const discountedPrice = (! isFixedPrice && (rankLevel > 0 || product.price.price >= 500)) || (rankLevel > 0 && product.price.price >= 500);

						if (discountedPrice && rankLevel > 0 && product.price.price > 500
						&& (shippingMethodId == 25 || shippingMethodId == 29
						|| ((product.shippingClass.id == 1 || product.shippingClass.id == 2
						|| product.shippingClass.id == 3 || product.shippingClass.id == 4)
						&& (shippingMethodId == 3 || shippingMethodId == 26)))) {
							if (rankLevel <= 8) method.price = 49;
							else if (rankLevel <= 16) method.price = 39;
							else if (rankLevel <= 24) method.price = 29;
							else method.price = 0;
							method.regularPrice = price;
						} else if (discountedPrice && rankLevel > 0 && product.price.price > 500 && (shippingMethodId == 3 || shippingMethodId == 26)) {
							if (rankLevel <= 8) method.price = 299;
							else if (rankLevel <= 16) method.price = 199;
							else if (rankLevel <= 24) method.price = 99;
							else method.price = 0;
							method.regularPrice = price;
						} else if (discountedPrice && shippingMethodId !== 2) {
							method.price = 0;
							method.regularPrice = price;
						} else {
							method.price = price;
							method.regularPrice = null;
						}

						if (product.shippingClass.id == 22 && shippingMethodId == 3) {
							method.price = 0;
						}
						result.push(method);
						break;
					}
				}
			}
			const priceList = result.sort((a, b) => {
				const diff = a.price - b.price;
				return diff === 0 ? (a.regularPrice || a.price) - (b.regularPrice || b.price) : diff;
			});
			// Remove hämta i butik and företagspaket when getting the lowest cost
			const methods = priceList.filter(method => method.id != 4 && method.id != 2);
			const lowestPrice = Math.min(...methods.map(item => item.price));
			commit('setLowestShippingPrice', lowestPrice);
			commit('setPriceList', priceList);
		},
	},
	mutations: {
		setLowestShippingPrice (state, price) {
			state.lowestShippingPrice = price;
		},
		setPriceList (state, priceList) {
			state.priceList = priceList;
		},
	},
});
