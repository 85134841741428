<template>
	<toggle-element class="dropdown" :tag="tag" :check-id="checkId" :label-class="['button-dropdown',labelClass]" :label-style="labelStyle" v-model="open" ref="dropdown" :keep="keep">
		<template slot="show"><slot name="show" /></template>
		<template slot="label"><slot name="label" /></template>
		<template><slot /></template>
	</toggle-element>
</template>

<script>
import bus from 'eventbus';

export default {
	data () {
		return {
			open: this.value,
		};
	},
	methods: {
		globalClickListener (ev) {
			if (ev.target && ev.target.id.indexOf('__lpform_input') === 0) {
				// Don't close dropdowns when clicking lastpass icons
				return;
			}

			// Check if a modal is open, in that case, dismiss
			if (document.getElementsByClassName('modal-root').length && this.dismissClickEvents) {
				return;
			}

			if (! this.$refs.dropdown.$el.contains(ev.target)) {
				this.open = false;
			}
		},
		onClose () {
			this.open = false;
		},
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		tag: {
			type: String,
		},
		checkId: {
			type: String,
			default () {
				return '_dropdown-' + this._uid;
			},
		},
		dismissClickEvents: {
			type: Boolean,
			default: true,
		},
		labelClass: [String, Object, Array],
		labelStyle: [String, Object, Array],
		keep: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		dropdownIcon () {
			return this.active ? 'glyphicon-triangle-top' : 'glyphicon-triangle-bottom';
		},
	},
	beforeDestroy () {
		bus.$off('dropdown.close', this.onClose);
		document.removeEventListener('click', this.globalClickListener, true);
	},
	mounted () {
		bus.$on('dropdown.close', this.onClose);
		if (this.open) {
			document.addEventListener('click', this.globalClickListener, true);
		}
	},
	watch: {
		open (to) {
			this.$emit('input', to);
			this.$emit('change', to);
			if (to) {
				document.addEventListener('click', this.globalClickListener, true);
			} else {
				document.removeEventListener('click', this.globalClickListener, true);
			}
		},
		value (to) {
			this.open = to;
		},
	},
};
</script>
