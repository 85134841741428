<template>
	<div :class="['site-usp', { blackweek: isBlackWeek }]">
		<div class="messages" :class="{ checkout: isCheckout }">
			<site-message v-if="! hideSiteMessage && isBlackWeek"  />
			<div class="banner-info">
				<a-link class="banner-text" :to="formatLink('info', 9, 'Leveransvillkor')">
					<transition-group name="fade" mode="out-in">
						<span v-if="textIndex === 0" key="logged-in-or-not-in-stock">{{ tu('STANDARD_NOT_IN_STOCK') }}</span>
						<span v-if="textIndex === 1" key="logged-in-productpage">
							<template v-if="productPrice < 500">{{ tu('PRODUCTPAGE_UNDER_500') }}</template>
							<template v-else>{{ tu('PRODUCTPAGE_OVER_500') }}</template>
							Leverans {{ earliestDelivery }}
						</span>
						<span v-if="textIndex === 2" key="not-logged-in">{{ tu('NOT_LOGGED_IN') }}</span>
						<span v-if="textIndex === 3" key="logged-in-large-product-in-stock">{{ tu('PRODUCTPAGE_LARGE_PRODUCT') }} Leverans {{ earliestDelivery }}</span>
						<span v-if="textIndex === 4" key="logged-in-large-product-not-in-stock">{{ tu('PRODUCTPAGE_LARGE_PRODUCT') }}</span>
						<span v-if="textIndex === 5" key="not-logged-in-large-product">{{ tu('PRODUCTPAGE_LARGE_PRODUCT_NOT_LOGGED_IN') }}</span>
					</transition-group>
				</a-link>
			</div>
			<site-message v-if="! hideSiteMessage && ! isBlackWeek" />
		</div>
		<div class="navigation">
			<div v-if="mediaQuery.current > mediaQuery.md && ! isCheckout">
				<a-link :to="formatLink('info', 1, 'Kundservice')">
					{{ t('CUSTOMER_SERVICE') }}
				</a-link>
				<a-link :to="formatLink('info', 5, 'Våra butiker')">
					{{ t('STORES') }}
				</a-link>
				<a-link :to="formatLink('campaign', 8827, 'Webhallen Företag')">
					{{ t('B2B') }}
				</a-link>
			</div>
			<portal-target
				name="site-message-navigation"
				slim
			/>
		</div>
	</div>
</template>

<script>
import { t } from 'datastore/i18n';
import mediaQuery from 'mediaQuery';
import { mapGetters, mapState } from 'vuex';
import bus from 'eventbus';
import { standardRoute } from 'utils';

export default {
	data () {
		return {
			mediaQuery,
			productStock: '',
			productPrice: '',
			isShippable: '',
			packageSize: '',
			currentProduct: {},
		};
	},
	computed: {
		...mapState({
			deliveryDates: state => state.user.deliveryDates,
			earliestDelivery:  state => state.user.earliestDeliveryDate,
			rankLevel:  state => state.user.rankLevel,
			lowestShippingPrice: state => state.shippingPrices.lowestShippingPrice,
		}),
		isCheckout () {
			return this.$route.name === 'checkout';
		},
		isProductPage () {
			return this.$route.name === 'product';
		},
		...mapGetters({
			cartSum: 'cart/sum',
			viewHistory: 'viewHistory/getHistory',
			userZipCode: 'user/hasZipCode',
		}),
		isBlackWeek () {
			const now = new Date();
			return now >= new Date('2024/11/15 00:00:00') && now <= new Date('2024/12/02 23:59:59');
		},
		textIndex () {
			let textIndex = 0;

			if (this.userZipCode && this.isProductPage) {
				textIndex = this.productStock && this.isShippable ? 1 : 0;
			}

			if (! this.userZipCode) {
				textIndex = 2;
			}

			if (this.lowestShippingPrice > 0 && this.userZipCode && this.isProductPage && this.packageSize > 5 && this.productStock) {
				textIndex = 3;
			}

			if (this.lowestShippingPrice > 0 && this.userZipCode && this.isProductPage && this.packageSize > 5 && ! this.productStock) {
				textIndex = 4;
			}

			if (this.lowestShippingPrice > 0 && ! this.userZipCode && this.isProductPage && this.packageSize > 5) {
				textIndex = 5;
			}

			return textIndex;
		},
		hideSiteMessage () {
			return this.isProductPage && this.currentProduct && this.currentProduct?.manufacturer?.id === 1872; // 1872 = apple
		},
	},
	watch: {
		$route (to, frm) {
			if (frm.name === 'product') {
				this.$store.commit('user/updateEarliestDeliveryDate', this.deliveryDates);
			}
		},
		currentProduct (to, frm) {
			if (to !== frm && this.isProductPage) {
				this.calculateShippingPrices(to);
			}
		},
		isProductPage (to) {
			// Reset all product data if not product page
			if (! to) {
				this.productStock = '';
				this.productPrice = '';
				this.isShippable = '';
				this.packageSize = '';
				this.currentProduct = {};
				this.calculateShippingPrices(null);
			}
		},
	},
	mounted () {
		bus.$on('current.product', this.product);
	},
	beforeDestroy () {
		bus.$off('current.product', this.product);
	},
	methods: {
		t,
		t: t.prefix('NAV'),
		tu: t.prefix('USP'),
		product (product) {
			this.productStock = product.stock.web > 0;
			this.productPrice = product.price.price;
			this.isShippable = product.isShippable;
			this.packageSize = product.packageSizeId;
			this.currentProduct = product;
		},
		formatLink (page, id, name ) {
			return standardRoute(page, { id, name });
		},
		calculateShippingPrices (product) {
			this.$store.dispatch('shippingPrices/getShippingMethods', { product, rankLevel: this.rankLevel });
		},
	},
};

</script>

