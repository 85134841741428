import waja from 'waja';
import mq, { isDesktop } from 'mediaQuery';
import store from './index';
import { filterIdenticalProducts, buildTopListQuery, inStockAnywhere } from 'utils';

function defaultState () {
	return {
		productTipsEngine: null,
		showTopLists: false,
		/**
		 * Loaded top lists.
		 */
		topLists: [],
		topListsLoaded: false,
		/**
		 * Top lists which are waiting to be loaded.
		 */
		selectedTopListId: 0,
		/**
		 * Id of the currently loaded/pending set of lists. Used to prevent the
		 * same top lists from loading again (when the user revisits a page).
		 */
		currentTopListId: null,
		/**
		 * Product lists.
		 */
		showLists: false,
		lists: [],
		currentListId: null,
		/**
		 * Bundle offers product list
		 */
		bundleOffers: {},
		/**
		 * Callback to call when product tips or top lists have been loaded.
		 */
		onLoad: null,
	};
}

const name = 'productTips:fetch';

store.registerModule('productTips', {
	namespaced: true,
	state: defaultState(),

	actions: {
		fetchTips ({ commit, state, dispatch }, product) {
			// @TODO ska vi kanske hämta alla i en enda request? högre prio på cachning då iom att de går i serie ist för parallellt.
			const listId = 'product:' + product.id;
			const fyndwareProduct = product.fyndwareOf ? product : false;

			if (state.currentListId === listId) {
				dispatch('onLoad');
				return;
			}
			// If the product is a fyndware, then fetch tips for the original product.
			if (fyndwareProduct) {
				product = product.fyndwareOf;
			}
			state.currentListId = listId;
			waja.abort(name);
			const data = {
				touchpoint: isDesktop() ? 'DESKTOP' : 'MOBILE'
			};

			if (product.discontinued && product.stock && ! inStockAnywhere(product.stock)) {
				waja.get('product-tips/' + product.id + '/discontinued-tips')
					.data(data)
					.on('success', res => {
						const lists = [{
							title: 'Liknande produkter',
							products: res.data.products,
							showRightCol: false,
						}];
						commit('setLists', lists);
						dispatch('onLoad');
					}).go();
			} else {
				// 4 in 1 - Andra har köpt, Liknande produkter, Tillbehör, Du tittade nyligen på
				const req1 = waja.get('product-tips/' + product.id)
					.data(data)
					.name(name);

				// Bundle offers
				const req2 = waja.get('product-tips/' + product.id + '/bundle-offers')
					.data(data)
					.name(name);

				waja.multiRequest([req1, req2], responses => {
					const suggestions = [];
					const res1 = responses[0];
					const res2 = responses[1];
					commit('setLists', []);

					if (res1.data.boughtTogether.length) {
						suggestions.push({
							title: 'Andra kunder har även köpt',
							products: res1.data.boughtTogether,
						});
					}
					if (res1.data.similarProducts.length) {
						suggestions.push({
							title: 'Liknande produkter',
							products: res1.data.similarProducts,
							showRightCol: true,
						});
					}
					if (res1.data.accessories.length) {
						suggestions.push({
							title: 'Tillbehör/Se även',
							products: res1.data.accessories,
							showRightCol: true,
						});
					}

					if (res1.data?.recentlyViewed?.length) {
						suggestions.push({
							title: 'Du tittade nyligen på',
							products: res1.data.recentlyViewed,
							showRightCol: true,
						});
					}

					if (res1.data.engine) {
						commit('setProductTipsEngine', res1.data.engine);
					}

					commit('setLists', suggestions);

					let bundleOfferData = {};

					if (res2.data.products.length) {
						const maxProducts = 2;
						const isBundle = res2.data.isBundle;
						const products = res2.data.products.slice(0, maxProducts);
						if (fyndwareProduct) {
							products.unshift(fyndwareProduct);
						} else {
							products.unshift(product);
						}

						bundleOfferData = {
							isBundle: isBundle,
							products: products,
						};
					}

					commit('setBundleOffers', bundleOfferData);
					dispatch('onLoad');
				});
			}
		},
		fetchTopLists ({ commit, state, dispatch, getters }, { section }) {
			const listId = getters.getTopListIdBySection(section);
			if (state.currentTopListId === listId) {
				dispatch('onLoad');
				return;
			}
			state.currentTopListId = listId;
			waja.abort(name);

			const data = {limit:12};

			commit('setTopLists', []);
			commit('setTopListsLoaded', false);

			if (section) {
				data.locationId = section.id;
				data.locationType = 'section';
			}
			waja.get('top-list')
				.data(data)
				.name(name)
				.on('success', res => {
					commit('setTopListsLoaded', true);
					commit('setTopLists', res.data.topLists);
					dispatch('onLoad');
				}).go();
		},
		/**
		 * Calls the assigned callback.
		 */
		onLoad ({ commit, state }) {
			if (state.onLoad) {
				state.onLoad();
				commit('onLoad', null);
			}
		},
	},
	mutations: {
		setProductTipsEngine(state, engine) {
			state.productTipsEngine = engine;
		},
		setSelectedTopList (state, index) {
			state.selectedTopListId = index;
		},
		setShowTopLists (state, show) {
			state.showTopLists = show;
		},
		setShowLists (state, show) {
			state.showLists = show;
		},
		setLists (state, lists) {
			state.lists = lists;
		},
		setTopLists (state, lists) {
			state.topLists = lists;
		},
		assignTopList (state, list) {
			for (let i = 0; i < state.topLists.length; i++) {
				if (state.topLists[i].id === list.id) {
					state.topLists.splice(i, 1, list);
					break;
				}
			}
		},
		setTopListsLoaded (state, loaded) {
			state.topListsLoaded = loaded;
		},
		setBundleOffers (state, list) {
			state.bundleOffers = list;
		},
		onLoad (state, onLoad) {
			state.onLoad = onLoad;
		},
	},

	getters: {
		showTopLists (state) {
			return state.showTopLists;
		},
		showLists (state) {
			if (mq.current <= mq.sm) {
				return false;
			}
			return state.showLists;
		},
		lists(state) {
			return state.lists;
		},
		rightColLists (state) {
			if (mq.current <= mq.sm) {
				return [];
			}
			return state.lists.slice(0, 2).filter(list => list.showRightCol);
		},
		overflowingLists (state) {
			if (mq.current <= mq.sm) {
				return state.lists;
			}
			return state.lists.slice(2);
		},
		topLists (state) {
			return state.topLists;
		},
		selectedTopList (state) {
			if (! state.topLists.length) {
				return null;
			}
			return state.topLists[state.selectedTopListId];
		},
		selectedTopListId (state) {
			return state.selectedTopListId;
		},
		topListsLoaded (state) {
			return state.topListsLoaded;
		},
		getTopListIdBySection (state) {
			return section => {
				let listId = 'section:';
				if (section) {
					listId += section.id;
				} else {
					listId += 'home';
				}

				return listId;
			};
		},
		isTopListLoaded (state, getters) {
			return function (section) {
				if (getters.getTopListIdBySection(section) === state.currentTopListId) {
					return true;
				}
				return false;
			};
		},
		bundleOffers (state) {
			return state.bundleOffers;
		},
	},
});
