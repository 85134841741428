<template>
	<modal
		v-model="isOpen"
		:rootClass="rootClass"
		:containerStyle="iframe ? iframe.containerStyle : null"
		:name="componentName"
		:hideCloseButton="hideCloseButton"
	>
		<component :is="componentName" v-if="componentName" v-bind="props" />
		<iframe v-else-if="iframe" :style="iframe.style" :src="iframe.src" />
		<div class="p-5" v-else>
			<div class="mb-5" v-if="htmlAllowed" v-html="text" />
			<div class="mb-5" v-else>{{ text }}</div>
			<div :class="['modal-buttons', buttonAlign]">
				<text-button
					v-for="(btn, index) in buttons"
					:key="'global-modal-btn-' + index"
					:text="btn.label"
					:variant="btn.type"
					@click="onClick(btn)"
				/>
			</div>
		</div>
	</modal>
</template>

<script>
import bus from 'eventbus';

export default {
	data () {
		return {
			isOpen: false,
			text: '',
			buttons: [],
			iframe: null,
			componentName: null,
			htmlAllowed: false,
			rootClass: null,
			buttonAlign: '',
			props: {},
			hideCloseButton: false,
		};
	},
	mounted () {
		bus.$on('modal.html', this.showHTML);
		bus.$on('modal.confirm', this.showConfirm);
		bus.$on('modal.component', this.showComponent);
		bus.$on('modal.iframe', this.showIframe);
		bus.$on('modal.alert', this.showAlert);
	},
	beforeDestroy () {
		bus.$off('modal.html', this.showHTML);
		bus.$off('modal.confirm', this.showConfirm);
		bus.$off('modal.component', this.showComponent);
		bus.$off('modal.iframe', this.showIframe);
		bus.$off('modal.alert', this.showAlert);
	},
	methods: {
		showAlert (text, onClose = null, htmlAllowed = true) {
			this.iframe = null;
			this.componentName = null;
			this.text = text;
			this.htmlAllowed = htmlAllowed;
			this.rootClass = null;
			this.buttonAlign = 'center';

			this.buttons = [{
				label: 'Ok',
				callback: onClose,
			}];
			this.isOpen = true;
		},
		showComponent (componentName, rootClass = null, props = {}) {
			this.isOpen = true;
			this.componentName = componentName;
			this.rootClass = rootClass;
			this.props = props;
		},
		showIframe (src, style, containerStyle) {
			this.componentName = null;
			this.iframe = {
				src,
				style,
				containerStyle,
			};
			this.isOpen = true;
			this.rootClass = null;
		},
		showConfirm (text, onYes = null, onNo = null, yesLabel = 'Ja', noLabel = 'Nej', htmlAllowed = false, options = {}) { // @TODO översätt och anv fraser.. väntar på replikering
			this.iframe = null;
			this.componentName = null;
			this.text = text;
			this.htmlAllowed = htmlAllowed;
			this.rootClass = null;
			this.buttonAlign = 'right';
			this.hideCloseButton = true;

			this.buttons = [
				{
					label: noLabel,
					callback: onNo,
					type: 'destructive',
				},
				{
					label: yesLabel,
					callback: onYes,
				},
			];
			this.isOpen = true;
		},
		showHTML (html, rootClass = null) {
			this.iframe = null;
			this.componentName = null;
			this.text = html;
			this.htmlAllowed = true;
			this.buttons = [];
			this.isOpen = true;
			this.rootClass = rootClass;
		},
		onClick (btn) {
			this.isOpen = false;
			if (btn.callback) {
				btn.callback();
			}
		},
	},
};
</script>
