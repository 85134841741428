<template>
	<div class="circle-root" :style="rootStyle">
		<div class="circle-content" :style="contentStyle">
			<slot></slot>
		</div>
		<slot name="over"></slot>
	</div>
</template>

<script>
export default {
	props: {
		diameter: {
			type: String,
			default: '110px',
		},
		/**
		 * Rotation in degrees.
		 */
		rotation: {
			type: Number,
			default: 3,
		},
		borderWidth: {
			type: Number,
			default: 3,
		},
	},
	computed: {
		contentStyle () {
			return {
				transform: 'rotate(' + this.rotation + 'deg)',
			};
		},
		rootStyle () {
			return {
				width: this.diameter,
				height: this.diameter,
				borderWidth: this.borderWidth + 'px',
			};
		},
	},
};
</script>