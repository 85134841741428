<template>
<div v-once>
	<script id="organization-markup" type="application/ld+json">
	{
		"@context": "http://schema.org",
		"@type": "Organization",
		"name": "Webhallen",
		"legalName": "Webhallen Sverige AB",
		"id": "{{ organizationId }}",
		"url": "{{ url }}",
		"logo": "{{ url }}/img/logos/wh_logo_blue.svg",
		"foundingDate": "1999",
		"contactPoint": {
			"@type": "ContactPoint",
			"telephone": "+468-673-60-00",
			"contactType": "customer service",
			"availableLanguage": ["Swedish", "English"]
		},
		"sameAs": [
			"https://www.facebook.com/webhallen/",
			"https://twitter.com/webhallen",
			"http://instagram.com/webhallen",
			"http://www.youtube.com/webhallenevent"
		],
		"employee": {
			"@type": "Person",
			"name": "Henri Blomqvist",
			"jobTitle": "CEO"
		}
	}
	</script>
	<script id="website-markup" type="application/ld+json">
	{
		"@context": "http://schema.org",
		"@type": "WebSite",
		"@id": "siteId",
		"name": "Webhallen",
		"url": "{{ url }}",
		"potentialAction": {
			"@type": "SearchAction",
			"target": "{{ searchUrl }}",
			"query-input": "required name=search_term"
		}
	}
	</script>
</div>
</template>

<script>
import router from 'router';

export default {
	computed: {
		organizationId () {
			return this.url + '#organization';
		},
		siteId () {
			return this.url + '#home';
		},
		url () {
			return router.getBaseUrl() + '/';
		},
		searchUrl () {
			return this.url + 'search?searchString={search_term}';
		},
	},
};
</script>
