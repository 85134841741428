<template>
	<router-link
		:to="path"
		@click.native="click"
	>
		<ximg
			class="banner cover"
			:src="src"
			:title="banner.description"
			:eager="topPanel"
			:lazy="! topPanel"
			:ariaLabel="analyticsData.name + ' banner'"
			role="img"
			:viewportMargin="1"
			@inViewport="trackImpression"
		/>
		<span
			v-if="topPanel && banner.button"
			class="banner-button"
			:style="bannerButtonStyle"
		>
			Se våra erbjudanden!
		</span>
		<slot></slot>
	</router-link>
</template>

<script>
import { routeFromHighlight, convertToCdnUrl } from 'utils';
import { trackPromotionImpression } from 'gtm';
import { mapGetters } from 'vuex';

export default {
	props: {
		banner: Object,
		// @TODO ta bort när vi ta bort right column och ändra till standard styling
		// dimensions: {
		// 	type: String,
		// 	default: '16:5',
		// },
		topPanel: {
			type: Boolean,
			default: false,
			required: false,
		},
		bannerWidth: {
			type: Number,
			default: 966,
		},
		listLink: {
			type: [Object, String],
			required: false,
		},
		analyticsData: {
			type: Object,
			default: () => null,
		},
	},
	computed: {
		path () {
			return this.listLink || (routeFromHighlight(this.banner) || '');
		},
		src () {
			let url = this.banner.imageUrl || this.banner.bannerImage.imageUrl;
			if (url.indexOf('?') === -1) {
				url += '?';
			} else {
				url += '&';
			}
			url += 'w=' + this.bannerWidth;

			return convertToCdnUrl(url);
		},
		bannerButtonStyle () {
			let buttonStyle = null;
			if (this.banner.button) {
				buttonStyle = {
					border: '1px solid ' + this.banner.button.textColor,
					color: this.banner.button.textColor,
					backgroundColor: this.banner.button.bgColor,
				};
			}
			return buttonStyle;
		},
		...mapGetters({
			isLoggedIn: 'user/isLoggedIn',
		}),
	},
	mounted () {
		if (this.topPanel) {
			this.banner.slot = 'Top';
			this.$emit('bannerLoaded',true);
		}

	},
	methods: {
		click () {
			const name = this.banner.campaign ? this.banner.campaign.name : this.banner.name;
			const id = (this.banner.campaign && this.banner.campaign.id) || this.banner.id;
			trackPromotionImpression(this.analyticsData, 'banner', true);
		},
		trackImpression () {
			if (this.analyticsData) {
				trackPromotionImpression(this.analyticsData, 'banner');
			}
		},
	},
};
</script>
