<template>
	<component :is="type" :highlight="highlight" :cover="cover" class="highlight col-md-4 col-xs-6 d-inline-block mb-4" @click.native="click" @inViewport="trackImpression" />
</template>

<script>
/* eslint-disable import/no-unresolved */
import campaignHighlight from './campaignHighlight';
import categoryHighlight from './categoryHighlight';
import productHighlight from './productHighlight';
import manufacturerHighlight from './manufacturerHighlight';
import basicHighlight from './basicHighlight';
/* eslint-enable import/no-unresolved */
import { trackPromotionImpression } from 'gtm';


export default {
	props: {
		highlight: {
			type: Object,
			required: true,
		},
		cover: {
			type: Boolean,
			default: true,
		},
		position: {
			type: Number,
			required: false,
			default: 1,
		},
		sectionIndex: {
			type: Number,
			required: false,
		},
	},
	data() {
	    return {
	    	currentRoute: null,
		};
	},
	mounted () {
		this.currentRoute = this.$route.name;
	},
	components: {
		campaignHighlight,
		categoryHighlight,
		productHighlight,
		manufacturerHighlight,
		basicHighlight,
	},
	computed: {
		type () {
			if (this.highlight.product) {
				return 'productHighlight';
			}
			if (this.highlight.campaign) {
				return 'campaignHighlight';
			}
			if (this.highlight.category) {
				return 'categoryHighlight';
			}
			if (this.highlight.manufacturer) {
				return 'manufacturerHighlight';
			}
			return 'basicHighlight';
		},
		analyticsData () {
			let data = {};
			switch (this.type) {
				case 'productHighlight':
					data = {
						id: this.highlight.product.id,
						name: this.highlight.product.name,
						type: 'product',
					}
					break;
				case 'campaignHighlight':
					data = {
						id: this.highlight.campaign.id,
						name: this.highlight.campaign.name,
						type: 'campaign',
					}
					break;
				case 'categoryHighlight':
					data = {
						id: this.highlight.category.id,
						name: this.highlight.category.name,
						type: 'category',
					}
					break;
				case 'manufacturerHighlight':
					data = {
						id: this.highlight.manufacturer.id,
						name: this.highlight.manufacturer.name,
						type: 'manufacturer',
					}
					break;
				default:
					data = {
						id: this.highlight.id,
						name: '',
						type: 'highlight',
					}
					break;
			}

			return {
				...data,
				location: this.currentRoute === 'index' ? 'startpage' : this.currentRoute,
				index: this.sectionIndex,
				position: this.position,
			};
		},
	},
	methods: {
		click () {
			trackPromotionImpression(this.analyticsData, 'tile', true);
		},
		trackImpression () {
			trackPromotionImpression(this.analyticsData, 'tile');
		}
	},
};
</script>
