import { isEqual, cloneDeep } from 'lodash';

export default {
	updateSearchTree ({ state, commit }, { categories, currentId }) {
		if (categories) {
			if (! categories?.level) {
				commit('setSearchTree', null);
			} else {
				const mappedTreeChildren = categories.children.map(category => {
					return {
						id: category.id,
						name: category.name,
						icon: category.icon,
						productCount: category.productCount,
						level: category.level,
					};
				});

				const tree = {
					current: {
						id: categories.id,
						level: categories.level,
						name: categories.name,
						parent: categories.parent,
						children: mappedTreeChildren,
					},
					tree: {
						id: categories.id,
						level: categories.level,
						name: categories.name,
						parent: categories.parent,
						children: mappedTreeChildren,
					},
				};
				commit('setSearchTree', tree);
			}
		} else {
			let current = null;
			if ((currentId === 0 && !state.searchTree) || !state.searchCategories) {
				commit('setSearchTree', null);
			} else {
				if (currentId === 0) {
					current = state.searchTree.tree;
				} else {
					const newCurrent = state.searchCategories.children.find((element) => {
						return element.id === currentId;
					});

					const mappedCurentChildren = newCurrent.children.map(child => {
						return {
							id: child.id,
							name: child.name,
							icon: child.icon,
							productCount: child.productCount,
							level: child.level,
						};
					});

					current = {
						id: newCurrent.id,
						icon: newCurrent.icon,
						level: newCurrent.level,
						name: newCurrent.name,
						parent: newCurrent.parent,
						children: mappedCurentChildren,
					}
				}

				const tree = {
					current: current,
				};

				if (state.searchTree?.tree) {
					tree.tree = state.searchTree.tree;
				} else {
					const mappedTreeChildren = state.searchCategories.children.map(category => {
						return {
							id: category.id,
							name: category.name,
							icon: category.icon,
							productCount: category.productCount,
							level: category.level,
						};
					});

					tree.tree = {
						id: state.searchCategories.id,
						level: state.searchCategories.level,
						name: state.searchCategories.name,
						parent: state.searchCategories.parent,
						children: mappedTreeChildren,
					}
				}

				commit('setSearchTree', tree);
			}
		}
	},
	parseUrlQuery ({ commit, dispatch }, { query }) {
		if (query.f) { // filters
			let filtersFromQuery = [];
			for (const f of Array.isArray(query.f) ? query.f : [query.f]) {
				const fil = f.split('^');
				let type = fil[0];
				let prioritized = null;

				// category-filter priority handling
				if (type.length > 1 && type[0] === '*') {
					type = type.substring(1);
					prioritized = true;
				}
				if (fil[0] === 'category-filter') {
					prioritized = false;
				}

				const val = fil[1];
				const castToInt = (type === 'stock' || type === 'manufacturer');

				const filter = {
					type,
					value: castToInt ? val | 0 : val,
				};

				if (prioritized !== null) {
					filter.prioritized = prioritized;
				}

				filtersFromQuery.push(filter);
			}
			commit('setEnabledFilters', filtersFromQuery);
		}
		dispatch('updateCurrentQuery', { query: query });
	},
	updateCurrentQuery ({ state, commit }, { query }) {
		const filters = [];
		const lastQuery = cloneDeep(state.currentQuery);

		for (let f of state.enabledFilters) {
			filters.push(f);
		}
		const newQuery = {
			initialFilters: state.initialFilters,
			filters,
		};

		if (state.sortingOption || query?.sortBy) {
			newQuery.sortBy = state.sortingOption || query.sortBy;
		}

		if (state.initialFilters[0]?.type === 'search' && query?.autoCorrect) {
			newQuery.autoCorrect = query.autoCorrect;
		}

		if (isEqual(newQuery, lastQuery)) {
			return;
		}

		commit('setCurrentQuery', newQuery);
	},
	// @TODO Ska kunna baseras på inställningar från admin
	updateAttributeShow ({ state, commit }, { settings }) {
		let show = {};
		if (state.attributes?.length) {
			for (let i = 0; i < state.attributes.length; i++) {
				show[state.attributes[i].id] = i < 7;
			}
		}
		commit('setAttributeShow', show);
	},
	resetWholeState({ commit }) {
		commit('setCurrentQuery', null);
		commit('setAttributes', null);
		commit('setAttributeShow', {});
		commit('setEnabledFilters', []);
		commit('setSortingOption', null);
		commit('setSearchTree', null);
		commit('setSearchCategories', null);
		commit('setInitialFilters', []);
		commit('setTotalProductCount', null);
		commit('setFilteredProductCount', null);
	},
};