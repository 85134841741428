<template>
	<div
		v-if="lockedLoading"
		class="locked-loading"
	>
		<img
			:src="convertToCdnUrl('/img/loading_dark.svg')"
			class="loading-spinner"
			alt="Loading-spinner"
		/>
	</div>
	<img
		v-else
		:src="convertToCdnUrl('/img/loading_light.svg')"
		class="loading-spinner"
		alt="Loading-spinner"
	/>
</template>

<script>
import { convertToCdnUrl } from 'utils';

export default {
	props: {
		lockedLoading: {
			type: Boolean,
			default: false,
		},
	},
	mounted () {
		if (this.lockedLoading) {
			document.addEventListener('click', this.preventUserInteraction, true);
			document.addEventListener('keydown', this.preventUserInteraction, true);
		}
	},
	beforeDestroy() {
		if (this.lockedLoading) {
			document.removeEventListener('click', this.preventUserInteraction);
			document.removeEventListener('keydown', this.preventUserInteraction);
		}
	},
	methods: {
		convertToCdnUrl,
		preventUserInteraction (e) {
			e.stopPropagation();
			e.preventDefault();
		},
	},
};
</script>
