/*
	Maps with hard coded links for categories, sections or sub sections, that
	are displayed in nav menus.
 */

const sectionLinks = {
	'1': [
		{
			id: 3600,
			name: 'Brädspel & Spelkort',
			icon: 'bradspel_spelkort',
			subCategories: [],
			to: {
				name: 'category',
				params: {
					id: 3600,
					name: 'Bradspel-Spelkort',
				},
			},
		},
	],
};

const subSectionLinks = {};

const categoryLinks = {};

export {
	sectionLinks,
	subSectionLinks,
	categoryLinks,
};

