import store from './index';

function defaultState () {
	const necessary = window.localStorage['necessaryConsent'] == undefined ? false : true;
	const marketing = window.localStorage['marketingConsent'] == undefined ? false : JSON.parse(window.localStorage['marketingConsent']);
	const personalisation = window.localStorage['personalisationConsent'] == undefined ? false :JSON.parse(window.localStorage['personalisationConsent']);

	return {
		necessary,
		marketing,
		personalisation,
	};
}

store.registerModule('cookieConsent', {
	namespaced: true,
	state: defaultState(),
	actions: {
		hoverComment ({ commit, state }, comment) {
		},
		deHoverComment ({ commit, state }, comment) {
		},
	},
	mutations: {
		updateNecessary (state, consent) {
			state.necessary = consent;
			window.localStorage['necessaryConsent'] = consent;
		},
		updateMarketing (state, consent) {
			state.marketing = consent;
			window.localStorage['marketingConsent'] = consent;
		},
		updatePersonalisation (state, consent) {
			state.personalisation = consent;
			window.localStorage['personalisationConsent'] = consent;
		},
	},
	getters: {
		necessary (state) {
			return state.necessary;
		},
		marketing (state) {
			return state.marketing;
		},
		personalisation (state) {
			return state.personalisation;
		},
	},
});
