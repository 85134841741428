<template>
	<button
		:class="[buttonProperties.class, buttonClasses]"
		:aria-label="buttonProperties.ariaLabel"
		type="button"
		@click="handleClick"
	>
		<img
			:src="buttonProperties.iconUrl"
			:alt="buttonProperties.ariaLabel"
			class="icon"
		>
	</button>
</template>

<script>
import { convertToCdnUrl } from 'utils';

export default {
	props: {
		/* Button variants:
			add
			subtract
			more
			next
			previous
			remove
			delete
			close
			left
			right
			check
		*/
		variant: {
			type: String,
			required: true,
		},
		/* Button sizes:
			large
			normal
			small
			tiny
		*/
		size: {
			type: String,
			default: 'normal',
			required: false,
		},
		cornerPlacement: {
			type: Boolean,
			default: false,
			required: false,
		},
		loading: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	data () {
		return {
			convertToCdnUrl,
		};
	},
	computed: {
		buttonProperties () {
			const types = {
				add: {
					ariaLabel: 'Lägg till en',
					class: 'btn-add-one',
					iconUrl: this.formatIconUrl('plus'),
				},
				subtract: {
					ariaLabel: 'Ta bort en',
					class: 'btn-remove-one',
					iconUrl: this.formatIconUrl('minus'),
				},
				more: {
					ariaLabel: 'Visa mer',
					class: 'btn-more',
					iconUrl: this.formatIconUrl('ellipsis'),
				},
				next: {
					ariaLabel: 'Nästa',
					class: 'btn-next',
					iconUrl: this.formatIconUrl('chevron_right'),
				},
				previous: {
					ariaLabel: 'Backa',
					class: 'btn-prev',
					iconUrl: this.formatIconUrl('chevron_left'),
				},
				remove: {
					ariaLabel: 'Ta bort',
					class: 'btn-remove-item',
					iconUrl: this.formatIconUrl('esc'),
				},
				delete: {
					ariaLabel: 'Släng',
					class: 'btn-delete-item',
					iconUrl: this.formatIconUrl('delete'),
				},
				close: {
					ariaLabel: 'Stäng ner',
					class: 'btn-close',
					iconUrl: this.formatIconUrl('esc'),
				},
				left: {
					ariaLabel: 'Scrolla åt vänster',
					class: 'btn-scroll-left',
					iconUrl: this.formatIconUrl('chevron_left'),
				},
				right: {
					ariaLabel: 'Scrolla åt höger',
					class: 'btn-scroll-right',
					iconUrl: this.formatIconUrl('chevron_right'),
				},
				check: {
					ariaLabel: 'Bekräfta',
					class: 'btn-check',
					iconUrl: this.formatIconUrl('check_mark'),
				},
			};

			return types[this.variant] || null;
		},
		buttonClasses () {
			return [
				{
					'_corner': this.cornerPlacement,
					'loading': this.loading,
				},
				this.size !== 'normal' ? '_' + this.size : null,
			];
		},
		iconColor () {
			if (this.variant === 'close' || this.variant === 'delete' || this.variant === 'next' || this.variant === 'previous' || this.variant === 'check') {
				return 'FFFFFF';
			} else {
				return '1A1A1D';
			}
		},
	},
	methods: {
		formatIconUrl (icon) {
			return convertToCdnUrl('/api/dynimg/icon/' + icon + '/' + this.iconColor);
		},
		handleClick () {
			this.$emit('click');
		},
	},
};
</script>
