import store from './index';
import waja from 'waja';

function defaultState () {
	return {
		viewHistory: [],
	};
}

store.registerModule('viewHistory', {
	namespaced: true,
	state: defaultState(),
	actions: {
		loadHistory ({ commit }) {
			waja.get('product/recent')
				.on('success', res => {
					commit('setHistory', res.data.products);
				}).go();
		},
	},
	getters: {
		getHistory (state) {
			return state.viewHistory;
		},
	},
	mutations: {
		addProduct (state, product, pass) {
			let newProduct = {
				...product,
				resursPartPaymentPrice: null,
			};

			newProduct = JSON.parse(JSON.stringify(newProduct));
			newProduct.pass = pass;
			for (let i = 0; i < state.viewHistory.length; ++i) {
				const histProduct = state.viewHistory[i];

				if (histProduct.id === newProduct.id) {
					state.viewHistory.splice(i, 1);
					state.viewHistory.unshift(newProduct);
					return;
				}
			}

			state.viewHistory.unshift(newProduct);
			if (state.viewHistory.length > 5) {
				state.viewHistory = state.viewHistory.slice(0, 5);
			}
		},
		removeProduct (state, product) {
			// Remove the product from the hisotry cookie.
			waja.post('product/' + product.id + '/remove-from-history')
				.on('success', () => {
					// Remove the product from the history array.
					for (let i = 0; i < state.viewHistory.length; ++i) {
						const histProduct = state.viewHistory[i];
						if (histProduct.id === product.id) {
							state.viewHistory.splice(i, 1);
						}
					}
				}).go();
		},
		setHistory (state, history) {
			state.viewHistory = history;
		},
	},
});
