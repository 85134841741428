import waja from 'waja';
import { detect } from 'detect-browser';

const ignores = [
	/SecurityError (DOM Exception 18):(.*)"facebook.com"/,
];

window.onerror = function (message, file, line, col, error) {
	require.ensure(['sourcemapped-stacktrace'], () => {
		const smst = require('sourcemapped-stacktrace');
		const browser = detect();
		for (const pattern of ignores) {
			if (message.match(pattern)) {
				return;
			}
		}
		smst.mapStackTrace(error.stack, function (mappedStack) {
			const stack = window.location.href + '\n' + mappedStack.join('\n');
			waja.post('client-error')
				.data({
					stack,
					message,
					browser,
				})
				.go();
		});
	}, 'debug');
};
