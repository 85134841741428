<template>
	<span :style="[style, ellipsisStyle]" class="fixed-lines" :class="{ 'fixed-lines-ellipsis': useEllipsis }" ref="text">
		<slot />
	</span>
</template>

<script>
const regex = /^(\d+.?\d*|\d*.?\d+)([^\d]*)$/;

export default {
	props: {
		lineHeight: {
			type: String,
			default: '1.4em',
		},
		/**
		 * This value is subtracted from the final height.
		 * If the text from a line that should not be shown bleeds through and
		 * becomes visible, this value can be used to decrease the final height
		 * a bit.
		 */
		bleedHeight: {
			type: Number,
			default: 0,
		},
		lines: {
			type: Number,
			required: true,
		},
		useEllipsis: {
			type: Boolean,
			default: true,
		},
		useHeight: {
			type: Boolean,
			default: true,
		}
	},
	mounted () {
		this.$forceUpdate();
	},
	computed: {
		overflowing: {
			cache: false,
			get () {
				const el = this.$refs.text;
				if (! el) return false;
				return el.scrollHeight !== el.clientHeight;
			},
		},
		style () {
			let heightObj = {
				lineHeight: this.height + this.unit,
			}
			if (this.useHeight) {
				heightObj.height = (this.height * this.lines) - this.bleedHeight + this.unit;
			}
			return heightObj;
		},
		ellipsisStyle () {
			if (this.useEllipsis) {
				return {
					display: '-webkit-box',
					'-webkit-line-clamp': this.lines, // Only works for webkit based browsers.
				};
			}
			return {};
		},
		matches () {
			return this.lineHeight.match(regex);
		},
		height () {
			if (this.matches.length === 3) {
				return this.matches[1] * 1;
			}
			return 0;
		},
		unit () {
			if (this.matches.length === 3) {
				return this.matches[2] || 'em';
			}
			return 'em';
		},
	},
};
</script>
