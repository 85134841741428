<template>
	<a v-if="openInAdmin" :href="adminHref" :title="product.mainTitle" :target="adminTarget">
		<slot />
	</a>
	<router-link v-else :to="route" :title="product.mainTitle">
		<slot />
	</router-link>
</template>

<script>
import { getProductPath } from 'utils';
import store from 'datastore';

export default {
	props: {
		product: {
			type: Object,
			required: true,
		},
		pass: {
			type: String,
			default: null,
		},
		hidePass: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		openInAdmin () {
			const user = store.state.user;
			return user && user.isEmployee && user.settings && user.settings.open_products_in_admin;
		},
		adminTarget () {
			const user = store.state.user;
			if (user && user.isEmployee && user.settings && user.settings.open_classic_admin_in_new_tab) {
				return '_blank';
			}
			return '_self';
		},
		adminHref () {
			return 'https://classic.webhallen.com/admin/admin_page.php?id=' + this.product.id;
		},
		route () {
			return getProductPath(this.product, this.pass, this.hidePass);
		},
	},
};
</script>
