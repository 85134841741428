import { parseJson } from './utils';
import { newRequest, onceClean, abort, addRequestInterceptor, addResponseInterceptor } from './request';
import { multiRequest } from './multiRequest';

const api = {
	request (method, url, headers, data, json = true, files = null) {
		const request = newRequest();
		if (method) request.method(method);
		if (url) request.url(url);
		if (headers) request.header(headers);
		if (data) request.data(data);
		if (json) request.on('success', parseJson);
		if (files) request.files(files);
		request.constructor = api.request;
		return request;
	},
	options (url, headers, data) {
		return api.request('OPTIONS', url, headers, data);
	},
	get (url, headers, data) {
		return api.request('GET', url, headers, data);
	},
	head (url, headers, data) {
		return api.request('HEAD', url, headers, data);
	},
	post (url, headers, data) {
		return api.request('POST', url, headers, data);
	},
	put (url, headers, data) {
		return api.request('PUT', url, headers, data);
	},
	patch (url, headers, data) {
		return api.request('PATCH', url, headers, data);
	},
	delete (url, headers, data) {
		return api.request('DELETE', url, headers, data);
	},
	trace (url, headers, data) {
		return api.request('TRACE', url, headers, data);
	},
	abort,
	onceClean,
	multiRequest,
	addRequestInterceptor,
	addResponseInterceptor,
};

window.waja = api;

if (process.env.NODE_ENV === 'development') {
	waja.testPerformance = function(url, tries = 10) {
		const results = [];
		function test () {
			const start = Date.now();
			waja.get(url)
				.on('success', res => {
					const end = Date.now();
					results.push(end - start);
					if (results.length < tries) {
						test();
					} else {
						console.log('Testing complete!');
						console.log(results);
						let total = 0;
						for (const time of results) {
							total += time;
						}
						const average = total / results.length;
						console.log('Average response time: ' + average + 'ms');
					}
				}).go();
		}
		test();
	}
}

export default api;
