<template>
	<div class="text-center">
		<loading-spinner v-if="loading" />
		<toggle-button
			v-else-if="! hasDefaultSlot"
			:text="computedBtnText"
			:value="computedBtnText"
			size="large"
			icon="chevron_down"
			iconRightPlacement
			stretch
			@click="trigger"
		/>
		<slot v-else></slot>
	</div>
</template>

<script>
import { t } from 'datastore/i18n';
import inViewport from 'vue-mixins/in-viewport';

export default {
	mixins: [inViewport],
	props: {
		loading: { default: false },
		trigger: {
			type: Function,
			required: true,
		},
		viewportMargin: {
			type: Number,
			default: 200,
		},
		disableAutoScroll: {
			type: Boolean,
			default: false,
		},
		btnText: {
			type: String,
			default: '',
		},
	},
	methods: {
		t,
		tryTrigger () {
			if (this.inViewport && ! this.loading && ! this.disableAutoScroll) {
				this.trigger();
			}
		},
	},
	computed: {
		hasDefaultSlot () {
			return !! this.$slots.default;
		},
		computedBtnText() {
			if (this.btnText) {
				return this.btnText;
			}
			return this.t('LOAD_MORE');
		},
	},
	mounted () {
		this.inViewportMargin = this.viewportMargin;
	},
	watch: {
		inViewport () {
			this.tryTrigger();
		},
		loading () {
			this.recalculateInViewport();
			this.tryTrigger();
		},
		disableAutoScroll () {
			this.tryTrigger();
		},
	},
};
</script>
