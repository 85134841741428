<template>
	<div class="text-center px-5 pb-5">
		<h2>Starta din BankID-app</h2>
		<p v-if="isWhPaymentPopup">För att komma åt din delbetalningsinformation behöver du legitimera dig med BankID.</p>
		<loading-spinner class="mb-4" />
		<a href="bankid:///?" target="_blank" class="text-btn _center mb-2">Öppna BankID på den här enheten</a><br>
		<i style="color:#333">Denna dialog kommer att stängas automatiskt efter slutförd signering</i>
	</div>
</template>

<script>
import waja from 'waja';
import alert from 'alert';
import bus from 'eventbus';

export default {
	data () {
		return {
			reference: null,
			intervalId: null,
			isConfirmed: false,
			isUserCancel: false,
			isWhPaymentPopup: false,
		};
	},
	mounted () {
		bus.$on('bankid.updateReference', this.updateReference);
	},
	beforeDestroy () {
		bus.$off('bankid.updateReference', this.updateReference);
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}
		if (! this.isConfirmed) {
			if (! this.isUserCancel) {
				this.cancelSign();
			}
			bus.$emit('bankid.closed');
		}
	},
	methods: {
		updateReference (ref, isWhPayment = false) {
			this.reference = ref;
			this.isWhPaymentPopup = isWhPayment;
			if (! this.intervalId) {
				if (isWhPayment) {
					this.intervalId = setInterval(this.pollBankIdAuth.bind(this), 2000);
				} else {
					this.intervalId = setInterval(this.ping.bind(this), 2000);
				}
			}
		},
		ping () {
			if (this.reference) {
				waja.get('bankid/' + this.reference)
					.on('success', res => {
						if (res.data.status === 'complete') {
							this.isConfirmed = true;
							bus.$emit('bankid.confirmed');
							bus.$emit('modal.close');
						} else if (res.data.status === 'failed' || res.data.status.status === 'failed') {
							if (res.data.status.hintCode === 'userCancel') {
								this.isUserCancel = true;
							}
							bus.$emit('modal.close');
						} else if (res.data.status === '') {
							alert('Tiden för signering har gått ut! Vänligen försök igen.');
							bus.$emit('modal.close');
						} else if (res.data.status.errorCode) {
							bus.$emit('modal.close');
						}
					})
					.on('error', res => {
						if (res.status === 401) {
							alert('Du måste vara inloggad för att använda BankID');
						}
						bus.$emit('modal.close');
					}).go();
			}
		},
		cancelSign () {
			waja.post('bankid/cancel/' + this.reference)
				.on('success', res => {
				}).go();
		},
		pollBankIdAuth () {
			if (this.reference) {
				waja.get('bankid/poll/' + this.reference)
					.on('success', res => {
						if (res.data.status.status === 'complete') {
							this.isConfirmed = true;
							bus.$emit('bankid.confirmed', res.data.status);
							bus.$emit('modal.close');
						} else if (res.data.status.status === 'failed') {
							if (res.data.status.hintCode === 'userCancel') {
								this.isUserCancel = true;
							}
							bus.$emit('modal.close');
						}
					}).on('error', res => {
						bus.$emit('modal.close');
					}).go();
			}
		},
	},
};
</script>
