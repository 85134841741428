<template>
	<div class="progress" :class="{ 'rank-legend': grej }">
		<div class="progress-text">
			<span>{{ resolvedText }}</span>
		</div>
		<div class="progress-bar progress-space" :style="{ width: spaceBar * 100 + '%', overflow: 'hidden' }">
			<div class="progress-text">
				<span>{{ resolvedText }}</span>
			</div>
		</div>

		<div
			:class="['progress-bar', {'_full': clampedValue === 1}]"
			:style="{ width: clampedValue * 100 + '%', overflow: 'hidden', 'background-color': barColor, 'background': gradientProgressBar}">
			<div class="progress-text">
				<span>{{ resolvedText }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import { clamp } from 'lodash';

export default {
	props: {
		text: {
			type: [String, Function],
			default: () => (p) => Math.round(p * 100) + '%',
		},
		value: {
			type: Number,
			default: 0,
		},
		spaceVal: {
			type: Number,
			default: 0,
		},
		barColor: {
		},
		gradientColors: {
			type: Array,
			default: null,
		},
		grej: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		resolvedText () {
			const text = this.text;
			return text instanceof Function ? text(this.value) : text;
		},
		clampedValue () {
			return clamp(isNaN(this.value) ? 1 : this.value, 0, 1);
		},
		spaceBar () {
			return clamp(isNaN(this.spaceVal) ? 1 : this.spaceVal, 0, 1);
		},
		gradientProgressBar () {
			if (this.gradientColors) {
				return 'linear-gradient(90deg, ' + this.gradientColors.join(', ') + ' ' + (2 - this.clampedValue) * 100 + '%)';
			} else {
				return null;
			}
		},
	},
};
</script>
