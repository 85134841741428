<template>
	<div v-if="componentData.length">
		<template v-for="(component, index) in componentData">
			<component
				v-if="component && component.show"
				class="section"
				v-bind="{ 'sectionIndex': index + addBannerIndex, ...component}"
				:is="component.componentName"
				:key="component.componentId"
				@hide="hideComponent(component,index)"
			/>
			<div
				v-if="component.componentType === 6"
				:key="'highlight-section' + component.componentId"
				class="highlight-section section"
			>
				<a-link :to="{ name:'campaign', params: { id: 7619, name: 'Streamhjalpen' }}" buttonStyle="secondary">
					Till insamlingen och läs mer!
				</a-link>
			</div>
			<hr v-if="component && component.show && componentData.length > index + 1" :key="'divider_' + component.componentId">
		</template>
	</div>
</template>

<script>
export default {
	props: {
		components: {
			type: Array,
			required: true,
		},
		toplists: {
			type: Array,
			required: false,
			default: () => [],
		},
		popularCategories: {
			type: Array,
			required: false,
			default: () => [],
		},
		extraData: {
			type: Object,
			required: false,
			default: null,
		},
		addBannerIndex: {
			type: Number,
			default: 0,
		},
	},
	created () {
		this.init();
	},
	data() {
		return {
			componentData: null,
			clonedToplists: [],
			clonedToplistsWithBanner: [],
			clonedPopularCategories: this.popularCategories ? [...this.popularCategories] : [],
		};
	},
	methods: {
		init() {
			this.clonedToplists = this.toplists.filter(t => { return t.hasBanner === false; });
			this.clonedToplistsWithBanner = this.toplists.filter(t => { return t.hasBanner === true; });
			if (this.popularCategories.length > 0) {
				this.clonedPopularCategories = [...this.popularCategories];
			}

			this.componentData = this.components.reduce((accumulator, c) => {
				const componentData = this.getComponentData(c);
				if (componentData.show) {
					accumulator.push(componentData);
				}
				return accumulator;
			}, []);
		},
		getComponentData(component) {
			const data = {
				show: false,
				componentName: component.component.componentName,
				componentId: component.id,
				componentType: component.component.id,
			};

			switch (component.component.id) {
				case 1:
					if (this.clonedToplists.length > 0) {
						data.show = true;
						data.id = this.clonedToplists[0].id;
						data.reviews = this.clonedToplists[0].showReviews;
						data.showBanner = false;
						this.clonedToplists.splice(0,1);
					}
					break;
				case 2:
					if (this.clonedPopularCategories.length > 0) {
						data.show = true;
						data.popularCategories = this.clonedPopularCategories[0];
						this.clonedPopularCategories.splice(0,1);
					}
					break;
				case 3:
					data.show = true;
					data.tiles = true;
					break;
				case 4:
					data.show = true;
					data.highlightSection = this.extraData.highlightSection;
					data.showButton = true;
					data.verticalPagination = true;
					data.setCurrentPage = 2;
					data.showMoreButton = false;
					break;
				case 5:
					if (this.clonedToplistsWithBanner.length > 0) {
						data.show = true;
						data.id = this.clonedToplistsWithBanner[0].id;
						data.reviews = this.clonedToplistsWithBanner[0].showReviews;
						data.showBanner = true;
						this.clonedToplistsWithBanner.splice(0,1);
					}
					break;
				case 6:
					data.show = true;
					break;
				default:
					break;
			}
			return data;
		},
		hideComponent(component, index) {
			this.componentData[index].show = false;
		},
	},
	watch: {
		components: {
			handler () {
				this.init();
			},
			deep: true,
		},
	},
};
</script>
