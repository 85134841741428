<template>
	<li @click="click">
		<a-link :to="categoryRoute">
			<div class="popular-categories-img">
				<ximg
					:src="src"
					:ariaLabel="categoryName"
					role="img"
					:viewportMargin="1"
					@inViewport="trackImpression"
				/>
			</div>
			<span class="popular-categories-title">
				{{ categoryName }}
			</span>
		</a-link>
	</li>
</template>

<script>
import redirects from 'routerRedirects';
import { convertToCdnUrl, standardRoute } from 'utils';
import { trackPromotionImpression } from 'gtm';

export default {
	props: {
		category: {
			type: Object,
			required: true,
		},
		product: {
			type: Object,
			required: true,
		},
		sectionIndex: {
			type: Number,
			required: false,
		},
		position: {
			type: Number,
			required: false,
		},
	},
	data () {
		return {
			analyticsData: {},
		};
	},
	mounted () {
		this.analyticsData = {
			id: this.category.id,
			name: this.category.name,
			type: this.categoryRoute.name,
			location: this.$route.name === 'index' ? 'startpage' : this.$route.name,
			index: this.sectionIndex,
			position: this.position,
		};
	},
	computed: {
		src () {
			return convertToCdnUrl('/images/product/' + this.product.id + '?trim&w=231');
		},
		categoryName() {
			if (this.category.seoName) {
				return this.category.seoName;
			}
			return this.category.name;
		},
		categoryRoute () {
			const route = standardRoute('category', this.category);

			const redirect = redirects.find(r => {
				const match = r.from;
				return match.name == route.name && match.params.id == route.params.id;
			});

			if (redirect) {
				return redirect.to;
			}

			return route;
		},
	},
	methods: {
		click () {
			trackPromotionImpression(this.analyticsData, 'tile', true);
		},
		trackImpression () {
			trackPromotionImpression(this.analyticsData, 'tile');
		},
	},
};
</script>
