import store from './index';
const namespace = 'synced-storage:';

function defaultState () {
	return {
		phraseFinderMode: null,
		ximgCacheBustMap: null,
		closedSiteMessages: {},
		seenSiteMessagesCount: {},
	};
}
store.registerModule('syncedStorage', {
	namespaced: true,
	state: defaultState(),

	mutations: {
		clear (state, key) {
			if (key === undefined) {
				for (const key in state) {
					state[key] = null;
					window.localStorage[namespace + key] = null;
				}
			}
			state[key] = null;
			window.localStorage[namespace + key] = null;
		},
		set (state, { key, value }) {
			state[key] = value;
			window.localStorage[namespace + key] = JSON.stringify({ value });
		},
		setNoSync (state, { key, value }) {
			state[key] = value;
		},
	},

	actions: {
		update ({ commit }) {
			const keys = Object.keys(window.localStorage);
			for (let i = 0; i < keys.length; i++) {
				if (keys[i].indexOf(namespace) === 0) {
					const internalKey = keys[i].substr(namespace.length);
					const entry = JSON.parse(window.localStorage[keys[i]]);
					if (entry instanceof Object && entry.value !== undefined) {
						commit('setNoSync', { key: internalKey, value: entry.value });
					} else {
						console.error('synced-storage read fail. key: ' + internalKey + ', entry: ', entry);
					}
				}
			};
		},
	},
});

window.addEventListener('storage', (ev) => {
	if (ev.storageArea !== window.localStorage) {
		return;
	}
	if (ev.key === null) {
		store.commit('syncedStorage/clear');
	} else if (ev.key.indexOf(namespace) === 0) {
		const key = ev.key.substr(namespace.length);
		store.commit('syncedStorage/setNoSync', { key, value: ev.newValue ? JSON.parse(ev.newValue).value : null });
	}
});

store.dispatch('syncedStorage/update');
