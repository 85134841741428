<!--
	The highlight section displays a grid of highlights.
	Highlights can be paginated both in a scrolling manner and in a vertical manner.
-->
<template>
	<div v-if="highlightSection && highlightSection.highlights.length" class="highlight-section">
		<header v-if="showHeading"
			:style="[headerStyling ? '' : { 'border-bottom-color': highlightSection.color }]">
			<h2>
				<router-link v-if="sectionPath && ! showButton" class="d-inline-block" :to="sectionPath">
					{{ highlightSection.name }}
				</router-link>
				<router-link v-else-if="sectionPath && ! headerStyling" class="d-inline-block" :to="sectionPath">
					<img :src="icon" v-if="icon" alt="highlight icon" />
					{{ highlightSection.name }}
				</router-link>
				<template v-else>
					{{ highlightSection.name }}
				</template>
			</h2>
			<div
				v-if="paginate && ! useVerticalPagination && pageCount > 1"
				class="highlight-nav"
			>
				<div
					v-for="i in pageCount"
					:key="'highlight_pageCount_' + i"
					:class="['hl-nav-box d-inline-block r-2 mx-3', { active: currentPage === i }]"
					@click="currentPage = i"
				/>
			</div>
		</header>
		<v-touch
			v-if="paginate && ! useVerticalPagination"
			class="highlight-container"
			@swipeleft="paginate && currentPage++"
			@swiperight="paginate && currentPage--"
		>
			<div class="highlight-scroller" :style="scrollerStyle">
				<div
					v-for="i in pageCount"
					:key="'highlight_pagecount_'+i"
					class="d-inline-block"
					style="vertical-align: top; width: 100%"
				>
					<highlight
						v-for="(highlight, index) in pages[i]"
						:key="index"
						:highlight="highlight"
						ref="highlights"
						:cover="cover"
						:position="index + ((i - 1) * computedHighlightsPerPage)"
						:sectionIndex="sectionIndex"
					/>
				</div>
			</div>
		</v-touch>
		<div v-else-if="paginate && useVerticalPagination" class="highlight-container">
			<div v-for="i in computedNumberOfPages" :key="'vertical-highlight-pagecount-' + i">
				<highlight
					v-for="(highlight, index) in pages[i]"
					:key="index"
					:highlight="highlight"
					ref="highlights"
					:cover="cover"
					:position="index + ((i - 1) * computedHighlightsPerPage)"
					:sectionIndex="sectionIndex"
				/>
			</div>
			<div class="clearfix" />
			<center ref="moreLessButtons" class="mb-5" v-if="showMoreButton && pageCount > 1">
				<text-button
					v-if="currentPage === 1 || currentPage === pageCount"
					:text="showMoreText"
					variant="secondary"
					@click="toggleVisibleHighlights"
				/>
				<span v-else>
					Visa <a @click="currentPage++">fler</a> /
					<a @click="currentPage = 1">färre</a> kampanjer
				</span>
			</center>
		</div>
		<div v-else class="highlight-container">
			<highlight
				v-for="(highlight, index) in highlightSection.highlights"
				:key="index"
				:highlight="highlight"
				ref="highlights"
				:cover="cover"
				:position="index"
				:sectionIndex="sectionIndex"
			/>
		</div>
		<a-link
			v-if="showButton && sectionPath"
			:to="sectionPath"
			buttonStyle="secondary"
			center
		>
			Visa alla kampanjer
		</a-link>
	</div>
</template>

<script>
import { convertToCdnUrl, fixElementToBottomOfPage, standardRoute } from 'utils';
import mediaQuery from 'mediaQuery';
import { mapGetters } from 'vuex';

export default {
	props: {
		/**
		 * The highlight section is an object containing the name of the section,
		 * and a list of highlights.
		 */
		highlightSection: {
			type: Object,
			required: true,
		},
		highlightsPerPage: {
			type: Number,
			default: null,
		},
		paginate: {
			type: Boolean,
			default: true,
		},
		verticalPagination: {
			type: Boolean,
			default: false,
		},
		setCurrentPage: {
			type: Number,
			default: 1,
		},
		cover: {
			type: Boolean,
			default: true,
		},
		showHeading: {
			type: Boolean,
			default: true,
		},
		showButton: {
			type: Boolean,
			default: true,
		},
		showMoreButton: {
			type: Boolean,
			default: true,
		},
		sectionIndex: {
			type: Number,
			default: 0,
			required: false,
		},
	},
	data () {
		return {
			mediaQuery,
			loadedPages: {},
			loadedHighlights: [],
			pages: {},
			currentPage: 1,
		};
	},
	computed: {
		useMobileView () {
			return this.mediaQuery.current <= this.mediaQuery.sm;
		},
		useVerticalPagination () {
			if (this.verticalPagination) {
				return true;
			}
			return false;
		},
		computedHighlightsPerPage () {
			if (this.highlightsPerPage) {
				return this.highlightsPerPage;
			}
			return this.useMobileView ? 2 : 3;
		},
		computedNumberOfPages () {
			// Enabling a grid of 2 x 3 highlights on index and section page while in mobile view
			if ((this.$route.name === 'index' || this.$route.name === 'section') && this.useMobileView) {
				return 3;
			}
			return this.currentPage;
		},
		pageCount () {
			if (! this.paginate || ! this.highlightSection || ! this.highlightSection.highlights) {
				return 0;
			}
			return Math.ceil(this.highlightSection.highlights.length / this.computedHighlightsPerPage);
		},
		icon () {
			if (! this.highlightSection || ! this.highlightSection.color) {
				return null;
			}
			const color = '89B5D5';
			return convertToCdnUrl('/api/dynimg/category/' + this.highlightSection.icon + '/' + color);
		},
		scrollerStyle () {
			const left = -((this.currentPage - 1) * 100) + '%';
			return {
				left,
			};
		},
		/* Isolates new styling to only home and section pages */
		headerStyling () {
			return this.$route.name === 'section' || this.$route.name === 'index' ;
		},
		sectionPath () {
			if (this.highlightSection.path) {
				return this.highlightSection.path;
			} else if (this.highlightSection.id) {
				return this.$router.route(standardRoute('section', this.highlightSection));
			}
			return false;
		},
		showMoreText () {
			if (this.currentPage === 1) {
				return 'Visa fler kampanjer';
			} else if (this.currentPage === this.pageCount) {
				return 'Visa färre kampanjer';
			}
		},
		...mapGetters({
			isLoggedIn: 'user/isLoggedIn',
		}),
	},
	methods: {
		emitToXimg (component) {
			for (const child of component.$children) {
				if (child.$options.name === 'ximg') {
					child.$emit('inViewport:recalculate');
					continue;
				}
				this.emitToXimg(child);
			}
		},
		/**
		 * Gets the highlights of a specific page.
		 */
		highlights(page) {
			return this.highlightSection.highlights.slice(
				(page - 1) * this.computedHighlightsPerPage,
				page * this.computedHighlightsPerPage
			);
		},
		reset () {
			this.currentPage = this.setCurrentPage;
			this.pages = {};
			for (let page = 1; page <= this.pageCount; ++page) {
				this.pages[page] = this.highlights(page);
			}
		},
		/**
		 * Scroll the page so the show more/less buttons sit at the bottom of the page.
		 */
		scrollPage () {
			// Wait until the highlights have re-rendered.
			Vue.nextTick(() => {
				fixElementToBottomOfPage(this.$refs.moreLessButtons);
			});
		},
		toggleVisibleHighlights () {
			if (this.currentPage === 1) {
				this.currentPage++;
			} else if (this.currentPage === this.pageCount) {
				this.currentPage = 1;
			}
		},
	},
	beforeMount () {
		this.reset();
	},
	watch: {
		pageCount (to) {
			if (this.currentPage >= to) {
				this.currentPage = to - 1;
			}
		},
		currentPage (to) {
			if (to < 1) {
				this.currentPage = this.setCurrentPage;
			} else if (to >= this.pageCount) {
				this.currentPage = this.pageCount;
			}

			// If using vertical pagination, then scroll the page to the top.
			if (this.currentPage === 1 && this.useVerticalPagination) {
				this.scrollPage();
			}

			setTimeout(() => {
				for (const highlight of this.$refs.highlights) {
					this.emitToXimg(highlight);
				}
			}, 200);
		},
		useMobileView (useMobileView) {
			this.reset();
		},
	},
};
</script>
