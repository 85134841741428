<template>
	<div class="panel panel-product" :title="highlight.description">
		<router-link @click.native="click" :to="path" style="display: block; line-height: 0">
			<ximg
				:class="{ cover }"
				:src="imageUrl"
				:ariaLabel="highlight.manufacturer.name + ' manufacturer highlight'"
				role="img"
				:viewportMargin="1"
				@inViewport="$emit('inViewport')"
			/>
		</router-link>
		<router-link @click.native="click" :to="path" class="p-3 highlight-info relative">
			<div class="highlight-text mr-3">
				<span class="fake-link">{{ highlight.manufacturer.name }}</span>
				<span class="highlight-subtitle">{{ highlight.description || '&nbsp;' }}</span>
			</div>
		</router-link>
	</div>
</template>

<script>
import {convertToCdnUrl, routeFromHighlight} from "../../utils";

export default {
	name: 'basicHighlight',
	props: {
		highlight: {
			type: Object,
			required: true,
		},
		cover: {
			type: Boolean,
			default: true,
		},
	},
	data () {
		return {
			convertToCdnUrl,
			routeFromHighlight
		};
	},
	computed: {
		path () {
			return routeFromHighlight(this.highlight);
		},
		imageUrl () {
			const url = this.highlight.imageUrl || '/img/icons/no-image.svg';
			return convertToCdnUrl(url);
		},
	},
};
</script>
